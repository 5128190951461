import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input/TextArea';
import { ChangeEventHandler, useMemo } from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import {
  Tracking,
  useFormControllerTracking,
} from '@hooks/useFormControlledTracking';

const { TextArea } = Input;

type TextAreaControlledProps<T extends FieldValues> = {
  controllerProps: UseControllerProps<T>;
  transform?: (value: string) => string;
  tracking?: Tracking;
} & TextAreaProps;

export const CreoleUtils = {
  newLineTransform: (value: string) => value?.replace(/\\\\/gm, '\n'),
  newLineTransformReverse: (value: string) => value?.replace(/\n/gm, '\\\\'),
};

type CreoleState = {
  userState: string;
  transformedState: string;
  totalCharacters: number;
};
const getCreoleState = (value: string): CreoleState => {
  const userState = CreoleUtils.newLineTransform(value);
  const transformedState = CreoleUtils.newLineTransformReverse(value);
  const totalCharacters = transformedState?.length;

  return {
    userState,
    transformedState,
    totalCharacters,
  };
};

const CreoleEditor = <T extends FieldValues>({
  tracking,
  controllerProps,
  ...restInputProps
}: TextAreaControlledProps<T>) => {
  const { field: props } = useController(controllerProps);
  const { onFocus, onBlur } = useFormControllerTracking<T>({
    controllerProps: props,
    tracking,
  });
  const creoleState = useMemo(() => getCreoleState(props.value), [props.value]);

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    const newCreoleState = getCreoleState(event.target.value);
    props.onChange(newCreoleState.transformedState);
    restInputProps.onChange?.(event);
  };

  return (
    <TextArea
      {...props}
      {...restInputProps}
      onChange={handleChange}
      showCount={{
        formatter: ({ maxLength }) =>
          `${creoleState.totalCharacters} / ${maxLength}`,
      }}
      value={creoleState.userState}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};

export default CreoleEditor;
