// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iN2xkbQMqxAURcb6smqP .antd-5-18-2-tabs-tab {\n  margin-right: 20px !important;\n  padding-right: 0 !important;\n}\n.pQbsAkkzsij1wODasKaU {\n  position: relative;\n}\n.pQbsAkkzsij1wODasKaU .antd-5-18-2-ribbon-wrapper {\n  padding: 8px 52px 8px 16px;\n}\n.MVcqKIc1BLUlkdyeXRcF {\n  text-transform: uppercase;\n}\n", "",{"version":3,"sources":["webpack://./src/sections/Classifieds/AutoheroWKDA/Imperfections/ImperfectionsField/style.less"],"names":[],"mappings":"AACA;EAGM,6BAAA;EACA,2BAAA;AAFN;AAOA;EACE,kBAAA;AALF;AAIA;EAKM,0BAAA;AANN;AAWA;EACE,yBAAA;AATF","sourcesContent":["@antVersion: antd-5-18-2;\n.tabs {\n  :global {\n    .@{antVersion}-tabs-tab {\n      margin-right: 20px !important;\n      padding-right: 0 !important;\n    }\n  }\n}\n\n.tab {\n  position: relative;\n\n  :global {\n    .@{antVersion}-ribbon-wrapper {\n      padding: 8px 52px 8px 16px;\n    }\n  }\n}\n\n.tabTitle {\n  text-transform: uppercase;\n}\n\n@ant-prefix: antd-5-18-2;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": "iN2xkbQMqxAURcb6smqP",
	"tab": "pQbsAkkzsij1wODasKaU",
	"tabTitle": "MVcqKIc1BLUlkdyeXRcF"
};
export default ___CSS_LOADER_EXPORT___;
