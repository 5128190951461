import { useRef } from 'react';
import cls from 'classnames';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useImageFallback } from '@hooks/useImageFallback';
import { Image } from '@gql_codegen/retail-types';
import cn from './styles.less';

const PERCENTAGE = 100;

type ImageGalleryThumbnailProps = {
  item: Image;
  grayscale?: boolean;
};
const loaderIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const ImageGalleryThumbnail = ({
  item,
  grayscale,
}: ImageGalleryThumbnailProps) => {
  const { thumbnail, tags = [] } = item;
  const imageRef = useRef<HTMLImageElement>(null);
  const { handleError, handleLoad, loading } = useImageFallback({
    src: thumbnail,
    imageRef,
  });

  return (
    <div className={cn.imageContainer}>
      {!!thumbnail && (
        <img
          ref={imageRef}
          className={cls(cn.image, {
            [`${cn.imageLoading}`]: loading,
            [`${cn.__grayscale}`]: grayscale,
          })}
          src={thumbnail}
          alt="gallery item"
          onError={handleError}
          onLoad={handleLoad}
        />
      )}

      {(tags || []).map((tag) => {
        const {
          tagImageId,
          position: { top, left },
        } = tag;

        return (
          <span
            key={tagImageId}
            className={cn.tag}
            style={{
              top: `${top * PERCENTAGE}%`,
              left: `${left * PERCENTAGE}%`,
            }}
          />
        );
      })}
      {loading && (
        <span className={cn.loaderContainer}>
          <Spin indicator={loaderIcon} />
        </span>
      )}
    </div>
  );
};
