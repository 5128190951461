import type { TanStackRouterDevtools } from '@tanstack/router-devtools';

const checkItsNotProductionAnd = (defaultValue: boolean) =>
  __WEBPACK_DEV_SERVER__ && defaultValue;
type TanStackRouterDevtoolsOptions = Parameters<
  typeof TanStackRouterDevtools
>[0] & { show: boolean };
const tanstackRouterDevtools: TanStackRouterDevtoolsOptions = {
  show: checkItsNotProductionAnd(true),
};
export const devtoolsConfig = {
  tanstackRouterDevtools,
};
