import { useCallback } from 'react';
import { Checkbox, CheckboxProps } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { useAnalytics } from '@hooks/useAnalytics';
import { Tracking } from '@hooks/useFormControlledTracking';

type CheckboxControlledProps<T extends FieldValues> = {
  controllerProps: UseControllerProps<T>;
  tracking?: Tracking;
} & CheckboxProps;

const CheckboxControlled = <T extends FieldValues>({
  controllerProps,
  tracking,
  ...restCheckboxProps
}: CheckboxControlledProps<T>): JSX.Element => {
  const {
    field: { value, ...props },
  } = useController(controllerProps);
  const track = useAnalytics();
  const onChange = useCallback(
    (e: CheckboxChangeEvent) => {
      const oldValue = value;
      if (tracking) {
        track(
          {
            eventType: 'edit',
            fieldId: tracking.fieldId,
            section: tracking.section,
            eventCategory: tracking.eventCategory,
            sectionCategory: tracking.sectionCategory,
          },
          {
            oldValue,
            value: e.target.checked,
          },
        );
      }
      props.onChange(e);
    },
    [tracking, track, props, value],
  );
  return (
    <Checkbox
      {...props}
      {...restCheckboxProps}
      checked={value}
      onChange={onChange}
    />
  );
};

export default CheckboxControlled;
