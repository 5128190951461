import { Form } from 'antd';
import { useAppForm } from '@hooks/useAppForm';
import InputControlled from '@components/formControlled/InputControlled';
import { TRACKING_SECTION, TRACKING_SECTION_CATEGORY } from '@src/constants';
import cn from './styles.less';

export const MobileTitle = () => {
  const {
    formState: { errors },
  } = useAppForm();

  return (
    <div className={cn.mobileTitle}>
      <Form.Item
        data-qa-selector="classifieds-mobile-title"
        label="Mobile Title"
        validateStatus={
          errors.adMgmt?.mobiledeV2?.data?.title ? 'error' : 'success'
        }
        help={errors.adMgmt?.mobiledeV2?.data?.title?.message}
        className={cn.noMargin}
      >
        <InputControlled
          data-qa-selector="classifieds-mobile-title-input"
          placeholder="Title"
          controllerProps={{
            name: 'adMgmt.mobiledeV2.data.title',
          }}
          tracking={{
            eventCategory: 'modify',
            fieldId: 'Subtitle',
            section: TRACKING_SECTION.MOBILE_DESCRIPTION,
            sectionCategory: TRACKING_SECTION_CATEGORY.CL,
          }}
          maxLength={48}
          showCount
        />
      </Form.Item>
    </div>
  );
};
