import { useState, useCallback, useRef } from 'react';

type Coords = {
  top: number;
  left: number;
};

export const useScrollBack = () => {
  const [position, setPosition] = useState<Coords | null>(null);
  const documentRef = useRef(document.documentElement);

  const savePosition = useCallback(() => {
    if (documentRef.current) {
      setPosition({
        top: documentRef.current.scrollTop,
        left: documentRef.current.scrollLeft,
      });
    }
  }, [setPosition]);

  const backToPosition = useCallback(() => {
    if (position && documentRef.current) {
      documentRef.current.scrollTo(position.left, position.top);
    }
  }, [position]);

  return {
    position,
    savePosition,
    backToPosition,
  };
};
