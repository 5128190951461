import { useState, useCallback, useMemo, useEffect, ChangeEvent } from 'react';
import { Button, Space, Row, Col } from 'antd';
import ImageGallery, { ReactImageGalleryProps } from 'react-image-gallery';
import {
  CloudDownloadOutlined,
  BranchesOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { FileDropZone } from '@components/FileDropZone';
import { Document } from '@src/types/serviceHistoryDocument';
import { ImageGalleryItem } from './ImageGalleryItem';
import { ImageThumbnail } from '../ImageThumbnail';
import { ImageGallerySortModal } from './ImageGallerySortModal';
import { useUpload } from './hooks/useUpload';
import { useDownload } from './hooks/useDownload';
import cn from './styles.less';

type ImageGalleryFieldProps = {
  documents: Document[];
  onChange: (documents: Document[]) => void;
  onLoading?: (isLoading: boolean) => void;
  noButtons?: boolean;
  qaSelector?: string;
  title?: string;
  galleryProps?: Partial<ReactImageGalleryProps>;
  focusOn?: string | null | undefined;
};

export const ImageGalleryField = ({
  documents,
  onChange,
  focusOn,
  title,
  galleryProps,
  qaSelector,
  onLoading,
  noButtons = false,
}: ImageGalleryFieldProps): JSX.Element => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isImageSortModalVisible, setIsImageSortModalVisible] = useState(false);
  const { upload, isLoading: isUploading } = useUpload();
  const { download, isLoading: isDownloading } = useDownload();

  const isLoading = isUploading || isDownloading;

  useEffect(() => {
    onLoading?.(isUploading);
  }, [isUploading, onLoading]);

  const mappedDocuments = useMemo(
    () =>
      documents?.map((item) => ({
        ...item,
        original: item.url,
      })),
    [documents],
  );
  let startIndex = documents
    ? documents.findIndex((document) => document.id === focusOn)
    : -1;

  if (startIndex === -1) {
    startIndex = 0;
  }

  const handleItemsChange = useCallback(
    (items: Document[]) => onChange(items),
    [onChange],
  );

  const handleSortModalCancel = useCallback(() => {
    setIsImageSortModalVisible(false);
  }, []);

  const handleRemove = useCallback(
    (id: string) =>
      onChange(documents.filter((document) => document.id !== id)),
    [onChange, documents],
  );

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const uploaded = await upload(event.target.files);
    onChange(documents.concat(uploaded));
  };

  const handleDownload = useCallback(async () => {
    download(documents, 'service-history');
  }, [download, documents]);

  const renderThumbInner = (document: Document) => (
    <div className={cn.thumbnailWrapper} key={document.id}>
      <ImageThumbnail document={document} />
    </div>
  );

  const renderItem = (document: Document) => {
    return (
      <ImageGalleryItem
        key={document.id}
        noButtons={noButtons}
        item={document}
        onRemove={() => handleRemove(document.id)}
        isFullScreen={isFullScreen}
      />
    );
  };

  return (
    <Row
      gutter={[4, 4]}
      data-qa-selector={qaSelector || 'image-gallery-wrapper'}
    >
      <ImageGallerySortModal
        show={isImageSortModalVisible}
        handleCancel={handleSortModalCancel}
        items={documents || []}
        onItemsChange={(items) => handleItemsChange(items)}
      />
      <Col span={24}>{title && <h3>{title}</h3>}</Col>
      <Col span={24}>
        {!noButtons && (
          <Space wrap size={4}>
            <FileDropZone multiple onChange={handleUpload}>
              <Button
                htmlType="button"
                icon={<UploadOutlined />}
                type="primary"
                data-qa-selector="galleryUpload"
                loading={isUploading}
                disabled={isLoading}
              >
                Upload
              </Button>
            </FileDropZone>
            {documents?.length > 0 && (
              <>
                <Button
                  htmlType="button"
                  icon={<BranchesOutlined />}
                  type="primary"
                  onClick={() => setIsImageSortModalVisible(true)}
                  data-qa-selector="gallerySorting"
                  disabled={isLoading}
                >
                  Sort
                </Button>
                <Button
                  htmlType="button"
                  icon={<CloudDownloadOutlined />}
                  type="primary"
                  onClick={handleDownload}
                  data-qa-selector="galleryDownload"
                  loading={isDownloading}
                  disabled={isLoading}
                >
                  Download
                </Button>
              </>
            )}
          </Space>
        )}
      </Col>
      <Col span={24}>
        {documents?.length > 0 ? (
          <div
            data-qa-selector="image-gallery-section"
            className="serviceHistoryDocumentsGallery"
          >
            <ImageGallery
              showIndex
              showNav={true}
              showPlayButton={false}
              items={mappedDocuments}
              startIndex={startIndex}
              onScreenChange={(fullScreen) => setIsFullScreen(fullScreen)}
              // FIX-ME: type mismatch ReactImageGalleryItem and Image
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              renderItem={(item) => renderItem(item as Document)}
              // FIX-ME: type mismatch ReactImageGalleryItem and Image
              renderThumbInner={(item) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return renderThumbInner(item as Document);
              }}
              renderFullscreenButton={(onClick, isFullScreen) => (
                <button
                  type="button"
                  onClick={onClick}
                  className={`image-gallery-fullscreen-button ${
                    isFullScreen ? 'active' : ''
                  }`}
                  data-qa-selector="service-history-gallery-fullscreen"
                />
              )}
              {...galleryProps}
            />
          </div>
        ) : (
          <div
            data-qa-selector="image-gallery-empty-section"
            className={cn.noImagePlaceHolder}
          >
            <h3 data-qa-selector="image-gallery-empty-section-text">
              No images
            </h3>
          </div>
        )}
      </Col>
    </Row>
  );
};
