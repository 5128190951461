import { PricingChangelogItem } from '@gql_codegen/retail-types';
import { formatDateTime } from '@utils/date';
import { ColumnsType } from 'antd/lib/table';
import { PriceChangeReason } from '@sections/PricingChangelog/PriceChangeReason';

export const PRICING_CHANGELOG_COLUMNS: ColumnsType<PricingChangelogItem> = [
  {
    title: 'Changed By',
    dataIndex: 'changedBy',
    key: 'changedBy',
  },
  {
    title: 'Stock Number',
    dataIndex: 'stockNumber',
    key: 'stockNumber',
  },
  {
    title: 'Changed At',
    dataIndex: 'changedAt',
    key: 'changedAt',
    render: (changedAt) => {
      if (!changedAt) {
        return;
      }
      return formatDateTime(changedAt);
    },
  },
  {
    title: 'Previous Price',
    dataIndex: 'previousPrice',
    key: 'previousPrice',
  },
  {
    title: 'New Price',
    dataIndex: 'newPrice',
    key: 'newPrice',
  },
  {
    title: 'Price Change Reason',
    dataIndex: 'changeReason',
    key: 'changeReason',
    render: (changeReason, item) => (
      <PriceChangeReason
        changeReason={changeReason}
        changeComment={item.changeComment}
      />
    ),
  },
];
