import { useState } from 'react';
import {
  ImperfectionItem,
  ImperfectionItemPartInput,
  ImperfectionItemTypeInput,
  Image,
  TagInput,
  Tag,
} from '@gql_codegen/retail-types';
import { usePageData } from '@hooks/usePageData';
import { ImageRotator } from '@sections/Classifieds/AutoheroWKDA/Imperfections/ImperfectionsField/ImageRotator';
import { useAppForm } from '@hooks/useAppForm';
import { useController } from 'react-hook-form';
import { Button, Col, Row, Tabs, Modal } from 'antd';
import {
  COUNTRY_CODES,
  GALLERY_MODES,
  TAGGABLE_IMAGE_TYPES,
  TGalleryModes,
  AUTOHERO_IMAGE_TYPES,
  TRACKING_SECTION,
  TRACKING_SECTION_CATEGORY,
} from '@src/constants';
import { ImageGalleryThumbnail } from '@components/ImageGalleryField/ImageGalleryThumbnail';
import { ImageGalleryField } from '@components/ImageGalleryField';
import cn from './styles.less';
import { CloseOutlined } from '@ant-design/icons';
import { useAnalytics } from '@hooks/useAnalytics';

const MODAL_TITLES = {
  [GALLERY_MODES.CREATE_TAG]: 'Click on image to create a new tag',
  [GALLERY_MODES.EDIT_TAG]: 'Move tag by drag and drop',
} as const;

type ImperfectionItemProps = {
  imperfection: ImperfectionItem;
  id: string;
  onChange(imperfection: ImperfectionItem): void;
  getTaggedItem(imperfectionItemId: string): {
    type: 'exterior' | 'interior' | 'nextgen_highlight' | undefined;
    image: Image | undefined;
  };
};

const ImperfectionItemWrapper = ({
  imperfection,
  onChange,
  getTaggedItem,
}: ImperfectionItemProps) => {
  const { data } = usePageData();
  const { restrictions } = data?.adMgmt?.autoheroAndWkda?.media || {};
  const {
    part,
    imperfectionType,
    image: { id: imperfectionImageId },
  } = imperfection;
  const { control, getValues } = useAppForm();
  const {
    field: { onChange: imagesOnChange },
  } = useController({
    control,
    name: 'adMgmt.autoheroAndWkda.media.data.images',
  });
  const {
    field: { value: imperfections, onChange: imperfectionsOnChange },
  } = useController({
    control,
    name: 'adMgmt.autoheroAndWkda.imperfections.items',
  });
  const [tagModalMode, setTagModalMode] = useState<TGalleryModes | null>(null);
  const track = useAnalytics();

  const handleRotate = async (imageUrl: string) => {
    const newImage = {
      ...imperfection.image,
      original: imageUrl,
      thumbnail: imageUrl,
    };
    onChange({
      ...imperfection,
      image: newImage,
    });
    track(
      {
        eventType: 'rotate',
        eventCategory: 'modify',
        fieldId: 'Imperfections_FRONT',
        section: TRACKING_SECTION.IMPERFECTIONS,
        sectionCategory: TRACKING_SECTION_CATEGORY.AH,
      },
      {
        image: {
          id: newImage.id,
          photoId: newImage.photoId,
          tags: newImage.tags,
          uploadedUrl: newImage.uploadedUrl,
        },
      },
    );
  };

  const isBE = (
    part: ImperfectionItemPartInput | ImperfectionItemTypeInput,
  ) => {
    return part?.locale?.split('_')[1] === COUNTRY_CODES.BE;
  };

  const renderCarPart = part.map((item: ImperfectionItemPartInput, index) => {
    return (
      <div key={index}>
        {isBE(item) ? `${item.locale}: ` : ''}
        {item.text}
      </div>
    );
  });

  const renderImperfectionType = imperfectionType.map(
    (item: ImperfectionItemTypeInput, index) => {
      return (
        <div key={index}>
          {isBE(item) ? `${item.locale}: ` : ''}
          {item.text}
        </div>
      );
    },
  );

  const handleRemoveTag = () => {
    const value = getValues('adMgmt.autoheroAndWkda.media.data.images') || {};
    const updates = TAGGABLE_IMAGE_TYPES.reduce((acc, type) => {
      // FIX-ME using this MediaImages type says there is not index signature
      // need to find a way how types works here. Please remove any and see errors
      const targetGallery = value[type];

      const galleryWithoutTag = targetGallery.map((image) => {
        return {
          ...image,
          tags: (image?.tags || []).filter(
            (tag) => tag.tagImageId !== imperfectionImageId,
          ),
        };
      });

      return {
        ...acc,
        [type]: galleryWithoutTag,
      };
    }, {});

    const newValue = {
      ...value,
      ...updates,
    };
    imagesOnChange({ ...newValue });
    track(
      {
        eventType: 'delete',
        eventCategory: 'modify',
        section: TRACKING_SECTION.IMPERFECTIONS,
        sectionCategory: TRACKING_SECTION_CATEGORY.AH,
        fieldId: 'Imperfections_FRONT_TAG',
      },
      {
        value: null,
        oldValue: TAGGABLE_IMAGE_TYPES.reduce<Tag[]>((acc, type) => {
          const targetGallery = value[type];
          targetGallery.forEach((image) => {
            acc = [
              ...acc,
              ...(image.tags?.filter(
                (tag) => tag.tagImageId === imperfectionImageId,
              ) || []),
            ];
          });
          return acc;
        }, []),
      },
    );
  };

  const handleEditTag = () => {
    setTagModalMode(GALLERY_MODES.EDIT_TAG);
  };

  const handleCreateTag = () => {
    setTagModalMode(GALLERY_MODES.CREATE_TAG);
  };

  const handleCancelTagModel = () => {
    setTagModalMode(null);
  };

  const handleTagCreated = (tag: TagInput) => {
    const updated = imperfections?.map((item) => ({
      ...item,
      image: {
        ...item.image,
        tags:
          item.image.id === tag.tagImageId
            ? [
                ...(item.image.tags?.filter(
                  (item) => item.tagImageId !== tag.tagImageId,
                ) ?? []),
                tag,
              ]
            : item.image.tags?.filter(
                (item) => item.tagImageId !== tag.tagImageId,
              ) ?? [],
      },
    }));
    imperfectionsOnChange(updated);
  };

  const taggedItem = getTaggedItem(imperfectionImageId);

  return (
    <>
      <Col span={5}>
        <ImageRotator
          disabled={!restrictions?.canRotateImperfectionImage}
          imageUrl={imperfection.image.original || ''}
          onRotate={handleRotate}
        />
      </Col>

      <Col span={12}>
        <Row className={cn.imperfectionInfo} gutter={[0, 30]}>
          <Col span={8}>Car Part:</Col>
          <Col span={16} data-qa-selector="imperfection-car-part">
            {renderCarPart}
          </Col>
          <Col span={8}>Damage Type:</Col>
          <Col span={16} data-qa-selector="imperfection-type">
            {renderImperfectionType}
          </Col>
        </Row>
      </Col>
      <Col span={6}>
        {taggedItem.type ? (
          <div className={cn.right}>
            <div data-qa-selector="tag-editor-thumbnail" className={cn.tagView}>
              <ImageGalleryThumbnail item={taggedItem.image as Image} />
            </div>
            <div
              data-qa-selector="edit-and-remove-tag-button"
              className={cn.actions}
            >
              <Button
                type="primary"
                onClick={handleEditTag}
                disabled={!restrictions?.canEditTags}
                data-qa-selector="imperfections-edit-tag-button"
              >
                Edit Tag
              </Button>
              <Button
                type="primary"
                onClick={handleRemoveTag}
                disabled={!restrictions?.canRemoveTags}
                danger
                data-qa-selector="imperfections-remove-tag-button"
              >
                Remove Tag
              </Button>
            </div>
          </div>
        ) : (
          <div data-qa-selector="create-tag-button" className={cn.actions}>
            <Button
              type="primary"
              onClick={handleCreateTag}
              data-qa-selector="imperfections-create-tag-button"
            >
              Create Tag
            </Button>
          </div>
        )}
      </Col>
      {!!tagModalMode && (
        <Modal
          title={MODAL_TITLES[tagModalMode]}
          open={!!tagModalMode}
          onCancel={handleCancelTagModel}
          footer={false}
          width={600}
          closeIcon={
            <CloseOutlined data-qa-selector="imperfection-modal-close-button" />
          }
          data-qa-selector="imperfection-modal"
        >
          <Tabs
            destroyInactiveTabPane={true}
            defaultActiveKey={taggedItem.type || AUTOHERO_IMAGE_TYPES.EXTERIOR}
          >
            <Tabs.TabPane tab={'Exterior'} key="exterior">
              <ImageGalleryField
                noButtons
                type={AUTOHERO_IMAGE_TYPES.EXTERIOR}
                name={`adMgmt.autoheroAndWkda.media.data.images.exterior`}
                focusOnImageId={
                  taggedItem.type === AUTOHERO_IMAGE_TYPES.EXTERIOR
                    ? taggedItem?.image?.id
                    : null
                }
                itemProps={{
                  mode: tagModalMode,
                  imperfectionImageId: imperfection.image.id,
                }}
                onTagCreated={handleTagCreated}
                tracking={{
                  fieldId: 'Imperfections_FRONT_TAG',
                  section: TRACKING_SECTION.IMPERFECTIONS,
                  sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                }}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab={'Interior'} key="interior">
              <ImageGalleryField
                noButtons
                type={AUTOHERO_IMAGE_TYPES.INTERIOR}
                name={`adMgmt.autoheroAndWkda.media.data.images.interior`}
                focusOnImageId={
                  taggedItem.type === AUTOHERO_IMAGE_TYPES.INTERIOR
                    ? taggedItem?.image?.id
                    : null
                }
                itemProps={{
                  mode: tagModalMode,
                  imperfectionImageId: imperfection.image.id,
                }}
                onTagCreated={handleTagCreated}
                tracking={{
                  fieldId: 'Imperfections_FRONT_TAG',
                  section: TRACKING_SECTION.IMPERFECTIONS,
                  sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                }}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab={'Highlights'} key="nextgen_highlight">
              <ImageGalleryField
                noButtons
                type={AUTOHERO_IMAGE_TYPES.HIGHLIGHTS}
                name={`adMgmt.autoheroAndWkda.media.data.images.nextgen_highlight`}
                focusOnImageId={
                  taggedItem.type === AUTOHERO_IMAGE_TYPES.HIGHLIGHTS
                    ? taggedItem?.image?.id
                    : null
                }
                itemProps={{
                  mode: tagModalMode,
                  imperfectionImageId: imperfection.image.id,
                }}
                onTagCreated={handleTagCreated}
                tracking={{
                  fieldId: 'Imperfections_FRONT_TAG',
                  section: TRACKING_SECTION.IMPERFECTIONS,
                  sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                }}
              />
            </Tabs.TabPane>
          </Tabs>
        </Modal>
      )}
    </>
  );
};

export default ImperfectionItemWrapper;
