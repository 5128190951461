import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input/TextArea';
import { ChangeEventHandler, useMemo } from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import {
  Tracking,
  useFormControllerTracking,
} from '@hooks/useFormControlledTracking';

const { TextArea } = Input;

type TextAreaControlledProps<T extends FieldValues> = {
  controllerProps: UseControllerProps<T>;
  transform?: (value: string) => string;
  tracking?: Tracking;
} & TextAreaProps;

export const AS24EditorUtils = {
  newLineTransform: (value: string) => value?.replace(/\\\\\*/gm, '\n*'),
  newLineTransformReverse: (value: string) =>
    value?.replace(/\n\*(?!\*)/gm, '\\\\*'),
};

type EditorState = {
  userState: string;
  transformedState: string;
  totalCharacters: number;
};
const getEditorState = (value: string): EditorState => {
  const userState = AS24EditorUtils.newLineTransform(value);
  const transformedState = AS24EditorUtils.newLineTransformReverse(value);
  const totalCharacters = transformedState?.length;

  return {
    userState,
    transformedState,
    totalCharacters,
  };
};

const AS24Editor = <T extends FieldValues>({
  tracking,
  controllerProps,
  ...restInputProps
}: TextAreaControlledProps<T>) => {
  const { field: props } = useController(controllerProps);
  const { onFocus, onBlur } = useFormControllerTracking<T>({
    controllerProps: props,
    tracking,
  });
  const EditorState = useMemo(() => getEditorState(props.value), [props.value]);

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    const newEditorState = getEditorState(event.target.value);
    props.onChange(newEditorState.transformedState);
    restInputProps.onChange?.(event);
  };

  return (
    <TextArea
      {...props}
      {...restInputProps}
      onChange={handleChange}
      showCount={{
        formatter: ({ maxLength }) =>
          `${EditorState.totalCharacters} / ${maxLength}`,
      }}
      value={EditorState.userState}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};

export default AS24Editor;
