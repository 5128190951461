// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zWzsy_dge3N_uHfMLxai {\n  margin-right: 5px;\n  margin-bottom: 5px;\n  cursor: pointer;\n  color: #ffffff;\n  font-size: 35px;\n  line-height: 0;\n}\n.zWzsy_dge3N_uHfMLxai:hover {\n  -webkit-transform: scale(1.1);\n          transform: scale(1.1);\n}\n", "",{"version":3,"sources":["webpack://./src/sections/Classifieds/AutoheroWKDA/Imperfections/ImperfectionsField/FullScreen/styles.less"],"names":[],"mappings":"AAGA;EACE,iBAAA;EACA,kBAAA;EAEA,eAAA;EAEA,cAAA;EAEA,eAAA;EAEA,cAAA;AANF;AAQE;EACE,6BAAA;UAAA,qBAAA;AANJ","sourcesContent":["@antVersion: antd-5-18-2;\n@import 'src/styles/colors.less';\n\n.button {\n  margin-right: 5px;\n  margin-bottom: 5px;\n\n  cursor: pointer;\n\n  color: @white;\n\n  font-size: 35px;\n\n  line-height: 0;\n\n  &:hover {\n    transform: scale(1.1);\n  }\n}\n\n@ant-prefix: antd-5-18-2;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "zWzsy_dge3N_uHfMLxai"
};
export default ___CSS_LOADER_EXPORT___;
