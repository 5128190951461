import { Row, Col, Collapse } from 'antd';
import { usePageData } from '@hooks/usePageData';
import { Autoscout24Title } from './Autoscout24Title';
import { MobileTitle } from './MobileTitle';
import { GenerateDescriptionButton } from './GenerateDescriptionButton';
import { Autoscout24Description } from './Autoscout24Description';
import { MobileDescription } from './MobileDescription';
import cn from './styles.less';

const { Panel } = Collapse;

export const Description = () => {
  const { data, isLoading } = usePageData();

  if (isLoading || !data) return <></>;

  const { autoscout24, mobiledeV2 } = data.adMgmt;

  return (
    <Row
      gutter={[16, 16]}
      data-qa-selector="classifieds-description"
      className={cn.description}
    >
      {autoscout24?.data ? (
        <Col span={24} data-qa-selector="classifieds-title-autoscout24-panel">
          <Autoscout24Title />
        </Col>
      ) : null}
      {mobiledeV2?.data ? (
        <Col span={24} data-qa-selector="classifieds-title-mobile-panel">
          <MobileTitle />
        </Col>
      ) : null}
      {autoscout24?.data || mobiledeV2?.data ? (
        <Col span={24}>
          <Collapse>
            <Panel header="Description" key="description">
              <Row gutter={[16, 16]}>
                <Col
                  span={24}
                  data-qa-selector="classifieds-description-generate-description-panel"
                >
                  <GenerateDescriptionButton />
                </Col>
                {autoscout24?.data ? (
                  <Col
                    span={24}
                    data-qa-selector="classifieds-description-autoscout24-panel"
                  >
                    <Autoscout24Description />
                  </Col>
                ) : null}
                {mobiledeV2?.data ? (
                  <Col
                    span={24}
                    data-qa-selector="classifieds-description-mobile-panel"
                  >
                    <MobileDescription />
                  </Col>
                ) : null}
              </Row>
            </Panel>
          </Collapse>
        </Col>
      ) : null}
    </Row>
  );
};
