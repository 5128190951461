import { notification } from 'antd';
import { useAppForm } from '@hooks/useAppForm';
import {
  usePublishAdMutation,
  useUnpublishAdMutation,
  useGetExportItemsQuery,
} from '@gql_codegen/retail-types';
import { useState } from 'react';
import { POSSIBLE_PUBLISHING_STATUSES } from '@src/constants';
import { UseFormReset, useFormState } from 'react-hook-form';
import { PublishStatusChecking } from '.';
import { formatServerErrors } from '@utils/formatServerErrors';
import { retailKibanaLogger } from '@utils/logger';

export type PublishData = {
  marketplaceId: string;
  accountIds: string[];
};

export const pollExportItems = (
  adMgmt: any, // TODO use correct typing after gql schema cleanup
  reset: UseFormReset<any>,
  setCommonPublishingStatus: PublishStatusChecking,
  setStatus: (status: boolean) => void,
) => {
  const getExportItems: () => void = async () => {
    try {
      const response = await useGetExportItemsQuery.fetcher({
        adId: adMgmt.id,
      })();
      const exportItems = response.adMgmt.exportOverview.exportItems;

      const hasInProgress = exportItems.some(
        (item) =>
          item.publishingStatus === POSSIBLE_PUBLISHING_STATUSES.IN_PROGRESS,
      );
      if (!hasInProgress) {
        global.clearTimeout(timeoutId);
        reset({
          adMgmt: {
            ...adMgmt,
            exportOverview: {
              ...adMgmt.exportOverview,
              exportItems: exportItems,
            },
          },
        });
        setCommonPublishingStatus({ isStatusChecking: false, platform: '' });
        setStatus(false);
      } else {
        timeoutId = global.setTimeout(getExportItems, 500);
      }
    } catch (e) {
      global.clearTimeout(timeoutId);
      setCommonPublishingStatus({ isStatusChecking: false, platform: '' });
      setStatus(false);
      notification.error({
        message: 'Failed to get publishing statuses',
      });
      retailKibanaLogger.warn('Publishing status polling failed', e);
    }
  };
  let timeoutId = global.setTimeout(getExportItems, 500);
};

export const usePublish: (
  setCommonPublishingStatus: PublishStatusChecking,
) => [
  boolean,
  ({ marketplaceId, accountIds }: PublishData) => Promise<void>,
] = (setCommonPublishingStatus) => {
  const { getValues, reset, trigger } = useAppForm();

  const publishMutation = usePublishAdMutation();
  const [isPublishing, setStatus] = useState(false);

  const { isDirty } = useFormState();
  const publish = async ({ marketplaceId, accountIds }: PublishData) => {
    const adMgmt = getValues('adMgmt');

    if (isDirty) {
      notification.error({
        message: 'Please, save changes before publishing the Ad',
      });
    } else {
      const isValid = await trigger();

      if (!isValid) {
        notification.error({
          message: 'Please, fix validation errors first',
        });
      } else {
        setStatus(true);
        setCommonPublishingStatus({
          isStatusChecking: true,
          platform: marketplaceId,
        });
        try {
          await publishMutation.mutateAsync({
            adId: adMgmt.id,
            marketplaceId,
            accountIds,
          });
          pollExportItems(adMgmt, reset, setCommonPublishingStatus, setStatus);
        } catch (e: any) {
          setStatus(false);
          setCommonPublishingStatus({
            isStatusChecking: false,
            platform: '',
          });
          notification.error({
            message: formatServerErrors(e.errors),
          });
          retailKibanaLogger.warn('Publishing Failed', e);
        }
      }
    }
  };
  return [isPublishing, publish];
};

export const useUnpublish: (
  setCommonPublishingStatus: PublishStatusChecking,
) => [
  boolean,
  ({ marketplaceId, accountIds }: PublishData) => Promise<void>,
] = (setCommonPublishingStatus) => {
  const unpublishMutation = useUnpublishAdMutation();
  const [isUnpublishing, setStatus] = useState(false);
  const { getValues, reset } = useAppForm();

  const unpublish = async ({ marketplaceId, accountIds }: PublishData) => {
    const adMgmt = getValues('adMgmt');
    setStatus(true);
    setCommonPublishingStatus({
      isStatusChecking: true,
      platform: marketplaceId,
    });
    try {
      await unpublishMutation.mutateAsync({
        adId: adMgmt.id,
        marketplaceId,
        accountIds,
      });
      pollExportItems(adMgmt, reset, setCommonPublishingStatus, setStatus);
    } catch (e: any) {
      setStatus(false);
      setCommonPublishingStatus({
        isStatusChecking: false,
        platform: '',
      });
      notification.error({
        message: formatServerErrors(e.errors),
      });
      retailKibanaLogger.warn('Unpublishing Failed', e);
    }
  };
  return [isUnpublishing, unpublish];
};

export const useHasPublishingBlockers = () => {
  const { getValues } = useAppForm();
  const publishinbBlockersFormState =
    getValues('adMgmt.exportOverview.data.publishingBlockers') ?? [];
  return publishinbBlockersFormState.length > 0;
};

export const useIsPublished = (marketplaceId: string) => {
  const { getValues } = useAppForm();
  const exportItems = getValues('adMgmt.exportOverview.exportItems') ?? [];
  const exportData = exportItems.find(
    (item) => item.marketplace.id.toUpperCase() === marketplaceId,
  );

  return exportData
    ? exportData.publishingStatus === POSSIBLE_PUBLISHING_STATUSES.PUBLISHED
    : false;
};
