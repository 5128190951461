import cn from './styles.less';

type TextBoxProps = {
  children: React.ReactNode;
  qaSelector?: string;
};
export const TextBox = ({ children, qaSelector = '' }: TextBoxProps) => {
  return (
    <div data-qa-selector={qaSelector} className={cn.text}>
      {children}
    </div>
  );
};
