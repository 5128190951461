import { appAnalytics } from '@utils/analytics';
import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import getCookieValue from './getCookieValue';

const getAccessToken = () => getCookieValue('t');

interface TokenFailResponse {
  timestamp: string;
  status: number;
  error: string;
  exception: string;
  message: string;
  trace: string;
  error_description: string;
}
interface TokenSuccessResponse {
  access_token: string;
  token_type: string;
  refresh_token: string;
  expires_in: number;
  scope: string;
  user_uuid: string;
  user_roles: string[];
  user_chksum: string;
  jti: string;
}

const isTokenRecieved = (
  res: TokenFailResponse | TokenSuccessResponse,
): res is TokenSuccessResponse => {
  return 'access_token' in res;
};

export async function getRefreshToken() {
  const token = getCookieValue('t');

  try {
    const response = await fetch('/api/v1/auth/oauth/token', {
      headers: {
        accept: 'application/json',
        'content-type': 'application/x-www-form-urlencoded',
      },
      referrerPolicy: 'strict-origin-when-cross-origin',
      body: `client_id=back_office&refresh_token=${token}&grant_type=refresh_token`,
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
    });
    const responseJson = await response.json();

    if (!isTokenRecieved(responseJson)) {
      throw new Error('no token recieved');
    }
    const { refresh_token, access_token } = responseJson;

    document.cookie = `t=${access_token};`;
    document.cookie = `refresh_token=${refresh_token};`;
  } catch (error) {
    console.error('axiosInstance -> getRefreshToken error:', error);
  }
}

function refreshToken() {
  let count = 0;
  return function () {
    console.log('axiosInstance -> refreshToken: count', count);

    if (count >= 1) {
      return;
    }
    count += 1;
    // DO api request.
    return getRefreshToken();
  };
}

const axiosInstance = axios.create({
  baseURL: '/',
  headers: {
    accept: 'application/json, text/plain, */*',
    'access-domain': 'ad.management',
    authorization: `Bearer ${getAccessToken()}`, //TODO: fix possible "Bearer null"
    'content-type': 'application/json;charset=UTF-8',
  },
  method: 'POST',
});

// before each request
axiosInstance.interceptors.request.use(
  (config): InternalAxiosRequestConfig => {
    // Do something before request is sent
    const token = getAccessToken();

    if (token !== null && config.headers?.['authorization']) {
      config.headers['authorization'] = `Bearer ${token}`;
    }

    appAnalytics.trackEvent({
      name: 'backendSuccess',
      props: {
        ...appAnalytics.EMPTY_EVENT,
        section: 'BACKEND',
      },
    });

    // always return the config otherwise the request will not be sent
    return config;
  },
  (error) => {
    console.log(error);
    appAnalytics.trackEvent({
      name: 'backendFail',
      props: {
        ...appAnalytics.EMPTY_EVENT,
        eventType: 'BACKEND',
        meta: {
          errorCode: error.response.status,
          message: error.message,
          traceId: error.headers['x-b3-traceid'] ?? '',
        },
      },
    });
    Promise.reject(error);
  },
);
export const callRefreshToken = refreshToken();

// Intercept errors
export const errorInterceptor = async <T>(err: AxiosError<T>) => {
  if (err.response && __WEBPACK_DEV_SERVER__) {
    if (err.response.status === 401) {
      await callRefreshToken();
    }
  } else if (err.request) {
    // The request was made but no response was received
    // `err.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(
      'AxiosInstance-> errorInterceptor error with request:',
      err.request,
    );
    appAnalytics.trackEvent({
      name: 'backendFail',
      props: {
        ...appAnalytics.EMPTY_EVENT,
        eventType: 'BACKEND',
        meta: {
          errorCode: err?.response?.status || 501,
          message: err?.message || '',
          traceId: err?.response?.headers?.['x-b3-traceid'] || '',
        },
      },
    });
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log(
      'AxiosInstance-> errorInterceptor error with message:',
      err.message,
    );
  }
  throw err;
};

axiosInstance.interceptors.response.use((response) => {
  // Check if the 'tracing' property exists, and if not, initialize it as an empty object
  if (!response.data.extensions) {
    response.data.extensions = {};
  }

  response.data.extensions.traceId = response?.headers?.['x-b3-traceid'] ?? '';

  return response;
}, errorInterceptor);

export default axiosInstance;
