import useFullScreen from './useFullScreen';
import cn from './styles.less';
import { FullscreenOutlined } from '@ant-design/icons';

type FullScreenProps = {
  target: HTMLImageElement;
};

const FullScreen = ({ target }: FullScreenProps) => {
  const [, toggleFullScreen] = useFullScreen(target);

  return (
    <div
      onClick={toggleFullScreen}
      className={cn.button}
      data-qa-selector="fullscreen-button"
    >
      <FullscreenOutlined />
    </div>
  );
};

export default FullScreen;
