import { Image } from '@gql_codegen/retail-types';
import classNames from 'classnames';
import { useState } from 'react';
import cn from './styles.less';
import { ZoomWithUI } from './Zoom';

type ImageSliderProps = { images: Image[] };
export const ImageSlider = (props: ImageSliderProps) => {
  const [currentIdx, setIdx] = useState(0);

  const handleThumbnailClick = (idx: number) => setIdx(idx);
  const currentImage = props.images[currentIdx];

  if (!currentImage) return <>something went wrong</>;

  return (
    <div className={cn.imageSlider} data-qa-selector="all-images-gallery">
      <div className={cn.main}>
        <ZoomWithUI>
          <img src={currentImage.original} />
        </ZoomWithUI>
      </div>

      <div className={cn.thumbnail}>
        {props.images.map((image, idx) => (
          <img
            key={image.id}
            src={image.thumbnail}
            onClick={() => handleThumbnailClick(idx)}
            className={classNames(idx === currentIdx && cn.active_img)}
          />
        ))}
      </div>
    </div>
  );
};
