import { useReducer } from 'react';
import { CheckOutlined, CloseOutlined, EditTwoTone } from '@ant-design/icons';
import { FeatureDetailTranslation } from '@gql_codegen/retail-types';
import { useAnalytics } from '@hooks/useAnalytics';
import { TRACKING_SECTION, TRACKING_SECTION_CATEGORY } from '@src/constants';
import { Button, Form, Input } from 'antd';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';

type EditEquipmentProps = {
  name: string;
  translation: FeatureDetailTranslation;
  initialTranslation?: FeatureDetailTranslation | undefined;
  onEdit: (translation: FeatureDetailTranslation) => void;
  editMode?: boolean | undefined;
  onEditClick: () => void;
  onCancelClick: () => void;
  hasError?: boolean;
  errorText?: string | undefined;
  validator?: (value: string) => string | undefined;
  disabled?: boolean;
};

export const EditEquipment = (props: EditEquipmentProps): JSX.Element => {
  const [antdForm] = Form.useForm<{ translation: string }>();
  const track = useAnalytics();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const showForm = () => {
    antdForm.resetFields();
    track(
      {
        eventType: 'click',
        eventCategory: 'modify',
        fieldId: 'Equipment_Translation',
        section: TRACKING_SECTION.AS24_EQUIPMENT,
        sectionCategory: TRACKING_SECTION_CATEGORY.CL,
      },
      null,
    );
    props.onEditClick();
  };

  const hideForm = () => {
    props.onCancelClick();
  };

  const onFinish = (values: { translation: string }) => {
    track(
      {
        eventType: 'edit',
        eventCategory: 'modify',
        fieldId: 'Equipment_Name_translated',
        section: TRACKING_SECTION.EQUIPMENT,
        sectionCategory: TRACKING_SECTION_CATEGORY.AH,
      },
      null,
    );
    props.onEdit({
      locale: props.translation.locale,
      text: values.translation.trim(),
    });
  };

  const onFinishFailed = (
    errorInfo: ValidateErrorEntity<{ translation: string }>,
  ) => {
    console.error(
      'AutoHeroWKDA::EquipmentNew::EditEquipment -> Failed to save:',
      errorInfo,
    );
    forceUpdate();
    return;
  };

  const preventDefaultEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      antdForm.submit();
    }
  };

  const inlineQASelector = 'edit-equipments-inline';
  const editEquipQASelector = 'edit-translation';
  const saveBtnQASelector = 'save-button';
  const resetBtnQASelector = 'reset-button';

  const showResetButton =
    props.translation.text.trim() !== '' && !props.hasError;

  const hasError =
    props.hasError || antdForm.getFieldError('translation').length > 0;
  const errorText =
    antdForm.getFieldError('translation').join('') || props.errorText;

  return (
    <>
      {!props.editMode && (
        <Button
          onClick={showForm}
          data-qa-selector-name={props?.name}
          data-qa-selector={editEquipQASelector}
          size="small"
          title="Edit"
          type="text"
          disabled={props.disabled ?? false}
        >
          <EditTwoTone />
        </Button>
      )}

      {props.editMode && (
        <Form
          name="edit-equipment"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          data-qa-selector={inlineQASelector}
          form={antdForm}
          size="small"
          component={false}
          disabled={props.disabled ?? false}
        >
          <Input.Group size="small" compact style={{ display: 'flex' }}>
            <Form.Item
              name="translation"
              initialValue={props.translation.text}
              required={true}
              style={{ width: '100%', display: 'block', paddingRight: '10px' }}
              rules={[
                () => ({
                  required: true,
                  validateTrigger: 'onSubmit',
                  validator: (_, value) => {
                    const error = props.validator?.(value);
                    if (error) {
                      return Promise.reject(new Error(error));
                    }
                    return Promise.resolve();
                  },
                  type: 'string',
                }),
              ]}
              validateStatus={hasError ? 'error' : 'success'}
              help={hasError ? errorText : undefined}
            >
              <Input
                bordered={false}
                name={'translation'}
                data-qa-selector={`edit-equipment-translation`}
                style={{
                  borderBottomStyle: 'solid',
                  borderBottomWidth: '2px',
                  borderBottomColor: hasError ? '#f5222d' : '#434343',
                  borderRadius: 0,
                }}
                autoFocus
                onKeyDown={preventDefaultEnter}
                status={hasError ? 'error' : ''}
              />
            </Form.Item>
            <Form.Item>
              <Button
                key="edit-equipment-submit"
                form="edit-equipment"
                type="primary"
                data-qa-selector={saveBtnQASelector}
                onClick={() => {
                  antdForm.submit();
                }}
                size="small"
              >
                <CheckOutlined />
              </Button>
            </Form.Item>
            {showResetButton && (
              <Form.Item>
                <Button
                  key="edit-equipment-cancel"
                  form="edit-equipment"
                  type="default"
                  data-qa-selector={resetBtnQASelector}
                  htmlType="reset"
                  onClick={hideForm}
                  size="small"
                >
                  <CloseOutlined data-qa-selector="edit-equipment-inline-close" />
                </Button>
              </Form.Item>
            )}
          </Input.Group>
        </Form>
      )}
    </>
  );
};
