import { MouseEventHandler } from 'react';
import { Button, Tooltip } from 'antd';
type AddRecordButtonProps = {
  onClick: MouseEventHandler;
  allowed: boolean;
};

export const AddNewServiceButton = ({
  onClick,
  allowed,
}: AddRecordButtonProps) => {
  const button = (
    <Button
      htmlType="button"
      type="primary"
      onClick={onClick}
      disabled={!allowed}
      data-qa-selector="add-new-service-record"
    >
      ADD NEW SERVICE
    </Button>
  );
  return allowed ? (
    button
  ) : (
    <Tooltip title="Maximum number of entries reached">{button}</Tooltip>
  );
};
