import { forEach } from 'lodash/fp';
import JZIP from 'jszip';

export type ZipFileInput = {
  fileName: string;
  content: string;
};

export const createZip = (filesList: ZipFileInput[]) => {
  const zip = new JZIP();
  forEach((f) => zip.file(f.fileName, f.content), filesList);

  return zip.generateAsync({ type: 'blob' });
};
