// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".D6esSyRLthrRqbV3Xgq9 {\n  margin-bottom: 20px;\n  border: 1px solid #dddddd;\n  border-radius: 0;\n  background-color: #ffffff;\n  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);\n          box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);\n}\n.D6esSyRLthrRqbV3Xgq9 .zMXIkrMkeq1CNkxYrLOn {\n  padding: 10px 15px;\n  color: #333333;\n  border-color: #dddddd;\n  border-bottom: 1px solid transparent;\n  background-color: #f5f5f5;\n}\n.D6esSyRLthrRqbV3Xgq9 .FvPPuf6kyqfGaUxL_Zzd {\n  padding: 15px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Panel/styles.less"],"names":[],"mappings":"AACA;EACE,mBAAA;EAEA,yBAAA;EACA,gBAAA;EACA,yBAAA;EACA,iDAAA;UAAA,yCAAA;AADF;AALA;EASI,kBAAA;EAEA,cAAA;EACA,qBAAA;EACA,oCAAA;EACA,yBAAA;AAFJ;AAZA;EAkBI,aAAA;AAHJ","sourcesContent":["@antVersion: antd-5-18-2;\n.panel {\n  margin-bottom: 20px;\n\n  border: 1px solid #dddddd;\n  border-radius: 0;\n  background-color: #ffffff;\n  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);\n\n  .panelHeading {\n    padding: 10px 15px;\n\n    color: #333333;\n    border-color: #dddddd;\n    border-bottom: 1px solid transparent;\n    background-color: #f5f5f5;\n  }\n\n  .panelBody {\n    padding: 15px;\n  }\n}\n\n@ant-prefix: antd-5-18-2;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": "D6esSyRLthrRqbV3Xgq9",
	"panelHeading": "zMXIkrMkeq1CNkxYrLOn",
	"panelBody": "FvPPuf6kyqfGaUxL_Zzd"
};
export default ___CSS_LOADER_EXPORT___;
