import { useEffect, useState } from 'react';
import { useEquipment } from '../../useEquipment';
import { Button, Space } from 'antd';

const scrollOffset = 260;
export const AnchorEquipment = () => {
  const { featureDetailsData } = useEquipment();

  const categories = featureDetailsData.map((featureDetail) => ({
    name: featureDetail.name,
    itemsCount: featureDetail.items.length,
  }));

  const [activeSection, setActiveSection] = useState('');

  const handleLinkClick = (section: string) => {
    setActiveSection(section);
    scrollToSection(section);
  };

  const scrollToSection = (section: string) => {
    const element = document.getElementById(section);
    if (!element) return;

    const offset = (el: HTMLElement) => {
      const boundingRect = el.getBoundingClientRect();
      const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      return {
        top: boundingRect.top + scrollTop,
        left: boundingRect.left + scrollLeft,
      };
    };

    const elementOffset = offset(element);

    window.scrollTo({
      top: elementOffset.top - scrollOffset,
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll<HTMLElement>(
        'section[id*="section-category-name"]',
      );

      sections.forEach((section) => {
        const boundingRect = section.getBoundingClientRect();

        if (
          boundingRect.top - scrollOffset <= 0 && // Adjust the offset as needed
          boundingRect.bottom - scrollOffset >= 0 // Adjust the offset as needed
        ) {
          setActiveSection(section.id);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav>
      <Space
        direction="horizontal"
        style={{
          background: '#e3e8ec',
          display: 'flex',
          padding: '0.2rem 0.4rem',
          justifyContent: 'space-around',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        {categories.map((category, idx) => {
          const sectionName = `section-category-name-${category.name}`;
          const isActive = activeSection === sectionName;

          return (
            <Button
              key={`${sectionName}_${category.itemsCount}_${idx}`}
              data-qa-selector="anchor-equipment-category"
              data-qa-selector-name={category.name}
              style={{
                padding: '0.4rem 0.8rem',
                color: isActive ? '#6558f5' : 'black',
                borderBottom: isActive ? '0.2rem solid #6558f5' : undefined,
                textTransform: 'capitalize',
              }}
              type="link"
              onClick={() => handleLinkClick(sectionName)}
            >
              {category.name} ({category.itemsCount})
            </Button>
          );
        })}
      </Space>
    </nav>
  );
};
