import { DeepPartial } from 'react-hook-form';
import * as z from 'zod';
import {
  ServiceHistoryDocument,
  ServiceHistoryDocumentInput,
  ServiceHistoryRecordInput,
} from '@gql_codegen/retail-types';
import {
  getMaxLengthValidationMessage,
  REQUIRED_VALIDATION_MESSAGE,
} from '@src/constants';

export const documentSchema: z.ZodSchema<
  ServiceHistoryDocument | ServiceHistoryDocumentInput
> = z.object({
  id: z.string(),
  fileType: z.enum(['IMAGE', 'PDF']),
  url: z.string(),
  thumbnailUrl: z.string().nullable(),
  fileExt: z.string().nullish(),
  name: z.string().nullish(),
});

export const schema: z.ZodSchema<DeepPartial<ServiceHistoryRecordInput>> =
  z.object({
    lastServiceOn: z
      .string({
        required_error: REQUIRED_VALIDATION_MESSAGE,
        invalid_type_error: REQUIRED_VALIDATION_MESSAGE,
      })
      .nonempty(),
    lastServiceMileage: z.number({
      required_error: REQUIRED_VALIDATION_MESSAGE,
      invalid_type_error: REQUIRED_VALIDATION_MESSAGE,
    }),
    tasksPerformed: z.string().array().nullable(),
    servicePerformedBy: z
      .string()
      .max(255, getMaxLengthValidationMessage(255))
      .nullable(),
    documentType: z
      .enum(['HARD_COPY', 'DIGITAL', 'AUTO_HERO'])
      .optional()
      .array()
      .nullable(),
    editable: z.boolean(),
    documents: z.array(documentSchema),
  });
