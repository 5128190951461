// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HqUj93TcBO2zD1Kk55JG {\n  position: absolute;\n  display: block;\n  -webkit-transform: translate(-50%, -50%);\n          transform: translate(-50%, -50%);\n}\n.HtNoPwg8aRNgvkwyQJUN {\n  padding: 1.5px 1px 2px 2px;\n  border: 1px solid #000000;\n  border-radius: 50%;\n  background-color: #e87f36;\n}\n.HtNoPwg8aRNgvkwyQJUN.ofWrqho9rYhtvFXElzus {\n  cursor: -webkit-grab;\n  cursor: grab;\n  background-color: #6eb5f2;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ImageGalleryField/Tag/styles.less"],"names":[],"mappings":"AAGA;EACE,kBAAA;EAEA,cAAA;EAEA,wCAAA;UAAA,gCAAA;AAJF;AAOA;EACE,0BAAA;EAEA,yBAAA;EACA,kBAAA;EACA,yBAAA;AANF;AAQE;EACE,oBAAA;EAAA,YAAA;EAEA,yBAAA;AAPJ","sourcesContent":["@antVersion: antd-5-18-2;\n@import 'src/styles/colors.less';\n\n.tagIconContainer {\n  position: absolute;\n\n  display: block;\n\n  transform: translate(-50%, -50%);\n}\n\n.icon {\n  padding: 1.5px 1px 2px 2px;\n\n  border: 1px solid @black;\n  border-radius: 50%;\n  background-color: @brand-primary;\n\n  &.editIcon {\n    cursor: grab;\n\n    background-color: @blue-lighter;\n  }\n}\n\n@ant-prefix: antd-5-18-2;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tagIconContainer": "HqUj93TcBO2zD1Kk55JG",
	"icon": "HtNoPwg8aRNgvkwyQJUN",
	"editIcon": "ofWrqho9rYhtvFXElzus"
};
export default ___CSS_LOADER_EXPORT___;
