import { Button, notification, Progress, Tooltip } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import { ImageGalleryManageModal } from './ImageGalleryManageModal';
import {
  UploadOutlined,
  CloudDownloadOutlined,
  BranchesOutlined,
} from '@ant-design/icons';
import ImageGallery, { ReactImageGalleryProps } from 'react-image-gallery';
import {
  FileDropZone,
  convertFileListIntoArray,
} from '@components/FileDropZone';
import { useAppForm } from '@hooks/useAppForm';
import { ImageGalleryItem } from './ImageGalleryItem';
import { useUploadingImages } from '@hooks/useUploadingImages';
import { useDownloadingImages } from '@hooks/useDownloadingImages';
import { Image, ImageInput, Tag, TagInput } from '@gql_codegen/retail-types';
import { ImageGalleryThumbnail } from './ImageGalleryThumbnail';
import { useController } from 'react-hook-form';
import { TAGGABLE_IMAGE_TYPES, TGalleryModes } from '@src/constants';

import cn from './styles.less';
import { useAnalytics } from '@hooks/useAnalytics';

type ImageGalleryFieldNames =
  | 'adMgmt.mobiledeV2.data.images'
  | 'adMgmt.autoscout24.data.images'
  | 'adMgmt.autoheroAndWkda.media.data.images.nextgen_highlight'
  | 'adMgmt.autoheroAndWkda.media.data.images.interior'
  | 'adMgmt.autoheroAndWkda.media.data.images.exterior'
  | 'adMgmt.autoheroAndWkda.media.data.images.panorama'
  | 'adMgmt.autoheroAndWkda.secondaryWheels.data.ad_secondary_wheels'
  | 'adMgmt.autoheroAndWkda.secondaryWheels.data.ad_secondary_wheels_damages';

type ImageGalleryFieldProps = {
  name: ImageGalleryFieldNames;
  type: string;
  noButtons?: boolean;
  qaSelector?: string;
  title?: string;
  isExternalImage?: boolean;
  galleryProps?: Partial<ReactImageGalleryProps>;
  limit?: number;
  exportedLimit?: number;
  focusOnImageId?: string | null | undefined;
  itemProps?: {
    imperfectionImageId: string;
    mode: TGalleryModes | null | undefined;
  };
  onTagCreated?: (tag: TagInput) => void;
  tracking?: {
    section: string;
    sectionCategory: string;
    fieldId: string;
  };
};

const THUMBNAIL_PROGRESSBAR_PLACEHOLDER = '__thumbnail_progressbar_placeholder';

export const ImageGalleryField = ({
  focusOnImageId,
  name,
  isExternalImage,
  type,
  title,
  galleryProps,
  itemProps,
  qaSelector,
  onTagCreated,
  noButtons = false,
  limit = global.Infinity,
  exportedLimit = global.Infinity,
  tracking,
}: ImageGalleryFieldProps): JSX.Element => {
  const { control, setValue, getValues } = useAppForm();
  const {
    field: { value },
  } = useController({
    control,
    name,
  });
  const {
    field: {
      value: autoheroAndWkdaImages,
      onChange: onAutoheroAndWkdaImagesOnChange,
    },
  } = useController({
    control,
    name: 'adMgmt.autoheroAndWkda.media.data.images',
  });
  const [isImageManageModalVisible, setIsImageManageModalVisible] =
    useState(false);
  const { startDownloadingImages } = useDownloadingImages();

  const { uploadImages, currentUploads } = useUploadingImages();

  const track = useAnalytics();

  const images = useMemo(
    () =>
      value?.filter(
        (image) => image.thumbnail !== THUMBNAIL_PROGRESSBAR_PLACEHOLDER,
      ) ?? [],
    [value],
  );
  let startIndex = value
    ? value.findIndex((image) => image.id === focusOnImageId)
    : -1;

  if (startIndex === -1) {
    startIndex = 0;
  }

  const setGalleryItemValue = (newItems: Image[] | ImageInput[]) => {
    // FIX-ME Find proper way to type setValue react-hook-form
    setValue(name, newItems as any, { shouldDirty: true });
  };

  const handleItemsChange = useCallback(
    (items: Image[]) => {
      // FIX-ME Find proper way to type setValue react-hook-form
      setValue(name, items, { shouldDirty: true });
      if (tracking) {
        track(
          {
            eventType: 'edit',
            eventCategory: 'modify',
            fieldId: tracking.fieldId,
            section: tracking.section,
            sectionCategory: tracking.sectionCategory,
          },
          {
            value: items,
            oldValue: images,
          },
        );
      }
    },
    [setValue, name, track, tracking, images],
  );

  const handleManageModalCancel = () => {
    setIsImageManageModalVisible(false);
  };

  const handleDownload = (type: string) => {
    startDownloadingImages({ images: value, type });
    if (tracking) {
      track(
        {
          eventType: 'download',
          eventCategory: 'modify',
          fieldId: tracking.fieldId,
          section: tracking.section,
          sectionCategory: tracking.sectionCategory,
        },
        {
          data: images,
        },
      );
    }
  };

  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const inputFiles = convertFileListIntoArray(event?.target?.files);
      const allowedToUpload = limit - inputFiles.length;
      const filesToUpload: File[] = inputFiles.slice(0, allowedToUpload);
      if (inputFiles.length > allowedToUpload) {
        notification.warning({
          message: `The limit is ${limit} files. Only ${allowedToUpload} files will be uploaded`,
        });
      }

      const filesWithLoaders = [
        ...value,
        ...filesToUpload.map<Image>((file) => ({
          id: file.name,
          original: file.name,
          thumbnail: THUMBNAIL_PROGRESSBAR_PLACEHOLDER,
          uploadedUrl: file.name,
          tags: [],
          nonRemovable: false,
          photoId: null,
          thumbnailClass: cn.progressbar,
        })),
      ];
      setGalleryItemValue(filesWithLoaders);

      const uploadedImages = await uploadImages({
        filesToUpload,
        type,
        startIndex: inputFiles.length,
      });

      if (!uploadedImages) {
        console.error('ImageGalleryField::handleUpload -> no uploaded images');
        return;
      }

      const mappedImages = uploadedImages.map((img) => {
        if (isExternalImage) {
          return {
            id: img.id,
            original: img.fullUrl,
            thumbnail: img.fullUrl,
            nonRemovable: false,
            uploadedUrl: img.url,
            photoId: null,
          };
        }

        return {
          id: img.id,
          original: img.fullUrl,
          thumbnail: img.fullUrl,
          tags: [],
          nonRemovable: false,
          uploadedUrl: img.url,
          photoId: null,
        };
      });

      const newImages: Image[] = images.concat(mappedImages);

      setGalleryItemValue(newImages);
      if (tracking) {
        track(
          {
            eventType: 'add',
            eventCategory: 'modify',
            fieldId: tracking.fieldId,
            section: tracking.section,
            sectionCategory: tracking.sectionCategory,
          },
          {
            value: newImages,
            oldValue: images,
          },
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleRemove = (id: string) => {
    const filteredImageList: Image[] = (value || []).filter(
      (img) => img.id !== id,
    );
    setGalleryItemValue(filteredImageList);
    if (tracking) {
      track(
        {
          eventType: 'delete',
          eventCategory: 'modify',
          fieldId: tracking.fieldId,
          section: tracking.section,
          sectionCategory: tracking.sectionCategory,
        },
        {
          value: filteredImageList,
          oldValue: images,
        },
      );
    }
  };

  const handleChangeTagPosition = (updatedTag: Tag) => {
    let prevValue: Tag | null = null;
    const newValue: Image[] = value.map((image: Image) => {
      return {
        ...image,
        tags: (image?.tags || []).map((tag: Tag) => {
          if (tag.tagImageId !== updatedTag?.tagImageId) {
            return tag;
          }

          prevValue = tag;

          return updatedTag;
        }),
      };
    });
    if (tracking) {
      track(
        {
          eventType: 'edit',
          eventCategory: 'modify',
          fieldId: tracking.fieldId,
          section: tracking.section,
          sectionCategory: tracking.sectionCategory,
        },
        {
          value: updatedTag,
          oldValue: prevValue,
        },
      );
    }
    setGalleryItemValue([...newValue]);
  };

  const cleanUnwantedTags = (imperfectionImageId: string) => {
    const updates = TAGGABLE_IMAGE_TYPES.reduce((acc, type) => {
      // FIX-ME using this MediaImages type says there is no index signature
      // need to find a way how types works here. Please remove any and see errors
      const targetGallery: Image[] = (autoheroAndWkdaImages as any)[type] || [];
      const galleryWithoutTag = targetGallery.map((image) => {
        return {
          ...image,
          tags: (image?.tags || []).filter(
            (tag) => tag.tagImageId !== imperfectionImageId,
          ),
        };
      });
      return {
        ...acc,
        [type]: galleryWithoutTag,
      };
    }, {});
    const newValue = {
      ...autoheroAndWkdaImages,
      ...updates,
    };
    onAutoheroAndWkdaImagesOnChange(newValue);
  };

  const handleCreateTag = (item: Image, newTag: TagInput) => {
    if (newTag?.tagImageId) {
      cleanUnwantedTags(newTag?.tagImageId);
    }
    const freshImageData = getValues(name);
    const targetGallery: ImageInput[] = freshImageData.map((image: Image) => {
      return {
        ...image,
        tags:
          image.id === item?.id
            ? [...(image?.tags || []), newTag]
            : image?.tags || [],
      };
    });
    setGalleryItemValue(targetGallery);
    onTagCreated?.(newTag);
    if (tracking) {
      track(
        {
          eventType: 'add',
          eventCategory: 'modify',
          fieldId: tracking?.fieldId,
          section: tracking.section,
          sectionCategory: tracking.sectionCategory,
        },
        {
          value: newTag,
          oldValue: null,
        },
      );
    }
  };

  const isOutOfExportedLimit = useCallback(
    (item: Image) =>
      value.findIndex((img) => img.id === item.id) >= exportedLimit,
    [exportedLimit, value],
  );

  const renderThumbInner = (item: Image) => (
    <div className={cn.thumbnailWrapper}>
      {item.thumbnail === THUMBNAIL_PROGRESSBAR_PLACEHOLDER ? (
        <Progress
          type="circle"
          data-qa-selector="imageUpload_progress_bar"
          percent={
            currentUploads.find((upload) => upload.imageName === item.id)
              ?.progress ?? 100
          }
          width={70}
        />
      ) : isOutOfExportedLimit(item) ? (
        <Tooltip title="Out of limit">
          <div>
            <ImageGalleryThumbnail
              item={item}
              grayscale={isOutOfExportedLimit(item)}
            />
          </div>
        </Tooltip>
      ) : (
        <ImageGalleryThumbnail
          item={item}
          grayscale={isOutOfExportedLimit(item)}
        />
      )}
    </div>
  );

  const renderItem = (item: Image) => {
    if (item.thumbnail === THUMBNAIL_PROGRESSBAR_PLACEHOLDER) {
      return (
        <Progress
          type="circle"
          data-qa-selector="imageUpload_progress_bar"
          percent={
            currentUploads.find((upload) => upload.imageName === item.id)
              ?.progress ?? 100
          }
          width={250}
        />
      );
    }

    return (
      <ImageGalleryItem
        {...itemProps}
        noButtons={noButtons}
        item={item}
        onRemove={handleRemove}
        onChangeTagPosition={handleChangeTagPosition}
        onCreateTag={handleCreateTag}
        grayscale={isOutOfExportedLimit(item)}
      />
    );
  };

  return (
    <div data-qa-selector={qaSelector || 'image-gallery-wrapper'}>
      {title && <h3>{title}</h3>}
      <div className="imageGalleryField">
        <ImageGalleryManageModal
          show={isImageManageModalVisible}
          handleCancel={handleManageModalCancel}
          items={images}
          onItemsChange={(items) => handleItemsChange(items)}
          grayscaleLimit={exportedLimit}
        />

        {!noButtons && (
          <div className={cn.actionButtons}>
            <FileDropZone multiple onChange={handleUpload}>
              <Button
                className={cn.actionButton}
                icon={<UploadOutlined />}
                type="primary"
                data-qa-selector="galleryUpload"
                disabled={value?.length >= limit}
              >
                Upload
              </Button>
            </FileDropZone>
            {value?.length > 0 && (
              <div>
                <Button
                  className={cn.actionButton}
                  icon={<BranchesOutlined />}
                  type="primary"
                  onClick={() => setIsImageManageModalVisible(true)}
                  data-qa-selector="gallerySorting"
                  title="Here you can sort or delete images"
                >
                  Manage
                </Button>
                <Button
                  className={cn.actionButton}
                  icon={<CloudDownloadOutlined />}
                  type="primary"
                  onClick={() => handleDownload(type)}
                  data-qa-selector="galleryDownload"
                >
                  Download
                </Button>
              </div>
            )}
          </div>
        )}

        {value?.length > 0 ? (
          <div data-qa-selector="image-gallery-section">
            <ImageGallery
              showIndex
              showNav={false}
              showPlayButton={false}
              // TODO: map value to be the "ReactImageGalleryItem"
              items={value || []}
              startIndex={startIndex}
              // FIX-ME: type mismatch ReactImageGalleryItem and Image
              renderItem={(item) => renderItem(item as Image)}
              // FIX-ME: type mismatch ReactImageGalleryItem and Image
              renderThumbInner={(item) => renderThumbInner(item as Image)}
              {...galleryProps}
            />
          </div>
        ) : (
          <div
            data-qa-selector="image-gallery-empty-section"
            className={cn.noImagePlaceHolder}
          >
            <h3 data-qa-selector="image-gallery-empty-section-text">
              No images
            </h3>
          </div>
        )}
      </div>
    </div>
  );
};
