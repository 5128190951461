import { getAdId } from '@hooks/useAdId';
import axiosInstance from '@utils/axiosInstance';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { v4 as uuid } from 'uuid';
import {
  AwsUploadCredentials,
  AwsVideoUploadCredentials,
  ImageMetaData,
  UploadToS3Input,
  VideoPresignedUrlEntityVariables,
  VideoPreSignedUrlsResponsePayload,
  VideoPreSignedUrlsResponse,
} from './s3-uploader-types';
import { Buffer } from 'buffer';
import { retailKibanaLogger } from './logger';

export const getAwsUploadMetadata = async (body: ImageMetaData[]) => {
  return axiosInstance.post<AwsUploadCredentials[]>(
    '/api/v1/retail-image/aws-upload-metadata/batch',
    body,
  );
};

export const uploadToS3 = async ({
  file,
  credentials: { bucketName, formData },
  metaData: { uploadId, order, adId, extension },
  axiosOptions,
}: UploadToS3Input & { axiosOptions?: AxiosRequestConfig }) => {
  const bodyFormData = new FormData();
  const fields: Record<string, string | Blob> = {
    ...formData,
    'Content-Type': file.type,
    file: file,
  };
  Object.keys(fields).forEach((item) => {
    bodyFormData.append(item, fields[item] as string | Blob);
  });
  await axios.post(`https://${bucketName}.s3.amazonaws.com/`, bodyFormData, {
    timeout: 900000,
    headers: { 'Content-Type': 'multipart/form-data' },
    ...axiosOptions,
  });
  return {
    id: uploadId,
    order,
    url: `/img/${adId}/{size}${uploadId}.${extension}`,
    fullUrl: global.URL.createObjectURL(file),
  };
};

export function fileExtension(filename: string | undefined) {
  if (!filename) return '';
  const ext: string | undefined = (/[^./\\]*$/.exec(filename) || [''])[0];
  if (!ext) return '';
  return ext.toLowerCase();
}

export const dataURLtoFile = (
  dataurl: string,
  filename: string,
): File | undefined => {
  const arr = dataurl.split(',');
  if (!arr[0] || !arr[1]) return;
  const parts = arr[0].match(/:(.*?);/);
  const mime = parts?.[1];
  if (!mime) return;
  const bstr = Buffer.from(arr[1], 'base64');
  return new File([bstr], filename, { type: mime });
};

export const getImageMetadata = (
  adId: string,
  type: string,
  order: number,
  file: File,
): ImageMetaData => ({
  uploadId: uuid(),
  adId,
  type: type?.toLowerCase(),
  order,
  extension: fileExtension(file.name),
});

export const uploadImageToS3 = async (type: string, file: File) => {
  const adId = getAdId();
  if (!adId) {
    return;
  }
  const metaData = getImageMetadata(adId, type, 1, file);
  const { data: credentialList } = await getAwsUploadMetadata([metaData]);
  if (!credentialList || !credentialList[0]) return;
  return uploadToS3({ file, credentials: credentialList[0], metaData });
};

export const uploadVideoToS3 = async (
  files: File[],
  credentials: AwsVideoUploadCredentials[],
): Promise<void> => {
  await Promise.all(
    credentials.map(({ presignedUrl, httpMethod }, i) => {
      const file = files[i];
      if (!file) {
        retailKibanaLogger.info(
          [
            'uploadVideoToS3 -> no file for url:',
            presignedUrl,
            'files:',
            JSON.stringify(files),
            'idx:',
            i,
          ].join(''),
        );
        return console.error(
          'uploadVideoToS3 -> no file for url:',
          presignedUrl,
          'files:',
          JSON.stringify(files),
          'idx:',
          i,
        );
      }
      return axios({
        method: httpMethod,
        url: presignedUrl,
        timeout: 900000,
        data: file,
      });
    }),
  );
};

export const getVideoUploadCredentials = async (
  adId: string,
  files: File[],
): Promise<AwsVideoUploadCredentials[]> => {
  const entities = files.map((file) => ({
    adId,
    httpMethod: 'PUT',
    fileName: `${Date.now()}_${file.name}`,
  }));
  const {
    data: { data: data },
  } = await loadVideoPreSignedUrls({ entities });
  return data.data;
};

export const loadVideoPreSignedUrls = async (
  variables: VideoPresignedUrlEntityVariables,
): Promise<AxiosResponse<VideoPreSignedUrlsResponse, any>> => {
  const query = `
  query($entities: [AwsS3AdVideoPresignedUrlRequestDTOInput]) {
    data: adVideoPresignedUrls(request: { entities: $entities }) {
      fileName
      httpMethod
      presignedUrl
    }
  }
`;
  return axiosInstance.post<
    VideoPreSignedUrlsResponsePayload,
    AxiosResponse<VideoPreSignedUrlsResponse>
  >('api/v1/retail-graphql-gateway', {
    query,
    variables,
  });
};
