import {
  CustomFeatureTranslationConfig,
  FeatureDetailCategory,
  FeatureDetailItem,
  FeatureDetailType,
} from '@gql_codegen/retail-types';
import { useAppForm } from '@hooks/useAppForm';
import { CURRENCIES } from '@src/constants';
import formatPrice from '@utils/formatPrice';
import { generateHexFromString } from '@utils/generateHexFromString';
import { group } from '@utils/group';
import {
  Col,
  Divider,
  Row,
  Space,
  Table,
  TableColumnsType,
  TableProps,
  Tag,
  Typography,
} from 'antd';
import { FilterValue } from 'antd/lib/table/interface';
import classNames from 'classnames';
import { Fragment, HTMLAttributes, useMemo, useState } from 'react';
import { SHOW_EQUIPMENT_STATE, ShowEquipmentState } from '..';
import { EQUIPMENT_CATEGORIES_IDS } from '../constants';
import { default as sharedStyles } from '../styles.less';
import { useEquipment } from '../useEquipment';
import { useFindEquipmentHelpers } from '../useFindEquipmentHelpers';
import { equipmentHelpers, logError } from '../utils';
import { EquipmentTableFeature } from './EquipementTableFeature';
import { EquipmentTableCheckbox } from './EquipmentTableCheckbox';
import { EquipmentTableDeleteEquipment } from './EquipmentTableDeleteEquipment';
import { EquipmentTableNameTranslated } from './EquipmentTableNameTranslated';
import styles from './styles.less';
const { Title } = Typography;

const NO_SUBCATEGORY = '';

const getAllTranslationConfigsByCategory =
  (currentCategory: FeatureDetailCategory | undefined) =>
  (config: CustomFeatureTranslationConfig) => {
    if (
      currentCategory?.id ===
      EQUIPMENT_CATEGORIES_IDS['additional (unclassified)']
    )
      return currentCategory.items;

    return currentCategory?.items.filter((featureDetailItem) => {
      return (
        featureDetailItem.group.name === config.featureGroup &&
        (featureDetailItem.subGroup?.name ?? null) === config.featureSubGroup
      );
    });
  };

type EquipmentTableProps = {
  showEquipmentState: ShowEquipmentState;
};

type TableDataItem = CustomFeatureTranslationConfig & {
  item?: FeatureDetailItem;
  key?: string;
};
type TableData = TableDataItem[];
const useTableData = (showEquipmentState: ShowEquipmentState) => {
  const {
    featureDetailsData,
    customFeatureTranslationConfigsSortedAndFiltered,
  } = useEquipment();
  const { getItemByTranslationConfigNonTranslationCompare } =
    useFindEquipmentHelpers();

  const TABLE_DATA = useMemo(
    () =>
      Object.entries(
        group(
          customFeatureTranslationConfigsSortedAndFiltered,
          (config) => config.category.name,
        ),
      ).map(([categoryName, categoryConfigs]) => {
        categoryConfigs = categoryConfigs.map((config, idx) => ({
          ...config,
          key: `idx:${idx}_category.id:${config.category.id}_order:${
            config.order
          }_${equipmentHelpers.convertOptionToValue({
            group: config.featureGroup,
            subgroup: config.featureSubGroup ?? null,
          })}`,
        }));
        //TODO: handle undefined case
        const currentCategory = featureDetailsData.find(
          (featureDetailCategory) =>
            categoryConfigs[0]?.category.id === featureDetailCategory.id,
        );

        const getAllTranslationConfigs =
          getAllTranslationConfigsByCategory(currentCategory);

        const groupedBySubcategory = group(
          categoryConfigs,
          (config) => config.subcategory?.name ?? NO_SUBCATEGORY,
        );

        const tableDataSource: {
          subcategoryName: string;
          subcategoryEquipment: TableData;
        }[] = Object.entries(groupedBySubcategory)
          .map(([subcategoryName, subcategoryEquipment]) => ({
            subcategoryName,
            subcategoryEquipment: subcategoryEquipment
              .filter((config) => {
                switch (showEquipmentState) {
                  case SHOW_EQUIPMENT_STATE.all:
                    return true;

                  case SHOW_EQUIPMENT_STATE.current:
                    return getItemByTranslationConfigNonTranslationCompare(
                      config,
                    );

                  case SHOW_EQUIPMENT_STATE.unselected:
                    return !getItemByTranslationConfigNonTranslationCompare(
                      config,
                    );

                  default:
                    return logError('useTableData', 'wrong filter');
                }
              })
              .reduce<TableData>((acc, config) => {
                const items = getAllTranslationConfigs(config);

                if (!config.defaultVisible && !items?.length) return acc;

                //TODO: Remove after phase 2
                if (
                  config.category.id ===
                    EQUIPMENT_CATEGORIES_IDS['additional (unclassified)'] &&
                  items?.length === 0
                )
                  return acc;

                if (!items?.length || items.length === 0) {
                  acc.push(config);
                  return acc;
                }

                const featureDetailsConfigs: TableData = items.map(
                  (item, idx) => ({
                    ...config,
                    key: `idx:${idx}_item.id:${item.id}_${
                      config.category.id
                    }_order:${
                      config.order
                    }_${equipmentHelpers.convertOptionToValue({
                      group: config.featureGroup,
                      subgroup: config.featureSubGroup ?? null,
                    })}`,
                    featureGroup: item.group.name,
                    featureSubGroup: item.subGroup?.name ?? null,
                    translations: item.translations.map((itemTranslation) => ({
                      locale: itemTranslation.locale,
                      text: itemTranslation.text,
                    })),
                    item,
                  }),
                );

                acc.push(...featureDetailsConfigs);

                return acc;
              }, []),
          }))
          .filter((tableData) => tableData.subcategoryEquipment.length > 0)
          //make no subcategory last
          .sort((_itemA, itemB) =>
            itemB.subcategoryName === NO_SUBCATEGORY ? -1 : 0,
          );

        return { categoryName, tableDataSource, currentCategory };
      }),
    [
      customFeatureTranslationConfigsSortedAndFiltered,
      featureDetailsData,
      // getItemByTranslationConfigNonTranslationCompare,
      showEquipmentState,
    ],
  );

  return TABLE_DATA;
};

export const EquipmentTable = (props: EquipmentTableProps) => {
  const [filteredInfo, setFilteredInfo] = useState<
    Record<string, FilterValue | null>
  >({});

  const { getValues, formState } = useAppForm();
  const {
    handleDeleteItem,
    handleAddItemWKDAOnly,
    restrictions,
    customFeatureTranslationConfigsSortedAndFiltered,
    handleEditItem,
    locales,
    editTranslationByPath,
    validateDuplicateTranslation,
    uniqueClassifieds,
  } = useEquipment();
  const {
    getItemByTranslationConfig,
    getFeatureDetailsTranslationWithIdxPathByItem,
    getFeatureDetailsItemPath,
    getFieldStateByItem,
    isNewlyAddedEquipment,
  } = useFindEquipmentHelpers();

  const TABLE_DATA = useTableData(props.showEquipmentState);

  const handleChange: TableProps<TableDataItem>['onChange'] = (
    _pagination,
    filters,
  ) => {
    setFilteredInfo(filters);
  };

  const featureDetailColumns: TableColumnsType<TableDataItem> = [
    {
      title: <b>ID</b>,
      width: 90,
      render: (_, record) => {
        const item = record.item ?? getItemByTranslationConfig(record);

        return (
          <EquipmentTableCheckbox
            item={item}
            record={record}
            handleDeleteItem={handleDeleteItem}
            handleAddItemWKDAOnly={handleAddItemWKDAOnly}
            restrictions={restrictions}
          />
        );
      },
      key: 'id',
    },
    {
      title: <b>Type</b>,
      width: 80,
      key: 'type',
      align: 'center',
      render(_, record) {
        if (record.item?.type === FeatureDetailType.Standard) {
          return 'S';
        }
        if (record.item?.type === FeatureDetailType.Optional) {
          return 'O';
        }
        return '';
      },
    },
    {
      title: <b>Feature</b>,
      render: (_, record) => {
        const item = record.item ?? getItemByTranslationConfig(record);

        return (
          <EquipmentTableFeature
            item={item}
            config={record}
            customFeatureTranslationConfigsSortedAndFiltered={
              customFeatureTranslationConfigsSortedAndFiltered
            }
            handleEditItem={handleEditItem}
            restrictions={restrictions}
          />
        );
      },
      key: 'feature',
    },
    ...locales.map<TableColumnsType<TableDataItem>[0]>((locale) => ({
      title: () => <b>{`AH Description (${locale})`}</b>,
      render: (_, record) => {
        const translation =
          record.translations.find(({ locale: l }) => l === locale)?.text ?? '';
        const item = record.item ?? getItemByTranslationConfig(record);

        if (!item) return <Row>{translation}</Row>;

        const translationPath = getFeatureDetailsTranslationWithIdxPathByItem(
          item,
          locale,
        );
        const itemPath = getFeatureDetailsItemPath(item);

        if (!translationPath || !itemPath) {
          return <Row>{translation}</Row>;
        }

        const fieldState = getFieldStateByItem(item);

        if (!fieldState) {
          return <Row>{translation}</Row>;
        }

        const isNewItem = isNewlyAddedEquipment(item);

        return (
          <EquipmentTableNameTranslated
            getValues={getValues}
            formState={formState}
            data-qa-selector={item.id}
            translationPath={translationPath}
            item={item}
            isNewItem={isNewItem}
            editTranslationByPath={editTranslationByPath}
            validateDuplicateTranslation={validateDuplicateTranslation}
            restrictions={restrictions}
            fieldState={fieldState}
          />
        );
      },
      key: `translation_${locale}`,
    })),
    {
      title: <b>Name (DAT)</b>,
      render: (_, record) => {
        const item = record.item ?? getItemByTranslationConfig(record);

        return (
          <Row
            gutter={{ xs: 4, sm: 4, md: 4, lg: 8, xl: 8, xxl: 8 }}
            wrap={false}
            align="middle"
          >
            <Col>{item?.name}</Col>
          </Row>
        );
      },
      key: 'name',
    },
    {
      width: 100,
      title: <b>Price</b>,
      key: 'price',
      render(_, record) {
        return (
          <span data-qa-selector="equipment-ui-price">
            {typeof record.item?.price === 'number'
              ? formatPrice(record.item.price, CURRENCIES.EUR)
              : record.item?.price}
          </span>
        );
      },
    },
    {
      width: 220,
      title: <b>Classifieds</b>,
      key: 'classifieds',
      align: 'center',
      filters: uniqueClassifieds.map((classified) => ({
        text: classified,
        value: classified,
      })),
      filteredValue: filteredInfo['classifieds'] || null,
      onFilter: (value, record) => {
        return record.classifieds.includes(value.toString());
      },
      render: (_, record) => {
        return record.classifieds.map((classified) => (
          <Tag
            key={classified}
            color={generateHexFromString(classified)}
            data-qa-selector-equipment-label={classified}
          >
            {classified}
          </Tag>
        ));
      },
    },
  ];

  return (
    <Space direction="vertical" className={sharedStyles.fullWidth}>
      {TABLE_DATA.map(({ categoryName, tableDataSource, currentCategory }) => {
        if (typeof categoryName === 'undefined') return <></>;
        return (
          <section
            key={categoryName}
            id={`section-category-name-${categoryName}`}
          >
            <Row justify="space-between" align="middle">
              <Col>
                <Title
                  data-qa-selector={`category-name-${categoryName}`}
                  className={classNames(
                    sharedStyles.title,
                    styles.equipmentTitle,
                    styles.equipmentTitle,
                  )}
                  level={4}
                  id={categoryName}
                >
                  {categoryName}
                </Title>
              </Col>
              <Col>
                <EquipmentTableDeleteEquipment categoryName={categoryName} />
              </Col>
            </Row>
            {tableDataSource.map(
              (tableData, idx, tableDataArr) =>
                tableData.subcategoryEquipment.length > 0 && (
                  <Fragment key={idx}>
                    {tableDataArr.length > 1 && (
                      <Divider
                        orientation="left"
                        orientationMargin="0"
                        className={classNames(styles.divider, styles.divider)}
                      >
                        {tableData.subcategoryName === NO_SUBCATEGORY
                          ? 'No subcategory'
                          : tableData.subcategoryName}
                      </Divider>
                    )}
                    <Table<TableDataItem>
                      onRow={(record) => {
                        return {
                          'data-qa-selector': 'equipment-table-row',
                          'data-qa-selector-name': `${record.featureGroup}${
                            record.featureSubGroup
                              ? `_${record.featureSubGroup}`
                              : ''
                          }`,
                          'data-qa-selector-dat':
                            record.item?.uiFeatureDetailIdentifier,
                        } as HTMLAttributes<HTMLTableRowElement>;
                      }}
                      onChange={handleChange}
                      showHeader={idx === 0}
                      data-qa-selector="eqTable"
                      pagination={false}
                      size="small"
                      dataSource={tableData.subcategoryEquipment}
                      tableLayout={'fixed'}
                      columns={featureDetailColumns}
                      className={sharedStyles.equipmentTable}
                      rowClassName={(record) => {
                        const totalItems =
                          getAllTranslationConfigsByCategory(currentCategory)(
                            record,
                          ) ?? [];

                        const item = getItemByTranslationConfig(record);

                        return classNames(
                          totalItems.length > 1 && styles.groupedRow,
                          item?.translations.some(
                            (translation) => translation.text === '',
                          ) &&
                            !item.id &&
                            styles.highlight,
                        );
                      }}
                      bordered
                    />
                  </Fragment>
                ),
            )}
          </section>
        );
      })}
    </Space>
  );
};
