// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IrBb_XSDD54vgXmKyDbg {\n  position: relative;\n}\n.AoAfAFQCFUUOfxvmv2uv {\n  position: absolute;\n  top: 3px;\n  right: 6px;\n}\n", "",{"version":3,"sources":["webpack://./src/sections/General/VehicleHistoryAndService/CarHistoryUrl/styles.less"],"names":[],"mappings":"AACA;EACE,kBAAA;AAAF;AAGA;EACE,kBAAA;EACA,QAAA;EACA,UAAA;AADF","sourcesContent":["@antVersion: antd-5-18-2;\n.container {\n  position: relative;\n}\n\n.suffix {\n  position: absolute;\n  top: 3px;\n  right: 6px;\n}\n\n@ant-prefix: antd-5-18-2;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "IrBb_XSDD54vgXmKyDbg",
	"suffix": "AoAfAFQCFUUOfxvmv2uv"
};
export default ___CSS_LOADER_EXPORT___;
