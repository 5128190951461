import { Row, Col, Form } from 'antd';
import { TextBox } from './textBox';
import { useAppForm } from '@hooks/useAppForm';
import CheckboxControlled from '@components/formControlled/CheckboxControlled';
import { TRACKING_SECTION, TRACKING_SECTION_CATEGORY } from '@src/constants';
import { ShowToCustomerCheckbox } from './showToCustomerCheckbox';

type DamageItemProps = {
  index: number;
};
export const DamageItem = ({ index }: DamageItemProps) => {
  const { control, getValues } = useAppForm();
  const damage = getValues(
    `adMgmt.general.data.carAccidentDamages.accidentDamages.${index}`,
  );
  if (!damage) return <></>;
  return (
    <Row gutter={24}>
      <Col span={4}>
        <TextBox qaSelector={`accident-damage-car-part-${index}`}>
          {damage.carPart}
        </TextBox>
      </Col>
      <Col span={4}>
        <TextBox qaSelector={`accident-damage-type-${index}`}>
          {damage.damageType}
        </TextBox>
      </Col>
      <Col span={6}>
        <TextBox qaSelector={`accident-damage-attributes-${index}`}>
          {damage.attributes}
        </TextBox>
      </Col>
      <Col span={3}>
        <ShowToCustomerCheckbox
          index={index}
          disabled={damage.isRepaired !== null}
        />
      </Col>
      {damage.isRepaired !== null && (
        <Col span={3}>
          <Form.Item>
            <CheckboxControlled
              data-qa-selector={`accident-damage-isRepaired-${index}`}
              controllerProps={{
                control,
                name: `adMgmt.general.data.carAccidentDamages.accidentDamages.${index}.isRepaired`,
              }}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.HISTORY_DAMAGES,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'IsRepaired',
              }}
            ></CheckboxControlled>
          </Form.Item>
        </Col>
      )}
    </Row>
  );
};
