import { useCallback } from 'react';
import { Button, notification } from 'antd';
import { useGenerateDescriptionQuery } from '@gql_codegen/retail-types';
import useAdId from '@hooks/useAdId';
import { useAnalytics } from '@hooks/useAnalytics';
import { usePageData } from '@hooks/usePageData';
import { useAppForm } from '@hooks/useAppForm';
import {
  MARKETPLACES,
  TRACKING_SECTION,
  TRACKING_SECTION_CATEGORY,
} from '@src/constants';

export const GenerateDescriptionButton = () => {
  const { data } = usePageData();
  const adId = useAdId() ?? '';
  const { setValue } = useAppForm();
  const track = useAnalytics();

  const handleGenerate = useCallback(async () => {
    if (data?.adMgmt?.autoscout24?.data) {
      try {
        const as24Data = await useGenerateDescriptionQuery.fetcher({
          adId,
          marketplaceId: MARKETPLACES.AUTOSCOUT24,
        })();
        setValue(
          'adMgmt.autoscout24.data.description',
          as24Data.generateDescription.description,
          {
            shouldDirty: true,
            shouldTouch: true,
          },
        );
        track(
          {
            eventType: 'click',
            eventCategory: 'modify',
            fieldId: 'Generate Description',
            section: TRACKING_SECTION.AS24_DESCRIPTION,
            sectionCategory: TRACKING_SECTION_CATEGORY.CL,
          },
          as24Data?.generateDescription?.description ?? null,
        );
      } catch (error: unknown) {
        notification.error({
          message: `Can't generate description for Autoscout24`,
        });
      }
    }

    if (!data?.adMgmt?.mobiledeV2?.data) return;
    try {
      const mobileData = await useGenerateDescriptionQuery.fetcher({
        adId,
        marketplaceId: MARKETPLACES.MOBILEDE_V2.toLowerCase(),
      })();
      setValue(
        'adMgmt.mobiledeV2.data.description',
        mobileData.generateDescription.description,
        {
          shouldDirty: true,
          shouldTouch: true,
        },
      );
      track(
        {
          eventType: 'click',
          eventCategory: 'modify',
          fieldId: 'Generate Description',
          section: TRACKING_SECTION.MOBILE_DESCRIPTION,
          sectionCategory: TRACKING_SECTION_CATEGORY.CL,
        },
        mobileData?.generateDescription?.description ?? null,
      );
    } catch (error: unknown) {
      notification.error({
        message: `Can't generate description for Mobile`,
      });
    }
  }, [adId, setValue, track, data]);

  return (
    <Button
      onClick={handleGenerate}
      htmlType="button"
      data-qa-selector="classifieds-generate-description"
    >
      Generate description
    </Button>
  );
};
