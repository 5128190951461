// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IyOZA6WZUUQD4WCTtkY8 label {\n  width: 100%;\n  min-width: 100%;\n}\n.qqpDfbSiBpnNBOKiCNRU {\n  display: -webkit-box !important;\n  display: -ms-flexbox !important;\n  display: flex !important;\n}\n", "",{"version":3,"sources":["webpack://./src/sections/General/CarData/styles.less"],"names":[],"mappings":"AACA;EAEI,WAAA;EACA,eAAA;AADJ;AAKA;EACE,+BAAA;EAAA,+BAAA;EAAA,wBAAA;AAHF","sourcesContent":["@antVersion: antd-5-18-2;\n.label {\n  label {\n    width: 100%;\n    min-width: 100%;\n  }\n}\n\n.subTypeInputWrapper {\n  display: flex !important;\n}\n\n@ant-prefix: antd-5-18-2;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "IyOZA6WZUUQD4WCTtkY8",
	"subTypeInputWrapper": "qqpDfbSiBpnNBOKiCNRU"
};
export default ___CSS_LOADER_EXPORT___;
