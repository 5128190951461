import { useCallback, useState } from 'react';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';
import { useAnalytics } from '@hooks/useAnalytics';

export interface Tracking {
  section: string;
  fieldId: string;
  eventCategory: string;
  sectionCategory: string;
}
interface Props<T extends FieldValues> {
  controllerProps: ControllerRenderProps<T>;
  tracking: Tracking | undefined;
}

export const useFormControllerTracking = <T extends FieldValues>({
  tracking,
  controllerProps,
}: Props<T>) => {
  const track = useAnalytics();
  const [oldValue, setOldValue] = useState(controllerProps.value || null);

  const onBlur = useCallback(() => {
    controllerProps.onBlur();
    tracking &&
      oldValue !== controllerProps.value &&
      track(
        {
          eventType: 'edit',
          fieldId: tracking.fieldId,
          section: tracking.section,
          eventCategory: tracking.eventCategory,
          sectionCategory: tracking.sectionCategory,
        },
        {
          value: controllerProps.value,
          oldValue,
        },
      );
  }, [track, tracking, controllerProps, oldValue]);

  const onFocus = useCallback(() => {
    tracking && setOldValue(controllerProps.value);
  }, [tracking, setOldValue, controllerProps]);

  return {
    onFocus,
    onBlur,
  };
};
