import { useAppForm } from '@hooks/useAppForm';
import { notification } from 'antd';
import { useEffect } from 'react';
import { dataPaths } from './constants';
import { useFormState } from 'react-hook-form';

export const SaveYourChangesNotification = () => {
  const { control } = useAppForm();

  const { isDirty } = useFormState({ name: dataPaths.featureDetails, control });

  useEffect(() => {
    if (isDirty) {
      notification.info({
        message: <b>Please save your changes</b>,
        key: 'equipment_feature_details_save_changes',
        duration: 10,
        placement: 'bottom',
      });
    }
  }, [isDirty]);

  return <></>;
};
