const colorCacheMap: Record<string, string> = {};
export const generateHexFromString = (
  string: string,
  seed = 1,
  min = 0x60,
  max = 0xcf,
): string => {
  const cacheHit = colorCacheMap[string];
  if (cacheHit) return cacheHit;

  let hash = seed;

  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Convert to hex string
  let color = '#'; // + scaledHash.toString(16).padStart(6, '0').slice(-6);

  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    const normalizedValue = (Math.abs(value) % 0xff) / 0xff;

    const scaledValue = Math.floor(min + (max - min) * normalizedValue);

    color += scaledValue.toString(16).padStart(2, '0').slice(-2);
  }
  colorCacheMap[string] = color;

  return color;
};
