// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YcktRBxMu5PqfpoMLfHz {\n  margin-left: 16px;\n  color: #4a4a4a;\n  font-size: 24px;\n}\n.xLskyDCFG8TzEPcU8Zfh {\n  margin-bottom: 20px;\n  padding-bottom: 20px;\n  border-bottom: 2px solid #ebebeb;\n}\n.SRz9kaYIiSplRwkt3sM2 {\n  margin-bottom: 40px;\n  font-size: 24px;\n}\n.SRz9kaYIiSplRwkt3sM2.Mzk2uQRFnfNnDNtGehBF {\n  color: #62a31a;\n}\n.SRz9kaYIiSplRwkt3sM2.wmkVFN4KErOg5nqNZ_CB {\n  color: #f14f4f;\n}\n", "",{"version":3,"sources":["webpack://./src/sections/Export/PublishingBlockers/styles.less"],"names":[],"mappings":"AACA;EACE,iBAAA;EAEA,cAAA;EAEA,eAAA;AAFF;AAKA;EACE,mBAAA;EACA,oBAAA;EAEA,gCAAA;AAJF;AAOA;EACE,mBAAA;EAEA,eAAA;AANF;AAQE;EACE,cAAA;AANJ;AASE;EACE,cAAA;AAPJ","sourcesContent":["@antVersion: antd-5-18-2;\n.adBlockerHint {\n  margin-left: 16px;\n\n  color: #4a4a4a;\n\n  font-size: 24px;\n}\n\n.blockerItem {\n  margin-bottom: 20px;\n  padding-bottom: 20px;\n\n  border-bottom: 2px solid #ebebeb;\n}\n\n.statusText {\n  margin-bottom: 40px;\n\n  font-size: 24px;\n\n  &.noBlockers {\n    color: #62a31a;\n  }\n\n  &.hasBlockers {\n    color: #f14f4f;\n  }\n}\n\n@ant-prefix: antd-5-18-2;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"adBlockerHint": "YcktRBxMu5PqfpoMLfHz",
	"blockerItem": "xLskyDCFG8TzEPcU8Zfh",
	"statusText": "SRz9kaYIiSplRwkt3sM2",
	"noBlockers": "Mzk2uQRFnfNnDNtGehBF",
	"hasBlockers": "wmkVFN4KErOg5nqNZ_CB"
};
export default ___CSS_LOADER_EXPORT___;
