import { Select, SelectProps } from 'antd';
import { SelectValue } from 'antd/lib/select';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import {
  Tracking,
  useFormControllerTracking,
} from '@hooks/useFormControlledTracking';

type SelectControlledProps<T extends FieldValues> = {
  controllerProps: UseControllerProps<T>;
  onChangeCustom?: (e: SelectValue) => void;
  tracking?: Tracking;
} & SelectProps<SelectValue>;

const SelectControlled = <T extends FieldValues>({
  controllerProps,
  onChangeCustom,
  tracking,
  onFocus,
  onBlur,
  ...restSelectProps
}: SelectControlledProps<T>): JSX.Element => {
  const { field: props } = useController(controllerProps);
  const { onFocus: trackingFocus, onBlur: trackingBlur } =
    useFormControllerTracking({
      tracking,
      controllerProps: props,
    });

  const onChange = (e: SelectValue) => {
    props.onChange(e ?? null);
    onChangeCustom?.(e);
  };

  return (
    <Select
      {...props}
      {...restSelectProps}
      onChange={onChange}
      onFocus={(e) => {
        onFocus?.(e);
        trackingFocus();
      }}
      onBlur={(e) => {
        onBlur?.(e);
        trackingBlur();
      }}
    />
  );
};

export default SelectControlled;
