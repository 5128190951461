import { Popover, Row, Col } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import styles from './trimLevelHint.styles.less';

export const TrimLevelHint = () => {
  return (
    <>
      <span data-qa-selector="trim-level-label">Trim Level</span>
      <span className={styles.icon} data-qa-selector="trim-level-icon">
        <Popover
          placement="left"
          content={
            <div data-qa-selector="trim-level-hint-content">
              <Row justify="center">
                <Col>
                  <ul className={styles.list}>
                    <li>
                      For models that have 3 trim levels: 1, 3, 5 should be
                      used. (ex. Toyota Corolla)
                    </li>
                    <li>
                      For models that have 4 trim levels: 1, 2, 4, 5 should be
                      used (ex. Honda Civic)
                    </li>
                    <li>
                      For models that have 5 trim levels: 1, 2, 3, 4, 5 should
                      be used (ex. Opel Insignia)
                    </li>
                  </ul>
                </Col>
              </Row>
              <Row justify="center">
                <Col>
                  <table className={styles.table}>
                    <thead>
                      <tr>
                        <th>Trim level</th>
                        <th>
                          Toyota Corolla
                          <br />
                          (3 Levels)
                        </th>
                        <th>
                          Honda Civic
                          <br />
                          (4 Levels)
                        </th>
                        <th>
                          Opel Insignia <br />
                          (5 Levels)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>5 - Fully loaded</td>
                        <td>XSE</td>
                        <td>Touring</td>
                        <td>Business Edition</td>
                      </tr>
                      <tr>
                        <td>4 - Upper mid</td>
                        <td></td>
                        <td>EX</td>
                        <td>Dynamic</td>
                      </tr>
                      <tr>
                        <td>3 - Mid range</td>
                        <td>SE</td>
                        <td></td>
                        <td>Innovation</td>
                      </tr>
                      <tr>
                        <td>2 - Lower mid</td>
                        <td></td>
                        <td>Sport</td>
                        <td>Style</td>
                      </tr>
                      <tr>
                        <td>1 - Basic</td>
                        <td>LE</td>
                        <td>LX</td>
                        <td>Edition</td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </div>
          }
        >
          <QuestionCircleOutlined />
        </Popover>
      </span>
    </>
  );
};
