import { LiteralUnion } from '@src/types/utilTypes';
import { FieldError, FieldErrors } from 'react-hook-form';
import { FormNamesUnion, FormType } from '.';

type FieldsMapKeys = LiteralUnion<FormNamesUnion, string>;
//PRO TIP: use can use {NUMBER} for the array's index.  Example: `obj.array.{NUMBER}.someField`
export const defaultHeaderByField: Partial<Record<FieldsMapKeys, string>> = {
  'adMgmt.general.data.bpmMajorUnits': 'BPM',
  'adMgmt.general.data.carType': 'Car Type',
  'adMgmt.general.data.grossPriceMajorUnits': 'Gross Vehicle Price',
  'adMgmt.general.data.mileage.distance': 'Mileage',
  'adMgmt.general.data.taxRating': 'Tax Rating',
  'adMgmt.general.data.subType': 'Subtype',
  'adMgmt.general.data.gearType': 'Gear Type',
  'adMgmt.general.data.builtYear': 'Built Year',
  'adMgmt.general.data.fuelType': 'Fuel Type',
  'adMgmt.general.data.co2Value': 'CO2',
  'adMgmt.general.data.handoverKeyCount': 'Number of Keys',
  'adMgmt.general.data.fuelConsumption.city': 'City (Emission and Consumption)',
  'adMgmt.general.data.fuelConsumption.highway':
    'Highway (Emission and Consumption)',
  'adMgmt.general.data.fuelConsumption.combined':
    'Combined (Emission and Consumption)',
  'adMgmt.general.data.carPreownerCount':
    'Pre-owners (Vehicle history and Service)',
  'adMgmt.general.data.upholsteryColor': 'Upholstery Color',
  'adMgmt.general.data.driveTrain': 'Drivetrain',
  'adMgmt.general.data.doorCount': 'Door count',
  'adMgmt.general.data.hasFullServiceHistory': 'Full Service History',
  'adMgmt.general.data.oemPaintName': 'OEM Paint Name',
  'adMgmt.general.data.metallic': 'Metallic',
  'adMgmt.general.data.isNonSmokerVehicle': 'Non smoker vehicle',
  'adMgmt.autoheroAndWkda.secondaryWheels.data.grossPrice':
    'Gross Price (Secondary wheels)',
  'adMgmt.autoheroAndWkda.secondaryWheels.data.damageCondition':
    'Damage Condition (Secondary wheels)',
  'adMgmt.autoheroAndWkda.secondaryWheels.data.manufacturer.frontLeft':
    'Manufacturer - Front left (Secondary wheels)',
  'adMgmt.autoheroAndWkda.secondaryWheels.data.manufacturer.frontRight':
    'Manufacturer - Front right (Secondary wheels)',
  'adMgmt.autoheroAndWkda.secondaryWheels.data.manufacturer.rearLeft':
    'Manufacturer - Back left (Secondary wheels)',
  'adMgmt.autoheroAndWkda.secondaryWheels.data.manufacturer.rearRight':
    'Manufacturer - Back right (Secondary wheels)',
  'adMgmt.autoheroAndWkda.secondaryWheels.data.dot.frontLeft':
    'DOT - Front left (Secondary wheels)',
  'adMgmt.autoheroAndWkda.secondaryWheels.data.dot.frontRight':
    'DOT - Front right (Secondary wheels)',
  'adMgmt.autoheroAndWkda.secondaryWheels.data.dot.rearLeft':
    'DOT - Back left (Secondary wheels)',
  'adMgmt.autoheroAndWkda.secondaryWheels.data.dot.rearRight':
    'DOT - Back right (Secondary wheels)',
  'adMgmt.autoheroAndWkda.secondaryWheels.data.seasonType.frontLeft':
    'Season - Front left (Secondary wheels)',
  'adMgmt.autoheroAndWkda.secondaryWheels.data.seasonType.frontRight':
    'Season - Front right (Secondary wheels)',
  'adMgmt.autoheroAndWkda.secondaryWheels.data.seasonType.rearLeft':
    'Season - Back left (Secondary wheels)',
  'adMgmt.autoheroAndWkda.secondaryWheels.data.seasonType.rearRight':
    'Season - Back right (Secondary wheels)',
  'adMgmt.autoheroAndWkda.secondaryWheels.data.treadDepth.frontLeft':
    'Profile depth - Front left (Secondary wheels)',
  'adMgmt.autoheroAndWkda.secondaryWheels.data.treadDepth.frontRight':
    'Profile depth - Front right (Secondary wheels)',
  'adMgmt.autoheroAndWkda.secondaryWheels.data.treadDepth.rearLeft':
    'Profile depth - Back left (Secondary wheels)',
  'adMgmt.autoheroAndWkda.secondaryWheels.data.treadDepth.rearRight':
    'Profile depth - Back right (Secondary wheels)',
  'adMgmt.autoheroAndWkda.secondaryWheels.data.material.frontLeft':
    'Rim type - Front left (Secondary wheels)',
  'adMgmt.autoheroAndWkda.secondaryWheels.data.material.frontRight':
    'Rim type - Front right (Secondary wheels)',
  'adMgmt.autoheroAndWkda.secondaryWheels.data.material.rearLeft':
    'Rim type - Back left (Secondary wheels)',
  'adMgmt.autoheroAndWkda.secondaryWheels.data.material.rearRight':
    'Rim type - Back right (Secondary wheels)',
  'adMgmt.autoheroAndWkda.secondaryWheels.data.speedIndex.frontLeft':
    'Speed Index - Front left (Secondary wheels)',
  'adMgmt.autoheroAndWkda.secondaryWheels.data.speedIndex.frontRight':
    'Speed Index - Front right (Secondary wheels)',
  'adMgmt.autoheroAndWkda.secondaryWheels.data.speedIndex.rearLeft':
    'Speed Index - Back left (Secondary wheels)',
  'adMgmt.autoheroAndWkda.secondaryWheels.data.speedIndex.rearRight':
    'Speed Index - Back right (Secondary wheels)',
  'adMgmt.exportOverview.data.publishingBlockers.{NUMBER}.reasonId':
    'Publishing blocker reason',
  'adMgmt.exportOverview.data.publishingBlockers.{NUMBER}.subReasonId':
    'Publishing blocker subreason',
  'adMgmt.exportOverview.data.publishingBlockers.{NUMBER}.comment':
    'Publishing blocker comment',
  'adMgmt.autoheroAndWkda.featureDetails.data.{NUMBER}.items.{NUMBER}.group.name':
    'Equipment - Feature Group',
  'adMgmt.autoheroAndWkda.featureDetails.data.{NUMBER}.items.{NUMBER}.subGroup.name':
    'Equipment - Feature Subgroup',
  'adMgmt.autoscout24.data.title': 'Autoscout24 - Subtitle',
  'adMgmt.autoheroAndWkda.featureDetails.data': 'Autohero and WKDA - Equipment',
  'adMgmt.mobiledeV2.data.title': 'Mobile - Subtitle',
  'adMgmt.general.data.manufacturerCode': 'HSN',
  'adMgmt.general.data.uniqueSellingPropositions.usp1': 'USP 1',
  'adMgmt.general.data.uniqueSellingPropositions.usp2': 'USP 2',
  'adMgmt.general.data.uniqueSellingPropositions.usp3': 'USP 3',
  'adMgmt.general.data.uniqueSellingPropositions': 'USPs',
  'adMgmt.autoheroAndWkda.specsSpotlight.data.topFeatureDetails':
    'Specs Spotlight - Top Features',
  'adMgmt.autoheroAndWkda.specsSpotlight.data.topHighlights':
    'Specs Spotlight - Top Highlights',
};

const isErrorField = (object: unknown): object is FieldError =>
  typeof object === 'object' &&
  object !== null &&
  'message' in object &&
  'type' in object;

type FormErrors = FieldErrors<FormType>;
export const toFlatPropertyErrorMap = (
  obj: FormErrors,
  keySeparator = '.',
): Partial<Record<FormNamesUnion, FieldError>> => {
  const flattenRecursive = (
    obj: FormErrors | Record<keyof object, unknown>,
    parentProperty?: FormNamesUnion,
    propertyMap: Partial<Record<FormNamesUnion, FieldError>> = {},
  ): typeof propertyMap => {
    for (const [key, value] of Object.entries(obj)) {
      const property: FormNamesUnion = parentProperty
        ? (`${parentProperty}${keySeparator}${key}` as FormNamesUnion)
        : (key as FormNamesUnion);

      if (value && typeof value === 'object' && !isErrorField(value)) {
        flattenRecursive(value, property, propertyMap);
      } else {
        propertyMap[property] = value;
      }
    }
    return propertyMap;
  };
  return flattenRecursive(obj);
};

type getFormErrorsReturn = {
  field: string;
  message: string;
  path: FormNamesUnion;
}[];
export const getFormErrors = (formErrors: FormErrors): getFormErrorsReturn => {
  const flattedErrors = toFlatPropertyErrorMap(formErrors);
  return Object.entries(flattedErrors).map(([path, value]) => {
    //the tuple of operations for replacing in the format [search, replacer][] to make it more universal
    const universalisationOps: ReadonlyArray<
      readonly [RegExp | string, string]
    > = [[/\d+/gm, '{NUMBER}']];
    const universalPath = universalisationOps.reduce(
      (key, [search, replacer]) => {
        return key.replace(search, replacer);
      },
      path,
    );

    const looseMatch =
      Object.keys(defaultHeaderByField).find((key) =>
        universalPath.includes(key),
      ) ?? '';
    // firstly direct hit, then a more common/universal key or the key itself, then loose match and just return path
    const field =
      defaultHeaderByField[path] ??
      defaultHeaderByField[universalPath] ??
      defaultHeaderByField[looseMatch] ??
      path;

    const message = value?.message ?? 'Error, please check field';

    return {
      field,
      message,
      path: path as FormNamesUnion,
    };
  });
};
