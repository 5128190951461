// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tSOkiGObqQ6yxrUEE95I {\n  position: relative;\n  width: 100%;\n  height: 100%;\n}\n.LjbdIM3wV3MyM6Y6qC9q {\n  width: 100%;\n  height: 100%;\n  -o-object-fit: contain;\n     object-fit: contain;\n}\n.tMHBKGQ278Oho3_llO42 {\n  display: none;\n}\n.SiIsOLnVoIutuLjRarMl {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  width: 100%;\n  height: 100%;\n  background: #ffffff;\n}\n.TMwaptfblaa3AH_PVUUX {\n  overflow: hidden;\n  white-space: normal;\n  text-overflow: ellipsis;\n  line-height: 1.2;\n}\n.dcF_KYbHoT2utlJAHVC2 {\n  font-size: 32px;\n}\n", "",{"version":3,"sources":["webpack://./src/sections/General/VehicleHistoryAndService/ServiceHistory/ImageThumbnail/styles.less"],"names":[],"mappings":"AACA;EACE,kBAAA;EAEA,WAAA;EACA,YAAA;AADF;AAIA;EACE,WAAA;EACA,YAAA;EACA,sBAAA;KAAA,mBAAA;AAFF;AAKA;EACE,aAAA;AAHF;AAMA;EACE,oBAAA;EAAA,oBAAA;EAAA,aAAA;EACA,yBAAA;MAAA,sBAAA;UAAA,mBAAA;EACA,wBAAA;MAAA,qBAAA;UAAA,uBAAA;EAEA,WAAA;EACA,YAAA;EAEA,mBAAA;AANF;AASA;EACE,gBAAA;EAEA,mBAAA;EACA,uBAAA;EAEA,gBAAA;AATF;AAYA;EACE,eAAA;AAVF","sourcesContent":["@antVersion: antd-5-18-2;\n.imageContainer {\n  position: relative;\n\n  width: 100%;\n  height: 100%;\n}\n\n.image {\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n}\n\n.hide {\n  display: none;\n}\n\n.imagePlaceholder {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  width: 100%;\n  height: 100%;\n\n  background: #ffffff;\n}\n\n.imagePlaceholderText {\n  overflow: hidden;\n\n  white-space: normal;\n  text-overflow: ellipsis;\n\n  line-height: 1.2;\n}\n\n.fileIcon {\n  font-size: 32px;\n}\n\n@ant-prefix: antd-5-18-2;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageContainer": "tSOkiGObqQ6yxrUEE95I",
	"image": "LjbdIM3wV3MyM6Y6qC9q",
	"hide": "tMHBKGQ278Oho3_llO42",
	"imagePlaceholder": "SiIsOLnVoIutuLjRarMl",
	"imagePlaceholderText": "TMwaptfblaa3AH_PVUUX",
	"fileIcon": "dcF_KYbHoT2utlJAHVC2"
};
export default ___CSS_LOADER_EXPORT___;
