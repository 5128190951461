import { Row, Button, Col, Form } from 'antd';
import InputControlled from '@components/formControlled/InputControlled';
import SelectControlled from '@components/formControlled/SelectControlled';
import { SelectValue } from 'antd/lib/select';
import { useState, useMemo } from 'react';
import cn from './styles.less';
import { usePageData } from '@hooks/usePageData';
import { useAppForm } from '@hooks/useAppForm';
import {
  POSSIBLE_PUBLISHING_STATUSES,
  PUBLISHING_CONTEXTS,
  MARKETPLACES,
  TRACKING_SECTION,
  TRACKING_SECTION_CATEGORY,
} from '@src/constants';
import {
  PublishingBlockerReason,
  PublishingBlockerSubreason,
} from '@gql_codegen/retail-types';
import { useWatch } from 'react-hook-form';
import { useAnalytics } from '@hooks/useAnalytics';

export type PublishingBlockerProps = {
  handleRemoveBlocker: (index: number) => void;
  index: number;
};

export const PublishingBlockerItem = (
  props: PublishingBlockerProps,
): JSX.Element => {
  const { handleRemoveBlocker, index } = props;
  const { data } = usePageData();
  const { getValues, formState } = useAppForm();
  const { errors: formErrors } = formState;
  const blockersFormState = getValues(
    'adMgmt.exportOverview.data.publishingBlockers',
  );
  const ahExportData = getValues('adMgmt.exportOverview.exportItems')?.find(
    (item) => item.marketplace.id.toUpperCase() === MARKETPLACES.AUTOHERO,
  );
  const isAhUnpublished =
    useWatch({
      name: 'adMgmt.exportOverview.exportItems.0.publishingStatus',
    }) === POSSIBLE_PUBLISHING_STATUSES.UNPUBLISHED;

  const track = useAnalytics();

  const isComingSoon = ahExportData?.comingSoon ?? null;
  const blocker = blockersFormState[index];
  const blockerReasonsOptions =
    data?.adMgmt.dictionaries.global.publishingBlockersReasons ?? [];
  const blockerReasonsOptionsMapped = blockerReasonsOptions.map((reason) => ({
    label: reason.label,
    value: reason.value,
  }));
  const restrictions = data?.adMgmt.exportOverview.restrictions;
  const [subReason, setSubReason] = useState<SelectValue>(blocker?.subReasonId);

  const [blockerSubreasonsOptions, setOptions] = useState<
    (Omit<PublishingBlockerSubreason, 'publishingContexts'> & {
      disabled?: boolean;
    })[]
  >(() => {
    if (blocker?.reasonId) {
      return (
        blockerReasonsOptions.find(
          (reason: PublishingBlockerReason) =>
            reason.value === blocker.reasonId,
        )?.subReasons ?? []
      );
    }
    return [];
  });

  const isNew = useMemo(
    () =>
      !data?.adMgmt.exportOverview.data.publishingBlockers.some(
        (item) => blocker?.subReasonId === item.subReasonId,
      ),
    [blocker, data?.adMgmt.exportOverview.data.publishingBlockers],
  );

  const handleReasonChange = (reasonId: SelectValue) => {
    const filteredOptions: typeof blockerSubreasonsOptions =
      blockerReasonsOptions
        .find((reason: PublishingBlockerReason) => reason.value === reasonId)
        ?.subReasons.map((subReason) => {
          const subReasonAlreadySelected = blockersFormState.some(
            (blocker) =>
              blocker.reasonId === reasonId &&
              blocker.subReasonId === subReason.value,
          );

          const isComingSoonBlocker =
            !isAhUnpublished &&
            isComingSoon &&
            subReason.publishingContexts.includes(
              PUBLISHING_CONTEXTS.COMING_SOON,
            );

          return subReasonAlreadySelected || isComingSoonBlocker
            ? { value: subReason.value, label: subReason.label, disabled: true }
            : { value: subReason.value, label: subReason.label };
        }) ?? [];
    setSubReason('');
    setOptions(filteredOptions);
    track(
      {
        eventType: 'edit',
        eventCategory: 'modify',
        section: TRACKING_SECTION.BLOCKERS,
        sectionCategory: TRACKING_SECTION_CATEGORY.AH,
        fieldId: 'Reason Subreason',
      },
      {
        value: {
          ...blocker,
          reasonId,
        },
      },
    );
  };

  const handleSubReasonChange = (subReasonId: SelectValue) => {
    setSubReason(subReasonId);
    track(
      {
        eventType: 'edit',
        eventCategory: 'modify',
        section: TRACKING_SECTION.BLOCKERS,
        sectionCategory: TRACKING_SECTION_CATEGORY.AH,
        fieldId: 'Reason Subreason',
      },
      {
        value: {
          ...blocker,
          subReasonId,
        },
      },
    );
  };

  return (
    <div className={cn.blockerItem} data-qa-selector={`blockerItem-${index}`}>
      <Row align="middle" gutter={16}>
        <Col span={5}>
          <Form.Item
            label="Reason"
            required={true}
            validateStatus={
              formErrors.adMgmt?.exportOverview?.data?.publishingBlockers?.[
                index
              ]?.reasonId
                ? 'error'
                : 'success'
            }
            help={
              <div data-qa-selector={`blockerReason-${index}-explain-error`}>
                {formErrors.adMgmt?.exportOverview?.data?.publishingBlockers?.[
                  index
                ]?.reasonId?.message?.toString()}
              </div>
            }
          >
            <SelectControlled
              disabled={!isNew}
              data-qa-selector={`blockerReason-${index}`}
              onChangeCustom={handleReasonChange}
              options={blockerReasonsOptionsMapped}
              controllerProps={{
                name: `adMgmt.exportOverview.data.publishingBlockers.${index}.reasonId`,
              }}
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item
            label="Subreason"
            required={true}
            validateStatus={
              formErrors.adMgmt?.exportOverview?.data?.publishingBlockers?.[
                index
              ]?.subReasonId
                ? 'error'
                : 'success'
            }
            help={
              <div data-qa-selector={`blockerSubReason-${index}-explain-error`}>
                {formErrors.adMgmt?.exportOverview?.data?.publishingBlockers?.[
                  index
                ]?.subReasonId?.message?.toString()}
              </div>
            }
          >
            <SelectControlled
              disabled={!isNew}
              data-qa-selector={`blockerSubReason-${index}`}
              options={blockerSubreasonsOptions}
              value={subReason}
              onChangeCustom={handleSubReasonChange}
              controllerProps={{
                name: `adMgmt.exportOverview.data.publishingBlockers.${index}.subReasonId`,
              }}
            />
          </Form.Item>
        </Col>
        {restrictions?.canRemovePublishingBlocker && (
          <Col span={2}>
            <Button
              style={{ background: 'black', color: 'white', marginTop: '5px' }} // temporary untill we found a way to override antd styles
              data-qa-selector={`blockerRemoveBtn-${index}`}
              onClick={() => handleRemoveBlocker(index)}
            >
              &#10005;
            </Button>
          </Col>
        )}
      </Row>
      <Row>
        <Col span={10}>
          <Form.Item
            label="Comment"
            required={true}
            validateStatus={
              formErrors.adMgmt?.exportOverview?.data?.publishingBlockers?.[
                index
              ]?.comment
                ? 'error'
                : 'success'
            }
            help={
              <div data-qa-selector={`blockerComment-${index}-explain-error`}>
                {
                  formErrors.adMgmt?.exportOverview?.data?.publishingBlockers?.[
                    index
                  ]?.comment?.message
                }
              </div>
            }
          >
            <InputControlled
              disabled={!isNew}
              maxLength={255}
              data-qa-selector={`blockerComment-${index}`}
              controllerProps={{
                name: `adMgmt.exportOverview.data.publishingBlockers.${index}.comment`,
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};
