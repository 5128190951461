// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._PxtTKiMbps0dLiHuCtX {\n  position: absolute;\n  z-index: 1001;\n  top: 50%;\n  left: 50%;\n  -webkit-transform: translate(-50%, -50%);\n          transform: translate(-50%, -50%);\n}\n.bwanxxu21aQ3CBMyzYmQ {\n  position: relative;\n}\n", "",{"version":3,"sources":["webpack://./src/sections/Classifieds/AutoheroWKDA/Equipment/ImportEquipmentFromCSV/ImportModal/styles.less"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,aAAA;EACA,QAAA;EACA,SAAA;EAEA,wCAAA;UAAA,gCAAA;AADF;AAIA;EACE,kBAAA;AAFF","sourcesContent":["@antVersion: antd-5-18-2;\n.loadingSpinner {\n  position: absolute;\n  z-index: 1001; // taking in consideration that ant design modal has 1000 zindex\n  top: 50%;\n  left: 50%;\n\n  transform: translate(-50%, -50%);\n}\n\n.importModalContainer {\n  position: relative;\n}\n\n@ant-prefix: antd-5-18-2;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingSpinner": "_PxtTKiMbps0dLiHuCtX",
	"importModalContainer": "bwanxxu21aQ3CBMyzYmQ"
};
export default ___CSS_LOADER_EXPORT___;
