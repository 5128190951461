import { Form } from 'antd';
import { useAppForm } from '@hooks/useAppForm';
import InputControlled from '@components/formControlled/InputControlled';
import { TRACKING_SECTION, TRACKING_SECTION_CATEGORY } from '@src/constants';

export const Autoscout24Title = () => {
  const {
    formState: { errors },
  } = useAppForm();

  return (
    <Form.Item
      data-qa-selector="classifieds-autoscout24-title"
      label="Autoscout24 subtitle"
      validateStatus={
        errors.adMgmt?.autoscout24?.data?.title ? 'error' : 'success'
      }
      help={errors.adMgmt?.autoscout24?.data?.title?.message}
    >
      <InputControlled
        data-qa-selector="classifieds-autoscout24-title-input"
        placeholder="Title"
        controllerProps={{
          name: 'adMgmt.autoscout24.data.title',
        }}
        tracking={{
          eventCategory: 'modify',
          fieldId: 'Subtitle',
          section: TRACKING_SECTION.AS24_DESCRIPTION,
          sectionCategory: TRACKING_SECTION_CATEGORY.CL,
        }}
        maxLength={50}
        showCount
      />
    </Form.Item>
  );
};
