import { useRef } from 'react';

type FileDropZoneProps = React.PropsWithChildren<{
  onDrag?: () => void;
  onDragEnd?: () => void;
  onDragEnter?: () => void;
  onDragLeave?: () => void;
  onDragOver?: () => void;
  onDrop?: (event: React.DragEvent<HTMLSpanElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  multiple?: boolean;
}>;

export const FileDropZone = ({
  onDrag,
  onDragEnd,
  onDragEnter,
  onDragLeave,
  onDragOver,
  onDrop,
  onChange,
  multiple,
  children,
}: FileDropZoneProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const ondblclick = () => {
    (inputRef.current as HTMLInputElement).click();
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <span
      onClick={ondblclick}
      onDrag={onDrag}
      onDragEnd={onDragEnd}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={onDrop}
    >
      <input
        style={{ display: 'none' }}
        type="file"
        multiple={multiple}
        ref={inputRef}
        onChange={onInputChange}
        data-qa-selector="file-drop-zone"
      />
      {children}
    </span>
  );
};

export const convertFileListIntoArray = (fileList: FileList | null): File[] => {
  if (!fileList) {
    return [];
  }

  return Array.from(fileList);
};
