import { Button, notification } from 'antd';
import { useState } from 'react';
import { useEquipment } from '../useEquipment';
import { ImportModal } from './ImportModal';

export const ImportEquipmentFromCSV = () => {
  const { restrictions, processCSV } = useEquipment();
  const [showImportModal, setShowImportModal] = useState(false);

  const handleImportSuccess = (csv: string) => {
    processCSV(csv);
    setShowImportModal(false);
  };

  return (
    <>
      <Button
        type="primary"
        data-qa-selector="upload-csv-button"
        onClick={() => setShowImportModal(true)}
        disabled={!restrictions.canAddFeatureDetail}
      >
        Upload CSV
      </Button>
      {restrictions.canAddFeatureDetail && (
        <ImportModal
          show={showImportModal}
          onSuccess={handleImportSuccess}
          onError={(error) =>
            notification.error({
              message: 'Equipment CSV upload failed. Please try again',
              description: error?.message,
            })
          }
          handleCancel={() => setShowImportModal(false)}
        />
      )}
    </>
  );
};
