import { Subsection } from '@components/Subsection';
import { useAppForm } from '@hooks/useAppForm';
import { ComponentProps } from 'react';
import { useFormState, useWatch } from 'react-hook-form';
import { dataPaths } from './constants';

type EquipmentSubsectionProps = Omit<
  ComponentProps<typeof Subsection>,
  'title'
> & { title: (totalEquipment: number) => string };
export const EquipmentSubsection = (props: EquipmentSubsectionProps) => {
  const { control } = useAppForm();

  const { errors: featureDetailDataFormStateErrors } = useFormState({
    name: dataPaths.featureDetails,
    control,
  });

  const featureDetailsData = useWatch({
    name: dataPaths.featureDetails,
    control,
    exact: true,
  });

  const totalEquipment = featureDetailsData.reduce(
    (total, featureDetail) => total + featureDetail.items.length,
    0,
  );

  const hasEquipmentErrors =
    Object.keys(
      featureDetailDataFormStateErrors.adMgmt?.autoheroAndWkda?.featureDetails
        ?.data ?? {},
    ).length > 0;

  return (
    <Subsection
      {...props}
      isVisible
      title={props.title(totalEquipment)}
      folded={hasEquipmentErrors ? false : undefined}
    >
      {props.children}
    </Subsection>
  );
};
