import { Fragment, useState } from 'react';
import cls from 'classnames';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { Modal } from 'antd';
import { CloseOutlined, LinkOutlined } from '@ant-design/icons';
import { ServiceHistoryDocumentFileType } from '@gql_codegen/retail-types';
import { ImageThumbnail } from '../ImageThumbnail';
import cn from './styles.less';

type DocumentsModalProps = {
  showModal: boolean;
  documentPosition: number | undefined;
  mappedDocuments: ReactImageGalleryItem[];
  onModalClose(): void;
};

export const DocumentsModal = ({
  showModal,
  documentPosition,
  mappedDocuments,
  onModalClose,
}: DocumentsModalProps) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const renderItem = (document: ReactImageGalleryItem) => {
    const content = (
      <div
        className={cls(cn.documentContainer, {
          [cn.fullScreenDocumentContainer]: isFullScreen,
        })}
      >
        {document.description === 'PDF' ? (
          <iframe
            className={cn.iframe}
            src={document.original}
            title="document"
            data-qa-selector="pdf-document"
          />
        ) : (
          <img
            className={cn.image}
            src={document.original}
            alt={document.originalAlt}
            data-qa-selector="image-document"
          />
        )}
      </div>
    );
    return (
      <Fragment>
        {content}
        <a
          className={cn.externalLink}
          href={document.original}
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkOutlined className={cn.iconShadow} />
        </a>
      </Fragment>
    );
  };

  return (
    <>
      {showModal ? (
        <Modal
          title="Documents"
          open={showModal}
          onCancel={onModalClose}
          width={900}
          footer={null}
          closeIcon={
            <CloseOutlined data-qa-selector="documents-modal-close-button" />
          }
        >
          <div className="document-modal-wrapper document-modal-image-wrapper">
            <ImageGallery
              showIndex
              showPlayButton={false}
              items={mappedDocuments}
              startIndex={documentPosition}
              onScreenChange={(fullScreen) => setIsFullScreen(fullScreen)}
              renderItem={renderItem}
              renderThumbInner={(item) => {
                return (
                  <ImageThumbnail
                    document={{
                      id: item.originalAlt || '',
                      fileType:
                        item.description as ServiceHistoryDocumentFileType,
                      url: item.original,
                      thumbnailUrl: item.thumbnail as string,
                    }}
                  />
                );
              }}
              renderFullscreenButton={(onClick, isFullScreen) => (
                <button
                  type="button"
                  onClick={onClick}
                  className={`image-gallery-fullscreen-button ${
                    isFullScreen ? 'active' : ''
                  }`}
                  data-qa-selector="service-history-gallery-fullscreen"
                />
              )}
            />
          </div>
        </Modal>
      ) : null}
    </>
  );
};
