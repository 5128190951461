import { FileDropZone } from '@components/FileDropZone';
import useAdId from '@hooks/useAdId';
import { useAppForm } from '@hooks/useAppForm';
import { formatDateTime } from '@utils/dateFormatters';
import { getVideoUploadCredentials, uploadVideoToS3 } from '@utils/s3-uploader';
import { Button, Input, notification } from 'antd';
import { useController } from 'react-hook-form';
import cn from './styles.less';
import { convertFileListIntoArray } from '@components/FileDropZone';
import { retailKibanaLogger } from '@utils/logger';
import { useAnalytics } from '@hooks/useAnalytics';

type VideoFieldNames = 'adMgmt.autoheroAndWkda.media.data.video';

type VideoFieldProps = {
  name?: VideoFieldNames;
  tracking?: {
    section: string;
    sectionCategory: string;
    fieldId: string;
  };
};

export const VideoField = ({
  name = 'adMgmt.autoheroAndWkda.media.data.video',
  tracking,
}: VideoFieldProps): JSX.Element => {
  const { control } = useAppForm();
  const adId = useAdId();
  const {
    field: { value, onChange },
  } = useController({
    control,
    name,
  });
  const track = useAnalytics();
  const { originalUrl, createdAt, resizedVideos } = value || {};

  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const files = convertFileListIntoArray(event?.target?.files);
      if (!adId) {
        throw new Error(
          'No AdId Available. Video cannot be uploaded without AdId',
        );
      }
      const credentials = await getVideoUploadCredentials(adId, files);
      await uploadVideoToS3(files, credentials);
      const [credential] = credentials;
      const originalUrl = credential?.fileName;
      const newValue = {
        ...value,
        originalUrl,
      };
      if (tracking) {
        track(
          {
            eventType: 'edit',
            eventCategory: 'modify',
            section: tracking.section,
            sectionCategory: tracking.sectionCategory,
            fieldId: tracking.fieldId,
          },
          {
            value: newValue,
            oldValue: value,
          },
        );
      }
      onChange(newValue);
    } catch (error) {
      console.error('Something went wrong while uploading video:', error);
      notification.error({
        message: `Video upload has failed. Please try again after some moment`,
      });
      retailKibanaLogger.warn('Video upload has failed.', error);
    }
  };

  return (
    <>
      <h3>Video</h3>
      <Input.Group compact>
        <Input
          data-qa-selector="video-upload-input"
          style={{ width: 'calc(100% - 116px)' }}
          disabled
          value={originalUrl}
        />
        <FileDropZone onChange={handleUpload}>
          <Button data-qa-selector="video-upload-button" type="primary">
            Upload Video
          </Button>
        </FileDropZone>
      </Input.Group>
      {resizedVideos && createdAt && (
        <div data-qa-selector="resized-video-section" className={cn.videoData}>
          Created at: {formatDateTime(createdAt)}
          {(resizedVideos || []).map(({ resolution, url }, index) => (
            <div
              data-qa-selector={`resized-video-item-${index}`}
              key={resolution + index}
            >
              <strong>{resolution}: </strong>
              <a href={url} target="_blank" rel="noopener noreferrer">
                {url}
              </a>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
