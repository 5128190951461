import { devtoolsConfig } from '@src/configs/devtools';
import { Layout as AppLayout } from '@src/layout';
import { ImagesPage } from '@src/pages/images';
import { Outlet, RootRoute, Route, Router } from '@tanstack/react-router';
import { Spin } from 'antd';
import { Suspense, lazy } from 'react';

const TanStackRouterDevtools = devtoolsConfig.tanstackRouterDevtools.show
  ? lazy(() =>
      // Lazy load in development
      import('@tanstack/router-devtools').then((res) => ({
        default: res.TanStackRouterDevtools,
        // For Embedded Mode
        // default: res.TanStackRouterDevtoolsPanel
      })),
    )
  : () => null; // Render nothing in production

const rootRoute = new RootRoute({
  component: () => (
    <Suspense fallback={<Spin size="large" />}>
      <Outlet />
      <TanStackRouterDevtools router={router} position="top-left" />
    </Suspense>
  ),
});
const adRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '$lang/ad/$adId',
});

const indexRoute = new Route({
  getParentRoute: () => adRoute,
  path: '/',
  component: AppLayout,
});
const restFallbackRoute = new Route({
  getParentRoute: () => adRoute,
  path: '/*',
  component: AppLayout,
});
const imagesRoute = new Route({
  getParentRoute: () => adRoute,
  path: 'images',
  component: ImagesPage,
});

const routeTree = rootRoute.addChildren([
  adRoute.addChildren([imagesRoute, indexRoute, restFallbackRoute]),
]);

export const router = new Router({
  routeTree,
});

declare module '@tanstack/react-router' {
  interface RegisterRouter {
    router: typeof router;
  }
}
