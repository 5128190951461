import PackageJson from '../package.json';
import { EnumLike } from '@src/types/utilTypes';

type TPubStatuses = 'PUBLISHED' | 'UNPUBLISHED' | 'IN_PROGRESS' | 'BLOCKED';
export const POSSIBLE_PUBLISHING_STATUSES: EnumLike<TPubStatuses> = {
  PUBLISHED: 'PUBLISHED',
  UNPUBLISHED: 'UNPUBLISHED',
  IN_PROGRESS: 'IN_PROGRESS',
  BLOCKED: 'BLOCKED',
};

type TMarketplacess =
  | 'AUTOSCOUT24'
  | 'MOBILEDE'
  | 'AUTOHERO'
  | 'AUTOSCOUT24_AND_MOBILEDE'
  | 'MOBILEDE_V2';
export const MARKETPLACES: EnumLike<TMarketplacess> = {
  AUTOSCOUT24_AND_MOBILEDE: 'AUTOSCOUT24_AND_MOBILEDE',
  AUTOSCOUT24: 'AUTOSCOUT24',
  MOBILEDE: 'MOBILEDE',
  MOBILEDE_V2: 'MOBILEDE_V2',
  AUTOHERO: 'AUTOHERO',
};

export const REQUIRED_VALIDATION_MESSAGE = 'This field is required';
export const getMaxLengthValidationMessage = (chars: number) =>
  `This field is invalid (maximum is ${chars} characters).`;

export const SW_DOT_LENGTH_MESSAGE =
  'This field is invalid (should be 4 characters)';
export const MAX_24_LENGTH_MESSAGE =
  'This field is invalid (maximum is 24 characters).';

export const AUTOHERO_IMAGE_TYPES = {
  EXTERIOR: 'exterior',
  INTERIOR: 'interior',
  HIGHLIGHTS: 'nextgen_highlight',
  PANORAMA: 'panorama',
  SW_GENERAL: 'ad_secondary_wheels',
  SW_DAMAGES: 'ad_secondary_wheels_damages',
  DAMAGES: 'damages',
} as const;

export const MOBILEDE_IMAGE_TYPE = 'mobile_de';
export const AUTOSCOUT24_IMAGE_TYPE = 'autoscout24';

type TCountryCodes = 'BE' | 'AT' | 'IT' | 'DE';
export const COUNTRY_CODES: EnumLike<TCountryCodes> = {
  AT: 'AT',
  BE: 'BE',
  IT: 'IT',
  DE: 'DE',
};

// Export section
export const LINKS_IDS = {
  wkda: 'WKDA',
  autohero: 'Autohero',
  mobilede: 'mobile.de',
} as const;

export const AUTOHERO_ORIGINS_MAP = {
  qa: 'https://develop---qa-autohero.classified.auto1-test.com',
  prod: 'https://www.autohero.com',
} as const;

type ExternalUrlEnvs = 'localhost' | 'qa' | 'prod';
export const EXTERNAL_URLS_ENV: EnumLike<ExternalUrlEnvs> = {
  localhost: 'localhost',
  qa: 'qa',
  prod: 'prod',
} as const;

type TCarPassCountry =
  | 'CAR_PASS_BELGIUM'
  | 'CAR_HISTORY_FRANCE'
  | 'CAR_MEDIOAMBIENTAL_STICKER_SPAIN';
export const EXTERNAL_URL_TYPES: EnumLike<TCarPassCountry> = {
  CAR_PASS_BELGIUM: 'CAR_PASS_BELGIUM',
  CAR_HISTORY_FRANCE: 'CAR_HISTORY_FRANCE',
  CAR_MEDIOAMBIENTAL_STICKER_SPAIN: 'CAR_MEDIOAMBIENTAL_STICKER_SPAIN',
};

export type TGalleryModes = 'editTag' | 'createTag';
export const GALLERY_MODES = {
  EDIT_TAG: 'editTag',
  CREATE_TAG: 'createTag',
} as const;

export const DRAGGABLE_MODES = [
  GALLERY_MODES.CREATE_TAG,
  GALLERY_MODES.EDIT_TAG,
] as const;

export const TAGGABLE_IMAGE_TYPES = [
  AUTOHERO_IMAGE_TYPES.EXTERIOR,
  AUTOHERO_IMAGE_TYPES.INTERIOR,
  AUTOHERO_IMAGE_TYPES.HIGHLIGHTS,
] as const;

export const SERVICE_HISTORY_VALIDATION_MESSAGES = {
  CHANGE_MILEAGE:
    'New “Service mileage” added: you can now change “Mileage” under History and Service to make them match',
  ADD_LAST_SERVICE_ON:
    'New "Service date" added: you can now add "Last Service On" entry under History and Service',
  MATCH_LAST_SERVICE_ON:
    'New "Service date" added: you can now change "Last Service On" under History and Service to make them match',
} as const;

type TPubContexts = 'REGULAR' | 'COMING_SOON';
export const PUBLISHING_CONTEXTS: EnumLike<TPubContexts> = {
  REGULAR: 'REGULAR',
  COMING_SOON: 'COMING_SOON',
};

export const ANTD_PACKAGE_VERSION =
  `antd-${PackageJson.dependencies['antd']}` as const;
export const ANTD_BUNDLED_VERSION = ANTD_PACKAGE_VERSION.replaceAll('.', '-');

export const LOGGER_ENDPOINTS = {
  PROD: 'https://retail-logger.prod.retail.auto1.cloud/v1/log',
  QA: 'https://retail-logger.1.qa.retail.auto1.cloud/v1/log',
} as const;

export const TRACKING_SECTION = {
  CAR_DATA: 'CarData',
  HISTORY_SERVICE: 'HistoryService',
  HISTORY_DAMAGES: 'HistoryDamages',
  EXPORT_OVERVIEW_CL: 'ExportOverview.CL',
  EXPORT_OVERVIEW_AH: 'ExportOverview.AH',
  EMISSION_CONSUMPTION: 'EmissionConsumption',
  SECONDARY: 'Secondary',
  MEDIA: 'Media',
  EQUIPMENT: 'Equipment',
  AS24_EQUIPMENT: 'AS24.Equipment',
  MOBILE_EQUIPMENT: 'Mo.Equipment',
  AS24_DESCRIPTION: 'AS24.Description',
  MOBILE_DESCRIPTION: 'Mo.Description',
  IMPERFECTIONS: 'Imperfections',
  AS24_MEDIA: 'AS24.Media',
  MOBILE_MEDIA: 'Mo.Media',
  GENERAL: 'General',
  BLOCKERS: 'Blockers',
  BACKEND: 'BACKEND',
} as const;

export const TRACKING_SECTION_CATEGORY = {
  AH: 'AH',
  CL: 'CL',
} as const;

type TCurrencies = 'EUR' | 'PLN' | 'SEK';
export const CURRENCIES: EnumLike<TCurrencies> = {
  EUR: 'EUR',
  PLN: 'PLN',
  SEK: 'SEK',
};

export const DEFAULT_CONVERSION_MAJOR = 100;

export const LOCALES = {
  DE: 'de-DE', // http://www.localeplanet.com/icu/de-DE/index.html
  ES: 'es-ES', // http://www.localeplanet.com/icu/es-ES/index.html
  FR: 'fr-FR', // http://www.localeplanet.com/icu/fr-FR/index.html
  IT: 'it-IT', // http://www.localeplanet.com/icu/it-IT/index.html
  NL: 'nl-NL', // http://www.localeplanet.com/icu/nl-NL/index.html
  PL: 'pl-PL', // http://www.localeplanet.com/icu/pl-PL/index.html
  SV_SE: 'sv-SE', // http://www.localeplanet.com/icu/sv-SE/index.html
} as const;

export const CURRENCY_INFO_BY_CODE = {
  [CURRENCIES.EUR]: {
    conversionMajor: DEFAULT_CONVERSION_MAJOR,
    formatLocale: LOCALES.DE,
    currencySymbol: '€',
  },
  [CURRENCIES.PLN]: {
    conversionMajor: DEFAULT_CONVERSION_MAJOR,
    formatLocale: LOCALES.PL,
    currencySymbol: 'zł',
  },
  [CURRENCIES.SEK]: {
    conversionMajor: DEFAULT_CONVERSION_MAJOR,
    formatLocale: LOCALES.SV_SE,
    currencySymbol: 'kr',
  },
} as const;

export const PRODUCTION_ORDERS_PAGE = {
  PROD: 'https://www.backoffice.autohero.com/en/refurbishments?vinStockNumber=',
  QA: 'https://develop.qa.backoffice.retail.auto1.cloud/en/refurbishments?vinStockNumber=',
} as const;

export const ADMIN_REFURBISHMENTS_PAGE = {
  PROD: 'https://admin.wkda.de/car/detail/',
  QA: 'https://master.admin.auto1-test.com/car/detail/',
} as const;
