import debounce from 'lodash.debounce';
import { LOGGER } from './constants';
import { analytics } from './adMgmtPlugin';
import {
  AnalyticEvent,
  AppAnalyticsEvents,
  trackEventArgs,
  UserInteractionEvent,
} from './types';
import { storageService } from './storage';
import {
  PublisherEventTrackingUploadPreSignedUrlQuery,
  usePublisherEventTrackingUploadPreSignedUrlQuery,
} from '@gql_codegen/retail-types';
import axios from 'axios';
import { retailKibanaLogger } from '@utils/logger';
import { objectify } from 'radash';

export const DEFAULT_IDLE_PERIOD = 60 * 1000;
export const LOCK_STORAGE = '@analytics/v1/lock';
export const LOCK_SENDING = '@analytics/v1/sending_lock';

export class AppAnalytics {
  analytics = analytics;
  EMPTY_EVENT: AnalyticEvent<null> = {
    adId: '',
    eventType: '',
    fieldId: null,
    meta: null,
  };
  // FEATURE
  private isFeatureEnabled = false;
  // SESSION
  private isActive = false;
  // IDLE
  private idleTimer: NodeJS.Timeout | undefined;
  private idlePeriod = DEFAULT_IDLE_PERIOD;

  private isIdle = false;
  // INTERNET CONNECTION
  public isOnline: boolean | undefined;
  private timerId: NodeJS.Timer | number | undefined;
  private sendingInterval: number = 60 * 1000;

  private idleEvents = [
    'mousemove',
    'scroll',
    'keydown',
    'click',
    'touchstart',
    'pageshow',
  ] as const;

  private pageActiveEvents = ['pageshow', 'focus', 'resume'];
  private pageHiddenEvents = ['pagehide', 'freeze'];

  public setFeatureFlag = (value: boolean) => (this.isFeatureEnabled = value);
  public setSendingInterval = (value: number) => (this.sendingInterval = value);

  public initialize = async (): Promise<void> => {
    this.checkIdle();
    this.sessionStart();
    this.isOnline = navigator.onLine;
    this.observeState();
  };

  public addEvent = async (event: UserInteractionEvent): Promise<void> => {
    storageService.addEvent(event);
  };

  public trackEvent = async <T extends AppAnalyticsEvents, K extends keyof T>(
    { name, props, options }: trackEventArgs<T, K>,
    lock = true,
  ): Promise<void> => {
    if (this.isActive) {
      if (lock) {
        await navigator.locks.request(LOCK_STORAGE, async () => {
          await this.analytics.track(name.toString(), props, options);
        });
      } else {
        this.analytics.track(name.toString(), props, options);
      }
    } else {
      LOGGER.BASE &&
        console.log(
          `%c EVENT IS NOT TRACKED!!!`,
          'color: #000;background-color: #ff0000; font-weight: bold; font-size: 22px;',
          name.toString(),
          props,
          options,
        );
    }
  };

  public terminate = async () => {
    await this.sessionEnd();
    this.terminateIdle();
    this.terminateStateObservers();
    this.stopInterval();
    LOGGER.BASE &&
      console.log(
        `%c Analytics terminated`,
        'color: #000;background-color: #c3d7ff; font-weight: bold; font-size: 12px;',
      );
  };

  // Send events to server
  private stopInterval = async () => {
    this.sendEvents();
    clearTimeout(this.timerId);
  };

  private startInterval = async () => {
    if (this.timerId) clearTimeout(this.timerId);
    if (!this.isActive) {
      return;
    }
    LOGGER.SEND &&
      console.log(
        `%c Try to send events ${new Date().toLocaleTimeString()}`,
        'color: #000; background-color: #c7f7b6; font-weight: bolder; font-size: 12px;',
      );
    await this.sendEvents();
    LOGGER.SEND &&
      console.log(
        `%c Events were sent ${new Date().toLocaleTimeString()}`,
        'color: #000; background-color: #c7f7b6; font-weight: bolder; font-size: 12px;',
      );

    this.timerId = setTimeout(() => this.startInterval(), this.sendingInterval);
  };

  // Add listeners
  private observeState = () => {
    window.addEventListener('visibilitychange', this.handleVisibilityChange, {
      capture: true,
    });
    window.addEventListener('blur', this.handleBlur, { capture: true });
    this.pageActiveEvents.forEach((eventName) => {
      window.addEventListener(eventName, this.handleActiveState, {
        capture: true,
      });
    });
    this.pageHiddenEvents.forEach((event) => {
      window.addEventListener(event, this.handleHiddenState, { capture: true });
    });
  };

  private handleBlur = async () => {
    if (this.isActive && !document.hasFocus()) {
      await this.sessionEnd();
    }
  };

  private handleVisibilityChange = async () => {
    const state = document.visibilityState;
    if (state === 'hidden') {
      await this.handleHiddenState();
    } else if (state === 'visible') {
      await this.handleActiveState();
    }
  };

  private handleActiveState = async () => {
    if (!this.isActive) {
      await this.sessionStart();
    }
  };

  private handleHiddenState = async () => {
    if (this.isActive) {
      await this.sessionEnd();
    }
  };

  private terminateStateObservers = () => {
    window.removeEventListener('visibilitychange', this.handleVisibilityChange);
    window.removeEventListener('blur', this.handleBlur);
    this.pageActiveEvents.forEach((event) => {
      window.removeEventListener(event, this.handleActiveState);
    });
    this.pageHiddenEvents.forEach((event) => {
      window.removeEventListener(event, this.handleHiddenState);
    });
  };

  // Session
  private sessionStart = async () => {
    this.isActive = true;
    LOGGER.SESSION &&
      console.log(
        `%c sessionStart`,
        'color: #fff; background-color: #ff0000; font-weight: bolder; font-size: 12px;',
      );
    await this.trackEvent({
      name: 'sessionStart',
      props: this.EMPTY_EVENT,
    });
    await this.startInterval();
  };

  private sessionEnd = async () => {
    await this.endIdle();
    await this.trackEvent(
      { name: 'sessionEnd', props: this.EMPTY_EVENT },
      false,
    );
    LOGGER.SESSION &&
      console.log(
        `%c sessionEnd`,
        'color: #fff; background-color: #ff0000; font-weight: bolder; font-size: 12px;',
      );
    this.isActive = false;
    await this.stopInterval();
  };

  // Idle Period
  private startIdle = async () => {
    if (!this.isIdle && this.isActive) {
      this.isIdle = true;
      await this.trackEvent({
        name: 'idleStarted',
        props: this.EMPTY_EVENT,
      });
      LOGGER.IDLE &&
        console.log(
          `%c idleStarted`,
          'color: #fff; background-color: rgb(255, 117, 24); font-weight: bolder; font-size: 22px;',
          '\n this.isIdle:',
          this.isIdle,
        );
    }
    this.idleTimer = setTimeout(this.startIdle, this.idlePeriod);
  };

  private endIdle = debounce(
    async () => {
      clearTimeout(this.idleTimer);
      if (this.isIdle) {
        this.isIdle = false;
        await this.trackEvent({
          name: 'idleEnded',
          props: this.EMPTY_EVENT,
        });
        LOGGER.IDLE &&
          console.log(
            `%c idleEnded`,
            'color: #fff; background-color: rgb(255, 117, 24); font-weight: bolder; font-size: 22px;',
            '\n this.isIdle:',
            this.isIdle,
          );
      }
      this.idleTimer = setTimeout(this.startIdle, this.idlePeriod);
    },
    1000,
    {
      leading: true,
      maxWait: this.idlePeriod,
    },
  );

  // NODE IDLE - a period of time when a business does not operate or employees stop working
  private checkIdle() {
    this.idleEvents.forEach((type) =>
      window.addEventListener(type, this.endIdle, {
        capture: true,
      }),
    );
    this.idleTimer = setTimeout(this.startIdle, this.idlePeriod);
  }

  private terminateIdle() {
    this.idleEvents.forEach((type) =>
      window.removeEventListener(type, this.endIdle, {
        capture: true,
      }),
    );
    clearTimeout(this.idleTimer);
  }
  // End Idle
  private sendEvents = async () => {
    if (!this.isFeatureEnabled || !this.isOnline) {
      return;
    }
    let events: UserInteractionEvent[] = [];
    try {
      const formatPresignedUrlHeaders = (
        headers: PublisherEventTrackingUploadPreSignedUrlQuery['data']['signedHeaders'],
      ) =>
        objectify(
          headers ?? [],
          (item) => item.key ?? '',
          (item) => item.value,
        );

      const { data } =
        await usePublisherEventTrackingUploadPreSignedUrlQuery.fetcher({})();

      const dataUrl = data.url;
      if (!dataUrl) return console.log('NO ANALYTICS URL!');
      const headers = formatPresignedUrlHeaders(data.signedHeaders);

      await navigator.locks.request(LOCK_SENDING, async () => {
        await navigator.locks.request(LOCK_STORAGE, async () => {
          events = storageService.backupEvents();
        });

        await axios({
          method: 'PUT',
          url: dataUrl,
          data: events,
          headers,
        });
        LOGGER.BASE && console.info('SEND_EVENTS:Successfully111 ->', events);
        await navigator.locks.request(LOCK_STORAGE, async () => {
          storageService.removeBackup();
        });
      });
    } catch (error) {
      retailKibanaLogger.warn('Sending Events Failed', error);
      LOGGER.BASE && console.error('SEND_EVENTS:Error ->', events, error);
    }
  };
}

export const appAnalytics = new AppAnalytics();
