import { useState } from 'react';
import { useWatch } from 'react-hook-form';
import { Form } from 'antd';
import { useAppForm } from '@hooks/useAppForm';
import InputControlled from '@components/formControlled/InputControlled';
import CarHistoryLink from './link';
import isUrl from '@utils/isUrl';
import cn from './styles.less';
import { TRACKING_SECTION, TRACKING_SECTION_CATEGORY } from '@src/constants';

export const CarHistoryUrl = () => {
  const [isFocused, setIsFocused] = useState(false);
  const {
    control,
    formState: { errors },
    getValues,
  } = useAppForm();
  const url = useWatch({
    control,
    name: 'adMgmt.externalUrls.data.url',
  });
  const type = getValues('adMgmt.externalUrls.data.type');

  if (!type) {
    return null;
  }

  const label = getValues('adMgmt.externalUrls.data.label');

  return (
    <Form.Item
      data-qa-selector="general-carpass-url"
      label={label ?? ''}
      validateStatus={
        errors?.adMgmt?.externalUrls?.data?.url ? 'error' : 'success'
      }
      help={
        <div data-qa-selector="general-carpass-explain-error">
          {errors?.adMgmt?.externalUrls?.data?.url?.message}
        </div>
      }
      className={cn.container}
    >
      <InputControlled
        data-qa-selector="general-carpass-input"
        controllerProps={{
          name: 'adMgmt.externalUrls.data.url',
          defaultValue: '',
          control,
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        tracking={{
          fieldId: 'Car History URL',
          section: TRACKING_SECTION.GENERAL,
          sectionCategory: TRACKING_SECTION_CATEGORY.AH,
          eventCategory: 'modify',
        }}
      />
      <span className={cn.suffix}>
        {!isFocused && url && isUrl(url) ? <CarHistoryLink url={url} /> : null}
      </span>
    </Form.Item>
  );
};
