import { Row, Col, Form, Select, Typography } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import { usePageData } from '@hooks/usePageData';
import cn from './styles.less';
import SwitchControlled from '@components/formControlled/SwitcherControlled';
import { useAppForm } from '@hooks/useAppForm';
import { ImageGalleryField } from '@components/ImageGalleryField';
import {
  AUTOHERO_IMAGE_TYPES,
  TRACKING_SECTION,
  TRACKING_SECTION_CATEGORY,
} from '@src/constants';
import SelectControlled from '@components/formControlled/SelectControlled';
import InputNumberControlled from '@components/formControlled/InputNumberControlled';
import { getCurrencySymbolByCode } from '@utils/currency';
import { restrictFloatInput } from '@utils/validators';

import { SecondaryWheelsDetailsTable } from './SecondaryWheelsDetailsTable';
import { useWatch } from 'react-hook-form';

export const SecondaryWheels = (): JSX.Element => {
  const {
    getValues,
    formState: { errors },
  } = useAppForm();
  const swErrors = errors.adMgmt?.autoheroAndWkda?.secondaryWheels?.data;
  const { data } = usePageData();
  const isPurchaseEnabled = useWatch({
    name: 'adMgmt.autoheroAndWkda.secondaryWheels.data.isActive',
  });

  const totalDamageCondition = useWatch({
    name: 'adMgmt.autoheroAndWkda.secondaryWheels.data.damageCondition',
  });

  if (!data) return <></>;

  const { dictionaries, currencyCode } = data.adMgmt;
  const wheelsData = getValues('adMgmt.autoheroAndWkda.secondaryWheels.data');

  const location = wheelsData?.location ?? 'N/A';
  const restrictions =
    data.adMgmt.autoheroAndWkda.secondaryWheels?.restrictions;

  const canEditSWImages = restrictions?.canEditSWImages ?? false;
  const canEditPrice = restrictions?.canEditPrice ?? false;
  const isPurchaseDetailsEditable =
    restrictions?.canEditPrice && isPurchaseEnabled;
  const isTableFieldsEditable =
    (restrictions?.canEditTableFields ?? false) && isPurchaseEnabled;
  const dictionariesGlobal = dictionaries?.global ?? {};
  const damageOptions = dictionariesGlobal.secondaryWheelsDamageOptions ?? [];

  return (
    <div className="secondaryWheelsWrapper">
      <Row gutter={24}>
        <Col span={4}>
          <span className={cn.switchLabel}>Purchase:</span>
          <SwitchControlled
            checkedChildren="Enabled"
            unCheckedChildren="Disabled"
            data-qa-selector="purchaseEnabledSwitch"
            disabled={!canEditPrice}
            controllerProps={{
              name: `adMgmt.autoheroAndWkda.secondaryWheels.data.isActive`,
            }}
            tracking={{
              eventCategory: 'modify',
              section: TRACKING_SECTION.SECONDARY,
              sectionCategory: TRACKING_SECTION_CATEGORY.AH,
              fieldId: 'Secondary_Wheels_Purchase',
            }}
          />
        </Col>
        <Col span={4}>
          <span className={cn.switchLabel}>Shipping:</span>
          <SwitchControlled
            checkedChildren="Required"
            unCheckedChildren="Not Required"
            data-qa-selector="shippingRequiredSwitch"
            disabled={!isPurchaseDetailsEditable}
            controllerProps={{
              name: `adMgmt.autoheroAndWkda.secondaryWheels.data.isShippingRequired`,
            }}
            tracking={{
              eventCategory: 'modify',
              section: TRACKING_SECTION.SECONDARY,
              sectionCategory: TRACKING_SECTION_CATEGORY.AH,
              fieldId: 'Secondary_Wheels_Shipping',
            }}
          />
        </Col>
        <Col span={4}>
          <span className={cn.switchLabel}>Wheels Location:</span>
          <span data-qa-selector="swLocation">{location}</span>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item
            label="Total Damage Condition"
            required
            validateStatus={swErrors?.damageCondition ? 'error' : 'success'}
            help={
              <div data-qa-selector="totalDamageConditionsError">
                {swErrors?.damageCondition?.message}
              </div>
            }
          >
            <SelectControlled
              allowClear
              disabled={!isPurchaseDetailsEditable}
              data-qa-selector="totalDamageConditions"
              data-qa-selector-value={totalDamageCondition}
              controllerProps={{
                name: `adMgmt.autoheroAndWkda.secondaryWheels.data.damageCondition`,
              }}
              dropdownRender={(menu) => (
                <div data-qa-selector="totalDamageConditionsDropdown">
                  {menu}
                </div>
              )}
              clearIcon={
                <CloseCircleFilled data-qa-selector="totalDamageConditionsClear" />
              }
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.SECONDARY,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Secondary_Wheels_Total Damage Condition',
              }}
            >
              {damageOptions.map(({ value, label }) => (
                <Select.Option
                  value={value}
                  key={value}
                  data-qa-selector={value}
                >
                  {label}
                </Select.Option>
              ))}
            </SelectControlled>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Gross Wheels Price"
            required
            validateStatus={swErrors?.grossPrice ? 'error' : 'success'}
            help={
              <div data-qa-selector="swGrossPrice-explain-error">
                {swErrors?.grossPrice?.message}
              </div>
            }
          >
            <InputNumberControlled
              data-qa-selector="swGrossPrice"
              controllerProps={{
                name: 'adMgmt.autoheroAndWkda.secondaryWheels.data.grossPrice',
              }}
              disabled={!isPurchaseDetailsEditable}
              maxLength={8}
              min="0"
              onKeyDown={restrictFloatInput}
              addonBefore={getCurrencySymbolByCode(currencyCode)}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.SECONDARY,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Secondary_Wheels_Gross Wheels Price',
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Net Price">
            <InputNumberControlled
              data-qa-selector="swNetPrice"
              controllerProps={{
                name: 'adMgmt.autoheroAndWkda.secondaryWheels.data.netPrice',
              }}
              readOnly
              disabled
              addonBefore={getCurrencySymbolByCode(currencyCode)}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.SECONDARY,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Secondary_Wheels_Net Price',
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="VAT">
            <InputNumberControlled
              data-qa-selector="swVAT"
              controllerProps={{
                name: 'adMgmt.autoheroAndWkda.secondaryWheels.data.vatRate',
              }}
              readOnly
              disabled
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.SECONDARY,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Secondary_Wheels_VAT',
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <SecondaryWheelsDetailsTable
            isTableFieldsEditable={isTableFieldsEditable}
            options={dictionariesGlobal}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Typography.Title level={4}>Secondary Wheels</Typography.Title>
          <ImageGalleryField
            name={`adMgmt.autoheroAndWkda.secondaryWheels.data.ad_secondary_wheels`}
            type={AUTOHERO_IMAGE_TYPES.SW_GENERAL}
            noButtons={!canEditSWImages}
            qaSelector="swGeneralImages"
            tracking={{
              fieldId: 'Secondary_Wheels_Images',
              section: TRACKING_SECTION.SECONDARY,
              sectionCategory: TRACKING_SECTION_CATEGORY.AH,
            }}
          />
        </Col>
        {/*
          //TODO: may be reverted after 01.04.2024 otherwise delete it
          <Col span={12}>
            <Typography.Title level={4}>Secondary Wheels Damage</Typography.Title>
            <ImageGalleryField
              name={`adMgmt.autoheroAndWkda.secondaryWheels.data.ad_secondary_wheels_damages`}
              type={AUTOHERO_IMAGE_TYPES.SW_DAMAGES}
              noButtons={!canEditSWImages}
              qaSelector="swDamagesImages"
              tracking={{
                fieldId: 'Secondary_Wheels_Damage_Images',
                section: TRACKING_SECTION.SECONDARY,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
              }}
            />
          </Col> 
        */}
      </Row>
    </div>
  );
};
