import { useEffect, useState } from 'react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Document } from '@src/types/serviceHistoryDocument';
import { CSS } from '@dnd-kit/utilities';
import { ImageThumbnail } from '../../../ImageThumbnail';
import cn from './styles.less';

type ImageSorterProps = {
  documents: Document[];
  onItemsChange: (documents: Document[]) => void;
};

export const ImageSorter = ({
  documents,
  onItemsChange,
}: ImageSorterProps): JSX.Element => {
  const [sortedDocuments, setSortedDocuments] = useState(documents);

  useEffect(() => {
    setSortedDocuments(documents);
  }, [documents]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      setSortedDocuments((documents) => {
        const oldIndex = documents.findIndex(
          (item: Document) => item.id === active.id,
        );
        const newIndex = documents.findIndex(
          (item: Document) => item.id === over?.id,
        );
        const sortedArray = arrayMove(documents, oldIndex, newIndex);
        onItemsChange(sortedArray);
        return sortedArray;
      });
    }
  };
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={sortedDocuments}
        strategy={horizontalListSortingStrategy}
      >
        <div className={cn.sortableItemsWrapper}>
          {sortedDocuments.map((document) => (
            <SortableImage document={document} key={`item-${document.id}`} />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
};

type SortableImageProps = {
  document: Document;
};

export const SortableImage = ({ document }: SortableImageProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: document.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: 'grab',
  };
  return (
    <div
      data-qa-selector="sortableImage"
      className={cn.sortableItem}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <ImageThumbnail document={document} className={cn.sortableItemImage} />
    </div>
  );
};
