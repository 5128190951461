import { Subsection } from '@components/Subsection';
import { usePageData } from '@hooks/usePageData';
import { Equipment } from './Equipment';
import { Media } from './Media';
import { SecondaryWheels } from './SecondaryWheels';
import { Imperfections } from './Imperfections';
import { EquipmentSubsection } from './Equipment/EquipementSubsection';
import { SpecsSpotlight } from './SpecsSpotlight';
import { SpecsSpotlightSubsection } from './SpecsSpotlight/SpecsSpotlightSubsection';
import { useFeaturesFlagsQuery } from '@featureFlags/useFeaturesFlagsQuery';
import {
  FEATURE_FLAGS_COUNTRIES,
  FEATURE_FLAGS_LOCALES,
  FEATURE_FLAGS,
} from '@featureFlags/constants';

export const AutoheroWKDA = (): JSX.Element => {
  const { data, isLoading } = usePageData();
  const { data: featureFlags, isLoading: isFeatureFlagsLoading } =
    useFeaturesFlagsQuery({
      country: FEATURE_FLAGS_COUNTRIES.DE,
      locale: FEATURE_FLAGS_LOCALES.DE,
      names: [FEATURE_FLAGS.SPECS_SPOTLIGHT_SECTION],
    });

  if (isLoading || !data) return <></>;

  const { media, featureDetails, secondaryWheels, specsSpotlight } =
    data.adMgmt.autoheroAndWkda;

  return (
    <div data-qa-selector="section-classifieds-autoheroandwkda">
      {media ? (
        <Subsection
          title="Media"
          foldable
          renderable={false}
          foldBtnQaSelector="classified-autohero-wkda-media"
        >
          <Media />
        </Subsection>
      ) : null}
      <Subsection
        title="Imperfections"
        foldable
        foldBtnQaSelector="classified-autohero-wkda-imperfections"
      >
        <Imperfections />
      </Subsection>
      {secondaryWheels ? (
        <Subsection
          title="Secondary Wheels"
          foldable
          foldBtnQaSelector="classified-autohero-wkda-secondarywheels"
        >
          <SecondaryWheels />
        </Subsection>
      ) : null}
      {featureDetails ? (
        <EquipmentSubsection
          title={(totalEquipment) => `Equipment (${totalEquipment})`}
          foldable
          foldBtnQaSelector="classified-autohero-wkda-equipment"
        >
          <Equipment />
        </EquipmentSubsection>
      ) : null}
      {!isFeatureFlagsLoading &&
      featureFlags?.SPECS_SPOTLIGHT_SECTION &&
      specsSpotlight ? (
        <SpecsSpotlightSubsection
          title="Specs Spotlight"
          foldable
          foldBtnQaSelector="classified-autohero-wkda-spec-spotlight"
        >
          <SpecsSpotlight />
        </SpecsSpotlightSubsection>
      ) : null}
    </div>
  );
};
