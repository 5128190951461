import { Col, Form } from 'antd';
import InputNumberControlled from '@components/formControlled/InputNumberControlled';
import { restrictFloatInput } from '@utils/validators';
import { getCurrencySymbolByCode } from '@utils/currency';
import { TRACKING_SECTION, TRACKING_SECTION_CATEGORY } from '@src/constants';
import { useAppForm } from '@hooks/useAppForm';
import { GROSS_PRICE_PATH } from '@sections/General/CarData/GrossPrice/constants';

interface GrossPriceProps {
  canEditGrossPrice: boolean;
  currencyCode: string | undefined;
}

export const GrossPrice = ({
  canEditGrossPrice,
  currencyCode,
}: GrossPriceProps) => {
  const {
    formState: { errors },
    control,
  } = useAppForm();

  const generalErrors = errors?.adMgmt?.general?.data || {};

  return (
    <Col span={6}>
      <Form.Item
        data-qa-selector="general-gross-vehicle-price"
        label="Gross Vehicle Price"
        required
        validateStatus={
          generalErrors?.grossPriceMajorUnits ? 'error' : 'success'
        }
        help={
          <div data-qa-selector="general-gross-vehicle-price-explain-error">
            {generalErrors?.grossPriceMajorUnits?.message?.toString()}
          </div>
        }
      >
        <InputNumberControlled
          data-qa-selector="general-gross-vehicle-price-input"
          controllerProps={{
            control,
            name: GROSS_PRICE_PATH,
          }}
          maxLength={8}
          min="0"
          onKeyDown={restrictFloatInput}
          addonBefore={getCurrencySymbolByCode(currencyCode)}
          disabled={!canEditGrossPrice}
          tracking={{
            eventCategory: 'modify',
            section: TRACKING_SECTION.CAR_DATA,
            sectionCategory: TRACKING_SECTION_CATEGORY.AH,
            fieldId: 'Gross Vehicle Price',
          }}
        />
      </Form.Item>
    </Col>
  );
};
