export const isDebugEnabled = (name: string) =>
  new RegExp(name).test(window?.location?.href);

export const LOGGER = {
  BASE: isDebugEnabled('enable_base_logging'),
  IDLE: isDebugEnabled('enable_idle_logging'),
  SESSION: isDebugEnabled('enable_session_logging'),
  INTERNET_CONNECTION: isDebugEnabled('enable_internet_connection_logging'),
  SEND: isDebugEnabled('enable_send'),
};
