// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hduGN5FMPrKX1sOqtd4L {\n  margin: auto;\n  padding: 0 15px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Root/styles.less"],"names":[],"mappings":"AACA;EACE,YAAA;EAEA,eAAA;AADF","sourcesContent":["@antVersion: antd-5-18-2;\n.root {\n  margin: auto;\n\n  padding: 0 15px;\n}\n\n@ant-prefix: antd-5-18-2;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "hduGN5FMPrKX1sOqtd4L"
};
export default ___CSS_LOADER_EXPORT___;
