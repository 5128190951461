import { useCallback, useState } from 'react';
import axios from 'axios';
import { fileExtension } from '@utils/fileExtension';
import {
  Document,
  isUploaded,
  UploadedDocument,
} from '@src/types/serviceHistoryDocument';
import { createZip, ZipFileInput } from '@utils/archiver';
import FileSaver from 'file-saver';
import { notification } from 'antd';
import { retailKibanaLogger } from '@utils/logger';

export const errorMessage = 'Download Image Error';

export const getFileBuffer = async (url: string) => {
  const { data: buffer } = await axios({
    method: 'GET',
    url,
    responseType: 'arraybuffer',
  });

  return buffer;
};

export const useDownload = () => {
  const [isLoading, setIsLoading] = useState(false);
  const download = useCallback(
    async (documents: Array<Document | UploadedDocument>, type: string) => {
      setIsLoading(true);
      try {
        const files: ZipFileInput[] = await Promise.all(
          documents.map(async (document) => {
            const { id, url } = document;
            const { pathname } = new URL(url);
            const content = await getFileBuffer(url);
            const fileExt = isUploaded(document)
              ? document.fileExt
              : fileExtension(pathname);

            return {
              fileName: `${id}.${fileExt}`,
              content,
            };
          }),
        );
        const zip = await createZip(files);
        const blob = new global.Blob([zip], {
          type: 'application/octet-stream',
        });
        FileSaver.saveAs(blob, `${type}-files.zip`);
      } catch (err) {
        retailKibanaLogger.warn('Service history Image Download Failed', err);
        if (err instanceof Error) {
          notification.error({
            message: errorMessage,
            description: err.message,
          });
        } else if (typeof err === 'string') {
          notification.error({
            message: errorMessage,
            description: err,
          });
        } else {
          console.error(err);
        }
      }
      setIsLoading(false);
    },
    [],
  );

  return {
    download,
    isLoading,
  };
};
