import { usePageData } from '@hooks/usePageData';
import { Document } from '@src/types/serviceHistoryDocument';
import { Col, Row, Space } from 'antd';
import { useMemo, useRef } from 'react';
import { useResizeObserver } from 'usehooks-ts';
import { AddNewMaintenanceButton } from './Buttons/AddNewMaintenanceButton';
import { AddNewServiceButton } from './Buttons/AddNewServiceButton';
import AddMaintenanceHistoryForm from './Forms/AddMaintenanceHistoryForm';
import AddServiceHistoryForm from './Forms/AddServiceHistoryForm';
import { ServiceHistoryDocumentsPreview } from './ServiceHistoryDocumentsPreview';
import { ServiceHistoryTable } from './ServiceHistoryTable';
import useServiceHistoryRecords, {
  SERVICE_HISTORY_TYPES,
} from './useServiceHistoryRecords';
import { useServiceHistoryTasks } from './useServiceHistoryTasks';

const ServiceHistory = (): JSX.Element => {
  const {
    formData,
    showForm,
    add,
    edit,
    save,
    hide,
    records,
    remove,
    addAllowed,
  } = useServiceHistoryRecords();
  const { data } = usePageData();
  const tableRef = useRef<HTMLDivElement>(null);
  const { height: maxHeight = 0 } = useResizeObserver({
    ref: tableRef,
    box: 'border-box',
  });
  const tasksTypes = useServiceHistoryTasks();

  const documents = useMemo(
    () =>
      (records || [])
        .reduce<Document[]>((acc, record) => acc.concat(record.documents), [])
        .concat(data?.adMgmt.general.data.serviceHistory?.documents || []),
    [records, data],
  );

  const showData = records.length > 0 || documents.length > 0;

  return (
    <Row gutter={[14, 14]}>
      <AddServiceHistoryForm
        initialData={formData}
        visible={showForm === SERVICE_HISTORY_TYPES.Service}
        onCancel={hide}
        onSave={save}
      />
      <AddMaintenanceHistoryForm
        initialData={formData}
        visible={showForm === SERVICE_HISTORY_TYPES.Maintenance}
        onCancel={hide}
        onSave={save}
      />
      <Col span={24}>
        <Space>
          <AddNewServiceButton
            onClick={() => add(SERVICE_HISTORY_TYPES.Service)}
            allowed={addAllowed}
          />
          <AddNewMaintenanceButton
            onClick={() => add(SERVICE_HISTORY_TYPES.Maintenance)}
            allowed={addAllowed}
          />
        </Space>
      </Col>
      <Col span={24}>
        <Row>
          <Col span={16}>
            <div ref={tableRef}>
              {showData && (
                <ServiceHistoryTable
                  serviceHistoryRecords={records}
                  taskTypes={tasksTypes}
                  onDelete={remove}
                  onEdit={edit}
                />
              )}
            </div>
          </Col>
          <Col span={8}>
            {showData && (
              <ServiceHistoryDocumentsPreview
                documents={documents}
                maxHeight={maxHeight}
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ServiceHistory;
