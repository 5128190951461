import { FAIL_RESPONSE } from '@gql_codegen/typings/api/api_fail';
import { SUCCESS_RESPONSE } from '@gql_codegen/typings/api/api_success';

const isEmptySuccessResponse = (
  error: unknown,
): error is SUCCESS_RESPONSE<null> => {
  if (typeof error === 'object' && error !== null && 'errors' in error) {
    return true;
  }
  return false;
};

const isFailResponse = (error: unknown): error is FAIL_RESPONSE => {
  if (typeof error === 'object' && error !== null && 'error' in error) {
    return true;
  }
  return false;
};

export const getServerErrors = (error: unknown) => {
  //200 HTTP but no data
  if (isEmptySuccessResponse(error)) {
    return error.errors.map((err, idx) => (
      <div data-qa-selector="error-msg" key={err.message + idx}>
        {err.message}
      </div>
    ));
  }

  //400 HTTP but no data
  if (isFailResponse(error)) {
    return <div data-qa-selector="error-msg">{error.message}</div>;
  }

  //default
  return <div data-qa-selector="error-msg">Unknown error</div>;
};
