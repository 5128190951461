import {
  FeatureDetailItem,
  FeatureDetailRestrictions,
  FeatureDetailTranslation,
  GetDataQuery,
} from '@gql_codegen/retail-types';
import { Col, Row, Space } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import {
  ControllerFieldState,
  FormState,
  SetValueConfig,
  UseFormGetValues,
} from 'react-hook-form';
import { EQUIPMENT_NEWLY_ADDED_MAP, FeaturePaths } from '../constants';
import { EditEquipment } from './EditEquipment';
import { FormType } from '@src/layout';

const getErrorMessage = (fieldState: ControllerFieldState) => {
  if (!fieldState.error) return;

  if (
    'translations' in fieldState.error &&
    Array.isArray(fieldState.error.translations) &&
    fieldState.error.translations?.[0].message
  ) {
    return fieldState.error.translations[0].message;
  }

  return fieldState.error?.message ?? '';
};
type EquipmentNameTranslatedProps = {
  item: FeatureDetailItem;
  translationPath: FeaturePaths.FeatureDetailsTranslationPath;
  isNewItem: boolean | undefined;
  restrictions: FeatureDetailRestrictions;
  editTranslationByPath: (
    path: FeaturePaths.FeatureDetailsTranslationPath,
    newTranslation: FeatureDetailTranslation,
    item: FeatureDetailItem,
    options?: SetValueConfig,
  ) => void;
  validateDuplicateTranslation: (
    item: FeatureDetailItem,
    locale: string,
    newValue: string,
  ) => boolean;
  fieldState: ControllerFieldState;
  formState: FormState<GetDataQuery>;
  getValues: UseFormGetValues<FormType>;
};
export const EquipmentTableNameTranslated = ({
  item,
  translationPath,
  restrictions,
  editTranslationByPath,
  validateDuplicateTranslation,
  fieldState,
  formState,
  getValues,
  isNewItem,
}: EquipmentNameTranslatedProps): JSX.Element => {
  const translation = getValues(translationPath);

  const [editMode, setEditMode] = useState<boolean>(() => {
    return (isNewItem && translation.text.trim() === '') ?? false;
  });

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      setEditMode(false);
    }
  }, [formState.isSubmitSuccessful, formState.isSubmitting]);

  const showEdit = editMode || fieldState.invalid;

  const validator = useCallback(
    (value: string) => {
      if (
        !validateDuplicateTranslation(item, translation.locale, value.trim())
      ) {
        return `Duplicate translation for ${translation.locale}`;
      }
    },
    [validateDuplicateTranslation, translation, item],
  );

  const handleEdit = useCallback(
    (newTranslation: FeatureDetailTranslation) => {
      editTranslationByPath(translationPath, newTranslation, item, {
        shouldValidate: true,
      });
      setEditMode(false);
      EQUIPMENT_NEWLY_ADDED_MAP.set(item.id, { ...item, edited: true });
    },
    [setEditMode, editTranslationByPath, translationPath, item],
  );

  const errorText = getErrorMessage(fieldState);

  return (
    <Row
      align="middle"
      wrap={false}
      gutter={{
        xs: 4,
        sm: 4,
        md: 4,
        lg: 8,
        xl: 8,
        xxl: 8,
      }}
      data-translation-path={translationPath}
      data-equipment-error={fieldState.invalid ? true : false}
    >
      {!showEdit && (
        <Col
          flex="auto"
          data-qa-selector-name={translation.text}
          data-qa-selector="equipment-translation-text"
        >
          {translation.text}
        </Col>
      )}
      <Col flex={showEdit ? 1 : 0}>
        <Space size={0} direction="vertical" style={{ width: '100%' }}>
          {restrictions.canEditTranslations && (
            <EditEquipment
              translation={translation}
              name={item?.name}
              onEdit={handleEdit}
              initialTranslation={translation}
              editMode={showEdit}
              onEditClick={() => setEditMode(true)}
              onCancelClick={() => setEditMode(false)}
              hasError={fieldState.invalid}
              errorText={errorText}
              validator={validator}
              disabled={formState.isSubmitting}
            />
          )}
        </Space>
      </Col>
    </Row>
  );
};
