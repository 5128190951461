import { PricingChangelogItem } from '@gql_codegen/retail-types';
import { useGetPriceChangelogDataQuery } from '@gql_codegen/retail-types';
import { Table } from 'antd';
import { useMemo, useState, useEffect } from 'react';
import { Panel } from '@components/Panel';
import { PRICING_CHANGELOG_COLUMNS } from './PricingChangelogColumns';
import { usePageData } from '@hooks/usePageData';
import { GetPriceChangelogDataQuery } from '@gql_codegen/retail-types';
import useAdId from '@hooks/useAdId';

export const PricingChangelog = (): JSX.Element => {
  const [page, setPage] = useState(1);
  const [isLoadingPricingChangelogData, setIsLoadingPricingChangelogData] =
    useState(false);
  const [tableData, setTableData] = useState<GetPriceChangelogDataQuery | null>(
    null,
  );
  const { data, isLoading: isLoadingAdData } = usePageData();
  const adId = useAdId();

  useEffect(() => {
    if (data) {
      setTableData(data);
    }
  }, [data]);

  useEffect(() => {
    if (!tableData) return;
    async function fetchTableData() {
      setIsLoadingPricingChangelogData(true);
      const result = await useGetPriceChangelogDataQuery.fetcher({
        adId: adId || '',
        priceChangelogPage: page - 1,
        priceChangelogPageSize: 15,
      })();
      setTableData(result);
      setIsLoadingPricingChangelogData(false);
    }
    fetchTableData();
  }, [page]);

  const { items, pageSize, totalEntityCount } =
    tableData?.pricingChangelog.data ?? {};

  const itemsWithKeys: PricingChangelogItem[] = useMemo(
    () =>
      items?.map((item, idx) => ({
        ...item,
        key: item.stockNumber + idx,
      })) || [],
    [items],
  );

  return (
    <Panel
      title="Pricing Changelog"
      data-qa-selector="section-pricing-changelog"
    >
      <Table
        className="sectionPricingChangelogTable"
        data-qa-selector="section-pricing-changelog-table"
        dataSource={itemsWithKeys}
        columns={PRICING_CHANGELOG_COLUMNS}
        pagination={{
          position: ['topRight', 'bottomRight'],
          showTotal: (total, range) =>
            `Showing ${range[0]}-${range[1]} of ${total}`,
          total: totalEntityCount || 0,
          pageSize: pageSize || 0,
          current: page || 0,
          onChange: (page) => setPage(page),
        }}
        loading={isLoadingAdData || isLoadingPricingChangelogData}
      />
    </Panel>
  );
};
