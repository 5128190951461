import { useState } from 'react';

import { Autohero } from './Autohero';
import { MobileDe } from './MobileDe';
import { Autoscout24 } from './Autoscout24';

export type Status = {
  platform: string;
  isStatusChecking: boolean;
};

export type PublishStatusChecking = (status: Status) => void;

export const ExportOverview = (): JSX.Element => {
  const [status, setCommonPublishingStatus] = useState<Status>({
    platform: '',
    isStatusChecking: false,
  });

  return (
    <>
      <Autohero
        status={status}
        setCommonPublishingStatus={setCommonPublishingStatus}
      />
      <MobileDe
        status={status}
        setCommonPublishingStatus={setCommonPublishingStatus}
      />
      <Autoscout24
        status={status}
        setCommonPublishingStatus={setCommonPublishingStatus}
      />
    </>
  );
};
