import { FormType } from '@src/layout';
import {
  FieldArrayPath,
  useFieldArray,
  UseFieldArrayProps,
  UseFieldArrayReturn,
} from 'react-hook-form';

type FormPossibleFields = FieldArrayPath<FormType>;
export type UseFieldArrayTypedProps<
  FieldName extends FormPossibleFields = FormPossibleFields,
> = UseFieldArrayProps<FormType, FieldName>;
export type UseFieldArrayTypedReturn<
  FieldName extends FormPossibleFields = FormPossibleFields,
> = UseFieldArrayReturn<FormType, FieldName>;
export type UseFieldArrayTypedFields<
  FieldName extends FormPossibleFields = FormPossibleFields,
> = UseFieldArrayTypedReturn<FieldName>['fields'];

export const useFieldArrayTyped = <
  FieldName extends FormPossibleFields = FormPossibleFields,
>(
  props: UseFieldArrayTypedProps<FieldName>,
): UseFieldArrayTypedReturn<FieldName> => useFieldArray(props);
