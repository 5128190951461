// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".P6KTMbKcJQBTRt5ojYPl {\n  width: 100% !important;\n}\n", "",{"version":3,"sources":["webpack://./src/sections/General/VehicleHistoryAndService/ServiceHistory/Forms/AddMaintenanceHistoryForm/styles.less"],"names":[],"mappings":"AACA;EACE,sBAAA;AAAF","sourcesContent":["@antVersion: antd-5-18-2;\n.fullWidth {\n  width: 100% !important;\n}\n\n@ant-prefix: antd-5-18-2;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullWidth": "P6KTMbKcJQBTRt5ojYPl"
};
export default ___CSS_LOADER_EXPORT___;
