import { EditOutlined } from '@ant-design/icons';
import {
  CustomFeatureTranslationConfig,
  FeatureDetailItem,
  FeatureDetailRestrictions,
} from '@gql_codegen/retail-types';
import { useAnalytics } from '@hooks/useAnalytics';
import { TRACKING_SECTION, TRACKING_SECTION_CATEGORY } from '@src/constants';
import { Button, Col, Form, Select } from 'antd';
import { SetValueConfig } from 'react-hook-form';
import { INVALID_GROUPS } from '../constants';
import { useFindEquipmentHelpers } from '../useFindEquipmentHelpers';
import { equipmentHelpers } from '../utils';
import { EquipmentSelectOption } from './EquipentSelectOption';

type EquipmentTableNameTranslatedProps = {
  item?: FeatureDetailItem | undefined;
  config: CustomFeatureTranslationConfig;
  customFeatureTranslationConfigsSortedAndFiltered: CustomFeatureTranslationConfig[];
  handleEditItem: (
    itemToEdit: FeatureDetailItem,
    newValue: FeatureDetailItem,
    options?: SetValueConfig,
  ) => void;
  restrictions: FeatureDetailRestrictions;
};

const EquipmentFeatureText = ({
  config: config,
}: {
  config: CustomFeatureTranslationConfig;
}) => {
  return (
    <>
      {config.featureGroup}
      {config.featureSubGroup ? ` | ${config.featureSubGroup}` : null}
    </>
  );
};
const EquipmentFeatureEditBtn = ({
  item,
  restrictions,
  handleEditItem,
}: {
  item: FeatureDetailItem | undefined;
} & Omit<
  EquipmentTableNameTranslatedProps,
  'config' | 'customFeatureTranslationConfigsSortedAndFiltered'
>) => {
  if (!item) return <></>;

  const isUnclassified = item.group.name === INVALID_GROUPS.unclassified;
  const showEditGroupBtn =
    isUnclassified &&
    restrictions.canSeeUnclassifiedMappings &&
    restrictions.canEditUnclassifiedMappings;

  return (
    <>
      {showEditGroupBtn && (
        <Button
          size="small"
          title="Edit Feature Group"
          type="text"
          data-qa-selector="edit-group-button"
          onClick={() => {
            handleEditItem(item, item, { shouldTouch: true });
          }}
        >
          <EditOutlined />
        </Button>
      )}
    </>
  );
};

export const EquipmentTableFeature = (
  props: EquipmentTableNameTranslatedProps,
) => {
  const config = props.config;

  const track = useAnalytics();
  const { getFieldStateByConfig } = useFindEquipmentHelpers();
  const featureDetailItem = props.item;

  const featureDetailsFieldState = getFieldStateByConfig(config);
  if (!featureDetailsFieldState || !featureDetailItem)
    return <EquipmentFeatureText config={config} />;

  const { isTouched, error } = featureDetailsFieldState;

  return (
    <div>
      {!isTouched && (
        <>
          <EquipmentFeatureText config={config} />
          <EquipmentFeatureEditBtn
            item={featureDetailItem}
            handleEditItem={props.handleEditItem}
            restrictions={props.restrictions}
          />
        </>
      )}

      {isTouched && (
        <Col span={24}>
          <Form.Item
            required
            validateStatus={error ? 'error' : 'success'}
            help={
              <div data-qa-selector="edit-equipment-group-explain-error">
                {error?.message}
              </div>
            }
          >
            <Select
              // className={styles.fullWidth}
              data-qa-selector="edit-equipment-group-selector"
              status={error ? 'error' : ''}
              // data-qa-selector-value={val?.name}
              dropdownRender={(menu) => (
                <div data-qa-selector="add-equipment-group-selector-dropdown">
                  {menu}
                </div>
              )}
              defaultValue={equipmentHelpers.convertOptionToValue({
                group: featureDetailItem.group.translatedName,
                subgroup: featureDetailItem.subGroup?.translatedName ?? null,
              })}
              showSearch
              onChange={(value: string) => {
                if (value) {
                  const newValue =
                    equipmentHelpers.convertValueToOptions(value);
                  const newConfigItem =
                    props.customFeatureTranslationConfigsSortedAndFiltered.find(
                      (config) =>
                        config.featureGroup === newValue.group &&
                        (config.featureSubGroup ?? null) === newValue.subgroup,
                    );

                  const newItem: FeatureDetailItem = {
                    ...featureDetailItem,
                    subcategory: newConfigItem?.subcategory
                      ? {
                          id: newConfigItem.subcategory.id,
                          name: newConfigItem.subcategory.name,
                        }
                      : featureDetailItem.subcategory ?? null,
                    group: {
                      name: newValue.group,
                      translatedName: newValue.group,
                    },
                    subGroup:
                      newValue.subgroup !== null
                        ? {
                            name: newValue.subgroup,
                            translatedName: newValue.subgroup,
                          }
                        : null,
                  };
                  props.handleEditItem(featureDetailItem, newItem);

                  track(
                    {
                      eventType: 'edit',
                      eventCategory: 'modify',
                      fieldId: 'Equipment_Feature',
                      section: TRACKING_SECTION.AS24_EQUIPMENT,
                      sectionCategory: TRACKING_SECTION_CATEGORY.CL,
                    },
                    newValue.subgroup,
                  );
                }
              }}
              filterOption={equipmentHelpers.optionsFilter}
            >
              {props.customFeatureTranslationConfigsSortedAndFiltered.map(
                (item, idx) => (
                  <Select.Option
                    key={`${item.category}_${idx}_${item.featureGroup}_${item.featureSubGroup}`}
                    value={equipmentHelpers.convertOptionToValue({
                      group: item.featureGroup,
                      subgroup: item.featureSubGroup ?? null,
                    })}
                    label={equipmentHelpers.generateLabel(item)}
                    data-qa-selector="add-equipment-group-option"
                  >
                    <EquipmentSelectOption {...item} />
                  </Select.Option>
                ),
              )}
            </Select>
          </Form.Item>
        </Col>
      )}
    </div>
  );
};
