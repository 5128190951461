import { Panel } from '@components/Panel';
import { CarData } from './CarData';
import { EmissionAndConsumption } from './EmissionAndConsumption';
import { VehicleHistoryAndService } from './VehicleHistoryAndService';
import { UniqueSellingPropositions } from './UniqueSellingPropositions';
import { AdditionalCarData } from './AdditionalCarData';
import { DamagesHistory } from './DamagesHistory';

export const General = (): JSX.Element => {
  return (
    <Panel title="General" data-qa-selector="section-general">
      <div className="generalSections">
        <CarData />
        <UniqueSellingPropositions />
        <VehicleHistoryAndService />
        <DamagesHistory />
        <EmissionAndConsumption />
        <AdditionalCarData />
      </div>
    </Panel>
  );
};
