import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { Button, Tooltip, Typography } from 'antd';
import React, {
  ComponentProps,
  ComponentRef,
  useCallback,
  useRef,
} from 'react';
import { useLayoutEffect } from 'react';
import PrismaZoom from 'react-prismazoom';
import cn from './styles.less';

type PrismaZoomProps = ComponentProps<typeof PrismaZoom>;

type ZoomProps = {
  children: React.ReactNode;
} & PrismaZoomProps;
export const ZoomWithUI = ({ children, ...restPrismaZoomProps }: ZoomProps) => {
  const prismaZoomRef = useRef<ComponentRef<typeof PrismaZoom>>(null);
  const zoomCounterRef = useRef<HTMLSpanElement>(null);

  const onZoomChange = useCallback((zoom: number) => {
    if (!zoomCounterRef.current) return;
    zoomCounterRef.current.innerText = `${Math.round(zoom * 100)}%`;
  }, []);

  const onClickOnZoomOut = () => {
    prismaZoomRef.current?.zoomOut(1);
  };

  const onClickOnZoomIn = () => {
    prismaZoomRef.current?.zoomIn(1);
  };

  useLayoutEffect(() => {
    prismaZoomRef.current?.reset();
  }, [children]);

  return (
    <main className={cn.zoom}>
      <header className={cn.zoom_header}>
        <Tooltip title="Zoom in">
          <Button
            type="dashed"
            shape="circle"
            icon={<ZoomInOutlined />}
            onClick={onClickOnZoomIn}
            data-qa-selector="image-zoom-in-btn"
          />
        </Tooltip>
        <Typography.Text
          ref={zoomCounterRef}
          data-qa-selector="image-zoom-text"
        >
          100%
        </Typography.Text>
        <Tooltip title="Zoom out">
          <Button
            type="dashed"
            shape="circle"
            icon={<ZoomOutOutlined />}
            onClick={onClickOnZoomOut}
            data-qa-selector="image-zoom-out-btn"
          />
        </Tooltip>
      </header>
      <PrismaZoom
        onZoomChange={onZoomChange}
        ref={prismaZoomRef}
        maxZoom={10}
        {...restPrismaZoomProps}
      >
        {children}
      </PrismaZoom>
    </main>
  );
};
