export const ROTATE_90 = 1;
export const ROTATE_270 = 3;

export const IMAGE_QUALITY = 1;

export const rotateImageBySrc = (
  src: string,
  rotation: number,
  type: string,
): Promise<string> =>
  new Promise((resolve): void => {
    const image = new Image();

    image.setAttribute('crossOrigin', 'anonymous');

    image.onload = () => {
      rotation = (+rotation + 4) % 4;
      const mod = rotation & 1;
      const width = mod ? image.height : image.width;
      const height = mod ? image.width : image.height;

      const canvas = document.createElement('canvas');

      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext('2d');

      ctx?.rotate((rotation * Math.PI) / 2);
      ctx?.drawImage(
        image,
        image.width * -(rotation === 2 || rotation === 3),
        image.height * -(rotation === 1 || rotation === 2),
      );

      const dataUrl = canvas.toDataURL(type, IMAGE_QUALITY);

      resolve(dataUrl);
    };

    const timestamp = new Date().getTime();

    const isBlob = src.startsWith('data:image');

    image.src = isBlob ? src : `${src}?t=${timestamp}`;
  });
