// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mzX7l7H0LPwNlUmMs8u4 {\n  min-height: 150px;\n  background: #9b9b9b;\n}\n.BdDhnYYrBUOKd91xBXs_ {\n  max-width: 100%;\n}\n.N1rEmTSdV2bQbwEhqDWr {\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n}\n.l1cs7wSiJ9kVDyC5zEq5 {\n  width: 56px;\n  cursor: pointer;\n  color: #ffffff;\n  border: none;\n  background: none;\n  font-size: 56px;\n  line-height: 56px;\n}\n.l1cs7wSiJ9kVDyC5zEq5:hover {\n  -webkit-transform: scale(1.1);\n          transform: scale(1.1);\n}\n", "",{"version":3,"sources":["webpack://./src/sections/Classifieds/AutoheroWKDA/Imperfections/ImperfectionsField/ImageRotator/styles.less"],"names":[],"mappings":"AAGA;EACE,iBAAA;EAEA,mBAAA;AAHF;AAMA;EACE,eAAA;AAJF;AAOA;EACE,kBAAA;EACA,SAAA;EACA,OAAA;EAEA,WAAA;AANF;AASA;EACE,WAAA;EAEA,eAAA;EAEA,cAAA;EACA,YAAA;EACA,gBAAA;EAEA,eAAA;EACA,iBAAA;AAVF;AAYE;EACE,6BAAA;UAAA,qBAAA;AAVJ","sourcesContent":["@antVersion: antd-5-18-2;\n@import 'src/styles/colors.less';\n\n.imgWrapper {\n  min-height: 150px;\n\n  background: @gray-more-darkish;\n}\n\n.img {\n  max-width: 100%;\n}\n\n.buttons {\n  position: absolute;\n  bottom: 0;\n  left: 0;\n\n  width: 100%;\n}\n\n.rotateButton {\n  width: 56px;\n\n  cursor: pointer;\n\n  color: @white;\n  border: none;\n  background: none;\n\n  font-size: 56px;\n  line-height: 56px;\n\n  &:hover {\n    transform: scale(1.1);\n  }\n}\n\n@ant-prefix: antd-5-18-2;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imgWrapper": "mzX7l7H0LPwNlUmMs8u4",
	"img": "BdDhnYYrBUOKd91xBXs_",
	"buttons": "N1rEmTSdV2bQbwEhqDWr",
	"rotateButton": "l1cs7wSiJ9kVDyC5zEq5"
};
export default ___CSS_LOADER_EXPORT___;
