import { Collapse } from 'antd';
import { ImageGalleryField } from '@components/ImageGalleryField';
import {
  AUTOSCOUT24_IMAGE_TYPE,
  MOBILEDE_IMAGE_TYPE,
  TRACKING_SECTION,
  TRACKING_SECTION_CATEGORY,
} from '@src/constants';
import { usePageData } from '@hooks/usePageData';

const { Panel } = Collapse;

const IMAGES_LIMIT = 50;

export const Media = () => {
  const { data, isLoading } = usePageData();

  if (isLoading || !data) return <></>;

  const { autoscout24, mobiledeV2 } = data.adMgmt;

  return (
    <div data-qa-selector="classifieds-media">
      <Collapse>
        {autoscout24 ? (
          <Panel header="Autoscout24" key="autoscout">
            <ImageGalleryField
              name="adMgmt.autoscout24.data.images"
              type={AUTOSCOUT24_IMAGE_TYPE}
              limit={autoscout24.data?.imagesLimit ?? IMAGES_LIMIT}
              qaSelector="autoscout24Images"
              tracking={{
                fieldId: 'Media',
                section: TRACKING_SECTION.AS24_MEDIA,
                sectionCategory: TRACKING_SECTION_CATEGORY.CL,
              }}
            />
          </Panel>
        ) : null}

        {mobiledeV2 ? (
          <Panel header="Mobile" key="mobile">
            <ImageGalleryField
              name="adMgmt.mobiledeV2.data.images"
              type={MOBILEDE_IMAGE_TYPE}
              limit={IMAGES_LIMIT}
              exportedLimit={mobiledeV2.data?.imagesLimit ?? IMAGES_LIMIT}
              qaSelector="mobile-images"
              tracking={{
                fieldId: 'Media',
                section: TRACKING_SECTION.AS24_MEDIA,
                sectionCategory: TRACKING_SECTION_CATEGORY.CL,
              }}
            />
          </Panel>
        ) : null}
      </Collapse>
    </div>
  );
};
