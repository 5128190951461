import {
  GetServiceHistoryDocumentUploadPresignedUrlQuery,
  GetServiceHistoryDocumentUploadPresignedUrlQueryVariables,
  ServiceHistoryDocumentFileType,
  useGetServiceHistoryDocumentUploadPresignedUrlQuery,
} from '@gql_codegen/retail-types';
import { Document } from '@src/types/serviceHistoryDocument';
import { fileExtension } from '@utils/fileExtension';
import { retailKibanaLogger } from '@utils/logger';
import { notification } from 'antd';
import axios from 'axios';
import { objectify } from 'radash';
import { useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid';

const MAX_FILE_SIZE = 5000000;
export const errorMessage = 'Image Upload Error';

export const getFileMetadata = (
  file: File,
): GetServiceHistoryDocumentUploadPresignedUrlQueryVariables => ({
  uploadId: uuid(),
  fileExt: fileExtension(file.name),
});

export const formatPresignedUrlHeaders = (
  headers: GetServiceHistoryDocumentUploadPresignedUrlQuery['getServiceHistoryDocumentUploadPresignedUrl']['headers'],
) =>
  objectify(
    headers ?? [],
    (item) => item.name ?? '',
    (item) => item.values,
  );

export const getUploadCredentials = async (
  metadata: GetServiceHistoryDocumentUploadPresignedUrlQueryVariables,
) => {
  const {
    getServiceHistoryDocumentUploadPresignedUrl: { url, headers },
  } =
    await useGetServiceHistoryDocumentUploadPresignedUrlQuery.fetcher(
      metadata,
    )();

  return {
    url: url,
    headers: formatPresignedUrlHeaders(headers),
  };
};
type UploadToS3Input = {
  file: File;
  url: string;
  headers: Record<string, string | string[]>;
  id: string;
  fileExt: string;
};
export const uploadToS3 = async ({
  file,
  url: uploadUrl,
  headers,
  id,
  fileExt,
}: UploadToS3Input): Promise<Document> => {
  await axios.put(uploadUrl, file, {
    timeout: 900000,
    headers,
  });

  const fileType =
    ServiceHistoryDocumentFileType.Pdf === fileExt.toUpperCase()
      ? ServiceHistoryDocumentFileType.Pdf
      : ServiceHistoryDocumentFileType.Image;
  const url = global.URL.createObjectURL(file);

  return {
    id,
    fileExt,
    fileType,
    name: file.name,
    url,
    thumbnailUrl: fileType !== ServiceHistoryDocumentFileType.Pdf ? url : null,
  };
};

export const useUpload = () => {
  const [isLoading, setIsLoading] = useState(false);

  const upload = useCallback(
    async (input: FileList | null): Promise<Document[]> => {
      const filesToUpload = Array.from(input || []);
      const files: Document[] = [];
      setIsLoading(true);
      for (const file of filesToUpload) {
        if (file.size > MAX_FILE_SIZE) {
          notification.error({
            message: `${file.name} file size is more than 5 MB.`,
          });
          continue;
        }
        try {
          const { uploadId, fileExt } = getFileMetadata(file);
          const { url, headers } = await getUploadCredentials({
            uploadId,
            fileExt,
          });
          const data = await uploadToS3({
            file,
            url,
            headers,
            id: uploadId,
            fileExt,
          });
          files.push(data);
        } catch (err) {
          if (err instanceof Error) {
            notification.error({
              message: errorMessage,
              description: err.message,
            });
          } else if (typeof err === 'string') {
            notification.error({
              message: errorMessage,
              description: err,
            });
          } else {
            retailKibanaLogger.warn('useUploadToS3::Error', err);
          }
        }
      }
      setIsLoading(false);

      return files;
    },
    [],
  );

  return {
    upload,
    isLoading,
  };
};
