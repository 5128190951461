import InputControlled from '@components/formControlled/InputControlled';
import InputNumberControlled from '@components/formControlled/InputNumberControlled';
import SelectControlled from '@components/formControlled/SelectControlled';
import { GetDataQuery } from '@gql_codegen/retail-types';
import { useAppForm } from '@hooks/useAppForm';
import { assertUnreachable } from '@utils/assertUnreachable';
import { Form, Table } from 'antd';
import { LabeledValue } from 'antd/lib/select';
import get from 'lodash.get';
import {
  SWPathStart,
  WheelsInfoRecordItems,
  secondaryWheelsItemInputTypes,
  tableColOptions,
  wheelsInfo,
} from './wheels-details-table-config';

const { Column } = Table;

type DetailsTableProps = {
  isTableFieldsEditable: boolean;
  options: GetDataQuery['adMgmt']['dictionaries']['global'];
};

type WheelsInfoItems = (typeof wheelsInfo)[number];
export const SecondaryWheelsDetailsTable = ({
  options,
  isTableFieldsEditable,
}: DetailsTableProps): JSX.Element => {
  const {
    formState: { errors },
    control,
  } = useAppForm();
  const swErrors = errors?.adMgmt?.autoheroAndWkda?.secondaryWheels?.data || {};

  const getSelectFieldProps = (type: keyof DetailsTableProps['options']) => {
    return {
      options: options[type] as LabeledValue[],
      disabled: !isTableFieldsEditable,
    };
  };

  const renderTitle = ({ key, title }: { key: string; title: string }) => {
    return <b data-qa-selector={`${key}-header`}>{title}</b>;
  };

  const renderColumn = (
    recordValue:
      | WheelsInfoRecordItems['frontLeft']
      | WheelsInfoRecordItems['frontRight']
      | WheelsInfoRecordItems['rearLeft']
      | WheelsInfoRecordItems['rearRight'],
    record: WheelsInfoItems,
  ): React.ReactNode => {
    const type = record.inputType;

    switch (type) {
      //select inputs
      case secondaryWheelsItemInputTypes.select:
        return (
          <Form.Item>
            <SelectControlled
              allowClear
              placeholder="N/A"
              data-qa-selector={recordValue}
              {...getSelectFieldProps(record.dictionary)}
              {...record.inputProps}
              controllerProps={{
                name: `${SWPathStart}.${recordValue}`,
                control,
              }}
              tracking={record.tracking}
            />
          </Form.Item>
        );

      // free text inputs
      case secondaryWheelsItemInputTypes.input:
        return (
          <Form.Item
            validateStatus={get(swErrors, recordValue) ? 'error' : 'success'}
            help={
              <div data-qa-selector={`${recordValue}-explain-error`}>
                {get(swErrors, recordValue)?.message}
              </div>
            }
          >
            <InputControlled
              placeholder="N/A"
              data-qa-selector={recordValue}
              {...record.inputProps}
              controllerProps={{
                name: `${SWPathStart}.${recordValue}`,
                control,
              }}
              tracking={record.tracking}
            />
          </Form.Item>
        );

      //TODO: may be reverted after 01.04.2025 otherwise delete it
      //
      // //multiple select inputs
      // case tableRowOptions.secondaryWheelsTiresDetails:
      // case tableRowOptions.secondaryWheelsRimsDetails:
      //   return (
      //     <Form.Item>
      //       <SelectControlled
      //         allowClear={false}
      //         mode="multiple"
      //         labelInValue
      //         showArrow
      //         placeholder="None"
      //         data-qa-selector={recordValue}
      //         {...selectProps}
      //         controllerProps={{
      //           name: `${SWPathStart}.${recordValue}`,
      //           control,
      //         }}
      //         tracking={record.tracking}
      //       />
      //     </Form.Item>
      //   );

      //integer inputs
      case secondaryWheelsItemInputTypes.integer:
        return (
          <Form.Item
            validateStatus={get(swErrors, recordValue) ? 'error' : 'success'}
            help={
              <div data-qa-selector={`${recordValue}-explain-error`}>
                {get(swErrors, recordValue)?.message}
              </div>
            }
          >
            <InputNumberControlled
              placeholder="N/A"
              precision={0}
              data-qa-selector={recordValue}
              {...record.inputProps}
              controllerProps={{
                name: `${SWPathStart}.${recordValue}`,
                control,
              }}
              tracking={record.tracking}
            />
          </Form.Item>
        );
      default:
        //should never happen, if there's an error add missed case above
        assertUnreachable(type);
    }
  };

  return (
    <Table<WheelsInfoItems>
      data-qa-selector="swTable"
      pagination={false}
      size="small"
      dataSource={wheelsInfo}
      tableLayout={'fixed'}
      rowKey="type"
      sticky={{ offsetHeader: 124 }}
    >
      <Column
        dataIndex="title"
        title={() =>
          renderTitle({ key: 'title', title: 'Secondary Wheels Details' })
        }
      />
      {tableColOptions.map(({ key, title }) => {
        return (
          <Column
            key={key}
            dataIndex={key}
            title={() => renderTitle({ key, title })}
            render={renderColumn}
          />
        );
      })}
    </Table>
  );
};
