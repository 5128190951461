// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ovKurSvrgLU_oQ8SKCt_ {\n  height: 92px;\n  border: 1px solid #e6e6e6;\n}\n.XU04vW1dPjEEMyLw8Yth {\n  margin-bottom: 30px;\n  padding: 48px 60px;\n  background-color: #e6e6e6;\n}\n.XU04vW1dPjEEMyLw8Yth h3 {\n  margin-top: 21px;\n  margin-bottom: 10.5px;\n  font-size: 26px;\n  font-weight: 300;\n}\n", "",{"version":3,"sources":["webpack://./src/sections/General/VehicleHistoryAndService/ServiceHistory/ImageGallery/styles.less"],"names":[],"mappings":"AACA;EACE,YAAA;EAEA,yBAAA;AADF;AAIA;EACE,mBAAA;EACA,kBAAA;EAEA,yBAAA;AAHF;AADA;EAOI,gBAAA;EACA,qBAAA;EAEA,eAAA;EACA,gBAAA;AAJJ","sourcesContent":["@antVersion: antd-5-18-2;\n.thumbnailWrapper {\n  height: 92px;\n\n  border: 1px solid #e6e6e6;\n}\n\n.noImagePlaceHolder {\n  margin-bottom: 30px;\n  padding: 48px 60px;\n\n  background-color: #e6e6e6;\n\n  h3 {\n    margin-top: 21px;\n    margin-bottom: 10.5px;\n\n    font-size: 26px;\n    font-weight: 300;\n  }\n}\n\n@ant-prefix: antd-5-18-2;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thumbnailWrapper": "ovKurSvrgLU_oQ8SKCt_",
	"noImagePlaceHolder": "XU04vW1dPjEEMyLw8Yth"
};
export default ___CSS_LOADER_EXPORT___;
