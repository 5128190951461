import { Popover } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { usePageData } from '@hooks/usePageData';
import {
  ImperfectionItemPart,
  ImperfectionItemType,
  TagInput,
} from '@gql_codegen/retail-types';
import cns from 'classnames';

type TagProps = {
  isEditIcon?: boolean;
  onMouseDown?: () => void;
  tag: TagInput;
};
import cn from './styles.less';

const PERCENTAGE = 100;

export const Tag = ({
  isEditIcon,
  onMouseDown,
  tag,
}: TagProps): JSX.Element => {
  const { data } = usePageData();
  const {
    position: { top, left },
  } = tag;
  const imperfections =
    data?.adMgmt?.autoheroAndWkda?.imperfections?.items || [];
  const imperfection = imperfections.find((imp) => {
    return imp.image.id === tag?.tagImageId;
  });
  const { part = [], imperfectionType, image } = imperfection || {};
  const makeTitle = (
    parts: ImperfectionItemPart[],
    imperfectionType: ImperfectionItemType[],
  ) => {
    return parts.map(
      (part, i: number) =>
        `${part.locale}: ${part.text} - ${imperfectionType[i]?.text}`,
    );
  };
  const imperfectionImage = <img src={image?.thumbnail} alt="imperfection" />;
  return (
    <Popover
      placement="bottom"
      content={imperfectionImage}
      title={makeTitle(part, imperfectionType || [])}
    >
      <div
        className={cn.tagIconContainer}
        data-qa-selector="tagIcon"
        onMouseDown={onMouseDown}
        style={{
          top: `${top * PERCENTAGE}%`,
          left: `${left * PERCENTAGE}%`,
        }}
      >
        <PlusOutlined className={cns(cn.icon, { [cn.editIcon]: isEditIcon })} />
      </div>
    </Popover>
  );
};
