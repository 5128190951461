import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import { useControllableState } from '@hooks/useControllableState';
import cn from './styles.less';

type SubsectionProps = React.PropsWithChildren<{
  title: string;
  foldable?: boolean;
  folded?: boolean | undefined;
  onFoldedChange?: (folded: boolean) => void;
  isVisible?: boolean;
  renderable?: boolean;
  qaSelector?: string;
  foldBtnQaSelector?: string;
}>;

export const Subsection = (props: SubsectionProps): JSX.Element | null => {
  const {
    title,
    children,
    qaSelector,
    isVisible = true,
    renderable = true,
    foldable = false,
    folded,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onFoldedChange = () => {},
  } = props;
  const [isFolded, setIsFolded] = useControllableState({
    prop: folded,
    defaultProp: foldable,
    onChange: onFoldedChange,
  });

  const toggleFold = () => setIsFolded(!isFolded);

  const renderFoldButton = () => {
    const { foldBtnQaSelector } = props;

    if (!foldable) {
      return null;
    }

    return (
      <span
        role="button"
        tabIndex={-1}
        onClick={toggleFold}
        className={cn.foldButton}
        data-qa-selector={foldBtnQaSelector}
        title={isFolded ? 'unfold' : 'fold'}
      >
        {isFolded ? <DownCircleOutlined /> : <UpCircleOutlined />}
      </span>
    );
  };

  /* istanbul ignore if */
  if (!isVisible) {
    return null;
  }

  return (
    <div className={cn.root} data-qa-selector={qaSelector}>
      <div className={cn.titleWrapper}>
        <hr />
        <span className={cn.title}>
          {renderFoldButton()}
          {title}
        </span>
      </div>
      {renderable ? (
        <div
          aria-hidden={isFolded}
          className={isFolded ? cn.hidden : cn.visible}
        >
          {children}
        </div>
      ) : (
        <div>{!isFolded && children}</div>
      )}
    </div>
  );
};
