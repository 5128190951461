import { Button, Modal } from 'antd';
import { Document } from '@src/types/serviceHistoryDocument';
import { ImageSorter } from './ImageSorter';
import { CloseOutlined } from '@ant-design/icons';

type ImageGallerySortModalProps = {
  show: boolean;
  handleCancel: () => void;
  items: Document[];
  onItemsChange: (items: Document[]) => void;
};

export const ImageGallerySortModal = ({
  show,
  handleCancel,
  items,
  onItemsChange,
}: ImageGallerySortModalProps): JSX.Element => {
  return (
    <Modal
      title="Drag images to sort it"
      open={show}
      onCancel={handleCancel}
      footer={[
        <Button key="submit" type="primary" onClick={handleCancel}>
          Close
        </Button>,
      ]}
      closeIcon={
        <CloseOutlined data-qa-selector="image-gallery-sort-modal-close-button" />
      }
      width={900}
    >
      {show && <ImageSorter documents={items} onItemsChange={onItemsChange} />}
    </Modal>
  );
};
