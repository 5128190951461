import { Switch, SwitchProps } from 'antd';

import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { useAnalytics } from '@hooks/useAnalytics';
import { useCallback } from 'react';
import { Tracking } from '@hooks/useFormControlledTracking';

type CustomSwitchControlledProps<T extends FieldValues> = {
  controllerProps: UseControllerProps<T>;
  tracking?: Tracking;
} & SwitchProps;

const CustomSwitchControlled = <T extends FieldValues>({
  controllerProps,
  tracking,
  ...restSwitchProps
}: CustomSwitchControlledProps<T>): JSX.Element => {
  const { field: props } = useController(controllerProps);
  const track = useAnalytics();
  const onChange = useCallback(
    (checked: boolean) => {
      const oldValue = props.value;
      tracking &&
        track(
          {
            eventType: 'edit',
            fieldId: tracking.fieldId,
            section: tracking.section,
            sectionCategory: tracking.sectionCategory,
          },
          {
            value: checked,
            oldValue,
          },
        );
      props.onChange(checked ?? null);
    },
    [tracking, track, props],
  );

  return (
    <Switch
      {...props}
      checked={props.value}
      onChange={onChange}
      {...restSwitchProps}
    />
  );
};

export default CustomSwitchControlled;
