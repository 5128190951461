import { FilterFunc } from 'rc-select/lib/Select';
import { DefaultOptionType } from 'antd/lib/select';

export const filterOptionsHandler: FilterFunc<DefaultOptionType> = (
  input,
  option,
) => {
  const optionValue = option?.label as unknown as string;
  return optionValue.toLowerCase().includes(input.toLowerCase());
};
