import { CloseOutlined } from '@ant-design/icons';
import {
  AdMgmtInput,
  AutoheroAndWkda,
  ExportItem,
  GeneralSectionData,
  GetDataQuery,
  PublishingStatus,
  useSaveDataMutation,
} from '@gql_codegen/retail-types';

import { zodResolver } from '@hookform/resolvers/zod';
import useAdId from '@hooks/useAdId';
import { useAnalytics } from '@hooks/useAnalytics';
import useSaveFormHelpers from '@hooks/useFormSave';
import { usePageData } from '@hooks/usePageData';
import {
  ActionBar,
  Classifieds,
  Export,
  General,
  PriceManagementGroup,
  PricingChangelog,
} from '@sections/index';
import {
  MARKETPLACES,
  TRACKING_SECTION,
  TRACKING_SECTION_CATEGORY,
} from '@src/constants';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import { appAnalytics } from '@utils/analytics';
import { retailKibanaLogger } from '@utils/logger';
import { Form, Space, Spin, notification } from 'antd';
import axios from 'axios';
import { useCallback, useEffect, useMemo } from 'react';
import {
  FieldPath,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { getFormSchema } from './FormSchema';
import { getFormErrors } from './formValidationError';
import cn from './styles.less';
import { getServerErrors } from '@utils/getServerErrors';
import { EQUIPMENT_NEWLY_ADDED_MAP } from '@sections/Classifieds/AutoheroWKDA/Equipment/constants';

type FormPriceChangeReason = {
  priceChangeReason: string | undefined;
  priceChangeComment: string | undefined;
};
export type FormType = GetDataQuery & FormPriceChangeReason;

export type FormNamesUnion = FieldPath<FormType>;
export type AdMgmtInputUnion = FieldPath<AdMgmtInput>;

export const Layout = (): JSX.Element => {
  const adId = useAdId();
  const { data, isLoading: isPageDataLoading, error } = usePageData();
  useEffect(() => {
    if (!data && error) {
      console.log('error', error);
      // AdFail
      retailKibanaLogger.warn(
        'Server did not provide a response for this ad',
        error,
      );
      notification.error({
        className: cn.notification,
        message: 'Server did not provide a response for this ad',
        description: (
          <div>
            Error:
            <Space direction="vertical">{getServerErrors(error)}</Space>
          </div>
        ),
        style: {
          width: '100%',
        },
      });
      appAnalytics.trackEvent({
        name: 'adError',
        props: {
          ...appAnalytics.EMPTY_EVENT,
          adId: adId || '',
        },
      });
    } else {
      appAnalytics.trackEvent({
        name: 'adLoaded',
        props: appAnalytics.EMPTY_EVENT,
      });
    }
  }, [adId, data, error]);

  if (isPageDataLoading)
    return (
      <div className={cn.loadingSpinner} data-qa-selector="loading-spinner">
        <Spin size="large" />
      </div>
    );
  //Handle error cases, otherwise we will infinitely try to fetch
  if (!data && error) {
    retailKibanaLogger.warn(
      'Server did not provide a response for this ad',
      error,
    );
    notification.error({
      className: cn.notification,
      message: 'Server did not provide a response for this ad',
      description: (
        <div>
          Error:
          <Space direction="vertical">{getServerErrors(error)}</Space>
        </div>
      ),
      style: {
        width: '100%',
      },
    });
    return (
      <div>
        Server did not provide a response for this ad, error:{' '}
        <Space direction="vertical">{getServerErrors(error)}</Space>
      </div>
    );
  }
  return <LayoutInner />;
};

const LayoutInner = (): JSX.Element => {
  const { data, error, refetch } = usePageData();
  const saveFormHelpers = useSaveFormHelpers();
  const adId = useAdId();

  const { mutateAsync } = useSaveDataMutation();
  const isMutating = useIsMutating() > 0;
  const isFetching = useIsFetching() > 0;
  const formSchema = useMemo(
    () => getFormSchema(data?.adMgmt?.general),
    [data?.adMgmt?.general],
  );

  const methods = useForm<FormType>({
    defaultValues: {
      ...data,
      priceChangeReason: undefined,
      priceChangeComment: undefined,
    },
    resolver: (formValues, ctx, options) =>
      zodResolver(formSchema(formValues))(formValues, ctx, options),
  });
  const track = useAnalytics();

  useEffect(() => {
    if (data && !error) {
      methods.reset({
        ...data,
        priceChangeComment: undefined,
        priceChangeReason: undefined,
      });
    }
  }, [data, methods, error]);

  useEffect(() => {
    //save on ctrl+s
    const handleKeyDown = (event: WindowEventMap['keydown']) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 's') {
        event.preventDefault();
        methods.handleSubmit(handleSubmitForm, onValidationError)();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods]);

  const {
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { isSubmitting, defaultValues },
  } = methods;

  const handleSubmitForm: SubmitHandler<FormType> = async () => {
    if (isFetching || isMutating)
      return console.error(
        'Layout::handleSubmitForm -> fetching or mutating is in a progress',
        { isFetching, isMutating },
      );

    if (!data) {
      console.error('Layout::handleSubmitForm -> no data available', data);
      return;
    }
    let currentFormValues = getValues();

    //#region 1. check schema one more time for message to be shown
    // const isSchemaValid = formSchema.safeParse(currentFormValues);

    //#endregion

    //#region  2. check price changed
    const generalData = data.adMgmt.general.data;
    const currentPrice =
      currentFormValues.adMgmt.general.data.grossPriceMajorUnits ?? null;
    const initialPrice = generalData.grossPriceMajorUnits ?? null;
    const expectedPrice = generalData.expectedSalesPriceMajorUnits ?? null;
    const maxPriceChangePercent = 10;

    const isPriceExceedMaxPercentage =
      saveFormHelpers.checkPriceExceedPercentage({
        initial: initialPrice,
        current: currentPrice,
        expected: expectedPrice,
        allowedPercentage: maxPriceChangePercent,
      });

    if (isPriceExceedMaxPercentage) {
      const newPriceConfirmed = global.confirm(
        'Price change is more than 10%. Are you sure you want to proceed?',
      );
      if (!newPriceConfirmed) {
        methods.setValue(
          'adMgmt.general.data.grossPriceMajorUnits',
          initialPrice,
        );
        return;
      }
    }
    //#endregion

    //#region 3. check for the new version
    //TODO: implement storage of user changes based on checks if a field dirty
    try {
      const isNewVersionAvailable = await saveFormHelpers.checkNewVersion({
        currentVersion: data.adMgmt.version,
        currentChecksum: data.adMgmt.autoheroAndWkda.media.data?.checksum ?? '',
      });

      if (isNewVersionAvailable) {
        const userWantsReload = global.confirm(
          'There is a new version of this ad. Press ‘OK’ to load the new version of the Ad. Your unsaved changes will be lost.',
        );

        if (userWantsReload) {
          await refetch();
          notification.success({
            className: cn.notification,
            message: 'New Ad version was successfully loaded!',
          });
        } else {
          notification.error({
            className: cn.notification,
            message:
              'There is a new version of this ad. Please reload the page.',
          });
        }

        return;
      }
    } catch (error) {
      console.error(
        'Layout::handleSubmitForm -> cannot get a new version',
        error,
      );
      notification.error({
        className: cn.notification,
        message: `Unfortunately, check for new version has failed`,
      });
      retailKibanaLogger.warn(
        'Layout::handleSubmitForm -> cannot get a new version',
        error,
      );
      return;
    }

    //#region 3.1 check if items have new translations
    const showNewTranslationMessage =
      saveFormHelpers.checkItemsHaveNewTranslations(
        currentFormValues.adMgmt.autoheroAndWkda.featureDetails.data,
      );
    if (showNewTranslationMessage) {
      const userConfirmTranslations = global.confirm(
        'Some of the translations have been updated, do you agree with them?',
      );

      if (!userConfirmTranslations) {
        notification.info({
          message:
            'Please, check the new translations in the equipment section',
        });
        return;
      }
    }
    //#endregion
    //#endregion

    //#region 4. upload rotated images
    try {
      const data = currentFormValues.adMgmt.autoheroAndWkda as AutoheroAndWkda;
      if (data) {
        const { imperfections, images } =
          await saveFormHelpers.uploadRotatedImages(data);
        // FIX-ME find a proper way to type setValue
        setValue('adMgmt.autoheroAndWkda.imperfections', imperfections as any);
        setValue('adMgmt.autoheroAndWkda.media.data.images', images as any);
      }
    } catch (error) {
      console.error(
        'Layout::handleSubmitForm -> cannot upload rotated image',
        error,
      );
      notification.error({
        className: cn.notification,
        message: `Unfortunately, updating the rotated image has failed`,
      });
      retailKibanaLogger.warn(
        'Layout::handleSubmitForm -> cannot upload rotated image',
        error,
      );
      return;
    }

    //#endregion

    //#region 5. prepare the data
    currentFormValues = getValues();
    const preparedData = saveFormHelpers.mappingFormData(currentFormValues);
    // NOTE: Partner field in general section is not editatble for now and api does not support its mutation
    // so removing it from payload for now
    delete preparedData.general.data.partner;
    //#endregion

    //#region 6. save the data
    try {
      const exportItems = currentFormValues.adMgmt?.exportOverview?.exportItems;
      const defaultValuesExportItems =
        defaultValues?.adMgmt?.exportOverview?.exportItems;
      const autoheroData = exportItems?.find(
        (item) =>
          (item as ExportItem).marketplace.id.toUpperCase() ===
          MARKETPLACES.AUTOHERO,
      );
      const defaultValuesAutoheroData = defaultValuesExportItems?.find(
        (item) =>
          (item as ExportItem).marketplace.id.toUpperCase() ===
          MARKETPLACES.AUTOHERO,
      );
      if (
        autoheroData?.publishingStatus === PublishingStatus.Published &&
        !autoheroData?.comingSoon &&
        defaultValuesAutoheroData?.comingSoon
      ) {
        track(
          {
            eventType: 'publish',
            fieldId: 'CsToRegular publish',
          },
          preparedData,
        );
      } else if (
        autoheroData?.publishingStatus === PublishingStatus.Published
      ) {
        track(
          {
            eventType: 'publish',
            fieldId: 'Save publish',
          },
          preparedData,
        );
      } else {
        track(
          {
            eventType: 'save',
            fieldId: 'Regular save',
          },
          preparedData,
        );
      }
      await mutateAsync({
        adId: adId || '',
        adMgmtInput: preparedData,
      });
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        notification.error({
          className: cn.notification,
          message: error.message,
        });
      } else {
        notification.error({
          className: cn.notification,
          message: getServerErrors(error),
        });
      }
      retailKibanaLogger.warn('Layout::handleSubmitForm -> save failed', error);
      //prevent all other steps
      return;
    }
    //#endregion

    //#region 7. get new Ad data
    try {
      await refetch({ throwOnError: true });
    } catch (error) {
      if (error instanceof Error) {
        notification.error({
          className: cn.notification,

          message: "Data was successfully saved but wasn't refreshed",
          description: `Error: ${error.message}`,
        });
      }
      if (typeof error === 'string') {
        notification.error({
          className: cn.notification,
          message: "Data was successfully saved but wasn't refreshed",
          description: `Error: ${error}`,
        });
      }
      retailKibanaLogger.warn('Layout::handleSubmitForm -> save failed', error);
      //prevent all other steps
      return;
    }
    //#endregion

    //#region 8. Show service history validation messages
    const warningMessages = saveFormHelpers.getWarningMessages(
      preparedData.general.data as GeneralSectionData,
    );

    warningMessages.forEach(({ message, qaSelector }): void => {
      notification.warning({
        className: cn.notification,
        message: <span data-qa-selector={qaSelector}>{message}</span>,
        closeIcon: (
          <CloseOutlined data-qa-selector={`${qaSelector}-close-btn`} />
        ),
      });
    });
    //#endregion

    //#region 9. Show success message
    notification.success({
      className: cn.notification,
      message: (
        <span data-qa-selector="ad-saved-notification">
          Ad was successfully saved! Thank you
        </span>
      ),
      closeIcon: (
        <CloseOutlined data-qa-selector="ad-saved-notification-close-btn" />
      ),
    });
    //#endregion

    //#region 10. Clear the map of newly added equipment
    EQUIPMENT_NEWLY_ADDED_MAP.clear();
    //#endregion
  };

  const handleResetForm = useCallback(() => {
    reset();
    track({
      eventType: 'edit',
      eventCategory: 'modify',
      section: TRACKING_SECTION.GENERAL,
      sectionCategory: TRACKING_SECTION_CATEGORY.AH,
      fieldId: 'Reset',
    });
  }, [track, reset]);

  const onValidationError: SubmitErrorHandler<FormType> = (errors) => {
    if (!data) {
      console.error('Layout::handleSubmitForm -> no data available', data);
      return;
    }

    const formatedErrors = getFormErrors(errors);

    notification.error({
      className: cn.notification,
      message: formatedErrors.map(({ field, message }, idx) => {
        return (
          <div key={field + idx}>
            {field}: {message}
          </div>
        );
      }),
    });

    //TODO: remove setTimeout, it's only there because react rerender items only after this function exits and that's why document.querySelectorAll returns no items
    setTimeout(() => {
      const equipmentWithErrorElements = document.querySelectorAll(
        '[data-equipment-error=true]',
      );
      equipmentWithErrorElements.item(0)?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }, 50);
  };

  return (
    <FormProvider {...methods}>
      <Form
        noValidate={true}
        onFinish={handleSubmit(handleSubmitForm, onValidationError)}
        onReset={handleResetForm}
        data-qa-selector="layoutForm"
        layout="vertical"
      >
        <ActionBar saveInProgress={isSubmitting || isFetching || isMutating} />
        <Export />
        <General />
        <Classifieds />
        <PricingChangelog />
        <PriceManagementGroup />
      </Form>
    </FormProvider>
  );
};
