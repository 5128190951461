import { useHasPublishingBlockers, useIsPublished } from '../publishHooks';
import { Table, Button, Popover } from 'antd';
import SwitchControlled from '@components/formControlled/SwitcherControlled';
import cns from 'classnames';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import cn from './../styles.less';
import { formatDateTime } from '@utils/date';
import {
  MARKETPLACES,
  POSSIBLE_PUBLISHING_STATUSES,
  TRACKING_SECTION,
  TRACKING_SECTION_CATEGORY,
} from '@src/constants';
import { renderTitle, renderDate, getFullExternalUrl } from '../functions';
import { usePublish, useUnpublish } from '../publishHooks';
import { useAppForm } from '@hooks/useAppForm';
import { Status, PublishStatusChecking } from './../index';
import { useAnalytics } from '@hooks/useAnalytics';

const { Column } = Table;

type AutoheroProps = {
  status: Status;
  setCommonPublishingStatus: PublishStatusChecking;
};

export const Autohero = (props: AutoheroProps): JSX.Element | null => {
  const { status, setCommonPublishingStatus } = props;
  const { getValues } = useAppForm();
  const hasPublishingBlockers = useHasPublishingBlockers();
  const isPublished = useIsPublished(MARKETPLACES.AUTOHERO);
  const [isPublishing, publish] = usePublish(setCommonPublishingStatus);
  const [isUnpublishing, unpublish] = useUnpublish(setCommonPublishingStatus);
  const track = useAnalytics();

  const exportItems = getValues('adMgmt.exportOverview.exportItems') ?? [];
  const autoheroExportData = exportItems.find(
    (item) => item.marketplace.id.toUpperCase() === MARKETPLACES.AUTOHERO,
  );

  const autoheroExportDataMapped = autoheroExportData
    ? {
        marketplace: autoheroExportData.marketplace,
        daysOnline: autoheroExportData.daysOnline,
        firstPublishedAt: autoheroExportData.firstPublishedAt,
        firstEligibleToBePurchasedAt:
          autoheroExportData.firstEligibleToBePurchasedAt,
        updatedAt: autoheroExportData.updatedAt,
        lastPublishingErrors: autoheroExportData.lastPublishingErrors,
        links: autoheroExportData.links,
        publishingStatus: autoheroExportData.publishingStatus,
        canPublish: autoheroExportData.canPublish,
        comingSoon: autoheroExportData.comingSoon,
      }
    : null;

  if (!autoheroExportDataMapped) {
    return null;
  }

  const dataSource = [autoheroExportDataMapped];

  const handlePublish = () => {
    const data = {
      marketplaceId: autoheroExportDataMapped.marketplace.id,
      accountIds: [],
    };
    publish(data);
    if (autoheroExportDataMapped.comingSoon) {
      track(
        {
          eventType: 'cs_unpublish',
          fieldId: 'Coming soon publish',
          section: TRACKING_SECTION.EXPORT_OVERVIEW_AH,
          sectionCategory: TRACKING_SECTION_CATEGORY.AH,
        },
        data,
      );
    }
    track(
      {
        eventType: 'publish',
        fieldId: 'Regular publish',
        section: TRACKING_SECTION.EXPORT_OVERVIEW_AH,
        sectionCategory: TRACKING_SECTION_CATEGORY.AH,
      },
      data,
    );
  };

  const handleUnpublish = () => {
    const data = {
      marketplaceId: autoheroExportDataMapped.marketplace.id,
      accountIds: [],
    };
    unpublish(data);
    if (autoheroExportDataMapped.comingSoon) {
      track(
        {
          eventType: 'cs_unpublish',
          fieldId: 'Coming soon unpublish',
          section: TRACKING_SECTION.EXPORT_OVERVIEW_AH,
          sectionCategory: TRACKING_SECTION_CATEGORY.AH,
        },
        data,
      );
    }
    track(
      {
        eventType: 'unpublish',
        fieldId: 'Regular unpublish',
        section: TRACKING_SECTION.EXPORT_OVERVIEW_AH,
        sectionCategory: TRACKING_SECTION_CATEGORY.AH,
      },
      data,
    );
  };

  const renderStatus = () => {
    const { links, lastPublishingErrors, publishingStatus } =
      autoheroExportDataMapped;

    if (
      (status.isStatusChecking &&
        status.platform.toUpperCase() === MARKETPLACES.AUTOHERO) ||
      isPublishing ||
      isUnpublishing ||
      publishingStatus === POSSIBLE_PUBLISHING_STATUSES.IN_PROGRESS
    ) {
      return (
        <div className={cn.status} data-qa-selector="autohero-inProgress">
          In Progress ...
        </div>
      );
    }

    if (links && links.length) {
      return (
        <div className={cn.status}>
          {links.map((link) => {
            const fullUrl = getFullExternalUrl(link);

            return fullUrl ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                key={fullUrl}
                href={fullUrl}
                className={cns(cn.stateItem, cn.externalLink)}
                data-qa-selector={`${link.title}-link`}
              >
                {link.title}
              </a>
            ) : null;
          })}
        </div>
      );
    }

    const hasErrors = !!(lastPublishingErrors && lastPublishingErrors.length);

    const publishingErrorTitle =
      lastPublishingErrors.length === 1
        ? `Last publishing error`
        : `Last ${lastPublishingErrors.length} publishing errors`;

    const lastErrors = lastPublishingErrors.map(
      ({ createdAt, message }, index) => (
        <div key={index}>
          {formatDateTime(createdAt)} | {message}
        </div>
      ),
    );

    return (
      <div className={cn.status}>
        {!isPublished && (
          <div
            key="notPublished"
            className={cn.stateItem}
            data-qa-selector="autohero-notPublishedState"
          >
            Not Published
          </div>
        )}

        {hasErrors && (
          <Popover
            content={lastErrors}
            title={publishingErrorTitle}
            trigger="click"
            placement="bottom"
          >
            <div key="errors" className={cns(cn.stateItem, cn.hasErrors)}>
              {'Has errors '}
              <ExclamationCircleOutlined />
            </div>
          </Popover>
        )}
      </div>
    );
  };

  const renderActionBtns = () => {
    const { publishingStatus, canPublish, comingSoon, marketplace } =
      autoheroExportDataMapped;
    const isBtnDisabled =
      !canPublish ||
      status.isStatusChecking ||
      isPublishing ||
      isUnpublishing ||
      publishingStatus === POSSIBLE_PUBLISHING_STATUSES.IN_PROGRESS;
    const hasComingSoon = typeof comingSoon === 'boolean';
    const isBtnTextUnknown =
      (status.isStatusChecking &&
        status.platform.toUpperCase() === MARKETPLACES.AUTOHERO) ||
      isPublishing ||
      isUnpublishing ||
      publishingStatus === POSSIBLE_PUBLISHING_STATUSES.IN_PROGRESS;
    const isToggleDisabled =
      publishingStatus === POSSIBLE_PUBLISHING_STATUSES.PUBLISHED &&
      hasPublishingBlockers;

    const actionBtns = isPublished ? (
      <Button
        disabled={isBtnDisabled}
        className={cn.actionButton}
        data-qa-selector={`unpublish-${marketplace.id}`}
        type={status.isStatusChecking ? 'default' : 'primary'}
        onClick={handleUnpublish}
        style={
          status.isStatusChecking
            ? {}
            : { backgroundColor: '#ff7518', borderColor: 'transparent' }
        }
      >
        {isBtnTextUnknown ? '...' : 'Unpublish'}
      </Button>
    ) : (
      <Button
        disabled={isBtnDisabled}
        className={cn.actionButton}
        data-qa-selector={`publish-${marketplace.id}`}
        type={status.isStatusChecking ? 'default' : 'primary'}
        onClick={handlePublish}
      >
        {isBtnTextUnknown ? '...' : 'Publish'}
      </Button>
    );

    const comingSoonBlock = hasComingSoon ? (
      <div className="comingSoonWrapper">
        <SwitchControlled
          data-qa-selector="comingSoonSwitch"
          size="small"
          tracking={{
            fieldId: 'ComingSoon Toggle',
            eventCategory: 'modify',
            section: TRACKING_SECTION.EXPORT_OVERVIEW_AH,
            sectionCategory: TRACKING_SECTION_CATEGORY.AH,
          }}
          disabled={isToggleDisabled}
          controllerProps={{
            name: `adMgmt.exportOverview.exportItems.0.comingSoon`,
          }}
        />
        Coming soon
      </div>
    ) : null;
    return (
      <div className={cn.actionWrapper}>
        {actionBtns}
        {comingSoonBlock}
      </div>
    );
  };

  type ExportItem = typeof autoheroExportDataMapped;

  return (
    <div className={cn.root}>
      <Table<ExportItem>
        data-qa-selector="autohero-export-table"
        pagination={false}
        size="small"
        dataSource={dataSource}
        rowKey={(d) => d.marketplace.id}
        scroll={{
          x: true,
        }}
      >
        <Column<ExportItem>
          dataIndex="marketplace"
          title={() =>
            renderTitle({ key: 'marketplace', title: 'Marketplace' })
          }
          render={(marketplace) => (
            <span data-qa-selector="marketplace-value">
              {marketplace.title}
            </span>
          )}
        />
        <Column<ExportItem>
          dataIndex="firstPublishedAt"
          title={() =>
            renderTitle({
              key: 'firstPublishedAt',
              title: 'First Published At',
            })
          }
          render={(date: string) => (
            <span data-qa-selector={'firstPublishedAt-value'}>
              {renderDate(date)}
            </span>
          )}
        />
        <Column<ExportItem>
          dataIndex="firstEligibleToBePurchasedAt"
          title={() =>
            renderTitle({
              key: 'firstEligibleToBePurchasedAt',
              title: 'First Available For Purchase At',
            })
          }
          render={(date: string) => (
            <span data-qa-selector={'firstEligibleToBePurchasedAt-value'}>
              {renderDate(date)}
            </span>
          )}
        />
        <Column<ExportItem>
          dataIndex="updatedAt"
          title={() => renderTitle({ key: 'updatedAt', title: 'Updated At' })}
          render={(date: string) => (
            <span data-qa-selector={'updatedAt-value'}>{renderDate(date)}</span>
          )}
        />
        <Column<ExportItem>
          dataIndex="daysOnline"
          title={() => renderTitle({ key: 'daysOnline', title: 'Days Online' })}
          render={(daysCount: number) => (
            <span data-qa-selector={'daysOnline-value'}>{daysCount}</span>
          )}
        />
        <Column<ExportItem>
          dataIndex="publishingStatus"
          title={() => renderTitle({ key: 'publishingStatus', title: 'State' })}
          render={renderStatus}
        />
        <Column<ExportItem>
          dataIndex="_"
          title={() => renderTitle({ key: 'action', title: 'Action' })}
          render={renderActionBtns}
        />
      </Table>
    </div>
  );
};
