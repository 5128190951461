import { format } from 'date-fns';

export const formatDateTime = (str: string): string | null => {
  if (!str) {
    return null;
  }

  const date = new Date(str);
  return format(date, 'yyyy-MM-dd HH:mm');
};
