import { useWatch } from 'react-hook-form';
import { Form, Select, Input, Button } from 'antd';
import { useEffect, useState, useRef, useMemo, useCallback } from 'react';

import { useAppForm } from '@hooks/useAppForm';
import { usePageData } from '@hooks/usePageData';
import { CloseOutlined } from '@ant-design/icons';
import { ModelSubtypeItem } from '@gql_codegen/retail-types';
import InputControlled from '@components/formControlled/InputControlled';
import SelectControlled from '@components/formControlled/SelectControlled';
import { prepareOptionList } from '@sections/General/CarData/prepareOptionList';

import cn from './styles.less';
import { TRACKING_SECTION, TRACKING_SECTION_CATEGORY } from '@src/constants';
import { useAnalytics } from '@hooks/useAnalytics';

interface SubTypeProps {
  filterOptionsHandler: (input: any, option: any) => boolean;
}

export const SubTypeControlled = ({ filterOptionsHandler }: SubTypeProps) => {
  const isMounted = useRef(false);
  const [model, subType] = useWatch({
    name: ['adMgmt.general.data.model', 'adMgmt.general.data.subType'],
  });
  const { data } = usePageData();
  const [oldValue, setOldValue] = useState(subType);
  const track = useAnalytics();

  const subTypeOptions = useMemo(() => {
    const modelItem = (
      data?.adMgmt?.dictionaries?.global?.modelOptions || []
    ).find((item) => item.value === model);
    return (
      data?.adMgmt?.dictionaries?.global?.subtypeOptions?.find(
        (item: ModelSubtypeItem) => item.model === modelItem?.label,
      )?.subtypes || []
    );
  }, [
    data?.adMgmt?.dictionaries?.global?.modelOptions,
    data?.adMgmt?.dictionaries?.global?.subtypeOptions,
    model,
  ]);

  const {
    formState: { errors, submitCount, defaultValues },
    setValue,
  } = useAppForm();
  const generalErrors = errors?.adMgmt?.general?.data || {};

  const [isInputState, setIsInputState] = useState(
    !subTypeOptions?.find(({ value }) => value === subType),
  );

  useEffect(() => {
    const subType = defaultValues?.adMgmt?.general?.data?.subType;
    const model = defaultValues?.adMgmt?.general?.data?.model;
    const modelItem = (
      defaultValues?.adMgmt?.dictionaries?.global?.modelOptions || []
    ).find((item) => item?.value === model);
    const subTypeOptions =
      defaultValues?.adMgmt?.dictionaries?.global?.subtypeOptions?.find(
        (item) => item?.model === modelItem?.label,
      )?.subtypes || [];
    setIsInputState(!subTypeOptions?.find((item) => item?.value === subType));
  }, [defaultValues, submitCount]);

  useEffect(() => {
    if (isMounted.current) {
      setValue('adMgmt.general.data.subType', '');
      setIsInputState(false);
    } else {
      isMounted.current = true;
    }
  }, [model, setValue]);

  const handleBlur = useCallback(() => {
    if (subType !== oldValue) {
      track(
        {
          eventType: 'edit',
          fieldId: 'Subtype',
          section: TRACKING_SECTION.CAR_DATA,
          sectionCategory: TRACKING_SECTION_CATEGORY.AH,
        },
        {
          value: subType,
          oldValue,
        },
      );
    }
  }, [track, subType, oldValue]);

  return (
    <Form.Item
      data-qa-selector="general-subtype"
      label="Subtype"
      required
      validateStatus={generalErrors?.subType ? 'error' : 'success'}
      help={
        <div data-qa-selector="general-subtype-explain-error">
          {generalErrors?.subType?.message}
        </div>
      }
    >
      {isInputState ? (
        <Input.Group compact className={cn.subTypeInputWrapper}>
          <InputControlled
            disabled={!model}
            data-qa-selector="general-subtype-input"
            controllerProps={{
              name: 'adMgmt.general.data.subType',
            }}
            placeholder="Do not use this field unless absolutely necessary"
            onFocus={() => {
              subType && setOldValue(subType);
            }}
            onBlur={handleBlur}
          />
          <Button
            onClick={() => {
              setIsInputState(false);
              setValue('adMgmt.general.data.subType', '');
            }}
          >
            <CloseOutlined />
          </Button>
        </Input.Group>
      ) : (
        <SelectControlled
          showSearch
          disabled={!model}
          id="general-subtype-select"
          filterOption={filterOptionsHandler}
          data-qa-selector="general-subtype-select"
          controllerProps={{
            name: 'adMgmt.general.data.subType',
          }}
          placeholder="Select..."
          onChangeCustom={(value) => {
            if (value === 'other') {
              setIsInputState(true);
              setValue('adMgmt.general.data.subType', '');
            }
          }}
          onFocus={() => {
            subType && setOldValue(subType);
          }}
          onBlur={handleBlur}
        >
          {prepareOptionList(subTypeOptions)}
          <Select.Option key={-1} value="other" label="Other Subtype">
            Other Subtype
          </Select.Option>
        </SelectControlled>
      )}
    </Form.Item>
  );
};
