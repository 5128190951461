/* eslint-disable @typescript-eslint/no-namespace */
import { FeatureDetailItem } from '@gql_codegen/retail-types';
import { EnumLike } from '@src/types/utilTypes';

export namespace FeaturePaths {
  export type FeatureDetailsPath = `adMgmt.autoheroAndWkda.featureDetails`;
  export type FeatureDetailsDataPath = `${FeatureDetailsPath}.data`;
  export type FeatureDetailsCategoryPath =
    `${FeatureDetailsDataPath}.${number}`;
  export type FeatureDetailsCategoryItemsPath =
    `${FeatureDetailsCategoryPath}.items`;
  export type FeatureDetailsItemPath =
    `${FeatureDetailsCategoryItemsPath}.${number}`;
  export type FeatureDetailsItemTranslationsPath =
    `${FeatureDetailsItemPath}.translations`;
  export type FeatureDetailsTranslationPath =
    `${FeatureDetailsItemTranslationsPath}.${number}`;
}

export type EquipmentCSVInput = {
  group: string;
  subGroup: string;
  [key: string]: string;
};
export type EquipmentCSVRecord = {
  group: string;
  subGroup: string;
  translations: Array<{ locale: string; text: string }>;
};

export const dataPaths = {
  /**
   * current equipment of the ad
   */
  featureDetails: 'adMgmt.autoheroAndWkda.featureDetails.data',
  as24: 'adMgmt.autoscout24.data.featureDetails.data',
  mobiledeV2: 'adMgmt.mobiledeV2.data.featureDetails.data',
  restrictions: 'adMgmt.autoheroAndWkda.featureDetails.restrictions',
  /**
   * list of all allowed equipment that users can add to the ad
   */
  customFeatureTranslationConfigs:
    'adMgmt.dictionaries.global.customFeatureTranslationConfigs',
  /**
   * list of allowed locales for the ad
   */
  supportedLanguageLocales:
    'adMgmt.dictionaries.global.supportedLanguageLocales',
  as24EquipmentOptions:
    'adMgmt.dictionaries.autoscout24.localizedFeatureDetailCategories',
  mobiledeV2EquipmentOptions:
    'adMgmt.dictionaries.mobiledeV2.localizedFeatureDetailCategories',
} as const;

type Categories =
  | 'highlights'
  | 'comfort'
  | 'multimedia'
  | 'light and sight'
  | 'security'
  | 'additional'
  | 'additional (unclassified)';

export const EQUIPMENT_CATEGORIES: EnumLike<Categories> = {
  'light and sight': 'light and sight',
  additional: 'additional',
  comfort: 'comfort',
  highlights: 'highlights',
  multimedia: 'multimedia',
  security: 'security',
  'additional (unclassified)': 'additional (unclassified)',
};

export const EQUIPMENT_CATEGORIES_IDS = {
  'additional (unclassified)': '1a4f0c8f-6fd0-47f1-b4d0-728e09e85c11',
} satisfies Partial<Record<Categories, string>>;

export const INVALID_GROUPS: EnumLike<'unclassified' | 'car-communication'> = {
  unclassified: 'unclassified',
  'car-communication': 'car-communication',
};

export const INVALID_ITEMS = {
  [INVALID_GROUPS.unclassified]: {
    group: INVALID_GROUPS.unclassified,
    subGroup: null,
    category: EQUIPMENT_CATEGORIES['additional (unclassified)'],
  },
};

export const EQUIPMENT_NEWLY_ADDED_MAP = new Map<
  string,
  FeatureDetailItem & { edited: boolean }
>();
