import { unitsFromMinorToMajor } from './unitsConversion';
import { CURRENCY_INFO_BY_CODE } from '@src/constants';

export default function formatPrice(
  amountMinorUnits: number,
  currencyCode: string,
): string {
  const amount = unitsFromMinorToMajor(amountMinorUnits);
  const [, currencyInfo] =
    Object.entries(CURRENCY_INFO_BY_CODE).find(
      ([key]) => key === currencyCode,
    ) ?? [];
  if (!currencyInfo) {
    return String(amount);
  }
  const currencyFormat = new Intl.NumberFormat(currencyInfo.formatLocale, {
    currency: currencyCode,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  // eslint-disable-next-line no-irregular-whitespace
  return `${currencyFormat.format(amount)} ${currencyInfo.currencySymbol}`;
}
