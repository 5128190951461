// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ifpOk2dCXixoi9BG7PBy {\n  min-height: 30px;\n  margin-bottom: 20px;\n  padding: 4px 11px;\n  cursor: not-allowed;\n  text-align: left;\n  border: 1px solid #d9d9d9;\n  border-radius: 2px;\n  background-color: #f5f5f5;\n}\n", "",{"version":3,"sources":["webpack://./src/sections/General/DamagesHistory/styles.less"],"names":[],"mappings":"AACA;EACE,gBAAA;EACA,mBAAA;EACA,iBAAA;EAEA,mBAAA;EACA,gBAAA;EAEA,yBAAA;EACA,kBAAA;EACA,yBAAA;AAFF","sourcesContent":["@antVersion: antd-5-18-2;\n.text {\n  min-height: 30px;\n  margin-bottom: 20px;\n  padding: 4px 11px;\n\n  cursor: not-allowed;\n  text-align: left;\n\n  border: 1px solid #d9d9d9;\n  border-radius: 2px;\n  background-color: #f5f5f5;\n}\n\n@ant-prefix: antd-5-18-2;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "ifpOk2dCXixoi9BG7PBy"
};
export default ___CSS_LOADER_EXPORT___;
