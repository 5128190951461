import { ServiceHistoryConfigTask } from '@gql_codegen/retail-types';
import { usePageData } from '@hooks/usePageData';

export const useServiceHistoryTasks = (): ServiceHistoryConfigTask[] => {
  const { data } = usePageData();
  const { serviceHistoryConfigTasks: tasksTypes = [] } =
    data?.adMgmt?.dictionaries?.global || {};

  return tasksTypes;
};
