import get from 'lodash.get';
import { Row, Tabs, Badge } from 'antd';
import { useAppForm } from '@hooks/useAppForm';
import { useController } from 'react-hook-form';
import { useState, useMemo, useCallback } from 'react';
import { Image, ImperfectionItem } from '@gql_codegen/retail-types';
import ImperfectionItemWrapper from '@sections/Classifieds/AutoheroWKDA/Imperfections/ImperfectionsField/ImperfectionItemWrapper';
import { TAGGABLE_IMAGE_TYPES } from '@src/constants';

import cn from './style.less';
import { groupBy } from '@utils/groupBy';

const FIELD_PATH = 'adMgmt.autoheroAndWkda.imperfections.items';

const { TabPane } = Tabs;

type ImperfectionItemSystem = ImperfectionItem & {
  index?: number;
  hasTag?: boolean;
};

export const ImperfectionCategories = {
  BACK: 'BACK',
  FRONT: 'FRONT',
  RIGHT: 'RIGHT',
  LEFT: 'LEFT',
  ROOF: 'ROOF',
  INTERIOR: 'INTERIOR',
  UNDERBODY: 'UNDERBODY',
  WHEELS: 'WHEELS',
};

export const ImperfectionsField = () => {
  const { control, getValues } = useAppForm();

  const {
    field: { value: imperfections, onChange },
  } = useController({
    control,
    name: FIELD_PATH,
  });

  const list = imperfections as Array<ImperfectionItem>;

  const getTaggedItem = useCallback(
    (imperfectionImageId: string) => {
      let image: Image | undefined;
      const images =
        getValues('adMgmt.autoheroAndWkda.media.data.images') || {};
      const type = TAGGABLE_IMAGE_TYPES.find((imageType) => {
        // FIX-ME using this MediaImages type says there is not index signature
        // need to find a way how types works here. Please remove any and see errors
        const targetImages = images[imageType] || [];
        image = targetImages.find((image) =>
          (image?.tags || []).some(
            (tag) => tag.tagImageId === imperfectionImageId,
          ),
        );
        return image;
      });

      if (image) {
        image = {
          ...image,
          tags: (image?.tags || []).filter(
            (tag) => tag.tagImageId === imperfectionImageId,
          ),
        };
      }

      return { type, image };
    },
    [getValues],
  );

  const grouped = useMemo<{ [key: string]: Array<ImperfectionItemSystem> }>(
    () =>
      groupBy(
        (list || []).map((item, index) => {
          const hasTag = getTaggedItem(item?.image?.id).image?.tags?.length;
          return { ...item, index, hasTag: Boolean(hasTag) };
        }),
        'category',
      ),
    [list, getTaggedItem],
  );

  const [categories, data] = useMemo(() => {
    const adCategories: { [key: string]: Array<ImperfectionItemSystem> } = {};
    Object.keys(ImperfectionCategories).forEach((item: string) => {
      if (grouped[item]) {
        adCategories[item] = grouped[item] as Array<ImperfectionItemSystem>;
      }
    });
    const categories = Object.keys(adCategories);
    if (grouped['null']) {
      const label = 'OTHER';
      categories.push(label);
      adCategories[label] = grouped['null'];
    }
    return [categories, adCategories];
  }, [grouped]);

  const [tab, setTab] = useState(categories?.[0] || '');

  const handleItemUpdate = (imperfection: ImperfectionItemSystem): void => {
    onChange(
      list?.map((item: ImperfectionItem) => {
        if (item.id === imperfection.id) {
          delete imperfection.index;
          delete imperfection.hasTag;
          return imperfection;
        }
        return item;
      }),
    );
  };

  if (!list?.length) {
    return (
      <h3 data-qa-selector="empty-imperfections">
        There are no imperfections for the Ad
      </h3>
    );
  }

  return (
    <Tabs
      defaultActiveKey={tab}
      onChange={(tab) => {
        setTab(tab);
      }}
      type="card"
      className={cn.tabs}
    >
      {categories?.map((item: any) => (
        <TabPane
          tab={
            <div className={cn.tab}>
              <Badge.Ribbon
                text={
                  <div
                    data-qa-selector="ribbon-wrapper"
                    data-qa-selector-ribbon={item}
                  >
                    {get(data, item)?.filter(({ hasTag }) => hasTag).length} /{' '}
                    {data[item]?.length}
                  </div>
                }
              >
                <span data-qa-selector="ribbon-content" className={cn.tabTitle}>
                  {item}
                </span>
              </Badge.Ribbon>
            </div>
          }
          key={item}
        >
          <Row gutter={[0, 20]} data-qa-selector="imperfection-list">
            {get(data, tab)?.map((field: ImperfectionItemSystem) => (
              <ImperfectionItemWrapper
                id={field.id}
                key={field.id}
                imperfection={
                  list[field.index as number] as ImperfectionItemSystem
                }
                onChange={handleItemUpdate}
                getTaggedItem={getTaggedItem}
              />
            ))}
          </Row>
        </TabPane>
      ))}
    </Tabs>
  );
};
