import { Input, InputProps } from 'antd';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import {
  useFormControllerTracking,
  Tracking,
} from '@hooks/useFormControlledTracking';

type InputControlledProps<T extends FieldValues> = {
  controllerProps: UseControllerProps<T>;
  tracking?: Tracking;
  format?: (value: string) => string;
} & InputProps;

const InputControlled = <T extends FieldValues>({
  controllerProps,
  tracking,
  onFocus,
  onBlur,
  format,
  ...restInputProps
}: InputControlledProps<T>): JSX.Element => {
  const { field: props } = useController(controllerProps);
  const { onFocus: trackingFocus, onBlur: trackingBlur } =
    useFormControllerTracking<T>({
      controllerProps: props,
      tracking,
    });

  return (
    <Input
      {...props}
      {...restInputProps}
      onChange={(e) => {
        if (format) {
          props.onChange(format(e.target.value));
        } else {
          props.onChange(e);
        }
      }}
      onFocus={(e) => {
        onFocus?.(e);
        trackingFocus();
      }}
      onBlur={(e) => {
        onBlur?.(e);
        trackingBlur();
      }}
    />
  );
};

export default InputControlled;
