import { MouseEventHandler } from 'react';
import { Button, Tooltip } from 'antd';
type AddRecordButtonProps = {
  onClick: MouseEventHandler;
  allowed: boolean;
};

export const AddNewMaintenanceButton = ({
  onClick,
  allowed,
}: AddRecordButtonProps) => {
  return (
    <Tooltip title={allowed ? undefined : 'Maximum number of entries reached'}>
      <Button
        htmlType="button"
        type="primary"
        onClick={onClick}
        disabled={!allowed}
        data-qa-selector="add-new-maintenance-record"
      >
        ADD NEW MAINTENANCE
      </Button>
    </Tooltip>
  );
};
