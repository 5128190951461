import * as kibanaLogger from '@retail/kibana-logger/lib/client';
import { LOGGER_ENDPOINTS } from '@src/constants';
import { Logger, LogObject } from '@retail/kibana-logger/lib/shared/types';
import { FAIL_RESPONSE } from '@gql_codegen/typings/api/api_fail';

export class RetailKibanaLogger {
  private _clientSideLogger: Logger;

  constructor() {
    this._clientSideLogger = this.getLogger();
  }

  private isProduction(): boolean {
    return window?.__config?.APP_ENV === 'prod';
  }

  private getLogger() {
    const logFn =
      process.env['NODE_ENV'] === 'development'
        ? kibanaLogger.createDevelopmentLogFn()
        : kibanaLogger.createApiLogFn({
            appName: __PACKAGE_NAME__,
            appVersion: __PACKAGE_VERSION__,
            endpoint: this.isProduction()
              ? LOGGER_ENDPOINTS.PROD
              : LOGGER_ENDPOINTS.QA,
          });
    return kibanaLogger.createClientSideLogger({}, logFn);
  }

  private getLogObject(
    traceId: string | number,
    message: string,
    label: string,
  ): LogObject {
    return {
      message,
      traceId,
      label,
    };
  }

  private formatError(err: unknown): string {
    if (err instanceof Error) {
      return JSON.stringify({
        message: err.message,
        stack: err.stack,
      });
    }
    return JSON.stringify(err);
  }

  public error(label: string, err: unknown): void {
    this._clientSideLogger.error(
      this.getLogObject(
        (err as FAIL_RESPONSE)?.extensions?.traceId || '',
        this.formatError(err),
        label,
      ),
    );
  }

  public info(message: string): void {
    this._clientSideLogger.info(this.getLogObject('', message, 'info'));
  }

  warn(message: string, err?: unknown): void {
    this._clientSideLogger.warn(
      this.getLogObject(
        (err as FAIL_RESPONSE)?.extensions?.traceId ?? '',
        message,
        'warn',
      ),
    );
  }
}

export const retailKibanaLogger = new RetailKibanaLogger();
