import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
  ServiceHistoryConfigTask,
  ServiceHistoryDocumentType,
  ServiceHistoryRecord,
} from '@gql_codegen/retail-types';
import { formatDate } from '@utils/date';
import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { DOCUMENT_TYPES } from '../constants';
import cn from './styles.less';

const renderTestColumn = (testId: string, content: JSX.Element) => (
  <div data-qa-selector={`table-${testId}-column`}>{content}</div>
);

export const getServiceHistoryTableColumns = (
  taskTypes: Array<ServiceHistoryConfigTask>,
  onEdit: (index: number) => void,
  onDelete: (index: number) => void,
): ColumnsType<ServiceHistoryRecord> => {
  return [
    {
      title: <span>Last Service / Maintenance on</span>,
      dataIndex: 'lastServiceOn',
      key: 'lastServiceOn',
      render: (lastServiceOn) => {
        if (!lastServiceOn) {
          return;
        }
        return renderTestColumn(
          'last-service-on',
          <div>{formatDate(lastServiceOn)}</div>,
        );
      },
    },
    {
      title: <span>Last Service / Maintenance mileage</span>,
      dataIndex: 'lastServiceMileage',
      key: 'lastServiceMileage',
      render: (lastServiceMileage) =>
        renderTestColumn(
          'last-service-mileage',
          <div className={cn.tableColumnWordBreak}>{lastServiceMileage}</div>,
        ),
    },
    {
      title: <span>Service / Maintenance</span>,
      dataIndex: 'type',
      key: 'recordType',
      render: (recordType) =>
        renderTestColumn(
          'service/maintenance',
          <div className={cn.tableColumnWordBreak}>{recordType}</div>,
        ),
    },
    {
      title: <span>Done by</span>,
      dataIndex: 'servicePerformedBy',
      key: 'servicePerformedBy',
      render: (servicePerformedBy) =>
        renderTestColumn(
          'service-performed-by',
          <div className={cn.tableColumnWordBreak}>{servicePerformedBy}</div>,
        ),
    },
    {
      title: <span>Tasks Performed</span>,
      dataIndex: 'tasksPerformed',
      key: 'tasksPerformed',
      render: (tasksPerformed) =>
        tasksPerformed &&
        renderTestColumn(
          'tasks-performed',
          <ol>
            {tasksPerformed.map((task: string) => {
              const taskType = taskTypes.find(
                (taskType: ServiceHistoryConfigTask) => taskType.value === task,
              );

              return (
                <li key={taskType?.value ?? task}>{taskType?.label ?? task}</li>
              );
            })}
          </ol>,
        ),
    },
    {
      title: <span>Document type</span>,
      dataIndex: 'documentType',
      key: 'documentType',
      render: (documentTypes) => {
        return (
          documentTypes &&
          renderTestColumn(
            'document-type',
            documentTypes
              .map((type: ServiceHistoryDocumentType) => DOCUMENT_TYPES[type])
              .join(', '),
          )
        );
      },
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      render: (_id, _record, index) => {
        return (
          <div className={cn.actionButtons}>
            <Button
              className={cn.editButton}
              icon={<EditOutlined />}
              type="primary"
              onClick={() => onEdit(index)}
              data-qa-selector="edit-service-record-button"
            />
            <Button
              icon={<DeleteOutlined />}
              type="primary"
              danger
              onClick={() => onDelete(index)}
              data-qa-selector="remove-service-record-button"
            />
          </div>
        );
      },
    },
  ];
};
