import { useMemo, HTMLAttributes } from 'react';
import cls from 'classnames';
import { Spin } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import { Document, isUploaded } from '@src/types/serviceHistoryDocument';
import { useImagePolling } from './useImagePolling';
import cn from './styles.less';

type ImageThumbnailProps = {
  document: Document;
  fallbackClassname?: string;
} & HTMLAttributes<HTMLElement>;

export const ImageThumbnail = ({
  document,
  className,
  fallbackClassname,
}: ImageThumbnailProps) => {
  const { loading, isError, handleLoad, handleError, imageRef } =
    useImagePolling(document);

  const fallback = useMemo(() => {
    if (isUploaded(document)) {
      return (
        <div
          className={cn.imagePlaceholderText}
          title={document.name}
          data-qa-selector="service-history-image-thumbnail-filename"
        >
          {document.name}
        </div>
      );
    } else {
      return (
        <FilePdfOutlined
          className={cn.fileIcon}
          data-qa-selector="service-history-image-thumbnail-icon"
        />
      );
    }
  }, [document]);

  if (isError) {
    return (
      <div className={cls(cn.imageContainer, className, fallbackClassname)}>
        <div className={cn.imagePlaceholder}>{fallback}</div>
      </div>
    );
  }

  return (
    <div className={cls(cn.imageContainer, className)}>
      <img
        ref={imageRef}
        className={cls(cn.image, {
          [cn.hide]: loading,
        })}
        onLoad={handleLoad}
        onError={handleError}
        alt="gallery item"
        data-qa-selector="service-history-image-thumbnail"
      />

      {loading && (
        <div
          className={cn.imagePlaceholder}
          data-qa-selector="service-history-image-thumbnail-loading"
        >
          <Spin />
        </div>
      )}
    </div>
  );
};
