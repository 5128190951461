import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import dayjs from 'dayjs';
import { PickerProps } from 'antd/lib/date-picker/generatePicker';
import { CUSTOM_DATE_FORMATS } from '@utils/date';

import {
  Tracking,
  useFormControllerTracking,
} from '@hooks/useFormControlledTracking';
import { DatePicker } from 'antd';
import { ANTD_BUNDLED_VERSION } from '@src/constants';

type DatePickerControlledProps<T extends FieldValues> = {
  controllerProps: UseControllerProps<T>;
  tracking?: Tracking;
} & PickerProps;

const DatePickerControlled = <T extends FieldValues>({
  controllerProps,
  tracking,
  ...restDatePickerProps
}: DatePickerControlledProps<T>) => {
  const { field } = useController(controllerProps);
  const { onFocus, onBlur } = useFormControllerTracking({
    tracking,
    controllerProps: field,
  });

  return (
    <DatePicker
      {...field}
      onChange={(value) => {
        if (!value) return field.onChange(null);
        return field.onChange(
          Array.isArray(value)
            ? value.map((value) =>
                value.format(CUSTOM_DATE_FORMATS.BACKEND_FORMAT),
              )
            : value.format(CUSTOM_DATE_FORMATS.BACKEND_FORMAT),
        );
      }}
      value={
        field?.value
          ? dayjs(field.value, CUSTOM_DATE_FORMATS.BACKEND_FORMAT)
          : null
      }
      style={{ width: '100%' }}
      prefixCls={`${ANTD_BUNDLED_VERSION}-picker`}
      {...restDatePickerProps}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};

export default DatePickerControlled;
