// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NLkn__gEqeXpqYCmKTiW .keBCp2rBDVYRa9Cup3ID {\n  margin-bottom: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/sections/Classifieds/ClassifiedsTab/MobileTitle/styles.less"],"names":[],"mappings":"AACA;EACE,gBAAA;AAAF","sourcesContent":["@antVersion: antd-5-18-2;\n.mobileTitle .noMargin {\n  margin-bottom: 0;\n}\n\n@ant-prefix: antd-5-18-2;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobileTitle": "NLkn__gEqeXpqYCmKTiW",
	"noMargin": "keBCp2rBDVYRa9Cup3ID"
};
export default ___CSS_LOADER_EXPORT___;
