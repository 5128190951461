import { Table, Button, Popover, Checkbox } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { formatDateTime } from '@utils/date';
import { useHasPublishingBlockers } from '../../publishHooks';
import cn from './../../styles.less';
import { ExportListing } from '@gql_codegen/retail-types';
import {
  POSSIBLE_PUBLISHING_STATUSES,
  MARKETPLACES,
  TRACKING_SECTION,
  TRACKING_SECTION_CATEGORY,
} from '@src/constants';
import cns from 'classnames';
import { renderTitle, renderDate } from '../../functions';
import { usePublish, useUnpublish } from '../../publishHooks';
import { Status, PublishStatusChecking } from './../../index';
import { useAnalytics } from '@hooks/useAnalytics';

const { Column } = Table;

// TODO workaround to exclude deprecated fields
type Account = Omit<ExportListing, 'accountId' | 'state'> & {
  isSelected: boolean;
};

type AccountsProps = {
  exportListings: Account[];
  canPublish: boolean;
  status: Status;
  selectAS24Account: (accountId: string) => void;
  setCommonPublishingStatus: PublishStatusChecking;
};

export const Accounts = (props: AccountsProps): JSX.Element | null => {
  const {
    exportListings,
    canPublish,
    status,
    selectAS24Account,
    setCommonPublishingStatus,
  } = props;
  const hasPublishingBlockers = useHasPublishingBlockers();
  const [isPublishing, publish] = usePublish(setCommonPublishingStatus);
  const [isUnpublishing, unpublish] = useUnpublish(setCommonPublishingStatus);
  const track = useAnalytics();

  if (exportListings.length === 0) return null;

  const handlePublish = (accountId: string) => {
    const data = {
      marketplaceId: MARKETPLACES.AUTOSCOUT24,
      accountIds: [accountId],
    };
    publish(data);
    track(
      {
        eventType: 'publish',
        fieldId: 'Regular publish',
        section: TRACKING_SECTION.EXPORT_OVERVIEW_CL,
        sectionCategory: TRACKING_SECTION_CATEGORY.CL,
      },
      data,
    );
  };

  const handleUnpublish = (accountId: string) => {
    const data = {
      marketplaceId: MARKETPLACES.AUTOSCOUT24,
      accountIds: [accountId],
    };
    unpublish(data);
    track(
      {
        eventType: 'unpublish',
        fieldId: 'Regular unpublish',
        section: TRACKING_SECTION.EXPORT_OVERVIEW_CL,
        sectionCategory: TRACKING_SECTION_CATEGORY.CL,
      },
      data,
    );
  };

  const renderAccount = (account: Account) => {
    return (
      <div>
        <Checkbox
          data-qa-selector={`as24account-${account.account.title}`}
          checked={account.isSelected}
          value={account.account.id}
          onChange={() => {
            selectAS24Account(account.account.id);
            track(
              {
                eventType: 'edit',
                fieldId: 'City',
                section: TRACKING_SECTION.EXPORT_OVERVIEW_CL,
                sectionCategory: TRACKING_SECTION_CATEGORY.CL,
              },
              {
                oldValue: null,
                value: account.account,
              },
            );
          }}
        >
          {account.account.title}
        </Checkbox>
      </div>
    );
  };

  const renderStatus = (account: Account) => {
    if (
      (status.isStatusChecking &&
        status.platform.toUpperCase() === MARKETPLACES.AUTOSCOUT24) ||
      isPublishing ||
      isUnpublishing
    ) {
      return (
        <div
          className={cn.status}
          data-qa-selector="autoscout24-account-inProgress"
        >
          In Progress ...
        </div>
      );
    }
    const isPublished =
      account.publishingStatus === POSSIBLE_PUBLISHING_STATUSES.PUBLISHED;

    const { link, lastPublishingErrors } = account;

    if (link) {
      return (
        <a
          target="_blank"
          data-qa-selector="as24-account-link"
          rel="noopener noreferrer"
          key={link.url}
          href={link.url}
          className={cn.stateItem}
        >
          {link.title}
        </a>
      );
    }
    const hasErrors = !!(lastPublishingErrors && lastPublishingErrors.length);

    const publishingErrorTitle =
      lastPublishingErrors.length === 1
        ? `Last publishing error`
        : `Last ${lastPublishingErrors.length} publishing errors`;

    const lastErrors = lastPublishingErrors.map(
      ({ createdAt, message }, index) => (
        <div key={index}>
          {formatDateTime(createdAt)} | {message}
        </div>
      ),
    );

    return (
      <div className={cn.status}>
        {!isPublished && (
          <div
            data-qa-selector="autoscout24-notPublishedState"
            className={cn.stateItem}
          >
            Not Published
          </div>
        )}

        {hasErrors && (
          <Popover
            content={lastErrors}
            title={publishingErrorTitle}
            trigger="click"
            placement="bottom"
          >
            <div key="errors" className={cns(cn.stateItem, cn.hasErrors)}>
              {'Has errors '}
              <ExclamationCircleOutlined />
            </div>
          </Popover>
        )}
      </div>
    );
  };

  const renderActionBtns = (account: ExportListing) => {
    const isBtnDisabled =
      !canPublish ||
      hasPublishingBlockers ||
      isPublishing ||
      isUnpublishing ||
      status.isStatusChecking;
    const isPublished =
      account.publishingStatus === POSSIBLE_PUBLISHING_STATUSES.PUBLISHED;
    const isBtnTextUnknown =
      (status.isStatusChecking &&
        status.platform.toUpperCase() === MARKETPLACES.AUTOSCOUT24) ||
      isPublishing ||
      isUnpublishing;

    if (isPublished) {
      return (
        <Button
          disabled={isBtnDisabled}
          className={cn.actionButton}
          data-qa-selector={`unpublish-${account.account.id}`}
          type={status.isStatusChecking ? 'default' : 'primary'}
          danger={!status.isStatusChecking}
          onClick={() => {
            handleUnpublish(account.account.id);
          }}
          style={
            status.isStatusChecking
              ? {}
              : { backgroundColor: '#ff7518', borderColor: 'transparent' }
          }
        >
          {isBtnTextUnknown ? '...' : 'Unpublish'}
        </Button>
      );
    } else {
      return (
        <Button
          disabled={isBtnDisabled}
          className={cn.actionButton}
          data-qa-selector={`publish-${account.account.id}`}
          type={status.isStatusChecking ? 'default' : 'primary'}
          onClick={() => {
            handlePublish(account.account.id);
          }}
        >
          {isBtnTextUnknown ? '...' : 'Publish'}
        </Button>
      );
    }
  };

  const renderActions = (account: ExportListing) => {
    return <div className={cn.actionWrapper}>{renderActionBtns(account)}</div>;
  };

  return (
    <Table<Account>
      data-qa-selector="autoscout24-accounts-table"
      pagination={false}
      rowKey={(d) => d.account.id}
      size="small"
      tableLayout="fixed"
      dataSource={exportListings}
      showHeader={true}
    >
      <Column<Account>
        dataIndex=""
        title={() => renderTitle({ key: 'marketplace', title: '' })}
        render={(marketplace) => (
          <span data-qa-selector="marketplace-value">{marketplace.title}</span>
        )}
      />
      <Column<Account>
        dataIndex="firstPublishedAt"
        title={() =>
          renderTitle({ key: 'firstPublishedAt', title: 'First Published At' })
        }
        render={(date: string) => (
          <span data-qa-selector={'firstPublishedAt-value'}>
            {renderDate(date)}
          </span>
        )}
      />
      <Column<Account>
        dataIndex="lastPublishedAt"
        title={() =>
          renderTitle({ key: 'lastPublishedAt', title: 'Updated At' })
        }
        render={(date: string) => (
          <span data-qa-selector={'lastPublishedAt-value'}>
            {renderDate(date)}
          </span>
        )}
      />
      <Column<Account>
        dataIndex="daysOnline"
        title={() => renderTitle({ key: 'daysOnline', title: 'Days Online' })}
        render={(daysCount: number) => (
          <span data-qa-selector={'daysOnline-value'}>{daysCount}</span>
        )}
      />
      <Column<Account>
        dataIndex="publishingStatus"
        title={() => renderTitle({ key: 'publishingStatus', title: 'State' })}
        render={(_, account) => renderStatus(account)}
      />

      <Column<Account>
        dataIndex=""
        title={() => renderTitle({ key: 'city', title: 'City' })}
        render={(account) => renderAccount(account)}
      />
      <Column<Account>
        dataIndex=""
        title={() => renderTitle({ key: 'action', title: 'Action' })}
        render={(account) => renderActions(account)}
      />
    </Table>
  );
};
