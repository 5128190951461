import CheckboxControlled from '@components/formControlled/CheckboxControlled';
import { useAppForm } from '@hooks/useAppForm';
import { TRACKING_SECTION, TRACKING_SECTION_CATEGORY } from '@src/constants';
import { useWatch } from 'react-hook-form';
import { useEffect, useRef } from 'react';

type ShowToCustomerCheckboxProps = {
  index: number;
  disabled: boolean;
};

export const ShowToCustomerCheckbox = (props: ShowToCustomerCheckboxProps) => {
  const { control, setValue } = useAppForm();
  const isMounted = useRef<boolean>(false);

  const { index, disabled } = props;
  const isRepaired = useWatch({
    name: `adMgmt.general.data.carAccidentDamages.accidentDamages.${index}.isRepaired`,
    control,
  });

  useEffect(() => {
    if (isMounted.current) {
      setValue(
        `adMgmt.general.data.carAccidentDamages.accidentDamages.${index}.showToCustomer`,
        Boolean(isRepaired),
      );
    } else {
      isMounted.current = true;
    }
  }, [isRepaired, isMounted]);

  return (
    <CheckboxControlled
      data-qa-selector={`accident-damage-showToCustomer-${index}`}
      controllerProps={{
        control,
        name: `adMgmt.general.data.carAccidentDamages.accidentDamages.${index}.showToCustomer`,
      }}
      tracking={{
        eventCategory: 'modify',
        section: TRACKING_SECTION.HISTORY_DAMAGES,
        sectionCategory: TRACKING_SECTION_CATEGORY.AH,
        fieldId: 'ShowToCustomer',
      }}
      disabled={disabled}
    ></CheckboxControlled>
  );
};
