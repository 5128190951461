// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "button.oNGPP4FpX2qKxIbI45rz {\n  isolation: isolate;\n  position: fixed;\n  right: 1rem;\n  bottom: 1rem;\n  -webkit-transition: background 40ms;\n  transition: background 40ms;\n  opacity: 0.35;\n  border-color: hsl(33, 100%, 56%);\n  background: hsl(39, 100%, 86%);\n}\nbutton.oNGPP4FpX2qKxIbI45rz:hover {\n  opacity: 1;\n  border-color: hsl(41, 100%, 80%);\n  background: hsl(41, 100%, 98%);\n}\n", "",{"version":3,"sources":["webpack://./src/components/GetTraceIds/styles.less"],"names":[],"mappings":"AACA;EACE,kBAAA;EAEA,eAAA;EACA,WAAA;EACA,YAAA;EAEA,mCAAA;EAAA,2BAAA;EAEA,aAAA;EACA,gCAAA;EACA,8BAAA;AAHF;AAKE;EACE,UAAA;EACA,gCAAA;EACA,8BAAA;AAHJ","sourcesContent":["@antVersion: antd-5-18-2;\nbutton.getTraceId {\n  isolation: isolate;\n\n  position: fixed;\n  right: 1rem;\n  bottom: 1rem;\n\n  transition: background 40ms;\n\n  opacity: 0.35;\n  border-color: hsl(33deg 100% 56%);\n  background: hsl(39deg 100% 86%);\n\n  &:hover {\n    opacity: 1;\n    border-color: hsl(41deg 100% 80%);\n    background: hsl(41deg 100% 98%);\n  }\n}\n\n@ant-prefix: antd-5-18-2;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"getTraceId": "oNGPP4FpX2qKxIbI45rz"
};
export default ___CSS_LOADER_EXPORT___;
