import {
  ServiceHistoryDocument,
  ServiceHistoryDocumentInput,
} from '@gql_codegen/retail-types';

export type UploadedDocument = Omit<ServiceHistoryDocument, 'thumbnailUrl'> &
  ServiceHistoryDocumentInput & { name: string; thumbnailUrl: string | null };

export type Document = ServiceHistoryDocument | UploadedDocument;

export const isUploaded = (
  document: ServiceHistoryDocument | UploadedDocument,
): document is UploadedDocument =>
  (document as UploadedDocument).fileExt !== undefined;
