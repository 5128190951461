import CommentOutlined from '@ant-design/icons/lib/icons/CommentOutlined';
import { Tooltip } from 'antd';
import { FC } from 'react';
import { Maybe } from '@graphql-tools/utils';

interface PriceChangeReasonProps {
  changeReason: string;
  changeComment: Maybe<string>;
}

export const PriceChangeReason: FC<PriceChangeReasonProps> = ({
  changeReason,
  changeComment,
}) => {
  return (
    <div>
      {changeReason}
      {changeComment && (
        <Tooltip title={changeComment} style={{ display: 'block' }}>
          <CommentOutlined
            style={{ marginLeft: '8px', cursor: 'pointer', fontSize: '18px' }}
          />
        </Tooltip>
      )}
    </div>
  );
};
