import { useState } from 'react';
import { useController } from 'react-hook-form';
import { ServiceHistoryRecordInput } from '@gql_codegen/retail-types';
import { useAppForm } from '@hooks/useAppForm';
import { useAnalytics } from '@hooks/useAnalytics';
import { TRACKING_SECTION, TRACKING_SECTION_CATEGORY } from '@src/constants';
import { EnumLike } from '@src/types/utilTypes';

const FIELD_PATH = 'adMgmt.general.data.serviceHistory';
export const RECORDS_LIMIT = 10;

export type ServiceHistoryTypes = 'Service' | 'Maintenance';

export const SERVICE_HISTORY_TYPES: EnumLike<ServiceHistoryTypes> = {
  Maintenance: 'Maintenance',
  Service: 'Service',
};
const isServiceHistoryType = (value: string): value is ServiceHistoryTypes =>
  value in SERVICE_HISTORY_TYPES;

const useServiceHistoryRecords = () => {
  const { control } = useAppForm();
  const {
    field: { value, onChange },
  } = useController({
    control,
    name: FIELD_PATH,
  });

  const [showForm, setShowForm] = useState<ServiceHistoryTypes | undefined>(
    undefined,
  );
  const [form, setForm] = useState<{
    data: ServiceHistoryRecordInput | undefined;
    index: number;
  }>({ data: undefined, index: -1 });
  const track = useAnalytics();
  const records = (value?.records || []).sort(
    (first, second) =>
      +new Date(second.lastServiceOn) - +new Date(first.lastServiceOn),
  );

  const add = (formType: ServiceHistoryTypes) => {
    setShowForm(formType);
    setForm({
      data: undefined,
      index: records.length,
    });
  };

  const edit = (index: number) => {
    setForm({
      data: records[index],
      index: Math.min(records.length - 1, index),
    });
    const type = records[index]?.type;

    if (!type || !isServiceHistoryType(type)) return;

    setShowForm(type);
  };

  const save = (data: ServiceHistoryRecordInput): void => {
    const { index } = form;
    if (index < 0 || records.length > RECORDS_LIMIT) {
      return;
    }
    track(
      {
        eventType: index === records.length ? 'add' : 'edit',
        fieldId: showForm ?? 'Service',
        section: TRACKING_SECTION.HISTORY_SERVICE,
        sectionCategory: TRACKING_SECTION_CATEGORY.AH,
      },
      {
        value: data,
        oldValue: records[index] ?? null,
      },
    );

    const updatedValue = [
      ...records.slice(0, index),
      data,
      ...records.slice(index + 1),
    ];
    onChange({ ...value, records: updatedValue });
    setShowForm(undefined);
    setForm({
      data: undefined,
      index: -1,
    });
  };

  const remove = (index: number): void => {
    track(
      {
        eventType: 'delete',
        fieldId: showForm ?? 'Service',
        section: TRACKING_SECTION.HISTORY_SERVICE,
        sectionCategory: TRACKING_SECTION_CATEGORY.AH,
      },
      {
        value: null,
        oldValue: records[index],
      },
    );
    const updatedValue = [
      ...records.slice(0, index),
      ...records.slice(index + 1),
    ];
    onChange({ ...value, records: updatedValue });
  };

  const hide = () => {
    setShowForm(undefined);
    setForm({
      data: undefined,
      index: -1,
    });
  };

  const addAllowed = records.length < RECORDS_LIMIT;

  return {
    records,
    showForm,
    formData: form.data,
    add,
    edit,
    hide,
    save,
    remove,
    addAllowed,
  };
};

export default useServiceHistoryRecords;
