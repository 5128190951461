import { TRACKING_SECTION, TRACKING_SECTION_CATEGORY } from '@src/constants';
import { Form } from 'antd';
import AS24EditorControlled from './as24editor';

export const Autoscout24Description = () => {
  return (
    <Form.Item
      label="Autoscout24 description"
      data-qa-selector="autoscout24-description"
    >
      <AS24EditorControlled
        data-qa-selector="autoscout24-description-textarea"
        allowClear
        autoSize
        maxLength={4000}
        controllerProps={{
          name: 'adMgmt.autoscout24.data.description',
        }}
        tracking={{
          fieldId: 'Description',
          eventCategory: 'modify',
          section: TRACKING_SECTION.AS24_DESCRIPTION,
          sectionCategory: TRACKING_SECTION_CATEGORY.CL,
        }}
      />
    </Form.Item>
  );
};
