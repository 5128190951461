import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { MouseEvent, useState } from 'react';

type ImageSelectionWrapperProps = React.PropsWithChildren<
  {
    onCheck?: (checked: boolean) => void;
    checked?: boolean;
  } & JSX.IntrinsicElements['div']
>;

export const ImageSelectionWrapper = ({
  onCheck: onChange,
  children,
  checked,
  ...restProps
}: ImageSelectionWrapperProps) => {
  const [isChecked, setChecked] = useState(checked ?? false);

  const handleChange = (
    e: CheckboxChangeEvent | MouseEvent<HTMLDivElement>,
  ) => {
    const newChecked = 'checked' in e.target ? e.target.checked : !isChecked;
    setChecked(newChecked);
    onChange?.(newChecked);
  };

  return (
    <div {...restProps} onClick={handleChange}>
      {children}
      <Checkbox
        checked={checked ?? isChecked}
        //to prevent div onClick bubble
        onClick={(e) => e.stopPropagation()}
        onChange={handleChange}
        data-qa-selector="image-selection"
      />
    </div>
  );
};
