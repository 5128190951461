// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nZ4c2jb809vWKrPO9KL6 {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  margin-bottom: 8px;\n}\n.Zscn9_vfSogxqn0eMLjq {\n  height: 68px;\n}\n.s6U7n9Xz_ad1S4H9xEC8 {\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  margin-right: 8px;\n}\n.s6U7n9Xz_ad1S4H9xEC8 span {\n  margin-right: 8px;\n}\n.eeNeopXddD06u4_62sVQ {\n  margin-bottom: 30px;\n  padding: 48px 60px;\n  background-color: #e6e6e6;\n}\n.eeNeopXddD06u4_62sVQ h3 {\n  margin-top: 21px;\n  margin-bottom: 10.5px;\n  font-size: 26px;\n  font-weight: 300;\n}\n.bOxHUwwH6R2nrwKaHVhY {\n  pointer-events: none;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ImageGalleryField/styles.less"],"names":[],"mappings":"AACA;EACE,oBAAA;EAAA,oBAAA;EAAA,aAAA;EAEA,kBAAA;AADF;AAIA;EACE,YAAA;AAFF;AAKA;EACE,yBAAA;MAAA,sBAAA;UAAA,mBAAA;EAEA,iBAAA;AAJF;AACA;EAMI,iBAAA;AAJJ;AAQA;EACE,mBAAA;EACA,kBAAA;EAEA,yBAAA;AAPF;AAGA;EAOI,gBAAA;EACA,qBAAA;EAEA,eAAA;EACA,gBAAA;AARJ;AAYA;EACE,oBAAA;AAVF","sourcesContent":["@antVersion: antd-5-18-2;\n.actionButtons {\n  display: flex;\n\n  margin-bottom: 8px;\n}\n\n.thumbnailWrapper {\n  height: 68px;\n}\n\n.actionButton {\n  align-items: center;\n\n  margin-right: 8px;\n\n  span {\n    margin-right: 8px;\n  }\n}\n\n.noImagePlaceHolder {\n  margin-bottom: 30px;\n  padding: 48px 60px;\n\n  background-color: #e6e6e6;\n\n  h3 {\n    margin-top: 21px;\n    margin-bottom: 10.5px;\n\n    font-size: 26px;\n    font-weight: 300;\n  }\n}\n\n.progressbar {\n  pointer-events: none;\n}\n\n@ant-prefix: antd-5-18-2;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionButtons": "nZ4c2jb809vWKrPO9KL6",
	"thumbnailWrapper": "Zscn9_vfSogxqn0eMLjq",
	"actionButton": "s6U7n9Xz_ad1S4H9xEC8",
	"noImagePlaceHolder": "eeNeopXddD06u4_62sVQ",
	"progressbar": "bOxHUwwH6R2nrwKaHVhY"
};
export default ___CSS_LOADER_EXPORT___;
