import { SyntheticEvent, useEffect, useRef, useState } from 'react';

import { rotateImageBySrc, ROTATE_90, ROTATE_270 } from './rotateImage';
import FullScreen from '../FullScreen';
import cn from './styles.less';
import { FILE_TYPE_BY_EXTENSION } from './fileExtensions';
import { Col, Row } from 'antd';

type ImageRotatorProps = {
  imageUrl: string;
  onRotate(rotatedImageUrl: string): void;
  disabled: boolean;
};

const POLLING_INTERVAL_TIME = 1000;
const MAX_POLLING_TIME = 20000;

export const ImageRotator = ({
  imageUrl,
  onRotate,
  disabled,
}: ImageRotatorProps) => {
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [pollingInterval, setPollingInterval] = useState<NodeJS.Timer | null>(
    null,
  );
  const [startPollingTime, setStartPollingTime] = useState<number | null>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, [setIsMounted]);

  const onImageError = () => {
    const img = imgRef.current;

    if (
      pollingInterval &&
      startPollingTime &&
      Date.now() - startPollingTime > MAX_POLLING_TIME
    ) {
      onImageLoad();

      return;
    }
    if (!pollingInterval) {
      setStartPollingTime(Date.now());

      const pollingIntervalId = setInterval(() => {
        if (img) {
          img.src = imageUrl;
        }
      }, POLLING_INTERVAL_TIME);

      setPollingInterval(pollingIntervalId);
    }
  };

  const onImageLoad = () => {
    if (pollingInterval) {
      clearInterval(pollingInterval);
    }

    setPollingInterval(null);
    setStartPollingTime(null);
  };

  const rotate = async (angle: number) => {
    setInProgress(true);

    const DEFAULT_FILE_TYPE = FILE_TYPE_BY_EXTENSION.jpeg;
    const fileExtension = imageUrl.split('.').pop() ?? '';
    const fileType = Object.prototype.hasOwnProperty.call(
      FILE_TYPE_BY_EXTENSION,
      fileExtension,
    )
      ? FILE_TYPE_BY_EXTENSION[
          fileExtension as keyof typeof FILE_TYPE_BY_EXTENSION
        ]
      : DEFAULT_FILE_TYPE;
    const rotatedImageUrl = await rotateImageBySrc(imageUrl, angle, fileType);

    if (!isMounted) {
      return;
    }

    onRotate(rotatedImageUrl);
    setInProgress(false);
  };

  const handleRotateLeft = (e: SyntheticEvent) => {
    e.preventDefault();
    rotate(ROTATE_270);
  };

  const handleRotateRight = (e: SyntheticEvent) => {
    e.preventDefault();
    rotate(ROTATE_90);
  };

  return (
    <Row>
      <Col span={21}>
        <div className={cn.imgWrapper}>
          <img
            className={cn.img}
            ref={imgRef}
            alt=""
            src={imageUrl}
            onError={onImageError}
            onLoad={onImageLoad}
            data-qa-selector="imperfection-image"
          />
        </div>

        <div className={cn.buttons}>
          <Row align="bottom" justify="space-between">
            <Col>
              <button
                disabled={disabled || inProgress}
                className={cn.rotateButton}
                onClick={handleRotateLeft}
                data-qa-selector="rotateLeft"
              >
                &#8634;
              </button>

              <button
                disabled={disabled || inProgress}
                className={cn.rotateButton}
                onClick={handleRotateRight}
                data-qa-selector="rotateRight"
              >
                &#8635;
              </button>
            </Col>
            <Col>
              {imgRef.current && <FullScreen target={imgRef.current} />}
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};
