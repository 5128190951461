import { useRef, useEffect, memo } from 'react';
import { useWatch } from 'react-hook-form';
import { useAppForm } from '@hooks/useAppForm';

export const SyncMobileTitle = memo(function SyncTitles() {
  const isMounted = useRef<boolean>(false);
  const { setValue } = useAppForm();

  const [model, subType, subTypeExtra] = useWatch({
    name: [
      'adMgmt.general.data.model',
      'adMgmt.general.data.subType',
      'adMgmt.general.data.subTypeExtra',
    ],
  });

  useEffect(() => {
    if (isMounted.current) {
      setValue(
        'adMgmt.mobiledeV2.data.title',
        `${model} ${subType} ${subTypeExtra ?? ''}`,
      );
    } else {
      isMounted.current = true;
    }
  }, [model, subTypeExtra, subType, setValue, isMounted]);

  return <></>;
});
