import { GrossPrice } from './GrossPrice';
import { ChangeReason } from '@sections/General/CarData/GrossPrice/ChangeReason';

interface GrossPriceFormItem {
  canEditGrossPrice: boolean;
  currencyCode: string | undefined;
}

export default function GrossPriceFormItem({
  canEditGrossPrice,
  currencyCode,
}: GrossPriceFormItem) {
  return (
    <>
      <GrossPrice
        canEditGrossPrice={canEditGrossPrice}
        currencyCode={currencyCode}
      />
      {canEditGrossPrice && <ChangeReason />}
    </>
  );
}
