import { notification } from 'antd';
import { useMutation } from '@tanstack/react-query';
import {
  uploadToS3,
  getAwsUploadMetadata,
  getImageMetadata,
} from '@utils/s3-uploader';
import { AwsUploadCredentials, ImageMetaData } from '@utils/s3-uploader-types';
import { useState } from 'react';
import { retailKibanaLogger } from '@utils/logger';
import { AxiosProgressEvent } from 'axios';

const errorMessage = 'Image Upload Error';

export interface ImageUploadDetails {
  filesToUpload: File[];
  type: string;
  startIndex: number;
}

interface UploadStatus {
  progress: number;
  imageName: string;
}
export const useUploadingImages = () => {
  const [currentUploads, setCurrentUploads] = useState<UploadStatus[]>([]);
  const {
    mutateAsync,
    isLoading,
    isSuccess,
    isError,
    data: uploadedImages,
  } = useMutation(
    async ({
      fileMetaDataList,
      filesToUpload,
    }: {
      fileMetaDataList: ImageMetaData[];
      filesToUpload: File[];
    }) => {
      const { data: credentialList } =
        await getAwsUploadMetadata(fileMetaDataList);
      return Promise.all(
        filesToUpload.map(async (fileToUpload: File, i: number) => {
          return uploadToS3({
            file: fileToUpload,
            credentials: credentialList[i] as AwsUploadCredentials,
            metaData: fileMetaDataList[i] as ImageMetaData,
            axiosOptions: {
              onUploadProgress: (event: AxiosProgressEvent) => {
                const progressPercent = Math.round(
                  (event.progress ?? Infinity) * 100,
                );
                setCurrentUploads((prev) =>
                  prev.map((progress, progressIdx) => {
                    const toChange = progressIdx === i;

                    if (!toChange) return progress;

                    return { ...progress, progress: progressPercent };
                  }),
                );
              },
            },
          });
        }),
      );
    },
  );

  async function uploadImages({
    filesToUpload,
    type,
    startIndex,
  }: ImageUploadDetails) {
    try {
      if (!filesToUpload?.length) {
        throw new Error('No files to upload!');
      }
      const pathname = window?.location.pathname;
      // TODO: get rid of adId reading
      const adId = pathname?.split('/')[3];
      if (!adId || !type) {
        throw new Error('No Add Id or type provided!');
      }
      const fileMetaDataList = filesToUpload.map((file: File, i: number) => {
        const order = startIndex + i;
        return getImageMetadata(adId, type, order, file);
      });

      setCurrentUploads(
        filesToUpload.map((file) => ({ imageName: file.name, progress: 0 })),
      );

      const uploadedFiles = await mutateAsync({
        filesToUpload,
        fileMetaDataList,
      });
      setCurrentUploads([]);

      return uploadedFiles;
    } catch (error) {
      retailKibanaLogger.warn('Media Image Download Failed', error);
      if (error instanceof Error) {
        notification.error({
          message: errorMessage,
          description: `Error: ${error.message}`,
        });
      }
      if (typeof error === 'string') {
        notification.error({
          message: errorMessage,
          description: `Error: ${error}`,
        });
      }

      setCurrentUploads([]);
    }
  }

  return {
    isLoading,
    isSuccess,
    isError,
    uploadedImages,
    uploadImages,
    currentUploads,
  };
};
