import { SUCCESS_RESPONSE } from '@gql_codegen/typings/api/api_success';
import axiosInstance from '@utils/axiosInstance';

export const api =
  <TData, TVariables>(
    gqlString: string,
    variables?: TVariables,
    options?: RequestInit['headers'],
  ): (() => Promise<TData>) =>
  async () => {
    const headers = new Headers(options);
    const operation = gqlString.trim();
    const response = await axiosInstance.post<SUCCESS_RESPONSE<TData>>(
      '/api/v1/retail-inventory-gql/graphql',
      {
        query: operation,
        variables,
      },
      {
        timeout: 60000,
        headers: Object.fromEntries(headers),
      },
    );

    //in case of error with 200 HTTP Code we throw it and react-query will handle it
    if (response.data.data === null || response.data.errors?.length)
      throw response.data;

    return response.data.data;
  };

export default api;
