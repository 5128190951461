import { Button, Modal, Image as ImageComponent, Tooltip } from 'antd';
import ImageSorter from './ImageSorter';
import { Image } from '@gql_codegen/retail-types';
import { ImageSelectionWrapper } from './ImageSelectionWrapper';
import cn from './styles.less';
import { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { CloseOutlined } from '@ant-design/icons';
type ImageGallerySortModalProps = {
  show: boolean;
  handleCancel: () => void;
  items: Image[];
  onItemsChange: (items: Image[]) => void;
  grayscaleLimit: number;
};

export const ImageGalleryManageModal = ({
  show,
  handleCancel,
  items,
  onItemsChange,
  grayscaleLimit,
}: ImageGallerySortModalProps): JSX.Element => {
  const [selected, setSelected] = useState<Set<string>>(new Set());

  const hasSelected = useMemo(
    () => Array.from(selected.values()).some((selected) => selected),
    [selected],
  );

  const handleSelect = ({
    checked,
    item: image,
  }: {
    checked: boolean;
    item: Image;
  }) => {
    setSelected((prevState) => {
      const newState = new Set(prevState);
      if (checked) {
        newState.add(image.id);
      } else {
        newState.delete(image.id);
      }
      return newState;
    });
  };

  const handleDelete = () => {
    const selectedForDeletion = Array.from(selected);
    const newItems = items.filter(
      (item) => !selectedForDeletion.includes(item.id),
    );

    onItemsChange(newItems);
  };

  const handleModalClose = () => {
    setSelected(new Set());
  };

  const isOutOfLimit = useCallback(
    (item: Image) =>
      items.findIndex((img) => img.id === item.id) >= grayscaleLimit,
    [grayscaleLimit, items],
  );

  return (
    <Modal
      title="Drag images to sort them or select for deletion"
      open={show}
      onCancel={handleCancel}
      afterClose={handleModalClose}
      footer={[
        <Button
          key="delete"
          type="primary"
          danger
          onClick={handleDelete}
          disabled={!hasSelected}
          data-qa-selector="image-gallery-sort-field-modal-delete-button"
        >
          Delete selected
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleCancel}
          data-qa-selector="image-gallery-sort-field-modal-submit-button"
        >
          Close
        </Button>,
      ]}
      width={900}
      closeIcon={
        <CloseOutlined data-qa-selector="image-gallery-sort-field-modal-close-button" />
      }
      data-qa-selector="image-gallery-sort-field-modal"
    >
      <ImageSorter.Provider
        items={items}
        onItemsChange={onItemsChange}
        wrapperProps={{ className: cn.sortableItemsWrapper }}
      >
        {(item, idx) => (
          <ImageSorter.Item item={item} key={item.id}>
            <ImageSelectionWrapper
              checked={selected.has(item.id) ?? false}
              onCheck={(checked) => handleSelect({ item, checked })}
              className={classNames(
                cn.sortableItem,
                selected.has(item.id) && cn.sortableItemSelected,
              )}
              data-qa-selector={`image-gallery-sort-field-modal-item-${idx}`}
            >
              {isOutOfLimit(item) ? (
                <Tooltip title="Out of limit">
                  <ImageComponent
                    src={item.thumbnail}
                    className={(cn.sortableItemImage, cn.grayscale)}
                    preview={false}
                  />
                </Tooltip>
              ) : (
                <ImageComponent
                  src={item.thumbnail}
                  className={cn.sortableItemImage}
                  preview={false}
                />
              )}
            </ImageSelectionWrapper>
          </ImageSorter.Item>
        )}
      </ImageSorter.Provider>
    </Modal>
  );
};
