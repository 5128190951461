export const restrictFloatInput = (
  evt: React.KeyboardEvent<HTMLInputElement>,
) => {
  evt.persist();
  if (
    (evt.keyCode > 47 && evt.keyCode < 58) ||
    (evt.keyCode > 95 && evt.keyCode < 106) ||
    evt.keyCode === 8 ||
    evt.keyCode === 9 ||
    evt.keyCode === 13 ||
    evt.keyCode === 46 ||
    evt.keyCode === 37 ||
    evt.keyCode === 39 ||
    ((evt.ctrlKey || evt.metaKey) && evt.keyCode === 65) ||
    ((evt.ctrlKey || evt.metaKey) && evt.keyCode === 90) ||
    ((evt.ctrlKey || evt.metaKey) && evt.keyCode === 88) ||
    ((evt.ctrlKey || evt.metaKey) && evt.keyCode === 67) ||
    ((evt.ctrlKey || evt.metaKey) && evt.keyCode === 86)
  ) {
    return;
  }
  evt.preventDefault();
};

export const cleanYearInput = (evt: React.KeyboardEvent<HTMLInputElement>) => {
  evt.persist();
  if (
    ((<HTMLInputElement>evt.target).value?.length < 4 &&
      evt.keyCode > 47 &&
      evt.keyCode < 58) ||
    evt.keyCode === 8 ||
    evt.keyCode === 9 ||
    evt.keyCode === 13 ||
    evt.keyCode === 46 ||
    evt.keyCode === 37 ||
    evt.keyCode === 39 ||
    ((evt.ctrlKey || evt.metaKey) && evt.keyCode === 65) ||
    ((evt.ctrlKey || evt.metaKey) && evt.keyCode === 90) ||
    ((evt.ctrlKey || evt.metaKey) && evt.keyCode === 88) ||
    ((evt.ctrlKey || evt.metaKey) && evt.keyCode === 67) ||
    ((evt.ctrlKey || evt.metaKey) && evt.keyCode === 86)
  ) {
    return;
  }
  evt.preventDefault();
};

export const cleanNumberInput = (
  evt: React.KeyboardEvent<HTMLInputElement>,
) => {
  evt.persist();
  if (
    (evt.keyCode > 47 && evt.keyCode < 58) ||
    (evt.keyCode > 95 && evt.keyCode < 106) ||
    evt.keyCode === 188 ||
    evt.keyCode === 190 ||
    evt.keyCode === 8 ||
    evt.keyCode === 9 ||
    evt.keyCode === 13 ||
    evt.keyCode === 46 ||
    evt.keyCode === 37 ||
    evt.keyCode === 39 ||
    ((evt.ctrlKey || evt.metaKey) && evt.keyCode === 65) ||
    ((evt.ctrlKey || evt.metaKey) && evt.keyCode === 90) ||
    ((evt.ctrlKey || evt.metaKey) && evt.keyCode === 88) ||
    ((evt.ctrlKey || evt.metaKey) && evt.keyCode === 67) ||
    ((evt.ctrlKey || evt.metaKey) && evt.keyCode === 86)
  ) {
    return;
  }
  evt.preventDefault();
};
