// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".O1qmrBEybgqSWqRo3P3J .UUCQM0YpVDTPLm_cBBMq {\n  margin: 6px 0;\n}\n", "",{"version":3,"sources":["webpack://./src/sections/Classifieds/ClassifiedsTab/styles.less"],"names":[],"mappings":"AACA;EAGE,aAAA;AAFF","sourcesContent":["@antVersion: antd-5-18-2;\n.description .divider {\n  // for divider selector weight\n\n  margin: 6px 0;\n}\n\n@ant-prefix: antd-5-18-2;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": "O1qmrBEybgqSWqRo3P3J",
	"divider": "UUCQM0YpVDTPLm_cBBMq"
};
export default ___CSS_LOADER_EXPORT___;
