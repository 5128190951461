import { useEffect, useState } from 'react';

const enterFullScreen = (target: HTMLImageElement) => {
  if (target.requestFullscreen) {
    target.requestFullscreen();
  } else if (target.msRequestFullscreen) {
    target.msRequestFullscreen();
  } else if (target.mozRequestFullScreen) {
    target.mozRequestFullScreen();
  } else if (target.webkitRequestFullscreen) {
    target.webkitRequestFullscreen();
  }
};

const exitFullScreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
};

const screenChangeEvents = [
  'fullscreenchange',
  'MSFullscreenChange',
  'mozfullscreenchange',
  'webkitfullscreenchange',
];

function useFullScreen(target: HTMLImageElement): [boolean, () => void] {
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    function handler() {
      const fullScreenElement =
        target.fullscreenElement ||
        target.msFullscreenElement ||
        target.mozFullScreenElement ||
        target.webkitFullscreenElement;

      setIsFullScreen(target === fullScreenElement);
    }

    screenChangeEvents.forEach((eventName) => {
      document.addEventListener(eventName, handler);
    });

    return () =>
      screenChangeEvents.forEach((eventName) =>
        document.removeEventListener(eventName, handler),
      );
  }, [target]);

  const toggleFullScreen = (): void => {
    if (isFullScreen) {
      exitFullScreen();
    } else {
      enterFullScreen(target);
    }
  };

  return [isFullScreen, toggleFullScreen];
}

export default useFullScreen;
