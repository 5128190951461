import {
  closestCenter,
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Image } from '@gql_codegen/retail-types';
import { CSSProperties, ReactNode } from 'react';
import CustomKeyboardSensor from './customKeyboardSensor';

type ObjWithId = { id: string | number };
type ImageSorterProps<T extends ObjWithId> = {
  items: T[];
  onItemsChange: (items: T[]) => void;
  children: (item: T, idx: number) => ReactNode;
  wrapperProps?: JSX.IntrinsicElements['div'];
};

const isInteractiveElement = (element: Element | null) => {
  const interactiveElements = [
    'button',
    'input',
    'textarea',
    'select',
    'option',
    'label',
  ];
  if (
    element?.tagName &&
    interactiveElements.includes(element.tagName.toLowerCase())
  ) {
    //no dragging
    return false;
  }

  return true;
};

const ImageSorterProvider = <T extends ObjWithId>({
  items,
  onItemsChange,
  children,
  wrapperProps,
}: ImageSorterProps<T>): JSX.Element => {
  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 2 } }),
    useSensor(CustomKeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
      isDraggableElement: (elem) => isInteractiveElement(elem),
    }),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over?.id);
      const sortedArray = arrayMove(items, oldIndex, newIndex);

      onItemsChange(sortedArray);
    }
  };
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items} strategy={rectSortingStrategy}>
        <div {...wrapperProps}>
          {items.map((item, idx) => children(item, idx))}
        </div>
      </SortableContext>
    </DndContext>
  );
};

type SortableImageProps = {
  item: Image;
  children: ReactNode;
} & JSX.IntrinsicElements['div'];

const SortableImageItem = ({
  item,
  children,
  ...restProps
}: SortableImageProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: item.id });
  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: 'grab',
    zIndex: isDragging ? 1 : undefined,
  };
  return (
    <div
      style={style}
      {...restProps}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    >
      {children}
    </div>
  );
};

const ImageSorter = {
  Provider: ImageSorterProvider,
  Item: SortableImageItem,
};
export default ImageSorter;
