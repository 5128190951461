import { ServiceHistoryConfigTask } from '@gql_codegen/retail-types';
import { useMemo } from 'react';
import { ServiceHistoryTypes } from './useServiceHistoryRecords';
import { useServiceHistoryTasks } from './useServiceHistoryTasks';

type useServiceHistoryTasksByTypeProps = { type?: ServiceHistoryTypes };
export const useServiceHistoryTasksByType = ({
  type,
}: useServiceHistoryTasksByTypeProps): ServiceHistoryConfigTask[] => {
  const serviceHistoryTasks = useServiceHistoryTasks();
  const taskTypesMemo = useMemo(
    () => serviceHistoryTasks.filter((taskType) => taskType.type === type),
    [serviceHistoryTasks, type],
  );

  return taskTypesMemo;
};
