import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import cns from 'classnames';
import { ServiceHistoryDocumentFileType } from '@gql_codegen/retail-types';
import { Document } from '@src/types/serviceHistoryDocument';
import cn from './styles.less';

type ImageGalleryItemProps = {
  item: Document;
  onRemove: () => void;
  noButtons?: boolean;
  isFullScreen?: boolean;
};

export const ImageGalleryItem = ({
  item,
  onRemove,
  isFullScreen = false,
}: ImageGalleryItemProps): JSX.Element => {
  const { url, fileType } = item;
  return (
    <div
      className={cns(cn.imageContainer, {
        [cn.imageContainerFullScreen]: isFullScreen,
      })}
    >
      <div className={cn.imageActions}>
        <Button
          type="text"
          onClick={() => onRemove()}
          data-qa-selector="galleryRemove"
        >
          <DeleteOutlined style={{ color: '#ffffff' }} />
        </Button>
      </div>
      {fileType === ServiceHistoryDocumentFileType.Pdf ? (
        <iframe
          key={isFullScreen ? 'full-screen' : 'non-full-screen'}
          data-qa-selector="galleryPdf"
          className={cn.pdf}
          src={`${url}#toolbar=0`}
          title="document"
        />
      ) : (
        <img
          data-qa-selector="galleryImg"
          className={cns(cn.image)}
          src={url}
          alt="gallery item"
        />
      )}
    </div>
  );
};
