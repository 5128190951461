// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FZbKJVgPz8IA2tXtdO7J {\n  margin-left: 5px;\n}\n.tmyTg2jMnsAzeOQjFdKM {\n  padding-left: 10px;\n}\n.CQrhHh5jpik6qA3XDfvF {\n  font-size: 12px;\n}\n.CQrhHh5jpik6qA3XDfvF td,\n.CQrhHh5jpik6qA3XDfvF th {\n  padding: 5px;\n  border: 1px solid #d9d9d9;\n}\n.CQrhHh5jpik6qA3XDfvF thead {\n  background: #cce1e4;\n}\n", "",{"version":3,"sources":["webpack://./src/sections/General/CarData/trimLevelHint.styles.less"],"names":[],"mappings":"AACA;EACE,gBAAA;AAAF;AAGA;EACE,kBAAA;AADF;AAIA;EACE,eAAA;AAFF;AAKA;;EAEE,YAAA;EAEA,yBAAA;AAJF;AAOA;EACE,mBAAA;AALF","sourcesContent":["@antVersion: antd-5-18-2;\n.icon {\n  margin-left: 5px;\n}\n\n.list {\n  padding-left: 10px;\n}\n\n.table {\n  font-size: 12px;\n}\n\n.table td,\n.table th {\n  padding: 5px;\n\n  border: 1px solid #d9d9d9;\n}\n\n.table thead {\n  background: #cce1e4;\n}\n\n@ant-prefix: antd-5-18-2;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "FZbKJVgPz8IA2tXtdO7J",
	"list": "tmyTg2jMnsAzeOQjFdKM",
	"table": "CQrhHh5jpik6qA3XDfvF"
};
export default ___CSS_LOADER_EXPORT___;
