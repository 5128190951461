import { Typography } from 'antd';
import { Panel } from '@components/Panel';
import { usePriceManagementGroupLabel } from './usePriceManagementGroupLabel';

const { Title } = Typography;

export const PriceManagementGroup = (): JSX.Element => {
  const label = usePriceManagementGroupLabel();

  return label ? (
    <Panel
      title="Price Management Group"
      data-qa-selector="price-management-group-section"
    >
      <Title level={5} data-qa-selector="price-management-group-label">
        {label}
      </Title>
    </Panel>
  ) : (
    <></>
  );
};
