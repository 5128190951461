import {
  ServiceHistoryConfigTask,
  ServiceHistoryRecord,
} from '@gql_codegen/retail-types';
import { Table } from 'antd';
import { getServiceHistoryTableColumns } from './ServiceHistoryTableColumns';
import { HTMLAttributes } from 'react';

type ServiceHistoryTableProps = {
  serviceHistoryRecords: ServiceHistoryRecord[];
  taskTypes: ServiceHistoryConfigTask[];
  onEdit: (index: number) => void;
  onDelete: (index: number) => void;
};

export const ServiceHistoryTableQaSelector = 'section-service-history-table';
export const ServiceHistoryTable = ({
  serviceHistoryRecords,
  taskTypes,
  onEdit,
  onDelete,
}: ServiceHistoryTableProps): JSX.Element => {
  return (
    <Table
      className="sectionPricingChangelogTable"
      size="small"
      data-qa-selector={ServiceHistoryTableQaSelector}
      rowKey="id"
      dataSource={serviceHistoryRecords}
      columns={getServiceHistoryTableColumns(taskTypes, onEdit, onDelete)}
      pagination={false}
      scroll={{ x: true }}
      onRow={(record) => {
        return {
          'data-qa-selector': `serviceHistory-${record.type}-table-row`,
        } as HTMLAttributes<HTMLTableRowElement>;
      }}
    />
  );
};
