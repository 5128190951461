import { useState } from 'react';
import axios from 'axios';
import { createZip, ZipFileInput } from '@utils/archiver';
import FileSaver from 'file-saver';
import { Image } from '@gql_codegen/retail-types';
import { notification } from 'antd';
import { retailKibanaLogger } from '@utils/logger';

const errorMessage = 'Download Image Error';

export const useDownloadingImages = () => {
  const [isDownloadingImages, setIsDownloadingImages] = useState(false);
  const [isDownloadingImagesSuccess, setIsDownloadingImagesSuccess] =
    useState(false);
  const [isDownloadingImagesError, setIsDownloadingImagesError] =
    useState(false);

  const getImageBuffer = (url: string) => {
    return axios.get(`${url}?${Date.now()}`, {
      responseType: 'arraybuffer',
    });
  };
  async function startDownloadingImages(downloadImageConfig: {
    images: Image[];
    type: string;
  }) {
    try {
      const { images, type } = downloadImageConfig || {};
      if (!images) {
        return;
      }
      setIsDownloadingImages(true);
      const downloadableImages = await Promise.all(
        images
          .map(async (image) => {
            const { id, original } = image;
            if (!original) {
              return;
            }
            const { data: buffer } = await getImageBuffer(original);
            return {
              fileName: `${id}.jpg`,
              content: buffer,
            };
          })
          .filter(Boolean),
      );
      const result = await createZip(downloadableImages as ZipFileInput[]);
      const blob = new global.Blob([result], {
        type: 'application/octet-stream',
      });
      FileSaver.saveAs(blob, `${type}-images.zip`);
      setIsDownloadingImagesSuccess(true);
    } catch (err) {
      if (err instanceof Error) {
        notification.error({
          message: errorMessage,
          description: `Error: ${err.message}`,
        });
      }
      if (typeof err === 'string') {
        notification.error({
          message: errorMessage,
          description: `Error: ${err}`,
        });
      }
      setIsDownloadingImagesError(true);
      retailKibanaLogger.warn('Media Image Download Failed', err);
    } finally {
      setIsDownloadingImages(false);
    }
  }

  return {
    isDownloadingImagesSuccess,
    isDownloadingImages,
    isDownloadingImagesError,
    startDownloadingImages,
  };
};
