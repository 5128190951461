import { useMemo } from 'react';

//TODO: use react-router here
export const getAdId = (): string | undefined => {
  const pathname = global.location.pathname;
  const adId = pathname.split('/')[3];

  return adId;
};

const useAdId = () => {
  const adId = useMemo(getAdId, []);
  return adId;
};
export default useAdId;
