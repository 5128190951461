import { Form, Row, Col, Switch } from 'antd';
import { useAppForm } from '@hooks/useAppForm';
import { Subsection } from '@components/Subsection';
import InputControlled from '@components/formControlled/InputControlled';
import { restrictFloatInput } from '@utils/validators';
import { useWatch } from 'react-hook-form';
import CheckboxControlled from '@components/formControlled/CheckboxControlled';
import ServiceHistory from './ServiceHistory';
import { usePageData } from '@hooks/usePageData';
import InputNumberControlled from '@components/formControlled/InputNumberControlled';
import { EyeInvisibleOutlined } from '@ant-design/icons';
import DatePickerControlled from '@components/formControlled/DatePickerControlled';
import { FormType } from '@src/layout';
import { TRACKING_SECTION, TRACKING_SECTION_CATEGORY } from '@src/constants';
import { CarHistoryUrl } from './CarHistoryUrl';

export const VehicleHistoryAndService = (): JSX.Element => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useAppForm();
  const generalErrors = errors?.adMgmt?.general?.data || {};
  const { data } = usePageData();
  const { general } = data?.adMgmt || {};

  const { canSeeLicensePlate, canSeeRetailCountryLicensePlate } =
    general?.restrictions || {};

  const carPreownerCount = useWatch({
    control,
    name: 'adMgmt.general.data.carPreownerCount',
  });

  return (
    <Subsection title="History and Service">
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item
            data-qa-selector="general-mileage"
            label="Mileage"
            required
            validateStatus={
              generalErrors?.mileage?.distance ? 'error' : 'success'
            }
            help={
              <div data-qa-selector="general-mileage-explain-error">
                {generalErrors?.mileage?.distance?.message}
              </div>
            }
          >
            <InputNumberControlled
              data-qa-selector="general-mileage-input"
              controllerProps={{
                name: 'adMgmt.general.data.mileage.distance',
              }}
              maxLength={7}
              controls={false}
              min="0"
              step={1000}
              onKeyDown={restrictFloatInput}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.HISTORY_SERVICE,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Mileage',
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            data-qa-selector="general-pre-owners"
            label="Pre-owners, (Vorbesitzer) will be displayed +1"
            required
            validateStatus={
              generalErrors?.carPreownerCount ? 'error' : 'success'
            }
            help={
              <div data-qa-selector="general-pre-owners-explain-error">
                {generalErrors?.carPreownerCount?.message}
              </div>
            }
          >
            <InputNumberControlled
              addonAfter={
                <Switch
                  checkedChildren="N/A"
                  unCheckedChildren="N/A"
                  checked={carPreownerCount === null}
                  onChange={(checked) => {
                    setValue(
                      'adMgmt.general.data.carPreownerCount',
                      checked ? null : 0,
                    );
                  }}
                  data-qa-selector="general-pre-owners-na-switch"
                />
              }
              data-qa-selector="general-pre-owners-input"
              controllerProps={{
                name: 'adMgmt.general.data.carPreownerCount',
              }}
              controls={false}
              min="0"
              onKeyDown={restrictFloatInput}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.HISTORY_SERVICE,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Pre-owners',
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            data-qa-selector="general-country-of-origin"
            label="Country of origin"
          >
            <InputControlled
              data-qa-selector="general-country-of-origin-input"
              controllerProps={{
                name: 'adMgmt.general.data.countryOfOrigin',
              }}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.HISTORY_SERVICE,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Country of origin',
              }}
              disabled
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6} className="historyAndServiceDates">
          <Form.Item
            data-qa-selector="general-last-service-on-class"
            label="Last Service On"
          >
            <DatePickerControlled
              allowClear
              placeholder="DD/MM/YYYY"
              format={['DD/MM/YYYY', 'DD.MM.YYYY']}
              name="adMgmt.general.data.lastServiceOn"
              disabledDate={(d) => !d || d.isAfter('9999-12-31')}
              data-qa-selector="general-last-service-on-class-input"
              controllerProps={{ name: 'adMgmt.general.data.lastServiceOn' }}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.HISTORY_SERVICE,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Last Service On',
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6} className="historyAndServiceDates">
          <Form.Item
            data-qa-selector="general-technical-inspection-expiry"
            label="Technical Inspection Expiry"
          >
            <DatePickerControlled
              allowClear
              format={['DD/MM/YYYY', 'DD.MM.YYYY']}
              placeholder="DD/MM/YYYY"
              name="adMgmt.general.data.inspectionExpiryDate"
              disabledDate={(d) => !d || d.isAfter('9999-12-31')}
              data-qa-selector="general-technical-inspection-expiry-input"
              controllerProps={{
                name: 'adMgmt.general.data.inspectionExpiryDate',
              }}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.HISTORY_SERVICE,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Technical Inspection Expiry',
              }}
            />
          </Form.Item>
        </Col>
        {canSeeLicensePlate && (
          <Col span={6}>
            <Form.Item
              data-qa-selector="general-licence-plate"
              label="Licence plate"
            >
              <InputControlled
                data-qa-selector="general-licence-plate-input"
                controllerProps={{
                  name: 'adMgmt.general.data.licensePlate',
                }}
                disabled
                addonBefore={
                  canSeeRetailCountryLicensePlate ? (
                    <EyeInvisibleOutlined />
                  ) : (
                    ''
                  )
                }
              />
            </Form.Item>
          </Col>
        )}
        {canSeeRetailCountryLicensePlate && (
          <Col span={6}>
            <Form.Item
              data-qa-selector="general-retail-country-license-plate"
              label="Retail-country license plate"
            >
              <InputControlled
                data-qa-selector="general-retail-country-license-plate-input"
                controllerProps={{
                  name: 'adMgmt.general.data.retailCountryLicensePlate',
                }}
                allowClear
              />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row gutter={24}>
        <Col span={4}>
          <Form.Item data-qa-selector="general-original-engine">
            <CheckboxControlled<FormType>
              data-qa-selector="general-original-engine-checkbox"
              controllerProps={{
                name: 'adMgmt.general.data.originalEngine',
              }}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.HISTORY_SERVICE,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Original engine',
              }}
            >
              Original engine
            </CheckboxControlled>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item data-qa-selector="general-commercial-use">
            <CheckboxControlled<FormType>
              data-qa-selector="general-commercial-use-checkbox"
              controllerProps={{
                name: 'adMgmt.general.data.wasInCommercialUse',
              }}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.HISTORY_SERVICE,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Commercial use',
              }}
            >
              Commercial use
            </CheckboxControlled>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item data-qa-selector="general-is-non-smoker-vehicle">
            <CheckboxControlled<FormType>
              data-qa-selector="general-is-non-smoker-vehicle-checkbox"
              controllerProps={{
                name: 'adMgmt.general.data.isNonSmokerVehicle',
              }}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.HISTORY_SERVICE,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Non smoker vehicle',
              }}
              disabled
            >
              Non smoker vehicle
            </CheckboxControlled>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item data-qa-selector="general-has-full-service-history">
            <CheckboxControlled<FormType>
              data-qa-selector="general-has-full-service-history-checkbox"
              controllerProps={{
                name: 'adMgmt.general.data.hasFullServiceHistory',
              }}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.HISTORY_SERVICE,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Full Service History',
              }}
            >
              Full Service History
            </CheckboxControlled>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <CarHistoryUrl />
        </Col>
      </Row>
      <ServiceHistory />
    </Subsection>
  );
};
