import { GetDataQuery, useGetDataQuery } from '@gql_codegen/retail-types';
import useAdId from './useAdId';

import { UseQueryResult } from '@tanstack/react-query';
import { SUCCESS_RESPONSE } from '@gql_codegen/typings/api/api_success';
import { FAIL_RESPONSE } from '@gql_codegen/typings/api/api_fail';
import { appAnalytics } from '@utils/analytics';

//TODO: think of beter way to infer return types.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePageData = (): UseQueryResult<
  GetDataQuery,
  SUCCESS_RESPONSE<null> | FAIL_RESPONSE
> => {
  const adId = useAdId();

  return useGetDataQuery(
    {
      adId: adId || '',
      priceChangelogPage: 0,
      priceChangelogPageSize: 15,
    },
    {
      staleTime: 5 * 1000,
      onSuccess: ({ adMgmt }) => {
        const preSignedUrlGenerationEnabled = Boolean(
          adMgmt?.general?.restrictions?.preSignedUrlGenerationEnabled,
        );
        const savingActivityIntervalInSeconds = Number(
          adMgmt?.general?.restrictions?.savingActivityIntervalInSeconds,
        );
        appAnalytics.setFeatureFlag(preSignedUrlGenerationEnabled);
        appAnalytics.setSendingInterval(savingActivityIntervalInSeconds * 1000);
      },
    },
  );
};
