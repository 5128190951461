import {
  FeatureDetailItem,
  FeatureDetailTranslation,
} from '@gql_codegen/retail-types';
import { usePageData } from '@hooks/usePageData';
import { Col, Row, Space } from 'antd';
import { useState } from 'react';
import { EquipmentHeader } from './EquipmentHeader';
import { EquipmentTable } from './EquipmentTable';
import { SaveYourChangesNotification } from './SaveYourChangesNotification';
import { FeaturePaths } from './constants';
import styles from './styles.less';
import { EnumLike } from '@src/types/utilTypes';

export interface Equipment {
  group: string;
  subGroup: string;
  translations: Array<FeatureDetailTranslation>;
}

export const FeatureDetailsItemPathsMap = new WeakMap<
  FeatureDetailItem,
  {
    itemPath: FeaturePaths.FeatureDetailsItemPath;
    categoryItemsPath: FeaturePaths.FeatureDetailsCategoryItemsPath;
    categoryPath: FeaturePaths.FeatureDetailsCategoryPath;
  }
>();

export type ShowEquipmentState = 'all' | 'current' | 'unselected';
export const SHOW_EQUIPMENT_STATE: EnumLike<ShowEquipmentState> = {
  all: 'all',
  current: 'current',
  unselected: 'unselected',
};

export const Equipment = (): JSX.Element => {
  const { data, isLoading } = usePageData();
  const [showEquipmentState, setShowEquipmentState] =
    useState<ShowEquipmentState>(SHOW_EQUIPMENT_STATE.all);

  if (isLoading || !data) return <></>;

  return (
    <>
      <SaveYourChangesNotification />
      <Row gutter={16} data-qa-selector="new-equipment-section">
        <Col flex="auto">
          <Space direction="vertical" className={styles.fullWidth}>
            <EquipmentHeader
              showEquipmentState={showEquipmentState}
              setShowEquipmentState={setShowEquipmentState}
            />
            <EquipmentTable showEquipmentState={showEquipmentState} />
          </Space>
        </Col>
      </Row>
    </>
  );
};
