import { ANTD_BUNDLED_VERSION } from '@src/constants';
import { ConfigProvider, Layout, notification } from 'antd';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import cns from 'classnames';
import cn from './styles.less';
import { GetTraceIds } from '@components/GetTraceIds';
import { RouterProvider } from '@tanstack/react-router';
import { router } from '@src/router';
import { appAnalytics } from '@utils/analytics';
import { CloseOutlined } from '@ant-design/icons';

notification.config({
  top: 55,
  prefixCls: `${ANTD_BUNDLED_VERSION}-notification`,
  closeIcon: <CloseOutlined data-qa-selector="notification-close" />,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      networkMode: 'offlineFirst',
    },
    mutations: {
      networkMode: 'offlineFirst',
    },
  },
});

appAnalytics.initialize();

//TODO: Hide devtools on production
export const Root = (): JSX.Element => {
  return (
    <ConfigProvider
      theme={{
        token: {
          borderRadius: 2,
          fontFamily: "'Source Sans Pro', Calibri, Candara, Arial, sans-serif",
          colorTextDisabled: 'black',
        },
      }}
      prefixCls={ANTD_BUNDLED_VERSION}
    >
      <QueryClientProvider client={queryClient}>
        <Layout
          className={cns(cn.root, 'admgmt-root')}
          data-qa-selector="layout"
        >
          <RouterProvider router={router} />
          <GetTraceIds />
        </Layout>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ConfigProvider>
  );
};
