import { Panel } from '@components/Panel';
import { usePageData } from '@hooks/usePageData';
import { Tabs } from 'antd';
import { AutoheroWKDA } from './AutoheroWKDA';
import { ClassifiedsTab } from './ClassifiedsTab';
import { SyncAS24Title } from './syncAS24Title';
import { SyncMobileTitle } from './syncMobileTitle';

export const Classifieds = (): JSX.Element => {
  const { data, isLoading } = usePageData();

  if (isLoading || !data) return <></>;

  const { autoheroAndWkda, autoscout24, mobiledeV2 } = data.adMgmt;

  return (
    <Panel title="Classifieds" data-qa-selector="section-classifieds">
      {autoscout24 && <SyncAS24Title />}
      {mobiledeV2 && <SyncMobileTitle />}
      <Tabs defaultActiveKey="1">
        {autoheroAndWkda ? (
          <Tabs.TabPane tab="Autohero and WKDA" key="autohero-and-wkda">
            <AutoheroWKDA />
          </Tabs.TabPane>
        ) : null}
        {(mobiledeV2 || autoscout24) && (
          <Tabs.TabPane
            tab="Classifieds"
            key="classifieds"
            data-qa-selector="classifieds-tab"
          >
            <ClassifiedsTab />
          </Tabs.TabPane>
        )}
      </Tabs>
    </Panel>
  );
};
