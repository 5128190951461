import { useGetPreviewUrlQuery } from '@gql_codegen/retail-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Document } from '@src/types/serviceHistoryDocument';
import { retailKibanaLogger } from '@utils/logger';

const POLLING_ATTEMPTS = [1000, 1000, 2000, 4000];

export const useImagePolling = (document: Document) => {
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const attemptRef = useRef(0);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const stopPolling = () => {
    if (timerRef.current) {
      global.clearTimeout(timerRef.current);
    }
    attemptRef.current = 0;
    timerRef.current = null;
    setLoading(false);
  };

  const handleError = useCallback(() => {
    if (!imageRef.current) {
      return;
    }

    setLoading(true);
    attemptRef.current += 1;

    if (attemptRef.current > POLLING_ATTEMPTS.length) {
      setIsError(true);

      return stopPolling();
    }

    timerRef.current = setTimeout(
      () => {
        // eslint-disable-next-line no-use-before-define
        setUrl();
      },
      POLLING_ATTEMPTS[attemptRef.current - 1],
    );
  }, []);

  const setUrl = useCallback(async () => {
    if (!imageRef.current) {
      return;
    }
    if (document?.thumbnailUrl) {
      imageRef.current.src = document.thumbnailUrl;

      return;
    }

    try {
      const {
        previewUrl: { url },
      } = await useGetPreviewUrlQuery.fetcher({
        uploadId: document.id,
      })();

      if (url) {
        imageRef.current.src = url;
      } else {
        await handleError();
      }
    } catch (err) {
      console.error(err);
      retailKibanaLogger.warn('Image Polling Failed', err);

      await handleError();
    }
  }, [document, handleError]);

  useEffect(() => {
    setUrl();

    return () => {
      if (timerRef.current) {
        global.clearTimeout(timerRef.current);
      }
    };
  }, []);

  return {
    loading,
    isError,
    handleError,
    handleLoad: stopPolling,
    imageRef,
  };
};
