// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vfJP7eVquMCMrdUQ98TN {\n  overflow: auto;\n}\n.u66cYxQT51oheK013ra3 {\n  float: left;\n  width: 20%;\n  padding: 8px;\n}\n.k0360DoozpswLjfPVJsd {\n  display: block !important;\n  width: 100% !important;\n  height: 120px !important;\n}\n", "",{"version":3,"sources":["webpack://./src/sections/General/VehicleHistoryAndService/ServiceHistory/ImageGallery/ImageGallerySortModal/ImageSorter/styles.less"],"names":[],"mappings":"AACA;EACE,cAAA;AAAF;AAGA;EACE,WAAA;EAEA,UAAA;EACA,YAAA;AAFF;AAKA;EACE,yBAAA;EAEA,sBAAA;EACA,wBAAA;AAJF","sourcesContent":["@antVersion: antd-5-18-2;\n.sortableItemsWrapper {\n  overflow: auto;\n}\n\n.sortableItem {\n  float: left;\n\n  width: 20%;\n  padding: 8px;\n}\n\n.sortableItemImage {\n  display: block !important;\n\n  width: 100% !important;\n  height: 120px !important;\n}\n\n@ant-prefix: antd-5-18-2;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sortableItemsWrapper": "vfJP7eVquMCMrdUQ98TN",
	"sortableItem": "u66cYxQT51oheK013ra3",
	"sortableItemImage": "k0360DoozpswLjfPVJsd"
};
export default ___CSS_LOADER_EXPORT___;
