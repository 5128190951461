import { useEffect, useCallback, useState } from 'react';
import {
  Row,
  Col,
  Form,
  Modal,
  Checkbox,
  InputNumber,
  Button,
  Select,
  DatePicker,
} from 'antd';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { zodResolver } from '@hookform/resolvers/zod';
import { CloseOutlined } from '@ant-design/icons';
import { useScrollBack } from '@hooks/useScrollBack';
import SelectControlled from '@components/formControlled/SelectControlled';
import { ServiceHistoryRecordInput } from '@gql_codegen/retail-types';
import { Document } from '@src/types/serviceHistoryDocument';
import { ImageGalleryField } from '../../ImageGallery';
import { schema } from './validationSchema';
import { DOCUMENT_TYPES_OPTIONS } from '../../constants';
import { useServiceHistoryTasksByType } from '../../useServiceHistoryTasksByType';
import styles from './styles.less';
import InputControlled from '@components/formControlled/InputControlled';
import { CUSTOM_DATE_FORMATS } from '@utils/date';
import { SERVICE_HISTORY_TYPES } from '../../useServiceHistoryRecords';

export type FormShape = ServiceHistoryRecordInput;

interface ServiceHistoryFormProps {
  visible: boolean;
  initialData?: FormShape | undefined;
  onSave?: (data: FormShape) => void;
  onCancel?: () => void;
}

const ServiceHistoryForm = ({
  visible,
  initialData,
  onSave,
  onCancel,
}: ServiceHistoryFormProps): JSX.Element => {
  const [disabled, setDisabled] = useState(false);
  const { savePosition, backToPosition } = useScrollBack();
  const tasksTypes = useServiceHistoryTasksByType({
    type: SERVICE_HISTORY_TYPES.Service,
  });
  const { control, handleSubmit, reset, formState } = useForm<FormShape>({
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    reset({
      tasksPerformed: [],
      servicePerformedBy: null,
      documentType: null,
      editable: true,
      ...initialData,
      documents: initialData?.documents ?? [],
    });
    savePosition();
  }, [initialData, reset, visible, savePosition]);

  const clearForm = useCallback(
    () =>
      reset({
        tasksPerformed: [],
        servicePerformedBy: null,
        documentType: null,
        documents: [],
      }),
    [reset],
  );

  const onSubmit = useCallback(
    (values: FormShape) => {
      onSave?.({
        ...initialData,
        ...values,
        type: SERVICE_HISTORY_TYPES.Service,
      });
      clearForm();
      backToPosition();
    },
    [onSave, initialData, backToPosition, clearForm],
  );

  const handleCancel = useCallback(() => {
    if (!disabled) {
      clearForm();
      onCancel?.();
      backToPosition();
    }
  }, [clearForm, onCancel, backToPosition, disabled]);

  return (
    <Modal
      open={visible}
      title="Service History"
      maskClosable={false}
      closable={!disabled}
      onOk={handleSubmit(onSubmit)}
      onCancel={handleCancel}
      width={860}
      closeIcon={
        <CloseOutlined data-qa-selector="close-service-history-modal-button" />
      }
      footer={[
        <Button
          type="primary"
          data-qa-selector="save-service-history-record-button"
          key="submit"
          onClick={handleSubmit(onSubmit)}
          disabled={disabled}
        >
          {initialData ? 'Apply changes' : 'Add'}
        </Button>,
      ]}
    >
      <div data-qa-selector="service-history-modal">
        <Form>
          <Row gutter={16}>
            <Col span={14}>
              <Row gutter={16}>
                <Col span={12}>
                  <Controller
                    name="lastServiceOn"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Form.Item
                        data-qa-selector="last-service-on"
                        label="Service Date"
                        colon={false}
                        required={true}
                        labelCol={{
                          span: 24,
                        }}
                        validateStatus={fieldState.invalid ? 'error' : ''}
                        help={
                          <div data-qa-selector="last-service-on-explain-error">
                            {!!fieldState.error?.message && (
                              <span data-qa-selector="mandatory-field-alert">
                                {fieldState.error.message}
                              </span>
                            )}
                          </div>
                        }
                      >
                        <DatePicker
                          data-qa-selector="last-service-on-input"
                          allowClear={true}
                          value={field.value ? dayjs(field.value) : null}
                          disabled={disabled}
                          placeholder="DD/MM/YYYY"
                          format={['DD/MM/YYYY', 'DD.MM.YYYY']}
                          className={styles.fullWidth}
                          onChange={(value) => {
                            field.onChange(
                              value?.format(CUSTOM_DATE_FORMATS.BACKEND_FORMAT),
                            );
                          }}
                          disabledDate={(d) => !d || d.isAfter(dayjs(), 'day')}
                        />
                      </Form.Item>
                    )}
                  />
                </Col>
                <Col span={12}>
                  <Controller
                    name="lastServiceMileage"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Form.Item
                        data-qa-selector="last-service-mileage"
                        label="Service Mileage"
                        required={true}
                        labelCol={{
                          span: 24,
                        }}
                        validateStatus={fieldState.invalid ? 'error' : ''}
                        help={
                          <div data-qa-selector="last-service-mileage-explain-error">
                            {!!fieldState.error?.message && (
                              <span data-qa-selector="mandatory-field-alert">
                                {fieldState.error.message}
                              </span>
                            )}
                          </div>
                        }
                      >
                        <InputNumber
                          {...field}
                          disabled={disabled}
                          className={styles.fullWidth}
                          data-qa-selector="last-service-mileage-input"
                          min={0}
                          step={1000}
                        />
                      </Form.Item>
                    )}
                  />
                </Col>
                <Col sm={24}>
                  <Form.Item
                    label="Tasks performed"
                    colon={false}
                    labelCol={{
                      span: 24,
                    }}
                  >
                    <SelectControlled
                      data-qa-selector="tasks-performed-input"
                      controllerProps={{
                        name: 'tasksPerformed',
                        control,
                        defaultValue: [],
                      }}
                      disabled={disabled}
                      mode="multiple"
                      removeIcon={
                        <CloseOutlined data-qa-selector="serviceHistory-remove" />
                      }
                    >
                      {tasksTypes.map(({ value, label }) => (
                        <Select.Option
                          value={value}
                          key={value}
                          data-qa-selector={value}
                        >
                          {label}
                        </Select.Option>
                      ))}
                    </SelectControlled>
                  </Form.Item>
                </Col>
                <Col sm={24}>
                  <Form.Item
                    label="Service last done by (company)"
                    colon={false}
                    required={false}
                    labelCol={{
                      span: 24,
                    }}
                    validateStatus={
                      formState.errors?.servicePerformedBy ? 'error' : ''
                    }
                    help={
                      <div data-qa-selector="service-performed-by-explain-error">
                        {formState.errors?.servicePerformedBy?.message}
                      </div>
                    }
                  >
                    <InputControlled
                      data-qa-selector="service-performed-by-input"
                      maxLength={255}
                      controllerProps={{
                        name: 'servicePerformedBy',
                        control,
                      }}
                      disabled={disabled}
                    />
                  </Form.Item>
                </Col>
                <Col sm={24}>
                  <Form.Item
                    data-qa-selector="service-history-form-documentType"
                    label="Document Type"
                    colon={false}
                    labelCol={{
                      span: 24,
                    }}
                  >
                    <Controller
                      name="documentType"
                      control={control}
                      defaultValue={null}
                      data-qa-selector="document-type-input"
                      render={({ field }) => (
                        <Checkbox.Group {...field} value={field.value ?? []}>
                          <Row>
                            {DOCUMENT_TYPES_OPTIONS.map(({ value, label }) => (
                              <Col span={24} key={value}>
                                <Checkbox
                                  value={value}
                                  disabled={disabled}
                                  data-qa-selector={`service-document-type-checkbox-${value}`}
                                >
                                  {label}
                                </Checkbox>
                              </Col>
                            ))}
                          </Row>
                        </Checkbox.Group>
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={10}>
              <Controller
                name="documents"
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                  <ImageGalleryField
                    title="Service History Documents"
                    documents={field.value as Document[]}
                    onChange={field.onChange}
                    onLoading={(isLoading) => setDisabled(isLoading)}
                  />
                )}
              />
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default ServiceHistoryForm;
