import { RefObject, useCallback, useRef, useState } from 'react';

export const POLLING_ATTEMPTS = [1000, 1000, 2000, 4000, 10000, 10000];

interface Props {
  src: string;
  imageRef: RefObject<HTMLImageElement>;
  pollingAttempts?: number[];
}

export const useImageFallback = ({
  src,
  imageRef,
  pollingAttempts = POLLING_ATTEMPTS,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const attemptRef = useRef(0);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const clearTimer = useCallback(() => {
    if (timerRef.current) {
      global.clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  }, []);

  const handleError = useCallback(() => {
    clearTimer();
    if (
      attemptRef.current < pollingAttempts.length &&
      timerRef.current === null
    ) {
      setLoading(true);
      timerRef.current = global.setTimeout(() => {
        if (imageRef.current) {
          imageRef.current.src = src;
        }
      }, POLLING_ATTEMPTS[attemptRef.current]);
    } else {
      setLoading(false);
      clearTimer();
    }
    attemptRef.current++;
  }, [src, clearTimer, pollingAttempts, imageRef]);

  const handleLoad = useCallback(() => {
    clearTimer();
    setLoading(false);
  }, [clearTimer]);

  return {
    handleError,
    handleLoad,
    loading,
  };
};
