import {
  AUTOHERO_IMAGE_TYPES,
  TRACKING_SECTION,
  TRACKING_SECTION_CATEGORY,
} from '@src/constants';
import { Col, Row } from 'antd';
import { ImageGalleryField } from '@components/ImageGalleryField';
import { VideoField } from './VideoField';

export const Media = (): JSX.Element => {
  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <div data-qa-selector="videoField">
          <VideoField
            name="adMgmt.autoheroAndWkda.media.data.video"
            tracking={{
              fieldId: 'Video',
              section: TRACKING_SECTION.MEDIA,
              sectionCategory: TRACKING_SECTION_CATEGORY.AH,
            }}
          />
        </div>
      </Col>
      <Col span={24}>
        <Row gutter={24}>
          <Col span={12}>
            <ImageGalleryField
              title="Highlights"
              name={`adMgmt.autoheroAndWkda.media.data.images.nextgen_highlight`}
              type={AUTOHERO_IMAGE_TYPES.HIGHLIGHTS}
              qaSelector="highlightsImages"
              tracking={{
                fieldId: 'Highlights',
                section: TRACKING_SECTION.MEDIA,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
              }}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={24}>
          <Col span={12}>
            <ImageGalleryField
              title="Interior"
              name={`adMgmt.autoheroAndWkda.media.data.images.interior`}
              type={AUTOHERO_IMAGE_TYPES.INTERIOR}
              qaSelector="interiorImages"
              tracking={{
                fieldId: 'Interior',
                section: TRACKING_SECTION.MEDIA,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
              }}
            />
          </Col>
          <Col span={12}>
            <ImageGalleryField
              title="Exterior"
              name="adMgmt.autoheroAndWkda.media.data.images.exterior"
              type={AUTOHERO_IMAGE_TYPES.EXTERIOR}
              qaSelector="exteriorImages"
              tracking={{
                fieldId: 'Exterior',
                section: TRACKING_SECTION.MEDIA,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
              }}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <ImageGalleryField
          title="Panorama"
          name="adMgmt.autoheroAndWkda.media.data.images.panorama"
          type={AUTOHERO_IMAGE_TYPES.PANORAMA}
          galleryProps={{ showThumbnails: false }}
          qaSelector="panoramaImages"
          tracking={{
            fieldId: 'Panorama',
            section: TRACKING_SECTION.MEDIA,
            sectionCategory: TRACKING_SECTION_CATEGORY.AH,
          }}
        />
      </Col>
    </Row>
  );
};
