import './dayjsCompatabilityWithAntd';
import 'react-image-gallery/styles/css/image-gallery.css';

import { appAnalytics } from '@utils/analytics';
import { retailKibanaLogger } from '@utils/logger';
import React from 'react';
import ReactDOM from 'react-dom';
import { AppProps } from 'single-spa';
import singleSpaReact from 'single-spa-react';
import { Root } from './components/Root';

require('./styles/global.less');

type Props = AppProps;

const lifecycles = singleSpaReact<Props>({
  React,
  ReactDOM,
  rootComponent: Root,
  renderType: 'render',
  errorBoundary(err) {
    retailKibanaLogger.error('Root Error', err);
    // Customize the root error boundary for your microfrontend here.
    return (
      <div>
        <h2>Something went wrong.</h2>
        <pre>{JSON.stringify(err, null, 2)}</pre>
      </div>
    );
  },
});
const { bootstrap, mount } = lifecycles;

const unmount = async (props: Props) => {
  await appAnalytics.terminate();
  lifecycles.unmount(props);
};

export { bootstrap, mount, unmount };
