// THIS FILE IS GENERATED, DO NOT EDIT!
/* istanbul ignore next */
/* eslint-disable */
/* @ts-nocheck */
import type { SUCCESS_RESPONSE } from '@gql_codegen/typings/api/api_success';
import { FAIL_RESPONSE } from '@gql_codegen/typings/api/api_fail';

import {
  useMutation,
  useQuery,
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import { api } from './api';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  UUID: string;
  Long: number;
  LocalDate: string;
  DateTime: string;
};

export type AdMgmtInput = {
  general: GeneralSectionInput;
  autoheroAndWkda: AutoheroAndWkdaInput;
  mobiledeV2?: InputMaybe<MobiledeV2Input>;
  autoscout24?: InputMaybe<Autoscout24Input>;
  exportOverview: ExportOverviewInput;
  externalUrls?: InputMaybe<ExternalUrlsDataInput>;
  version: Scalars['Int'];
};

export type AutoheroAndWkdaInput = {
  media: MediaInput;
  imperfections?: InputMaybe<ImperfectionsInput>;
  secondaryWheels?: InputMaybe<SecondaryWheelsInput>;
  featureDetails?: InputMaybe<FeatureDetailsInput>;
  specsSpotlight?: InputMaybe<SpecSpotlightInput>;
};

export type Autoscout24Input = {
  data?: InputMaybe<Autoscout24InputData>;
};

export type MobiledeV2Input = {
  data?: InputMaybe<ExternalExportInputData>;
};

export type Autoscout24InputData = {
  title?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  images: Array<ImageInput>;
};

export type ExternalExportInputData = {
  title?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  images: Array<ImageInput>;
};

export type AdMgmt = {
  __typename?: 'AdMgmt';
  id: Scalars['UUID'];
  title: Scalars['String'];
  country: Scalars['String'];
  currencyCode: Scalars['String'];
  exportOverview: ExportOverview;
  general: GeneralSection;
  priceManagementGroup?: Maybe<PriceManagementGroup>;
  autoheroAndWkda: AutoheroAndWkda;
  mobiledeV2?: Maybe<MobiledeV2>;
  autoscout24?: Maybe<Autoscout24>;
  externalUrls?: Maybe<ExternalUrls>;
  dictionaries: Dictionaries;
  version: Scalars['Int'];
};

export type AutoheroAndWkda = {
  __typename?: 'AutoheroAndWkda';
  media: Media;
  imperfections?: Maybe<Imperfections>;
  secondaryWheels?: Maybe<SecondaryWheels>;
  featureDetails: FeatureDetails;
  enableFeatureDetailUi: Scalars['Boolean'];
  specsSpotlight?: Maybe<SpecSpotlight>;
};

export type Autoscout24 = {
  __typename?: 'Autoscout24';
  data?: Maybe<Autoscout24Data>;
};

export type MobiledeV2 = {
  __typename?: 'MobiledeV2';
  data?: Maybe<MobiledeV2Data>;
};

export type Autoscout24Data = {
  __typename?: 'Autoscout24Data';
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  images: Array<Image>;
  imagesLimit: Scalars['Int'];
};

export type MobiledeV2Data = {
  __typename?: 'MobiledeV2Data';
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  images: Array<Image>;
  imagesLimit: Scalars['Int'];
};

export type ExportDescription = {
  __typename?: 'ExportDescription';
  marketplace: Marketplace;
  description: Scalars['String'];
};

export type AdPreparation = {
  __typename?: 'AdPreparation';
  preparationState: Scalars['String'];
  preparationStateOptions: Array<OptionStringItem>;
  explainerLinks: Array<ExplainerLink>;
  tuningComment?: Maybe<Scalars['String']>;
  isFinanced?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
};

export type ExplainerLink = {
  __typename?: 'ExplainerLink';
  label: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type CarAccidentDamageInput = {
  id: Scalars['ID'];
  carPart: Scalars['String'];
  damageType: Scalars['String'];
  attributes?: InputMaybe<Scalars['String']>;
  showToCustomer: Scalars['Boolean'];
  isRepaired?: InputMaybe<Scalars['Boolean']>;
};

export type CarAccidentInput = {
  accidentDamages: Array<InputMaybe<CarAccidentDamageInput>>;
  accidentArea: Array<InputMaybe<Scalars['String']>>;
};

export type CarAccident = {
  __typename?: 'CarAccident';
  accidentDamages: Array<Maybe<CarAccidentDamage>>;
  accidentArea: Array<Maybe<Scalars['String']>>;
};

export type CarAccidentDamage = {
  __typename?: 'CarAccidentDamage';
  id: Scalars['ID'];
  carPart: Scalars['String'];
  damageType: Scalars['String'];
  attributes?: Maybe<Scalars['String']>;
  showToCustomer: Scalars['Boolean'];
  isRepaired?: Maybe<Scalars['Boolean']>;
};

export type Dictionaries = {
  __typename?: 'Dictionaries';
  global: GlobalDictionaries;
  autoscout24?: Maybe<Autoscout24Dictionaries>;
  mobiledeV2?: Maybe<MobiledeV2Dictionaries>;
};

export type GlobalDictionaries = {
  __typename?: 'GlobalDictionaries';
  featureDetailOptions: Array<FeatureDetailGroupCategoryList>;
  carTypeOptions: Array<OptionStringItem>;
  bodyTypeOptions: Array<OptionNumberItem>;
  fuelTypeOptions: Array<OptionStringItem>;
  gearTypeOptions: Array<OptionNumberItem>;
  customFeatureTranslationConfigs: Array<CustomFeatureTranslationConfig>;
  publishingBlockersReasons: Array<PublishingBlockerReason>;
  secondaryWheelsDamageOptions: Array<OptionStringItem>;
  secondaryWheelsMaterial: Array<OptionStringItem>;
  secondaryWheelsRadius: Array<OptionNumberNullableItem>;
  secondaryWheelsRimsDetails: Array<OptionStringItem>;
  secondaryWheelsSeasonType: Array<OptionStringItem>;
  secondaryWheelsTiresDetails: Array<OptionStringItem>;
  secondaryWheelsTreadDepth: Array<OptionNumberNullableItem>;
  taxRatingOptions: Array<OptionNumberItem>;
  primaryWheelSeasonOptions: Array<OptionStringItem>;
  vatTypeOptions: Array<OptionNumberItem>;
  serviceHistoryTaskTypes: Array<ServiceHistoryTaskType>;
  emissionStickers: Array<OptionStringItem>;
  efficiencyClasses: Array<OptionStringItem>;
  emissionClasses: Array<OptionStringItem>;
  modelOptions: Array<OptionStringItem>;
  subtypeOptions: Array<ModelSubtypeItem>;
  upholsteryOptions: Array<OptionNumberItem>;
  colorOptions: Array<OptionNumberItem>;
  supportedLanguageLocales: Array<Scalars['String']>;
  upholsteryColor: Array<OptionStringItem>;
  driveTrain: Array<OptionStringItem>;
  fieldLabels: Array<FieldLabel>;
  trimLevels: Array<OptionStringItem>;
  grossPriceChangeReasons: Array<OptionStringItem>;
  serviceHistoryConfigTasks: Array<ServiceHistoryConfigTask>;
};

export type CustomFeatureTranslationConfig = {
  __typename?: 'CustomFeatureTranslationConfig';
  category: CustomTranslationCategory;
  subcategory?: Maybe<CustomTranslationCategory>;
  featureGroup: Scalars['String'];
  featureSubGroup?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  defaultVisible: Scalars['Boolean'];
  classifieds: Array<Scalars['String']>;
  translations: Array<Translation>;
};

export type ModelSubtypeItem = {
  __typename?: 'ModelSubtypeItem';
  model: Scalars['String'];
  subtypes: Array<OptionStringItem>;
};

export type Translation = {
  __typename?: 'Translation';
  locale: Scalars['String'];
  text: Scalars['String'];
};

export type CustomTranslationCategory = {
  __typename?: 'CustomTranslationCategory';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ServiceHistoryTaskType = {
  __typename?: 'ServiceHistoryTaskType';
  value: Scalars['String'];
  label: Scalars['String'];
};

export type ServiceHistoryConfigTask = {
  __typename?: 'ServiceHistoryConfigTask';
  value: Scalars['String'];
  label: Scalars['String'];
  type: Scalars['String'];
};

export type Autoscout24Dictionaries = {
  __typename?: 'Autoscout24Dictionaries';
  marketplaceAccountOptions: Array<MarketplaceAccountOptions>;
};

export type MobiledeV2Dictionaries = {
  __typename?: 'MobiledeV2Dictionaries';
  marketplaceAccountOptions: Array<MarketplaceAccountOptions>;
};

export type OptionNumberItem = {
  __typename?: 'OptionNumberItem';
  label: Scalars['String'];
  value: Scalars['Int'];
};

export type OptionNumberNullableItem = {
  __typename?: 'OptionNumberNullableItem';
  label: Scalars['String'];
  value?: Maybe<Scalars['Int']>;
};

export type OptionStringItem = {
  __typename?: 'OptionStringItem';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type FeatureDetailGroupCategoryList = {
  __typename?: 'FeatureDetailGroupCategoryList';
  locale: Scalars['String'];
  featureDetailGroupCategory: Array<FeatureDetailGroupCategory>;
};

export type FeatureDetailGroupCategory = {
  __typename?: 'FeatureDetailGroupCategory';
  id: Scalars['String'];
  name: Scalars['String'];
  featureDetailGroupOptions: Array<FeatureDetailGroupOption>;
};

export type FeatureDetailGroupOption = {
  __typename?: 'FeatureDetailGroupOption';
  label: Scalars['String'];
  id: Scalars['String'];
  subGroupOptions: Array<OptionStringItem>;
};

export type MarketplaceAccountOptions = {
  __typename?: 'MarketplaceAccountOptions';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type PublishingBlockerReason = {
  __typename?: 'PublishingBlockerReason';
  label: Scalars['String'];
  value: Scalars['String'];
  subReasons: Array<PublishingBlockerSubreason>;
};

export type PublishingBlockerSubreason = {
  __typename?: 'PublishingBlockerSubreason';
  value: Scalars['String'];
  label: Scalars['String'];
  publishingContexts: Array<Scalars['String']>;
};

export type FieldLabel = {
  __typename?: 'FieldLabel';
  name: Scalars['String'];
  label: Scalars['String'];
};

export type PreviewUrl = {
  __typename?: 'PreviewUrl';
  url?: Maybe<Scalars['String']>;
};

export const MileageUnit = {
  Km: 'KM',
} as const;

export type MileageUnit = (typeof MileageUnit)[keyof typeof MileageUnit];
export const ExportListingState = {
  Published: 'PUBLISHED',
  Unpublished: 'UNPUBLISHED',
} as const;

export type ExportListingState =
  (typeof ExportListingState)[keyof typeof ExportListingState];
export const ExportListingSummaryState = {
  Published: 'PUBLISHED',
  Unpublished: 'UNPUBLISHED',
  PartiallyPublished: 'PARTIALLY_PUBLISHED',
  Other: 'OTHER',
} as const;

export type ExportListingSummaryState =
  (typeof ExportListingSummaryState)[keyof typeof ExportListingSummaryState];
export const PublishingStatus = {
  Published: 'PUBLISHED',
  Unpublished: 'UNPUBLISHED',
  InProgress: 'IN_PROGRESS',
} as const;

export type PublishingStatus =
  (typeof PublishingStatus)[keyof typeof PublishingStatus];
export const Damage_Condition = {
  NoDamage: 'NO_DAMAGE',
  MinorDamage: 'MINOR_DAMAGE',
  Damage: 'DAMAGE',
  FullDamage: 'FULL_DAMAGE',
} as const;

export type Damage_Condition =
  (typeof Damage_Condition)[keyof typeof Damage_Condition];
export const Rim_Material = {
  NoRims: 'NO_RIMS',
  AlloyOem: 'ALLOY_OEM',
  Steel: 'STEEL',
  SteelCover: 'STEEL_COVER',
  Alloy: 'ALLOY',
} as const;

export type Rim_Material = (typeof Rim_Material)[keyof typeof Rim_Material];
export const Season = {
  Summer: 'SUMMER',
  Winter: 'WINTER',
  AllSeason: 'ALL_SEASON',
  Emergency: 'EMERGENCY',
} as const;

export type Season = (typeof Season)[keyof typeof Season];
export const UrlType = {
  CarPassBelgium: 'CAR_PASS_BELGIUM',
  CarHistoryFrance: 'CAR_HISTORY_FRANCE',
  CarMedioambientalStickerSpain: 'CAR_MEDIOAMBIENTAL_STICKER_SPAIN',
} as const;

export type UrlType = (typeof UrlType)[keyof typeof UrlType];
export const ServiceHistoryDocumentType = {
  HardCopy: 'HARD_COPY',
  Digital: 'DIGITAL',
  AutoHero: 'AUTO_HERO',
} as const;

export type ServiceHistoryDocumentType =
  (typeof ServiceHistoryDocumentType)[keyof typeof ServiceHistoryDocumentType];
export const ServiceHistoryDocumentFileType = {
  Image: 'IMAGE',
  Pdf: 'PDF',
} as const;

export type ServiceHistoryDocumentFileType =
  (typeof ServiceHistoryDocumentFileType)[keyof typeof ServiceHistoryDocumentFileType];
export type ExportOverviewInput = {
  data: ExportDataInput;
};

export type ExportDataInput = {
  publishingBlockers: Array<PublishingBlockerInput>;
  exportItems?: InputMaybe<Array<ExportItemInput>>;
};

export type ExportItemInput = {
  marketplace: MarketplaceInput;
  comingSoon?: InputMaybe<Scalars['Boolean']>;
};

export type MarketplaceInput = {
  id: Scalars['String'];
};

export type PublishingBlockerInput = {
  reasonId: Scalars['String'];
  subReasonId: Scalars['String'];
  comment: Scalars['String'];
};

export type ExportOverview = {
  __typename?: 'ExportOverview';
  data: ExportData;
  exportItems: Array<ExportItem>;
  restrictions: ExportRestrictions;
  publishingStatuses: PublishingStatuses;
};

export type ExportListings = {
  __typename?: 'ExportListings';
  summary?: Maybe<ExportSummary>;
  listings: Array<ExportListing>;
};

export type ExportSummary = {
  __typename?: 'ExportSummary';
  daysOnline?: Maybe<Scalars['Int']>;
  firstPublishedAt?: Maybe<Scalars['String']>;
  lastPublishedAt?: Maybe<Scalars['String']>;
  lastPublishingErrors: Array<PublishingError>;
  state: ExportListingSummaryState;
};

export type ExportAccount = {
  __typename?: 'ExportAccount';
  id: Scalars['UUID'];
  title: Scalars['String'];
};

export type ExportListing = {
  __typename?: 'ExportListing';
  daysOnline?: Maybe<Scalars['Int']>;
  firstPublishedAt?: Maybe<Scalars['String']>;
  lastPublishedAt?: Maybe<Scalars['String']>;
  lastPublishingErrors: Array<PublishingError>;
  publishingStatus: ExportListingState;
  account: ExportAccount;
  link?: Maybe<ExternalLink>;
};

export type ExportData = {
  __typename?: 'ExportData';
  exportItems: Array<ExportItem>;
  publishingBlockers: Array<PublishingBlocker>;
};

export type ExportItem = {
  __typename?: 'ExportItem';
  marketplace: Marketplace;
  daysOnline?: Maybe<Scalars['Int']>;
  firstPublishedAt?: Maybe<Scalars['DateTime']>;
  firstEligibleToBePurchasedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastPublishingErrors: Array<PublishingError>;
  links: Array<ExternalLink>;
  publishingStatus?: Maybe<PublishingStatus>;
  exportListings?: Maybe<ExportListings>;
  canPublish: Scalars['Boolean'];
  comingSoon?: Maybe<Scalars['Boolean']>;
};

export type Marketplace = {
  __typename?: 'Marketplace';
  id: Scalars['String'];
  title: Scalars['String'];
};

export type PublishingError = {
  __typename?: 'PublishingError';
  createdAt: Scalars['DateTime'];
  message?: Maybe<Scalars['String']>;
};

export type PublishingBlocker = {
  __typename?: 'PublishingBlocker';
  reasonId: Scalars['UUID'];
  subReasonId: Scalars['UUID'];
  comment: Scalars['String'];
  publishingContexts?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PublishingStatuses = {
  __typename?: 'PublishingStatuses';
  autohero: PublishingStatus;
};

export type ExternalLink = {
  __typename?: 'ExternalLink';
  title: Scalars['String'];
  url: Scalars['String'];
};

export type ExportRestrictions = {
  __typename?: 'ExportRestrictions';
  canAddPublishingBlocker: Scalars['Boolean'];
  canRemovePublishingBlocker: Scalars['Boolean'];
};

export type ExternalUrlsDataInput = {
  data?: InputMaybe<RetailAdUrlInput>;
};

export type RetailAdUrlInput = {
  url?: InputMaybe<Scalars['String']>;
  type: UrlType;
};

export type ExternalUrls = {
  __typename?: 'ExternalUrls';
  data?: Maybe<RetailAdUrl>;
};

export type RetailAdUrl = {
  __typename?: 'RetailAdUrl';
  label: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  type: UrlType;
};

export type FeatureDetailsInput = {
  data: Array<FeatureDetailCategoryInput>;
};

export type FeatureDetailCategoryInput = {
  id: Scalars['UUID'];
  name: Scalars['String'];
  items: Array<FeatureDetailItemInput>;
};

export type FeatureDetailItemInput = {
  uiFeatureDetailIdentifier?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  translations: Array<FeatureDetailTranslationInput>;
  subGroup?: InputMaybe<FeatureDetailGroupInput>;
  group: FeatureDetailGroupInput;
  subcategory?: InputMaybe<FeatureDetailSubcategoryInput>;
  order?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<FeatureDetailType>;
  isOriginal: Scalars['Boolean'];
  isNewTranslation?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Int']>;
};

export type FeatureDetailGroupInput = {
  name: Scalars['String'];
  translatedName?: InputMaybe<Scalars['String']>;
};

export type FeatureDetailSubcategoryInput = {
  id: Scalars['UUID'];
  name: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
};

export type FeatureDetailTranslationInput = {
  locale: Scalars['String'];
  text: Scalars['String'];
};

export type FeatureDetails = {
  __typename?: 'FeatureDetails';
  data: Array<FeatureDetailCategory>;
  restrictions: FeatureDetailRestrictions;
};

export type FeatureDetailCategory = {
  __typename?: 'FeatureDetailCategory';
  id: Scalars['String'];
  name: Scalars['String'];
  items: Array<FeatureDetailItem>;
};

export type FeatureDetailItem = {
  __typename?: 'FeatureDetailItem';
  id: Scalars['String'];
  uiFeatureDetailIdentifier?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  translations: Array<FeatureDetailTranslation>;
  subGroup?: Maybe<FeatureDetailGroup>;
  group: FeatureDetailGroup;
  subcategory?: Maybe<FeatureDetailSubcategory>;
  order?: Maybe<Scalars['Int']>;
  classifieds: Array<Scalars['String']>;
  type?: Maybe<FeatureDetailType>;
  isOriginal: Scalars['Boolean'];
  isNewTranslation?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Int']>;
};

export const FeatureDetailType = {
  Standard: 'STANDARD',
  Optional: 'OPTIONAL',
} as const;

export type FeatureDetailType =
  (typeof FeatureDetailType)[keyof typeof FeatureDetailType];
export type FeatureDetailGroup = {
  __typename?: 'FeatureDetailGroup';
  name: Scalars['String'];
  translatedName: Scalars['String'];
};

export type FeatureDetailSubcategory = {
  __typename?: 'FeatureDetailSubcategory';
  id: Scalars['String'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
};

export type FeatureDetailTranslation = {
  __typename?: 'FeatureDetailTranslation';
  locale: Scalars['String'];
  text: Scalars['String'];
};

export type FeatureDetailRestrictions = {
  __typename?: 'FeatureDetailRestrictions';
  canAddFeatureDetail: Scalars['Boolean'];
  canRemoveFeatureDetail: Scalars['Boolean'];
  canTranslate: Scalars['Boolean'];
  canEditTranslations: Scalars['Boolean'];
  canSeeUnclassifiedMappings: Scalars['Boolean'];
  canEditUnclassifiedMappings: Scalars['Boolean'];
};

export type GeneralSectionInput = {
  data: GeneralSectionDataInput;
};

export type MileageValueInput = {
  distance: Scalars['Int'];
  unit?: InputMaybe<MileageUnit>;
};

export type FuelConsumptionInput = {
  city?: InputMaybe<Scalars['Float']>;
  highway?: InputMaybe<Scalars['Float']>;
  combined?: InputMaybe<Scalars['Float']>;
  common?: InputMaybe<Scalars['Float']>;
};

export type PartnerInput = {
  name: Scalars['String'];
};

export type ShowroomAvailabilityInput = {
  available: Scalars['Boolean'];
};

export type UniqueSellingPropositionsInput = {
  usp1?: InputMaybe<Scalars['String']>;
  usp2?: InputMaybe<Scalars['String']>;
  usp3?: InputMaybe<Scalars['String']>;
};

export type GeneralSectionDataInput = {
  title?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  manufacturer?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  datModel?: InputMaybe<Scalars['String']>;
  subType?: InputMaybe<Scalars['String']>;
  subTypeExtra?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  stockNumber?: InputMaybe<Scalars['String']>;
  vin?: InputMaybe<Scalars['String']>;
  builtYear?: InputMaybe<Scalars['Int']>;
  grossPriceMajorUnits?: InputMaybe<Scalars['Int']>;
  priceChangeReason?: InputMaybe<Scalars['String']>;
  priceChangeComment?: InputMaybe<Scalars['String']>;
  netPriceMajorUnits?: InputMaybe<Scalars['Int']>;
  expectedSalesPriceMajorUnits?: InputMaybe<Scalars['Int']>;
  vatAmount?: InputMaybe<Scalars['Int']>;
  vatType?: InputMaybe<Scalars['String']>;
  horsePower?: InputMaybe<Scalars['Int']>;
  bodyType?: InputMaybe<Scalars['Int']>;
  gearType?: InputMaybe<Scalars['Int']>;
  primaryWheelSeason?: InputMaybe<Season>;
  registration?: InputMaybe<Scalars['LocalDate']>;
  seatCount?: InputMaybe<Scalars['Int']>;
  handoverKeyCount?: InputMaybe<Scalars['Int']>;
  manufacturerCode?: InputMaybe<Scalars['String']>;
  modelCode?: InputMaybe<Scalars['String']>;
  carType?: InputMaybe<Scalars['String']>;
  taxRating?: InputMaybe<Scalars['Int']>;
  bpmMajorUnits?: InputMaybe<Scalars['Int']>;
  financedPriceMajorUnits?: InputMaybe<Scalars['Int']>;
  uniqueSellingPropositions?: InputMaybe<UniqueSellingPropositionsInput>;
  mileage: MileageValueInput;
  carPreownerCount?: InputMaybe<Scalars['Int']>;
  countryOfOrigin?: InputMaybe<Scalars['String']>;
  licensePlate?: InputMaybe<Scalars['String']>;
  lastServiceOn?: InputMaybe<Scalars['LocalDate']>;
  inspectionExpiryDate?: InputMaybe<Scalars['LocalDate']>;
  originalEngine?: InputMaybe<Scalars['Boolean']>;
  carAttrAccidentBool?: InputMaybe<Scalars['Boolean']>;
  wasInCommercialUse?: InputMaybe<Scalars['Boolean']>;
  fuelType?: InputMaybe<Scalars['String']>;
  energyEfficiencyClass?: InputMaybe<Scalars['String']>;
  emissionClass?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['Int']>;
  metallic?: InputMaybe<Scalars['Boolean']>;
  upholstery?: InputMaybe<Scalars['Int']>;
  upholsteryOriginal?: InputMaybe<Scalars['Boolean']>;
  fuelConsumption: FuelConsumptionInput;
  co2Value?: InputMaybe<Scalars['Int']>;
  kw?: InputMaybe<Scalars['Int']>;
  ccm?: InputMaybe<Scalars['Int']>;
  emissionSticker?: InputMaybe<Scalars['String']>;
  serviceHistory?: InputMaybe<ServiceHistoryInput>;
  carAccidentDamages?: InputMaybe<CarAccidentInput>;
  retailCountryLicensePlate?: InputMaybe<Scalars['String']>;
  partner?: InputMaybe<PartnerInput>;
  showroomAvailability?: InputMaybe<ShowroomAvailabilityInput>;
  localRetail?: InputMaybe<Scalars['Boolean']>;
  upholsteryColor?: InputMaybe<Scalars['String']>;
  driveTrain?: InputMaybe<Scalars['String']>;
  doorCount?: InputMaybe<Scalars['Int']>;
  hasFullServiceHistory?: InputMaybe<Scalars['Boolean']>;
  oemPaintName?: InputMaybe<Scalars['String']>;
  trimLevel?: InputMaybe<Scalars['String']>;
  branchName?: InputMaybe<Scalars['String']>;
  availableAtShowroom?: InputMaybe<Scalars['Boolean']>;
};

export type GeneralSection = {
  __typename?: 'GeneralSection';
  restrictions: GeneralSectionRestrictions;
  data: GeneralSectionData;
  additionalVehicleData: Array<AdditionalVehicleData>;
};

export type MileageValue = {
  __typename?: 'MileageValue';
  distance: Scalars['Int'];
  unit?: Maybe<MileageUnit>;
};

export type FuelConsumption = {
  __typename?: 'FuelConsumption';
  city?: Maybe<Scalars['Float']>;
  highway?: Maybe<Scalars['Float']>;
  combined?: Maybe<Scalars['Float']>;
  common?: Maybe<Scalars['Float']>;
};

export type Partner = {
  __typename?: 'Partner';
  name: Scalars['String'];
};

export type ShowroomAvailability = {
  __typename?: 'ShowroomAvailability';
  available: Scalars['Boolean'];
};

export type UniqueSellingPropositions = {
  __typename?: 'UniqueSellingPropositions';
  usp1?: Maybe<Scalars['String']>;
  usp2?: Maybe<Scalars['String']>;
  usp3?: Maybe<Scalars['String']>;
};

export type GeneralSectionData = {
  __typename?: 'GeneralSectionData';
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  datModel?: Maybe<Scalars['String']>;
  subType?: Maybe<Scalars['String']>;
  subTypeExtra?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
  builtYear?: Maybe<Scalars['Int']>;
  grossPriceMajorUnits?: Maybe<Scalars['Long']>;
  netPriceMajorUnits?: Maybe<Scalars['Long']>;
  expectedSalesPriceMajorUnits?: Maybe<Scalars['Int']>;
  vatAmount?: Maybe<Scalars['Long']>;
  vatType?: Maybe<Scalars['String']>;
  horsePower?: Maybe<Scalars['Int']>;
  bodyType?: Maybe<Scalars['Int']>;
  gearType?: Maybe<Scalars['Int']>;
  primaryWheelSeason?: Maybe<Season>;
  registration?: Maybe<Scalars['LocalDate']>;
  seatCount?: Maybe<Scalars['Int']>;
  handoverKeyCount?: Maybe<Scalars['Int']>;
  manufacturerCode?: Maybe<Scalars['String']>;
  modelCode?: Maybe<Scalars['String']>;
  carType?: Maybe<Scalars['String']>;
  taxRating?: Maybe<Scalars['Int']>;
  bpmMajorUnits?: Maybe<Scalars['Int']>;
  financedPriceMajorUnits?: Maybe<Scalars['Long']>;
  uniqueSellingPropositions?: Maybe<UniqueSellingPropositions>;
  mileage?: Maybe<MileageValue>;
  carPreownerCount?: Maybe<Scalars['Int']>;
  countryOfOrigin?: Maybe<Scalars['String']>;
  licensePlate?: Maybe<Scalars['String']>;
  lastServiceOn?: Maybe<Scalars['LocalDate']>;
  inspectionExpiryDate?: Maybe<Scalars['LocalDate']>;
  originalEngine?: Maybe<Scalars['Boolean']>;
  carAttrAccidentBool?: Maybe<Scalars['Boolean']>;
  wasInCommercialUse?: Maybe<Scalars['Boolean']>;
  fuelType?: Maybe<Scalars['String']>;
  energyEfficiencyClass?: Maybe<Scalars['String']>;
  emissionClass?: Maybe<Scalars['String']>;
  fuelConsumption: FuelConsumption;
  co2Value?: Maybe<Scalars['Int']>;
  kw?: Maybe<Scalars['Int']>;
  ccm?: Maybe<Scalars['Int']>;
  emissionSticker?: Maybe<Scalars['String']>;
  serviceHistory?: Maybe<ServiceHistory>;
  carAccidentDamages?: Maybe<CarAccident>;
  retailCountryLicensePlate?: Maybe<Scalars['String']>;
  partner?: Maybe<Partner>;
  color?: Maybe<Scalars['Int']>;
  metallic?: Maybe<Scalars['Boolean']>;
  upholstery?: Maybe<Scalars['Int']>;
  upholsteryOriginal?: Maybe<Scalars['Boolean']>;
  showroomAvailability?: Maybe<ShowroomAvailability>;
  localRetail?: Maybe<Scalars['Boolean']>;
  upholsteryColor?: Maybe<Scalars['String']>;
  driveTrain?: Maybe<Scalars['String']>;
  doorCount?: Maybe<Scalars['Int']>;
  hasFullServiceHistory?: Maybe<Scalars['Boolean']>;
  oemPaintName?: Maybe<Scalars['String']>;
  isNonSmokerVehicle?: Maybe<Scalars['Boolean']>;
  trimLevel?: Maybe<Scalars['String']>;
  branchName?: Maybe<Scalars['String']>;
  availableAtShowroom?: Maybe<Scalars['Boolean']>;
};

export type GeneralSectionRestrictions = {
  __typename?: 'GeneralSectionRestrictions';
  canSeeCarType: Scalars['Boolean'];
  canSeeTaxRating: Scalars['Boolean'];
  canSeeManufacturerCode: Scalars['Boolean'];
  canSeeModelCode: Scalars['Boolean'];
  canSeeBpm: Scalars['Boolean'];
  canSeeFinancedPrice: Scalars['Boolean'];
  canSeeLicensePlate: Scalars['Boolean'];
  canSeeRetailCountryLicensePlate: Scalars['Boolean'];
  canEditVehicleFields: Scalars['Boolean'];
  canEditGrossPrice: Scalars['Boolean'];
  preSignedUrlGenerationEnabled?: Maybe<Scalars['Boolean']>;
  savingActivityIntervalInSeconds?: Maybe<Scalars['Int']>;
  canSeeEmissionSticker: Scalars['Boolean'];
  canEditEmissionSticker: Scalars['Boolean'];
};

export type AdditionalVehicleData = {
  __typename?: 'AdditionalVehicleData';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type ImperfectionsInput = {
  items?: InputMaybe<Array<ImperfectionItemInput>>;
};

export type ImperfectionItemInput = {
  id: Scalars['UUID'];
  image: ImageInput;
  part: Array<ImperfectionItemPartInput>;
  imperfectionType: Array<ImperfectionItemTypeInput>;
  category?: InputMaybe<Scalars['String']>;
};

export type ImperfectionItemPartInput = {
  text: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  localeTitle?: InputMaybe<Scalars['String']>;
};

export type ImperfectionItemTypeInput = {
  text: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  localeTitle?: InputMaybe<Scalars['String']>;
};

export type Imperfections = {
  __typename?: 'Imperfections';
  items?: Maybe<Array<ImperfectionItem>>;
};

export type ImperfectionItem = {
  __typename?: 'ImperfectionItem';
  id: Scalars['UUID'];
  image: Image;
  part: Array<ImperfectionItemPart>;
  imperfectionType: Array<ImperfectionItemType>;
  category?: Maybe<Scalars['String']>;
};

export type ImperfectionItemPart = {
  __typename?: 'ImperfectionItemPart';
  text: Scalars['String'];
  locale: Scalars['String'];
  localeTitle?: Maybe<Scalars['String']>;
};

export type ImperfectionItemType = {
  __typename?: 'ImperfectionItemType';
  text: Scalars['String'];
  locale: Scalars['String'];
  localeTitle?: Maybe<Scalars['String']>;
};

export type TagInput = {
  id?: InputMaybe<Scalars['UUID']>;
  tagImageId: Scalars['UUID'];
  position: TagPositionInput;
};

export type TagPositionInput = {
  top: Scalars['Float'];
  left: Scalars['Float'];
};

export type MediaInput = {
  data: MediaDataInput;
};

export type MediaDataInput = {
  images: MediaImagesInput;
  video?: InputMaybe<VideoInput>;
  checksum?: InputMaybe<Scalars['String']>;
};

export type MediaImagesInput = {
  exterior: Array<ImageInput>;
  interior: Array<ImageInput>;
  panorama: Array<ImageInput>;
  nextgen_highlight: Array<ImageInput>;
};

export type ImageInput = {
  id: Scalars['UUID'];
  original?: InputMaybe<Scalars['String']>;
  thumbnail?: InputMaybe<Scalars['String']>;
  uploadedUrl?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<TagInput>>;
  nonRemovable?: InputMaybe<Scalars['Boolean']>;
  photoId?: InputMaybe<Scalars['Int']>;
};

export type VideoInput = {
  originalUrl: Scalars['String'];
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['UUID'];
  tagImageId: Scalars['UUID'];
  position: TagPosition;
};

export type TagPosition = {
  __typename?: 'TagPosition';
  top: Scalars['Float'];
  left: Scalars['Float'];
};

export type Media = {
  __typename?: 'Media';
  data?: Maybe<MediaData>;
  restrictions: MediaRestrictions;
};

export type MediaData = {
  __typename?: 'MediaData';
  video?: Maybe<Video>;
  images: MediaImages;
  checksum?: Maybe<Scalars['String']>;
};

export type MediaImages = {
  __typename?: 'MediaImages';
  exterior: Array<Image>;
  interior: Array<Image>;
  panorama: Array<Image>;
  nextgen_highlight: Array<Image>;
};

export type Image = {
  __typename?: 'Image';
  id: Scalars['UUID'];
  original: Scalars['String'];
  thumbnail: Scalars['String'];
  tags?: Maybe<Array<Tag>>;
  uploadedUrl: Scalars['String'];
  nonRemovable?: Maybe<Scalars['Boolean']>;
  photoId?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

export type ResizedVideo = {
  __typename?: 'ResizedVideo';
  resolution: Scalars['String'];
  url: Scalars['String'];
};

export type Video = {
  __typename?: 'Video';
  originalUrl: Scalars['String'];
  createdAt?: Maybe<Scalars['String']>;
  resizedVideos: Array<ResizedVideo>;
};

export type MediaRestrictions = {
  __typename?: 'MediaRestrictions';
  canAddImages: Scalars['Boolean'];
  canRemoveImages: Scalars['Boolean'];
  canSortImages: Scalars['Boolean'];
  canEditTags: Scalars['Boolean'];
  canRemoveTags: Scalars['Boolean'];
  canRotateImperfectionImage: Scalars['Boolean'];
  canSelectImperfectionImage: Scalars['Boolean'];
};

export type Mutation = {
  __typename?: 'Mutation';
  updateAdMgmt?: Maybe<AdMgmt>;
  publishAd: ExportItem;
  unpublishAd: ExportItem;
  updatePreparationState: AdPreparation;
  updatePreparationComment: AdPreparation;
};

export type MutationUpdateAdMgmtArgs = {
  adId: Scalars['UUID'];
  adMgmtInput: AdMgmtInput;
};

export type MutationPublishAdArgs = {
  adId: Scalars['UUID'];
  marketplaceId: Scalars['String'];
  accountIds: Array<Scalars['String']>;
};

export type MutationUnpublishAdArgs = {
  adId: Scalars['UUID'];
  marketplaceId: Scalars['String'];
  accountIds: Array<Scalars['String']>;
};

export type MutationUpdatePreparationStateArgs = {
  adId: Scalars['UUID'];
  preparationState: Scalars['String'];
};

export type MutationUpdatePreparationCommentArgs = {
  adId: Scalars['UUID'];
  comment?: InputMaybe<Scalars['String']>;
};

export type PriceManagementGroup = {
  __typename?: 'PriceManagementGroup';
  label: Scalars['String'];
};

export type PricingChangelog = {
  __typename?: 'PricingChangelog';
  data: PricingChangelogData;
};

export type PricingChangelogData = {
  __typename?: 'PricingChangelogData';
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount: Scalars['Int'];
  totalPageCount: Scalars['Int'];
  items: Array<PricingChangelogItem>;
};

export type PricingChangelogItem = {
  __typename?: 'PricingChangelogItem';
  changedBy: Scalars['String'];
  stockNumber: Scalars['String'];
  changedAt: Scalars['DateTime'];
  previousPrice?: Maybe<Scalars['String']>;
  newPrice?: Maybe<Scalars['String']>;
  changeReason: Scalars['String'];
  changeComment?: Maybe<Scalars['String']>;
};

export type AwsSignedHeader = {
  __typename?: 'AwsSignedHeader';
  key: Scalars['String'];
  value: Array<Scalars['String']>;
};

export type PublisherEventTrackingUploadPreSignedUrl = {
  __typename?: 'PublisherEventTrackingUploadPreSignedUrl';
  url?: Maybe<Scalars['String']>;
  signedHeaders: Array<AwsSignedHeader>;
};

export type Query = {
  __typename?: 'Query';
  adMgmt: AdMgmt;
  publisherEventTrackingUploadPreSignedUrl: PublisherEventTrackingUploadPreSignedUrl;
  pricingChangelog: PricingChangelog;
  getServiceHistoryDocumentUploadPresignedUrl: UploadPresignedUrl;
  previewUrl: PreviewUrl;
  generateDescription: ExportDescription;
  getPreparationState: AdPreparation;
  adPreparation: AdPreparation;
};

export type QueryAdMgmtArgs = {
  adId: Scalars['UUID'];
};

export type QueryPricingChangelogArgs = {
  adId: Scalars['UUID'];
  pageSize: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryGetServiceHistoryDocumentUploadPresignedUrlArgs = {
  uploadId: Scalars['String'];
  fileExt: Scalars['String'];
};

export type QueryPreviewUrlArgs = {
  uploadId: Scalars['String'];
};

export type QueryGenerateDescriptionArgs = {
  adId: Scalars['UUID'];
  marketplaceId: Scalars['String'];
};

export type QueryGetPreparationStateArgs = {
  adId: Scalars['UUID'];
};

export type QueryAdPreparationArgs = {
  adId: Scalars['UUID'];
};

export type SecondaryWheelsInput = {
  data: SecondaryWheelsDataInput;
};

export type SecondaryWheelsDataInput = {
  damageCondition: Damage_Condition;
  grossPrice?: InputMaybe<Scalars['Float']>;
  netPrice?: InputMaybe<Scalars['Float']>;
  vatRate: Scalars['Float'];
  isActive: Scalars['Boolean'];
  isShippingRequired?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['String']>;
  dot: StringWheelsAttributeInput;
  manufacturer: StringWheelsAttributeInput;
  material: MaterialInput;
  wheelWidth?: InputMaybe<IntWheelsAttributeInput>;
  wheelHeight?: InputMaybe<IntWheelsAttributeInput>;
  radius: IntWheelsAttributeInput;
  loadIndex?: InputMaybe<IntWheelsAttributeInput>;
  speedIndex?: InputMaybe<StringWheelsAttributeInput>;
  seasonType: SeasonTypeInput;
  treadDepth: IntWheelsAttributeInput;
  rimsDetails: RimsDetailsInput;
  tiresDetails: TiresDetailsInput;
  ad_secondary_wheels: Array<InputMaybe<ImageInput>>;
  ad_secondary_wheels_damages: Array<InputMaybe<ImageInput>>;
};

export type StringWheelsAttributeInput = {
  frontLeft?: InputMaybe<Scalars['String']>;
  frontRight?: InputMaybe<Scalars['String']>;
  rearLeft?: InputMaybe<Scalars['String']>;
  rearRight?: InputMaybe<Scalars['String']>;
};

export type MaterialInput = {
  frontLeft?: InputMaybe<Rim_Material>;
  frontRight?: InputMaybe<Rim_Material>;
  rearLeft?: InputMaybe<Rim_Material>;
  rearRight?: InputMaybe<Rim_Material>;
};

export type IntWheelsAttributeInput = {
  frontLeft?: InputMaybe<Scalars['Int']>;
  frontRight?: InputMaybe<Scalars['Int']>;
  rearLeft?: InputMaybe<Scalars['Int']>;
  rearRight?: InputMaybe<Scalars['Int']>;
};

export type SeasonTypeInput = {
  frontLeft?: InputMaybe<Season>;
  frontRight?: InputMaybe<Season>;
  rearLeft?: InputMaybe<Season>;
  rearRight?: InputMaybe<Season>;
};

export type RimsDetailsInput = {
  frontLeft: Array<DamageItemInput>;
  frontRight: Array<DamageItemInput>;
  rearLeft: Array<DamageItemInput>;
  rearRight: Array<DamageItemInput>;
};

export type TiresDetailsInput = {
  frontLeft: Array<DamageItemInput>;
  frontRight: Array<DamageItemInput>;
  rearLeft: Array<DamageItemInput>;
  rearRight: Array<DamageItemInput>;
};

export type DamageItemInput = {
  label: Scalars['String'];
  value: Scalars['String'];
};

export type SecondaryWheels = {
  __typename?: 'SecondaryWheels';
  data?: Maybe<SecondaryWheelsData>;
  restrictions?: Maybe<SecondaryWheelsRestrictions>;
};

export type SecondaryWheelsData = {
  __typename?: 'SecondaryWheelsData';
  damageCondition: Damage_Condition;
  grossPrice?: Maybe<Scalars['Float']>;
  netPrice?: Maybe<Scalars['Float']>;
  vatRate?: Maybe<Scalars['Float']>;
  isActive: Scalars['Boolean'];
  isShippingRequired: Scalars['Boolean'];
  location?: Maybe<Scalars['String']>;
  dot: StringWheelsAttribute;
  manufacturer: StringWheelsAttribute;
  material: Material;
  wheelWidth: IntWheelsAttribute;
  wheelHeight: IntWheelsAttribute;
  radius: IntWheelsAttribute;
  loadIndex: IntWheelsAttribute;
  speedIndex: StringWheelsAttribute;
  seasonType: SeasonType;
  treadDepth: IntWheelsAttribute;
  rimsDetails: RimsDetails;
  tiresDetails: TiresDetails;
  ad_secondary_wheels: Array<Image>;
  ad_secondary_wheels_damages: Array<Image>;
};

export type StringWheelsAttribute = {
  __typename?: 'StringWheelsAttribute';
  frontLeft?: Maybe<Scalars['String']>;
  frontRight?: Maybe<Scalars['String']>;
  rearLeft?: Maybe<Scalars['String']>;
  rearRight?: Maybe<Scalars['String']>;
};

export type IntWheelsAttribute = {
  __typename?: 'IntWheelsAttribute';
  frontLeft?: Maybe<Scalars['Int']>;
  frontRight?: Maybe<Scalars['Int']>;
  rearLeft?: Maybe<Scalars['Int']>;
  rearRight?: Maybe<Scalars['Int']>;
};

export type Material = {
  __typename?: 'Material';
  frontLeft?: Maybe<Rim_Material>;
  frontRight?: Maybe<Rim_Material>;
  rearLeft?: Maybe<Rim_Material>;
  rearRight?: Maybe<Rim_Material>;
};

export type SeasonType = {
  __typename?: 'SeasonType';
  frontLeft?: Maybe<Season>;
  frontRight?: Maybe<Season>;
  rearLeft?: Maybe<Season>;
  rearRight?: Maybe<Season>;
};

export type RimsDetails = {
  __typename?: 'RimsDetails';
  frontLeft: Array<DamageItem>;
  frontRight: Array<DamageItem>;
  rearLeft: Array<DamageItem>;
  rearRight: Array<DamageItem>;
};

export type TiresDetails = {
  __typename?: 'TiresDetails';
  frontLeft: Array<DamageItem>;
  frontRight: Array<DamageItem>;
  rearLeft: Array<DamageItem>;
  rearRight: Array<DamageItem>;
};

export type DamageItem = {
  __typename?: 'DamageItem';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type SecondaryWheelsRestrictions = {
  __typename?: 'SecondaryWheelsRestrictions';
  canEditPrice: Scalars['Boolean'];
  canEditTableFields: Scalars['Boolean'];
  canEditSWImages: Scalars['Boolean'];
  canViewSecondaryWheels: Scalars['Boolean'];
  canUpdateSecondaryWheels: Scalars['Boolean'];
};

export type ServiceHistoryInput = {
  id?: InputMaybe<Scalars['String']>;
  records: Array<ServiceHistoryRecordInput>;
};

export type ServiceHistoryRecordInput = {
  id?: InputMaybe<Scalars['String']>;
  lastServiceOn: Scalars['LocalDate'];
  lastServiceMileage: Scalars['Int'];
  type?: InputMaybe<Scalars['String']>;
  servicePerformedBy?: InputMaybe<Scalars['String']>;
  tasksPerformed?: InputMaybe<Array<Scalars['String']>>;
  documentType?: InputMaybe<Array<ServiceHistoryDocumentType>>;
  editable: Scalars['Boolean'];
  documents: Array<InputMaybe<ServiceHistoryDocumentInput>>;
};

export type ServiceHistoryDocumentInput = {
  id: Scalars['String'];
  fileType: ServiceHistoryDocumentFileType;
  fileExt?: InputMaybe<Scalars['String']>;
};

export type ServiceHistory = {
  __typename?: 'ServiceHistory';
  id: Scalars['String'];
  records: Array<ServiceHistoryRecord>;
  documents?: Maybe<Array<ServiceHistoryDocument>>;
};

export type ServiceHistoryRecord = {
  __typename?: 'ServiceHistoryRecord';
  id: Scalars['String'];
  lastServiceOn: Scalars['LocalDate'];
  lastServiceMileage: Scalars['Int'];
  type?: Maybe<Scalars['String']>;
  servicePerformedBy?: Maybe<Scalars['String']>;
  tasksPerformed?: Maybe<Array<Scalars['String']>>;
  documentType?: Maybe<Array<ServiceHistoryDocumentType>>;
  editable: Scalars['Boolean'];
  documents: Array<ServiceHistoryDocument>;
};

export type ServiceHistoryDocument = {
  __typename?: 'ServiceHistoryDocument';
  id: Scalars['String'];
  fileType: ServiceHistoryDocumentFileType;
  url: Scalars['String'];
  thumbnailUrl: Scalars['String'];
};

export type ServiceHistoryDocumentUploadData = {
  __typename?: 'ServiceHistoryDocumentUploadData';
  bucketName: Scalars['String'];
  s3MetaData: Array<KeyValue>;
};

export type UploadPresignedUrl = {
  __typename?: 'UploadPresignedUrl';
  bucket: Scalars['String'];
  url: Scalars['String'];
  headers?: Maybe<Array<UploadHeader>>;
};

export type KeyValue = {
  __typename?: 'KeyValue';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type UploadHeader = {
  __typename?: 'UploadHeader';
  name: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type SpecSpotlightInput = {
  topHighlights: Array<Scalars['String']>;
  topFeatureDetails: Array<Scalars['String']>;
};

export type SpecSpotlightData = {
  __typename?: 'SpecSpotlightData';
  topHighlights: Array<Scalars['String']>;
  topFeatureDetails: Array<Scalars['String']>;
};

export type SpecSpotlightOptions = {
  __typename?: 'SpecSpotlightOptions';
  topHighlightsOptions: Array<OptionStringItem>;
  topFeaturesOptions: Array<OptionStringItem>;
};

export type SpecSpotlight = {
  __typename?: 'SpecSpotlight';
  data: SpecSpotlightData;
  specSpotlightOptions: SpecSpotlightOptions;
};

export type PublishAdMutationVariables = Exact<{
  adId: Scalars['UUID'];
  marketplaceId: Scalars['String'];
  accountIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type PublishAdMutation = {
  __typename?: 'Mutation';
  publishAd: {
    __typename?: 'ExportItem';
    daysOnline?: number | null | undefined;
    firstPublishedAt?: string | null | undefined;
    updatedAt?: string | null | undefined;
    firstEligibleToBePurchasedAt?: string | null | undefined;
    publishingStatus?: PublishingStatus | null | undefined;
    canPublish: boolean;
    comingSoon?: boolean | null | undefined;
    marketplace: { __typename?: 'Marketplace'; title: string; id: string };
    lastPublishingErrors: Array<{
      __typename?: 'PublishingError';
      createdAt: string;
      message?: string | null | undefined;
    }>;
    links: Array<{ __typename?: 'ExternalLink'; title: string; url: string }>;
    exportListings?:
      | {
          __typename?: 'ExportListings';
          listings: Array<{
            __typename?: 'ExportListing';
            daysOnline?: number | null | undefined;
            firstPublishedAt?: string | null | undefined;
            lastPublishedAt?: string | null | undefined;
            publishingStatus: ExportListingState;
            lastPublishingErrors: Array<{
              __typename?: 'PublishingError';
              createdAt: string;
              message?: string | null | undefined;
            }>;
            account: {
              __typename?: 'ExportAccount';
              id: string;
              title: string;
            };
            link?:
              | { __typename?: 'ExternalLink'; title: string; url: string }
              | null
              | undefined;
          }>;
        }
      | null
      | undefined;
  };
};

export type SaveDataMutationVariables = Exact<{
  adId: Scalars['UUID'];
  adMgmtInput: AdMgmtInput;
}>;

export type SaveDataMutation = {
  __typename?: 'Mutation';
  updateAdMgmt?:
    | {
        __typename?: 'AdMgmt';
        id: string;
        title: string;
        country: string;
        currencyCode: string;
        version: number;
        general: {
          __typename?: 'GeneralSection';
          data: {
            __typename?: 'GeneralSectionData';
            title?: string | null | undefined;
            subtitle?: string | null | undefined;
            description?: string | null | undefined;
            manufacturer?: string | null | undefined;
            model?: string | null | undefined;
            datModel?: string | null | undefined;
            subType?: string | null | undefined;
            subTypeExtra?: string | null | undefined;
            status?: string | null | undefined;
            stockNumber?: string | null | undefined;
            vin?: string | null | undefined;
            builtYear?: number | null | undefined;
            grossPriceMajorUnits?: number | null | undefined;
            netPriceMajorUnits?: number | null | undefined;
            expectedSalesPriceMajorUnits?: number | null | undefined;
            vatAmount?: number | null | undefined;
            vatType?: string | null | undefined;
            horsePower?: number | null | undefined;
            bodyType?: number | null | undefined;
            gearType?: number | null | undefined;
            primaryWheelSeason?: Season | null | undefined;
            registration?: string | null | undefined;
            seatCount?: number | null | undefined;
            handoverKeyCount?: number | null | undefined;
            manufacturerCode?: string | null | undefined;
            modelCode?: string | null | undefined;
            carType?: string | null | undefined;
            taxRating?: number | null | undefined;
            bpmMajorUnits?: number | null | undefined;
            financedPriceMajorUnits?: number | null | undefined;
            carPreownerCount?: number | null | undefined;
            countryOfOrigin?: string | null | undefined;
            licensePlate?: string | null | undefined;
            lastServiceOn?: string | null | undefined;
            inspectionExpiryDate?: string | null | undefined;
            originalEngine?: boolean | null | undefined;
            carAttrAccidentBool?: boolean | null | undefined;
            wasInCommercialUse?: boolean | null | undefined;
            fuelType?: string | null | undefined;
            energyEfficiencyClass?: string | null | undefined;
            emissionClass?: string | null | undefined;
            co2Value?: number | null | undefined;
            kw?: number | null | undefined;
            ccm?: number | null | undefined;
            emissionSticker?: string | null | undefined;
            availableAtShowroom?: boolean | null | undefined;
            branchName?: string | null | undefined;
            localRetail?: boolean | null | undefined;
            upholsteryColor?: string | null | undefined;
            driveTrain?: string | null | undefined;
            doorCount?: number | null | undefined;
            hasFullServiceHistory?: boolean | null | undefined;
            oemPaintName?: string | null | undefined;
            metallic?: boolean | null | undefined;
            trimLevel?: string | null | undefined;
            mileage?:
              | {
                  __typename?: 'MileageValue';
                  distance: number;
                  unit?: MileageUnit | null | undefined;
                }
              | null
              | undefined;
            fuelConsumption: {
              __typename?: 'FuelConsumption';
              city?: number | null | undefined;
              highway?: number | null | undefined;
              combined?: number | null | undefined;
              common?: number | null | undefined;
            };
            serviceHistory?:
              | {
                  __typename?: 'ServiceHistory';
                  id: string;
                  records: Array<{
                    __typename?: 'ServiceHistoryRecord';
                    id: string;
                    lastServiceOn: string;
                    lastServiceMileage: number;
                    servicePerformedBy?: string | null | undefined;
                    tasksPerformed?: Array<string> | null | undefined;
                    documentType?:
                      | Array<ServiceHistoryDocumentType>
                      | null
                      | undefined;
                    editable: boolean;
                    type?: string | null | undefined;
                    documents: Array<{
                      __typename?: 'ServiceHistoryDocument';
                      id: string;
                      fileType: ServiceHistoryDocumentFileType;
                      url: string;
                      thumbnailUrl: string;
                    }>;
                  }>;
                  documents?:
                    | Array<{
                        __typename?: 'ServiceHistoryDocument';
                        id: string;
                        fileType: ServiceHistoryDocumentFileType;
                        url: string;
                        thumbnailUrl: string;
                      }>
                    | null
                    | undefined;
                }
              | null
              | undefined;
            partner?:
              | { __typename?: 'Partner'; name: string }
              | null
              | undefined;
            uniqueSellingPropositions?:
              | {
                  __typename?: 'UniqueSellingPropositions';
                  usp1?: string | null | undefined;
                  usp2?: string | null | undefined;
                  usp3?: string | null | undefined;
                }
              | null
              | undefined;
            carAccidentDamages?:
              | {
                  __typename?: 'CarAccident';
                  accidentArea: Array<string | null | undefined>;
                  accidentDamages: Array<
                    | {
                        __typename?: 'CarAccidentDamage';
                        id: string;
                        carPart: string;
                        damageType: string;
                        attributes?: string | null | undefined;
                        showToCustomer: boolean;
                        isRepaired?: boolean | null | undefined;
                      }
                    | null
                    | undefined
                  >;
                }
              | null
              | undefined;
          };
          restrictions: {
            __typename?: 'GeneralSectionRestrictions';
            canSeeCarType: boolean;
            canSeeTaxRating: boolean;
            canSeeManufacturerCode: boolean;
            canSeeModelCode: boolean;
            canSeeBpm: boolean;
            canSeeFinancedPrice: boolean;
            canSeeLicensePlate: boolean;
          };
        };
        exportOverview: {
          __typename?: 'ExportOverview';
          data: {
            __typename?: 'ExportData';
            publishingBlockers: Array<{
              __typename?: 'PublishingBlocker';
              reasonId: string;
              subReasonId: string;
              comment: string;
            }>;
          };
          exportItems: Array<{
            __typename?: 'ExportItem';
            daysOnline?: number | null | undefined;
            firstPublishedAt?: string | null | undefined;
            updatedAt?: string | null | undefined;
            publishingStatus?: PublishingStatus | null | undefined;
            canPublish: boolean;
            marketplace: {
              __typename?: 'Marketplace';
              title: string;
              id: string;
            };
            lastPublishingErrors: Array<{
              __typename?: 'PublishingError';
              createdAt: string;
              message?: string | null | undefined;
            }>;
            links: Array<{
              __typename?: 'ExternalLink';
              title: string;
              url: string;
            }>;
            exportListings?:
              | {
                  __typename?: 'ExportListings';
                  listings: Array<{
                    __typename?: 'ExportListing';
                    daysOnline?: number | null | undefined;
                    firstPublishedAt?: string | null | undefined;
                    lastPublishedAt?: string | null | undefined;
                    publishingStatus: ExportListingState;
                    lastPublishingErrors: Array<{
                      __typename?: 'PublishingError';
                      createdAt: string;
                      message?: string | null | undefined;
                    }>;
                    account: {
                      __typename?: 'ExportAccount';
                      id: string;
                      title: string;
                    };
                    link?:
                      | {
                          __typename?: 'ExternalLink';
                          title: string;
                          url: string;
                        }
                      | null
                      | undefined;
                  }>;
                }
              | null
              | undefined;
          }>;
          restrictions: {
            __typename?: 'ExportRestrictions';
            canAddPublishingBlocker: boolean;
            canRemovePublishingBlocker: boolean;
          };
        };
        dictionaries: {
          __typename?: 'Dictionaries';
          autoscout24?:
            | {
                __typename?: 'Autoscout24Dictionaries';
                marketplaceAccountOptions: Array<{
                  __typename?: 'MarketplaceAccountOptions';
                  label: string;
                  value: string;
                }>;
              }
            | null
            | undefined;
          global: {
            __typename?: 'GlobalDictionaries';
            publishingBlockersReasons: Array<{
              __typename?: 'PublishingBlockerReason';
              label: string;
              value: string;
              subReasons: Array<{
                __typename?: 'PublishingBlockerSubreason';
                label: string;
                value: string;
              }>;
            }>;
            bodyTypeOptions: Array<{
              __typename?: 'OptionNumberItem';
              label: string;
              value: number;
            }>;
            fuelTypeOptions: Array<{
              __typename?: 'OptionStringItem';
              label: string;
              value: string;
            }>;
            gearTypeOptions: Array<{
              __typename?: 'OptionNumberItem';
              label: string;
              value: number;
            }>;
            primaryWheelSeasonOptions: Array<{
              __typename?: 'OptionStringItem';
              label: string;
              value: string;
            }>;
            taxRatingOptions: Array<{
              __typename?: 'OptionNumberItem';
              label: string;
              value: number;
            }>;
            vatTypeOptions: Array<{
              __typename?: 'OptionNumberItem';
              label: string;
              value: number;
            }>;
            carTypeOptions: Array<{
              __typename?: 'OptionStringItem';
              label: string;
              value: string;
            }>;
            secondaryWheelsTreadDepth: Array<{
              __typename?: 'OptionNumberNullableItem';
              label: string;
              value?: number | null | undefined;
            }>;
            secondaryWheelsTiresDetails: Array<{
              __typename?: 'OptionStringItem';
              label: string;
              value: string;
            }>;
            secondaryWheelsSeasonType: Array<{
              __typename?: 'OptionStringItem';
              label: string;
              value: string;
            }>;
            secondaryWheelsRimsDetails: Array<{
              __typename?: 'OptionStringItem';
              label: string;
              value: string;
            }>;
            secondaryWheelsRadius: Array<{
              __typename?: 'OptionNumberNullableItem';
              label: string;
              value?: number | null | undefined;
            }>;
            secondaryWheelsMaterial: Array<{
              __typename?: 'OptionStringItem';
              label: string;
              value: string;
            }>;
            secondaryWheelsDamageOptions: Array<{
              __typename?: 'OptionStringItem';
              label: string;
              value: string;
            }>;
          };
        };
        autoheroAndWkda: {
          __typename?: 'AutoheroAndWkda';
          media: {
            __typename?: 'Media';
            data?:
              | {
                  __typename?: 'MediaData';
                  checksum?: string | null | undefined;
                  video?:
                    | {
                        __typename?: 'Video';
                        originalUrl: string;
                        createdAt?: string | null | undefined;
                        resizedVideos: Array<{
                          __typename?: 'ResizedVideo';
                          resolution: string;
                          url: string;
                        }>;
                      }
                    | null
                    | undefined;
                  images: {
                    __typename?: 'MediaImages';
                    exterior: Array<{
                      __typename?: 'Image';
                      id: string;
                      original: string;
                      thumbnail: string;
                      uploadedUrl: string;
                      nonRemovable?: boolean | null | undefined;
                      photoId?: number | null | undefined;
                      tags?:
                        | Array<{
                            __typename?: 'Tag';
                            id: string;
                            tagImageId: string;
                            position: {
                              __typename?: 'TagPosition';
                              top: number;
                              left: number;
                            };
                          }>
                        | null
                        | undefined;
                    }>;
                    interior: Array<{
                      __typename?: 'Image';
                      id: string;
                      original: string;
                      thumbnail: string;
                      uploadedUrl: string;
                      nonRemovable?: boolean | null | undefined;
                      photoId?: number | null | undefined;
                      tags?:
                        | Array<{
                            __typename?: 'Tag';
                            id: string;
                            tagImageId: string;
                            position: {
                              __typename?: 'TagPosition';
                              top: number;
                              left: number;
                            };
                          }>
                        | null
                        | undefined;
                    }>;
                    panorama: Array<{
                      __typename?: 'Image';
                      id: string;
                      original: string;
                      thumbnail: string;
                      uploadedUrl: string;
                      nonRemovable?: boolean | null | undefined;
                      photoId?: number | null | undefined;
                      tags?:
                        | Array<{
                            __typename?: 'Tag';
                            id: string;
                            tagImageId: string;
                            position: {
                              __typename?: 'TagPosition';
                              top: number;
                              left: number;
                            };
                          }>
                        | null
                        | undefined;
                    }>;
                    nextgen_highlight: Array<{
                      __typename?: 'Image';
                      id: string;
                      original: string;
                      thumbnail: string;
                      uploadedUrl: string;
                      nonRemovable?: boolean | null | undefined;
                      photoId?: number | null | undefined;
                      tags?:
                        | Array<{
                            __typename?: 'Tag';
                            id: string;
                            tagImageId: string;
                            position: {
                              __typename?: 'TagPosition';
                              top: number;
                              left: number;
                            };
                          }>
                        | null
                        | undefined;
                    }>;
                  };
                }
              | null
              | undefined;
            restrictions: {
              __typename?: 'MediaRestrictions';
              canAddImages: boolean;
              canRemoveImages: boolean;
              canSortImages: boolean;
              canEditTags: boolean;
              canRemoveTags: boolean;
              canRotateImperfectionImage: boolean;
              canSelectImperfectionImage: boolean;
            };
          };
          imperfections?:
            | {
                __typename?: 'Imperfections';
                items?:
                  | Array<{
                      __typename?: 'ImperfectionItem';
                      id: string;
                      category?: string | null | undefined;
                      part: Array<{
                        __typename?: 'ImperfectionItemPart';
                        text: string;
                        locale: string;
                      }>;
                      imperfectionType: Array<{
                        __typename?: 'ImperfectionItemType';
                        text: string;
                        locale: string;
                      }>;
                      image: {
                        __typename?: 'Image';
                        id: string;
                        original: string;
                        thumbnail: string;
                        uploadedUrl: string;
                        nonRemovable?: boolean | null | undefined;
                        photoId?: number | null | undefined;
                        tags?:
                          | Array<{
                              __typename?: 'Tag';
                              id: string;
                              tagImageId: string;
                              position: {
                                __typename?: 'TagPosition';
                                top: number;
                                left: number;
                              };
                            }>
                          | null
                          | undefined;
                      };
                    }>
                  | null
                  | undefined;
              }
            | null
            | undefined;
          secondaryWheels?:
            | {
                __typename?: 'SecondaryWheels';
                data?:
                  | {
                      __typename?: 'SecondaryWheelsData';
                      damageCondition: Damage_Condition;
                      grossPrice?: number | null | undefined;
                      netPrice?: number | null | undefined;
                      vatRate?: number | null | undefined;
                      isActive: boolean;
                      isShippingRequired: boolean;
                      location?: string | null | undefined;
                      dot: {
                        __typename?: 'StringWheelsAttribute';
                        frontLeft?: string | null | undefined;
                        frontRight?: string | null | undefined;
                        rearLeft?: string | null | undefined;
                        rearRight?: string | null | undefined;
                      };
                      manufacturer: {
                        __typename?: 'StringWheelsAttribute';
                        frontLeft?: string | null | undefined;
                        frontRight?: string | null | undefined;
                        rearLeft?: string | null | undefined;
                        rearRight?: string | null | undefined;
                      };
                      material: {
                        __typename?: 'Material';
                        frontLeft?: Rim_Material | null | undefined;
                        frontRight?: Rim_Material | null | undefined;
                        rearLeft?: Rim_Material | null | undefined;
                        rearRight?: Rim_Material | null | undefined;
                      };
                      radius: {
                        __typename?: 'IntWheelsAttribute';
                        frontLeft?: number | null | undefined;
                        frontRight?: number | null | undefined;
                        rearLeft?: number | null | undefined;
                        rearRight?: number | null | undefined;
                      };
                      seasonType: {
                        __typename?: 'SeasonType';
                        frontLeft?: Season | null | undefined;
                        frontRight?: Season | null | undefined;
                        rearLeft?: Season | null | undefined;
                        rearRight?: Season | null | undefined;
                      };
                      treadDepth: {
                        __typename?: 'IntWheelsAttribute';
                        frontLeft?: number | null | undefined;
                        frontRight?: number | null | undefined;
                        rearLeft?: number | null | undefined;
                        rearRight?: number | null | undefined;
                      };
                      rimsDetails: {
                        __typename?: 'RimsDetails';
                        frontLeft: Array<{
                          __typename?: 'DamageItem';
                          label: string;
                          value: string;
                        }>;
                        frontRight: Array<{
                          __typename?: 'DamageItem';
                          label: string;
                          value: string;
                        }>;
                        rearLeft: Array<{
                          __typename?: 'DamageItem';
                          label: string;
                          value: string;
                        }>;
                        rearRight: Array<{
                          __typename?: 'DamageItem';
                          label: string;
                          value: string;
                        }>;
                      };
                      tiresDetails: {
                        __typename?: 'TiresDetails';
                        frontLeft: Array<{
                          __typename?: 'DamageItem';
                          label: string;
                          value: string;
                        }>;
                        frontRight: Array<{
                          __typename?: 'DamageItem';
                          label: string;
                          value: string;
                        }>;
                        rearLeft: Array<{
                          __typename?: 'DamageItem';
                          label: string;
                          value: string;
                        }>;
                        rearRight: Array<{
                          __typename?: 'DamageItem';
                          label: string;
                          value: string;
                        }>;
                      };
                      ad_secondary_wheels: Array<{
                        __typename?: 'Image';
                        id: string;
                        original: string;
                        thumbnail: string;
                      }>;
                      ad_secondary_wheels_damages: Array<{
                        __typename?: 'Image';
                        id: string;
                        original: string;
                        thumbnail: string;
                      }>;
                    }
                  | null
                  | undefined;
                restrictions?:
                  | {
                      __typename?: 'SecondaryWheelsRestrictions';
                      canEditPrice: boolean;
                      canEditTableFields: boolean;
                      canEditSWImages: boolean;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
          featureDetails: {
            __typename?: 'FeatureDetails';
            data: Array<{
              __typename?: 'FeatureDetailCategory';
              id: string;
              name: string;
              items: Array<{
                __typename?: 'FeatureDetailItem';
                uiFeatureDetailIdentifier?: string | null | undefined;
                id: string;
                name: string;
                isOriginal: boolean;
                isNewTranslation?: boolean | null | undefined;
                order?: number | null | undefined;
                type?: FeatureDetailType | null | undefined;
                price?: number | null | undefined;
                classifieds: Array<string>;
                translations: Array<{
                  __typename?: 'FeatureDetailTranslation';
                  locale: string;
                  text: string;
                }>;
                subGroup?:
                  | {
                      __typename?: 'FeatureDetailGroup';
                      name: string;
                      translatedName: string;
                    }
                  | null
                  | undefined;
                group: {
                  __typename?: 'FeatureDetailGroup';
                  name: string;
                  translatedName: string;
                };
                subcategory?:
                  | {
                      __typename?: 'FeatureDetailSubcategory';
                      id: string;
                      name: string;
                    }
                  | null
                  | undefined;
              }>;
            }>;
            restrictions: {
              __typename?: 'FeatureDetailRestrictions';
              canAddFeatureDetail: boolean;
              canRemoveFeatureDetail: boolean;
              canTranslate: boolean;
              canEditTranslations: boolean;
              canSeeUnclassifiedMappings: boolean;
              canEditUnclassifiedMappings: boolean;
            };
          };
          specsSpotlight?:
            | {
                __typename?: 'SpecSpotlight';
                data: {
                  __typename?: 'SpecSpotlightData';
                  topHighlights: Array<string>;
                  topFeatureDetails: Array<string>;
                };
                specSpotlightOptions: {
                  __typename?: 'SpecSpotlightOptions';
                  topHighlightsOptions: Array<{
                    __typename?: 'OptionStringItem';
                    label: string;
                    value: string;
                  }>;
                  topFeaturesOptions: Array<{
                    __typename?: 'OptionStringItem';
                    label: string;
                    value: string;
                  }>;
                };
              }
            | null
            | undefined;
        };
        autoscout24?:
          | {
              __typename?: 'Autoscout24';
              data?:
                | {
                    __typename?: 'Autoscout24Data';
                    description?: string | null | undefined;
                    title?: string | null | undefined;
                    images: Array<{
                      __typename?: 'Image';
                      id: string;
                      original: string;
                      thumbnail: string;
                      uploadedUrl: string;
                      nonRemovable?: boolean | null | undefined;
                      photoId?: number | null | undefined;
                      tags?:
                        | Array<{
                            __typename?: 'Tag';
                            id: string;
                            tagImageId: string;
                            position: {
                              __typename?: 'TagPosition';
                              top: number;
                              left: number;
                            };
                          }>
                        | null
                        | undefined;
                    }>;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        mobiledeV2?:
          | {
              __typename?: 'MobiledeV2';
              data?:
                | {
                    __typename?: 'MobiledeV2Data';
                    description?: string | null | undefined;
                    title?: string | null | undefined;
                    images: Array<{
                      __typename?: 'Image';
                      id: string;
                      original: string;
                      thumbnail: string;
                      uploadedUrl: string;
                      nonRemovable?: boolean | null | undefined;
                      photoId?: number | null | undefined;
                      tags?:
                        | Array<{
                            __typename?: 'Tag';
                            id: string;
                            tagImageId: string;
                            position: {
                              __typename?: 'TagPosition';
                              top: number;
                              left: number;
                            };
                          }>
                        | null
                        | undefined;
                    }>;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        externalUrls?:
          | {
              __typename?: 'ExternalUrls';
              data?:
                | {
                    __typename?: 'RetailAdUrl';
                    url?: string | null | undefined;
                    type: UrlType;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UnpublishAdMutationVariables = Exact<{
  adId: Scalars['UUID'];
  marketplaceId: Scalars['String'];
  accountIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type UnpublishAdMutation = {
  __typename?: 'Mutation';
  unpublishAd: {
    __typename?: 'ExportItem';
    daysOnline?: number | null | undefined;
    firstPublishedAt?: string | null | undefined;
    updatedAt?: string | null | undefined;
    firstEligibleToBePurchasedAt?: string | null | undefined;
    publishingStatus?: PublishingStatus | null | undefined;
    canPublish: boolean;
    comingSoon?: boolean | null | undefined;
    marketplace: { __typename?: 'Marketplace'; title: string; id: string };
    lastPublishingErrors: Array<{
      __typename?: 'PublishingError';
      createdAt: string;
      message?: string | null | undefined;
    }>;
    links: Array<{ __typename?: 'ExternalLink'; title: string; url: string }>;
    exportListings?:
      | {
          __typename?: 'ExportListings';
          listings: Array<{
            __typename?: 'ExportListing';
            daysOnline?: number | null | undefined;
            firstPublishedAt?: string | null | undefined;
            lastPublishedAt?: string | null | undefined;
            publishingStatus: ExportListingState;
            lastPublishingErrors: Array<{
              __typename?: 'PublishingError';
              createdAt: string;
              message?: string | null | undefined;
            }>;
            account: {
              __typename?: 'ExportAccount';
              id: string;
              title: string;
            };
            link?:
              | { __typename?: 'ExternalLink'; title: string; url: string }
              | null
              | undefined;
          }>;
        }
      | null
      | undefined;
  };
};

export type UpdatePreparationCommentMutationVariables = Exact<{
  adId: Scalars['UUID'];
  comment?: InputMaybe<Scalars['String']>;
}>;

export type UpdatePreparationCommentMutation = {
  __typename?: 'Mutation';
  updatePreparationComment: {
    __typename?: 'AdPreparation';
    comment?: string | null | undefined;
  };
};

export type UpdatePreparationStateMutationVariables = Exact<{
  adId: Scalars['UUID'];
  preparationState: Scalars['String'];
}>;

export type UpdatePreparationStateMutation = {
  __typename?: 'Mutation';
  updatePreparationState: {
    __typename?: 'AdPreparation';
    preparationState: string;
    preparationStateOptions: Array<{
      __typename?: 'OptionStringItem';
      label: string;
      value: string;
    }>;
  };
};

export type CheckVersionQueryVariables = Exact<{
  adId: Scalars['UUID'];
}>;

export type CheckVersionQuery = {
  __typename?: 'Query';
  adMgmt: {
    __typename?: 'AdMgmt';
    version: number;
    autoheroAndWkda: {
      __typename?: 'AutoheroAndWkda';
      media: {
        __typename?: 'Media';
        data?:
          | { __typename?: 'MediaData'; checksum?: string | null | undefined }
          | null
          | undefined;
      };
    };
  };
};

export type GenerateDescriptionQueryVariables = Exact<{
  adId: Scalars['UUID'];
  marketplaceId: Scalars['String'];
}>;

export type GenerateDescriptionQuery = {
  __typename?: 'Query';
  generateDescription: {
    __typename?: 'ExportDescription';
    description: string;
    marketplace: { __typename?: 'Marketplace'; id: string; title: string };
  };
};

export type GetDataQueryVariables = Exact<{
  adId: Scalars['UUID'];
  priceChangelogPage: Scalars['Int'];
  priceChangelogPageSize: Scalars['Int'];
}>;

export type GetDataQuery = {
  __typename?: 'Query';
  adMgmt: {
    __typename?: 'AdMgmt';
    id: string;
    title: string;
    country: string;
    currencyCode: string;
    version: number;
    general: {
      __typename?: 'GeneralSection';
      data: {
        __typename?: 'GeneralSectionData';
        title?: string | null | undefined;
        subtitle?: string | null | undefined;
        description?: string | null | undefined;
        manufacturer?: string | null | undefined;
        model?: string | null | undefined;
        datModel?: string | null | undefined;
        subType?: string | null | undefined;
        subTypeExtra?: string | null | undefined;
        status?: string | null | undefined;
        stockNumber?: string | null | undefined;
        vin?: string | null | undefined;
        builtYear?: number | null | undefined;
        grossPriceMajorUnits?: number | null | undefined;
        netPriceMajorUnits?: number | null | undefined;
        expectedSalesPriceMajorUnits?: number | null | undefined;
        vatAmount?: number | null | undefined;
        vatType?: string | null | undefined;
        horsePower?: number | null | undefined;
        bodyType?: number | null | undefined;
        gearType?: number | null | undefined;
        primaryWheelSeason?: Season | null | undefined;
        registration?: string | null | undefined;
        seatCount?: number | null | undefined;
        handoverKeyCount?: number | null | undefined;
        manufacturerCode?: string | null | undefined;
        modelCode?: string | null | undefined;
        carType?: string | null | undefined;
        taxRating?: number | null | undefined;
        bpmMajorUnits?: number | null | undefined;
        financedPriceMajorUnits?: number | null | undefined;
        carPreownerCount?: number | null | undefined;
        countryOfOrigin?: string | null | undefined;
        licensePlate?: string | null | undefined;
        retailCountryLicensePlate?: string | null | undefined;
        lastServiceOn?: string | null | undefined;
        inspectionExpiryDate?: string | null | undefined;
        originalEngine?: boolean | null | undefined;
        carAttrAccidentBool?: boolean | null | undefined;
        wasInCommercialUse?: boolean | null | undefined;
        fuelType?: string | null | undefined;
        energyEfficiencyClass?: string | null | undefined;
        emissionClass?: string | null | undefined;
        color?: number | null | undefined;
        metallic?: boolean | null | undefined;
        upholstery?: number | null | undefined;
        upholsteryOriginal?: boolean | null | undefined;
        co2Value?: number | null | undefined;
        kw?: number | null | undefined;
        ccm?: number | null | undefined;
        emissionSticker?: string | null | undefined;
        availableAtShowroom?: boolean | null | undefined;
        branchName?: string | null | undefined;
        localRetail?: boolean | null | undefined;
        upholsteryColor?: string | null | undefined;
        driveTrain?: string | null | undefined;
        doorCount?: number | null | undefined;
        hasFullServiceHistory?: boolean | null | undefined;
        oemPaintName?: string | null | undefined;
        isNonSmokerVehicle?: boolean | null | undefined;
        trimLevel?: string | null | undefined;
        mileage?:
          | {
              __typename?: 'MileageValue';
              distance: number;
              unit?: MileageUnit | null | undefined;
            }
          | null
          | undefined;
        fuelConsumption: {
          __typename?: 'FuelConsumption';
          city?: number | null | undefined;
          highway?: number | null | undefined;
          combined?: number | null | undefined;
          common?: number | null | undefined;
        };
        serviceHistory?:
          | {
              __typename?: 'ServiceHistory';
              id: string;
              records: Array<{
                __typename?: 'ServiceHistoryRecord';
                id: string;
                lastServiceOn: string;
                lastServiceMileage: number;
                servicePerformedBy?: string | null | undefined;
                tasksPerformed?: Array<string> | null | undefined;
                documentType?:
                  | Array<ServiceHistoryDocumentType>
                  | null
                  | undefined;
                editable: boolean;
                type?: string | null | undefined;
                documents: Array<{
                  __typename?: 'ServiceHistoryDocument';
                  id: string;
                  fileType: ServiceHistoryDocumentFileType;
                  url: string;
                  thumbnailUrl: string;
                }>;
              }>;
              documents?:
                | Array<{
                    __typename?: 'ServiceHistoryDocument';
                    id: string;
                    fileType: ServiceHistoryDocumentFileType;
                    url: string;
                    thumbnailUrl: string;
                  }>
                | null
                | undefined;
            }
          | null
          | undefined;
        partner?: { __typename?: 'Partner'; name: string } | null | undefined;
        uniqueSellingPropositions?:
          | {
              __typename?: 'UniqueSellingPropositions';
              usp1?: string | null | undefined;
              usp2?: string | null | undefined;
              usp3?: string | null | undefined;
            }
          | null
          | undefined;
        carAccidentDamages?:
          | {
              __typename?: 'CarAccident';
              accidentArea: Array<string | null | undefined>;
              accidentDamages: Array<
                | {
                    __typename?: 'CarAccidentDamage';
                    id: string;
                    carPart: string;
                    damageType: string;
                    attributes?: string | null | undefined;
                    showToCustomer: boolean;
                    isRepaired?: boolean | null | undefined;
                  }
                | null
                | undefined
              >;
            }
          | null
          | undefined;
      };
      restrictions: {
        __typename?: 'GeneralSectionRestrictions';
        canSeeCarType: boolean;
        canSeeTaxRating: boolean;
        canSeeManufacturerCode: boolean;
        canSeeModelCode: boolean;
        canSeeBpm: boolean;
        canSeeFinancedPrice: boolean;
        canSeeLicensePlate: boolean;
        canSeeRetailCountryLicensePlate: boolean;
        canEditVehicleFields: boolean;
        canEditGrossPrice: boolean;
        preSignedUrlGenerationEnabled?: boolean | null | undefined;
        savingActivityIntervalInSeconds?: number | null | undefined;
        canSeeEmissionSticker: boolean;
        canEditEmissionSticker: boolean;
      };
      additionalVehicleData: Array<{
        __typename?: 'AdditionalVehicleData';
        label: string;
        value: string;
      }>;
    };
    exportOverview: {
      __typename?: 'ExportOverview';
      data: {
        __typename?: 'ExportData';
        publishingBlockers: Array<{
          __typename?: 'PublishingBlocker';
          reasonId: string;
          subReasonId: string;
          comment: string;
        }>;
      };
      exportItems: Array<{
        __typename?: 'ExportItem';
        daysOnline?: number | null | undefined;
        firstPublishedAt?: string | null | undefined;
        updatedAt?: string | null | undefined;
        firstEligibleToBePurchasedAt?: string | null | undefined;
        publishingStatus?: PublishingStatus | null | undefined;
        canPublish: boolean;
        comingSoon?: boolean | null | undefined;
        marketplace: { __typename?: 'Marketplace'; title: string; id: string };
        lastPublishingErrors: Array<{
          __typename?: 'PublishingError';
          createdAt: string;
          message?: string | null | undefined;
        }>;
        links: Array<{
          __typename?: 'ExternalLink';
          title: string;
          url: string;
        }>;
        exportListings?:
          | {
              __typename?: 'ExportListings';
              listings: Array<{
                __typename?: 'ExportListing';
                daysOnline?: number | null | undefined;
                firstPublishedAt?: string | null | undefined;
                lastPublishedAt?: string | null | undefined;
                publishingStatus: ExportListingState;
                lastPublishingErrors: Array<{
                  __typename?: 'PublishingError';
                  createdAt: string;
                  message?: string | null | undefined;
                }>;
                account: {
                  __typename?: 'ExportAccount';
                  id: string;
                  title: string;
                };
                link?:
                  | { __typename?: 'ExternalLink'; title: string; url: string }
                  | null
                  | undefined;
              }>;
            }
          | null
          | undefined;
      }>;
      restrictions: {
        __typename?: 'ExportRestrictions';
        canAddPublishingBlocker: boolean;
        canRemovePublishingBlocker: boolean;
      };
    };
    dictionaries: {
      __typename?: 'Dictionaries';
      autoscout24?:
        | {
            __typename?: 'Autoscout24Dictionaries';
            marketplaceAccountOptions: Array<{
              __typename?: 'MarketplaceAccountOptions';
              label: string;
              value: string;
            }>;
          }
        | null
        | undefined;
      global: {
        __typename?: 'GlobalDictionaries';
        supportedLanguageLocales: Array<string>;
        publishingBlockersReasons: Array<{
          __typename?: 'PublishingBlockerReason';
          label: string;
          value: string;
          subReasons: Array<{
            __typename?: 'PublishingBlockerSubreason';
            label: string;
            value: string;
            publishingContexts: Array<string>;
          }>;
        }>;
        bodyTypeOptions: Array<{
          __typename?: 'OptionNumberItem';
          label: string;
          value: number;
        }>;
        fuelTypeOptions: Array<{
          __typename?: 'OptionStringItem';
          label: string;
          value: string;
        }>;
        gearTypeOptions: Array<{
          __typename?: 'OptionNumberItem';
          label: string;
          value: number;
        }>;
        primaryWheelSeasonOptions: Array<{
          __typename?: 'OptionStringItem';
          label: string;
          value: string;
        }>;
        taxRatingOptions: Array<{
          __typename?: 'OptionNumberItem';
          label: string;
          value: number;
        }>;
        vatTypeOptions: Array<{
          __typename?: 'OptionNumberItem';
          label: string;
          value: number;
        }>;
        carTypeOptions: Array<{
          __typename?: 'OptionStringItem';
          label: string;
          value: string;
        }>;
        secondaryWheelsTreadDepth: Array<{
          __typename?: 'OptionNumberNullableItem';
          label: string;
          value?: number | null | undefined;
        }>;
        secondaryWheelsTiresDetails: Array<{
          __typename?: 'OptionStringItem';
          label: string;
          value: string;
        }>;
        secondaryWheelsSeasonType: Array<{
          __typename?: 'OptionStringItem';
          label: string;
          value: string;
        }>;
        secondaryWheelsRimsDetails: Array<{
          __typename?: 'OptionStringItem';
          label: string;
          value: string;
        }>;
        secondaryWheelsRadius: Array<{
          __typename?: 'OptionNumberNullableItem';
          label: string;
          value?: number | null | undefined;
        }>;
        secondaryWheelsMaterial: Array<{
          __typename?: 'OptionStringItem';
          label: string;
          value: string;
        }>;
        secondaryWheelsDamageOptions: Array<{
          __typename?: 'OptionStringItem';
          label: string;
          value: string;
        }>;
        serviceHistoryTaskTypes: Array<{
          __typename?: 'ServiceHistoryTaskType';
          label: string;
          value: string;
        }>;
        emissionStickers: Array<{
          __typename?: 'OptionStringItem';
          label: string;
          value: string;
        }>;
        efficiencyClasses: Array<{
          __typename?: 'OptionStringItem';
          label: string;
          value: string;
        }>;
        modelOptions: Array<{
          __typename?: 'OptionStringItem';
          label: string;
          value: string;
        }>;
        subtypeOptions: Array<{
          __typename?: 'ModelSubtypeItem';
          model: string;
          subtypes: Array<{
            __typename?: 'OptionStringItem';
            label: string;
            value: string;
          }>;
        }>;
        emissionClasses: Array<{
          __typename?: 'OptionStringItem';
          label: string;
          value: string;
        }>;
        upholsteryOptions: Array<{
          __typename?: 'OptionNumberItem';
          label: string;
          value: number;
        }>;
        upholsteryColor: Array<{
          __typename?: 'OptionStringItem';
          label: string;
          value: string;
        }>;
        driveTrain: Array<{
          __typename?: 'OptionStringItem';
          label: string;
          value: string;
        }>;
        fieldLabels: Array<{
          __typename?: 'FieldLabel';
          name: string;
          label: string;
        }>;
        colorOptions: Array<{
          __typename?: 'OptionNumberItem';
          label: string;
          value: number;
        }>;
        featureDetailOptions: Array<{
          __typename?: 'FeatureDetailGroupCategoryList';
          locale: string;
          featureDetailGroupCategory: Array<{
            __typename?: 'FeatureDetailGroupCategory';
            id: string;
            name: string;
            featureDetailGroupOptions: Array<{
              __typename?: 'FeatureDetailGroupOption';
              label: string;
              id: string;
              subGroupOptions: Array<{
                __typename?: 'OptionStringItem';
                label: string;
                value: string;
              }>;
            }>;
          }>;
        }>;
        customFeatureTranslationConfigs: Array<{
          __typename?: 'CustomFeatureTranslationConfig';
          featureGroup: string;
          featureSubGroup?: string | null | undefined;
          order: number;
          defaultVisible: boolean;
          classifieds: Array<string>;
          category: {
            __typename?: 'CustomTranslationCategory';
            id: string;
            name: string;
          };
          subcategory?:
            | {
                __typename?: 'CustomTranslationCategory';
                id: string;
                name: string;
              }
            | null
            | undefined;
          translations: Array<{
            __typename?: 'Translation';
            locale: string;
            text: string;
          }>;
        }>;
        trimLevels: Array<{
          __typename?: 'OptionStringItem';
          label: string;
          value: string;
        }>;
        grossPriceChangeReasons: Array<{
          __typename?: 'OptionStringItem';
          value: string;
          label: string;
        }>;
        serviceHistoryConfigTasks: Array<{
          __typename?: 'ServiceHistoryConfigTask';
          value: string;
          label: string;
          type: string;
        }>;
      };
      mobiledeV2?:
        | {
            __typename?: 'MobiledeV2Dictionaries';
            marketplaceAccountOptions: Array<{
              __typename?: 'MarketplaceAccountOptions';
              label: string;
              value: string;
            }>;
          }
        | null
        | undefined;
    };
    autoheroAndWkda: {
      __typename?: 'AutoheroAndWkda';
      imperfections?:
        | {
            __typename?: 'Imperfections';
            items?:
              | Array<{
                  __typename?: 'ImperfectionItem';
                  id: string;
                  category?: string | null | undefined;
                  part: Array<{
                    __typename?: 'ImperfectionItemPart';
                    text: string;
                    locale: string;
                  }>;
                  imperfectionType: Array<{
                    __typename?: 'ImperfectionItemType';
                    text: string;
                    locale: string;
                  }>;
                  image: {
                    __typename?: 'Image';
                    id: string;
                    original: string;
                    thumbnail: string;
                    uploadedUrl: string;
                    nonRemovable?: boolean | null | undefined;
                    photoId?: number | null | undefined;
                    tags?:
                      | Array<{
                          __typename?: 'Tag';
                          id: string;
                          tagImageId: string;
                          position: {
                            __typename?: 'TagPosition';
                            top: number;
                            left: number;
                          };
                        }>
                      | null
                      | undefined;
                  };
                }>
              | null
              | undefined;
          }
        | null
        | undefined;
      media: {
        __typename?: 'Media';
        data?:
          | {
              __typename?: 'MediaData';
              checksum?: string | null | undefined;
              video?:
                | {
                    __typename?: 'Video';
                    originalUrl: string;
                    createdAt?: string | null | undefined;
                    resizedVideos: Array<{
                      __typename?: 'ResizedVideo';
                      resolution: string;
                      url: string;
                    }>;
                  }
                | null
                | undefined;
              images: {
                __typename?: 'MediaImages';
                exterior: Array<{
                  __typename?: 'Image';
                  id: string;
                  original: string;
                  thumbnail: string;
                  uploadedUrl: string;
                  nonRemovable?: boolean | null | undefined;
                  photoId?: number | null | undefined;
                  tags?:
                    | Array<{
                        __typename?: 'Tag';
                        id: string;
                        tagImageId: string;
                        position: {
                          __typename?: 'TagPosition';
                          top: number;
                          left: number;
                        };
                      }>
                    | null
                    | undefined;
                }>;
                interior: Array<{
                  __typename?: 'Image';
                  id: string;
                  original: string;
                  thumbnail: string;
                  uploadedUrl: string;
                  nonRemovable?: boolean | null | undefined;
                  photoId?: number | null | undefined;
                  tags?:
                    | Array<{
                        __typename?: 'Tag';
                        id: string;
                        tagImageId: string;
                        position: {
                          __typename?: 'TagPosition';
                          top: number;
                          left: number;
                        };
                      }>
                    | null
                    | undefined;
                }>;
                panorama: Array<{
                  __typename?: 'Image';
                  id: string;
                  original: string;
                  thumbnail: string;
                  uploadedUrl: string;
                  nonRemovable?: boolean | null | undefined;
                  photoId?: number | null | undefined;
                  tags?:
                    | Array<{
                        __typename?: 'Tag';
                        id: string;
                        tagImageId: string;
                        position: {
                          __typename?: 'TagPosition';
                          top: number;
                          left: number;
                        };
                      }>
                    | null
                    | undefined;
                }>;
                nextgen_highlight: Array<{
                  __typename?: 'Image';
                  id: string;
                  original: string;
                  thumbnail: string;
                  uploadedUrl: string;
                  nonRemovable?: boolean | null | undefined;
                  photoId?: number | null | undefined;
                  tags?:
                    | Array<{
                        __typename?: 'Tag';
                        id: string;
                        tagImageId: string;
                        position: {
                          __typename?: 'TagPosition';
                          top: number;
                          left: number;
                        };
                      }>
                    | null
                    | undefined;
                }>;
              };
            }
          | null
          | undefined;
        restrictions: {
          __typename?: 'MediaRestrictions';
          canAddImages: boolean;
          canRemoveImages: boolean;
          canSortImages: boolean;
          canEditTags: boolean;
          canRemoveTags: boolean;
          canRotateImperfectionImage: boolean;
          canSelectImperfectionImage: boolean;
        };
      };
      secondaryWheels?:
        | {
            __typename?: 'SecondaryWheels';
            data?:
              | {
                  __typename?: 'SecondaryWheelsData';
                  damageCondition: Damage_Condition;
                  grossPrice?: number | null | undefined;
                  netPrice?: number | null | undefined;
                  vatRate?: number | null | undefined;
                  isActive: boolean;
                  isShippingRequired: boolean;
                  location?: string | null | undefined;
                  dot: {
                    __typename?: 'StringWheelsAttribute';
                    frontLeft?: string | null | undefined;
                    frontRight?: string | null | undefined;
                    rearLeft?: string | null | undefined;
                    rearRight?: string | null | undefined;
                  };
                  manufacturer: {
                    __typename?: 'StringWheelsAttribute';
                    frontLeft?: string | null | undefined;
                    frontRight?: string | null | undefined;
                    rearLeft?: string | null | undefined;
                    rearRight?: string | null | undefined;
                  };
                  material: {
                    __typename?: 'Material';
                    frontLeft?: Rim_Material | null | undefined;
                    frontRight?: Rim_Material | null | undefined;
                    rearLeft?: Rim_Material | null | undefined;
                    rearRight?: Rim_Material | null | undefined;
                  };
                  radius: {
                    __typename?: 'IntWheelsAttribute';
                    frontLeft?: number | null | undefined;
                    frontRight?: number | null | undefined;
                    rearLeft?: number | null | undefined;
                    rearRight?: number | null | undefined;
                  };
                  wheelWidth: {
                    __typename?: 'IntWheelsAttribute';
                    frontLeft?: number | null | undefined;
                    frontRight?: number | null | undefined;
                    rearLeft?: number | null | undefined;
                    rearRight?: number | null | undefined;
                  };
                  wheelHeight: {
                    __typename?: 'IntWheelsAttribute';
                    frontLeft?: number | null | undefined;
                    frontRight?: number | null | undefined;
                    rearLeft?: number | null | undefined;
                    rearRight?: number | null | undefined;
                  };
                  loadIndex: {
                    __typename?: 'IntWheelsAttribute';
                    frontLeft?: number | null | undefined;
                    frontRight?: number | null | undefined;
                    rearLeft?: number | null | undefined;
                    rearRight?: number | null | undefined;
                  };
                  speedIndex: {
                    __typename?: 'StringWheelsAttribute';
                    frontLeft?: string | null | undefined;
                    frontRight?: string | null | undefined;
                    rearLeft?: string | null | undefined;
                    rearRight?: string | null | undefined;
                  };
                  seasonType: {
                    __typename?: 'SeasonType';
                    frontLeft?: Season | null | undefined;
                    frontRight?: Season | null | undefined;
                    rearLeft?: Season | null | undefined;
                    rearRight?: Season | null | undefined;
                  };
                  treadDepth: {
                    __typename?: 'IntWheelsAttribute';
                    frontLeft?: number | null | undefined;
                    frontRight?: number | null | undefined;
                    rearLeft?: number | null | undefined;
                    rearRight?: number | null | undefined;
                  };
                  rimsDetails: {
                    __typename?: 'RimsDetails';
                    frontLeft: Array<{
                      __typename?: 'DamageItem';
                      label: string;
                      value: string;
                    }>;
                    frontRight: Array<{
                      __typename?: 'DamageItem';
                      label: string;
                      value: string;
                    }>;
                    rearLeft: Array<{
                      __typename?: 'DamageItem';
                      label: string;
                      value: string;
                    }>;
                    rearRight: Array<{
                      __typename?: 'DamageItem';
                      label: string;
                      value: string;
                    }>;
                  };
                  tiresDetails: {
                    __typename?: 'TiresDetails';
                    frontLeft: Array<{
                      __typename?: 'DamageItem';
                      label: string;
                      value: string;
                    }>;
                    frontRight: Array<{
                      __typename?: 'DamageItem';
                      label: string;
                      value: string;
                    }>;
                    rearLeft: Array<{
                      __typename?: 'DamageItem';
                      label: string;
                      value: string;
                    }>;
                    rearRight: Array<{
                      __typename?: 'DamageItem';
                      label: string;
                      value: string;
                    }>;
                  };
                  ad_secondary_wheels: Array<{
                    __typename?: 'Image';
                    id: string;
                    original: string;
                    thumbnail: string;
                    uploadedUrl: string;
                  }>;
                  ad_secondary_wheels_damages: Array<{
                    __typename?: 'Image';
                    id: string;
                    original: string;
                    thumbnail: string;
                    uploadedUrl: string;
                  }>;
                }
              | null
              | undefined;
            restrictions?:
              | {
                  __typename?: 'SecondaryWheelsRestrictions';
                  canEditPrice: boolean;
                  canEditTableFields: boolean;
                  canEditSWImages: boolean;
                }
              | null
              | undefined;
          }
        | null
        | undefined;
      featureDetails: {
        __typename?: 'FeatureDetails';
        data: Array<{
          __typename?: 'FeatureDetailCategory';
          id: string;
          name: string;
          items: Array<{
            __typename?: 'FeatureDetailItem';
            uiFeatureDetailIdentifier?: string | null | undefined;
            id: string;
            name: string;
            isOriginal: boolean;
            isNewTranslation?: boolean | null | undefined;
            order?: number | null | undefined;
            type?: FeatureDetailType | null | undefined;
            price?: number | null | undefined;
            classifieds: Array<string>;
            translations: Array<{
              __typename?: 'FeatureDetailTranslation';
              locale: string;
              text: string;
            }>;
            subGroup?:
              | {
                  __typename?: 'FeatureDetailGroup';
                  name: string;
                  translatedName: string;
                }
              | null
              | undefined;
            group: {
              __typename?: 'FeatureDetailGroup';
              name: string;
              translatedName: string;
            };
            subcategory?:
              | {
                  __typename?: 'FeatureDetailSubcategory';
                  id: string;
                  name: string;
                }
              | null
              | undefined;
          }>;
        }>;
        restrictions: {
          __typename?: 'FeatureDetailRestrictions';
          canAddFeatureDetail: boolean;
          canRemoveFeatureDetail: boolean;
          canTranslate: boolean;
          canEditTranslations: boolean;
          canSeeUnclassifiedMappings: boolean;
          canEditUnclassifiedMappings: boolean;
        };
      };
      specsSpotlight?:
        | {
            __typename?: 'SpecSpotlight';
            data: {
              __typename?: 'SpecSpotlightData';
              topHighlights: Array<string>;
              topFeatureDetails: Array<string>;
            };
            specSpotlightOptions: {
              __typename?: 'SpecSpotlightOptions';
              topHighlightsOptions: Array<{
                __typename?: 'OptionStringItem';
                label: string;
                value: string;
              }>;
              topFeaturesOptions: Array<{
                __typename?: 'OptionStringItem';
                label: string;
                value: string;
              }>;
            };
          }
        | null
        | undefined;
    };
    autoscout24?:
      | {
          __typename?: 'Autoscout24';
          data?:
            | {
                __typename?: 'Autoscout24Data';
                description?: string | null | undefined;
                title?: string | null | undefined;
                imagesLimit: number;
                images: Array<{
                  __typename?: 'Image';
                  id: string;
                  original: string;
                  thumbnail: string;
                  uploadedUrl: string;
                  nonRemovable?: boolean | null | undefined;
                  photoId?: number | null | undefined;
                  tags?:
                    | Array<{
                        __typename?: 'Tag';
                        id: string;
                        tagImageId: string;
                        position: {
                          __typename?: 'TagPosition';
                          top: number;
                          left: number;
                        };
                      }>
                    | null
                    | undefined;
                }>;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    mobiledeV2?:
      | {
          __typename?: 'MobiledeV2';
          data?:
            | {
                __typename?: 'MobiledeV2Data';
                description?: string | null | undefined;
                title?: string | null | undefined;
                imagesLimit: number;
                images: Array<{
                  __typename?: 'Image';
                  id: string;
                  original: string;
                  thumbnail: string;
                  uploadedUrl: string;
                  nonRemovable?: boolean | null | undefined;
                  photoId?: number | null | undefined;
                  tags?:
                    | Array<{
                        __typename?: 'Tag';
                        id: string;
                        tagImageId: string;
                        position: {
                          __typename?: 'TagPosition';
                          top: number;
                          left: number;
                        };
                      }>
                    | null
                    | undefined;
                }>;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    externalUrls?:
      | {
          __typename?: 'ExternalUrls';
          data?:
            | {
                __typename?: 'RetailAdUrl';
                label: string;
                url?: string | null | undefined;
                type: UrlType;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    priceManagementGroup?:
      | { __typename?: 'PriceManagementGroup'; label: string }
      | null
      | undefined;
  };
  pricingChangelog: {
    __typename?: 'PricingChangelog';
    data: {
      __typename?: 'PricingChangelogData';
      page: number;
      pageSize: number;
      totalEntityCount: number;
      totalPageCount: number;
      items: Array<{
        __typename?: 'PricingChangelogItem';
        changedBy: string;
        stockNumber: string;
        changedAt: string;
        previousPrice?: string | null | undefined;
        newPrice?: string | null | undefined;
        changeReason: string;
        changeComment?: string | null | undefined;
      }>;
    };
  };
};

export type GetExportItemsQueryVariables = Exact<{
  adId: Scalars['UUID'];
}>;

export type GetExportItemsQuery = {
  __typename?: 'Query';
  adMgmt: {
    __typename?: 'AdMgmt';
    exportOverview: {
      __typename?: 'ExportOverview';
      exportItems: Array<{
        __typename?: 'ExportItem';
        daysOnline?: number | null | undefined;
        firstPublishedAt?: string | null | undefined;
        updatedAt?: string | null | undefined;
        firstEligibleToBePurchasedAt?: string | null | undefined;
        publishingStatus?: PublishingStatus | null | undefined;
        canPublish: boolean;
        comingSoon?: boolean | null | undefined;
        marketplace: { __typename?: 'Marketplace'; title: string; id: string };
        lastPublishingErrors: Array<{
          __typename?: 'PublishingError';
          createdAt: string;
          message?: string | null | undefined;
        }>;
        links: Array<{
          __typename?: 'ExternalLink';
          title: string;
          url: string;
        }>;
        exportListings?:
          | {
              __typename?: 'ExportListings';
              listings: Array<{
                __typename?: 'ExportListing';
                daysOnline?: number | null | undefined;
                firstPublishedAt?: string | null | undefined;
                lastPublishedAt?: string | null | undefined;
                publishingStatus: ExportListingState;
                lastPublishingErrors: Array<{
                  __typename?: 'PublishingError';
                  createdAt: string;
                  message?: string | null | undefined;
                }>;
                account: {
                  __typename?: 'ExportAccount';
                  id: string;
                  title: string;
                };
                link?:
                  | { __typename?: 'ExternalLink'; title: string; url: string }
                  | null
                  | undefined;
              }>;
            }
          | null
          | undefined;
      }>;
    };
  };
};

export type GetAdPreparationQueryVariables = Exact<{
  adId: Scalars['UUID'];
}>;

export type GetAdPreparationQuery = {
  __typename?: 'Query';
  adPreparation: {
    __typename?: 'AdPreparation';
    preparationState: string;
    tuningComment?: string | null | undefined;
    isFinanced?: boolean | null | undefined;
    comment?: string | null | undefined;
    preparationStateOptions: Array<{
      __typename?: 'OptionStringItem';
      label: string;
      value: string;
    }>;
    explainerLinks: Array<{
      __typename?: 'ExplainerLink';
      label: string;
      text?: string | null | undefined;
      url?: string | null | undefined;
    }>;
  };
};

export type GetPreviewUrlQueryVariables = Exact<{
  uploadId: Scalars['String'];
}>;

export type GetPreviewUrlQuery = {
  __typename?: 'Query';
  previewUrl: { __typename?: 'PreviewUrl'; url?: string | null | undefined };
};

export type GetPriceChangelogDataQueryVariables = Exact<{
  adId: Scalars['UUID'];
  priceChangelogPage: Scalars['Int'];
  priceChangelogPageSize: Scalars['Int'];
}>;

export type GetPriceChangelogDataQuery = {
  __typename?: 'Query';
  pricingChangelog: {
    __typename?: 'PricingChangelog';
    data: {
      __typename?: 'PricingChangelogData';
      page: number;
      pageSize: number;
      totalEntityCount: number;
      totalPageCount: number;
      items: Array<{
        __typename?: 'PricingChangelogItem';
        changedBy: string;
        stockNumber: string;
        changedAt: string;
        previousPrice?: string | null | undefined;
        newPrice?: string | null | undefined;
        changeReason: string;
        changeComment?: string | null | undefined;
      }>;
    };
  };
};

export type GetServiceHistoryDocumentUploadPresignedUrlQueryVariables = Exact<{
  uploadId: Scalars['String'];
  fileExt: Scalars['String'];
}>;

export type GetServiceHistoryDocumentUploadPresignedUrlQuery = {
  __typename?: 'Query';
  getServiceHistoryDocumentUploadPresignedUrl: {
    __typename?: 'UploadPresignedUrl';
    bucket: string;
    url: string;
    headers?:
      | Array<{
          __typename?: 'UploadHeader';
          name: string;
          values: Array<string>;
        }>
      | null
      | undefined;
  };
};

export type PublisherEventTrackingUploadPreSignedUrlQueryVariables = Exact<{
  [key: string]: never;
}>;

export type PublisherEventTrackingUploadPreSignedUrlQuery = {
  __typename?: 'Query';
  data: {
    __typename?: 'PublisherEventTrackingUploadPreSignedUrl';
    url?: string | null | undefined;
    signedHeaders: Array<{
      __typename?: 'AwsSignedHeader';
      key: string;
      value: Array<string>;
    }>;
  };
};

export type FeatureDetails_FragmentFragment = {
  __typename?: 'FeatureDetails';
  data: Array<{
    __typename?: 'FeatureDetailCategory';
    id: string;
    name: string;
    items: Array<{
      __typename?: 'FeatureDetailItem';
      uiFeatureDetailIdentifier?: string | null | undefined;
      id: string;
      name: string;
      isOriginal: boolean;
      isNewTranslation?: boolean | null | undefined;
      order?: number | null | undefined;
      type?: FeatureDetailType | null | undefined;
      price?: number | null | undefined;
      classifieds: Array<string>;
      translations: Array<{
        __typename?: 'FeatureDetailTranslation';
        locale: string;
        text: string;
      }>;
      subGroup?:
        | {
            __typename?: 'FeatureDetailGroup';
            name: string;
            translatedName: string;
          }
        | null
        | undefined;
      group: {
        __typename?: 'FeatureDetailGroup';
        name: string;
        translatedName: string;
      };
      subcategory?:
        | { __typename?: 'FeatureDetailSubcategory'; id: string; name: string }
        | null
        | undefined;
    }>;
  }>;
  restrictions: {
    __typename?: 'FeatureDetailRestrictions';
    canAddFeatureDetail: boolean;
    canRemoveFeatureDetail: boolean;
    canTranslate: boolean;
    canEditTranslations: boolean;
    canSeeUnclassifiedMappings: boolean;
    canEditUnclassifiedMappings: boolean;
  };
};

export const FeatureDetails_FragmentFragmentDoc = `
    fragment FeatureDetails_Fragment on FeatureDetails {
  data {
    id
    name
    items {
      uiFeatureDetailIdentifier
      id
      name
      isOriginal
      translations {
        locale
        text
      }
      subGroup {
        name
        translatedName
      }
      group {
        name
        translatedName
      }
      subcategory {
        id
        name
      }
      isNewTranslation
      order
      type
      price
      classifieds
    }
  }
  restrictions {
    canAddFeatureDetail
    canRemoveFeatureDetail
    canTranslate
    canEditTranslations
    canSeeUnclassifiedMappings
    canEditUnclassifiedMappings
  }
}
    `;
export const PublishAdDocument = `
    mutation PublishAd($adId: UUID!, $marketplaceId: String!, $accountIds: [String!]!) {
  publishAd(adId: $adId, marketplaceId: $marketplaceId, accountIds: $accountIds) {
    marketplace {
      title
      id
    }
    daysOnline
    firstPublishedAt
    updatedAt
    firstEligibleToBePurchasedAt
    lastPublishingErrors {
      createdAt
      message
    }
    links {
      title
      url
    }
    publishingStatus
    canPublish
    comingSoon
    exportListings {
      listings {
        daysOnline
        firstPublishedAt
        lastPublishedAt
        lastPublishingErrors {
          createdAt
          message
        }
        publishingStatus
        account {
          id
          title
        }
        link {
          title
          url
        }
      }
    }
  }
}
    `;
export const usePublishAdMutation = <
  TError = SUCCESS_RESPONSE<null> | FAIL_RESPONSE,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    PublishAdMutation,
    TError,
    PublishAdMutationVariables,
    TContext
  >,
) =>
  useMutation<PublishAdMutation, TError, PublishAdMutationVariables, TContext>(
    ['PublishAd'],
    (variables?: PublishAdMutationVariables) =>
      api<PublishAdMutation, PublishAdMutationVariables>(
        PublishAdDocument,
        variables,
      )(),
    options,
  );
usePublishAdMutation.fetcher = (
  variables: PublishAdMutationVariables,
  options?: RequestInit['headers'],
) =>
  api<PublishAdMutation, PublishAdMutationVariables>(
    PublishAdDocument,
    variables,
    options,
  );
export const SaveDataDocument = `
    mutation SaveData($adId: UUID!, $adMgmtInput: AdMgmtInput!) {
  updateAdMgmt(adId: $adId, adMgmtInput: $adMgmtInput) {
    id
    title
    country
    currencyCode
    version
    general {
      data {
        title
        subtitle
        description
        manufacturer
        model
        datModel
        subType
        subTypeExtra
        status
        stockNumber
        vin
        builtYear
        grossPriceMajorUnits
        netPriceMajorUnits
        expectedSalesPriceMajorUnits
        vatAmount
        vatType
        horsePower
        bodyType
        gearType
        primaryWheelSeason
        registration
        seatCount
        handoverKeyCount
        manufacturerCode
        modelCode
        carType
        taxRating
        bpmMajorUnits
        financedPriceMajorUnits
        mileage {
          distance
          unit
        }
        carPreownerCount
        countryOfOrigin
        licensePlate
        lastServiceOn
        inspectionExpiryDate
        originalEngine
        carAttrAccidentBool
        wasInCommercialUse
        fuelType
        energyEfficiencyClass
        emissionClass
        fuelConsumption {
          city
          highway
          combined
          common
        }
        co2Value
        kw
        ccm
        emissionSticker
        serviceHistory {
          id
          records {
            id
            lastServiceOn
            lastServiceMileage
            servicePerformedBy
            tasksPerformed
            documentType
            editable
            documents {
              id
              fileType
              url
              thumbnailUrl
            }
            type
          }
          documents {
            id
            fileType
            url
            thumbnailUrl
          }
        }
        partner {
          name
        }
        availableAtShowroom
        branchName
        localRetail
        upholsteryColor
        driveTrain
        doorCount
        hasFullServiceHistory
        oemPaintName
        metallic
        uniqueSellingPropositions {
          usp1
          usp2
          usp3
        }
        trimLevel
        carAccidentDamages {
          accidentDamages {
            id
            carPart
            damageType
            attributes
            showToCustomer
            isRepaired
          }
          accidentArea
        }
      }
      restrictions {
        canSeeCarType
        canSeeTaxRating
        canSeeManufacturerCode
        canSeeModelCode
        canSeeBpm
        canSeeFinancedPrice
        canSeeLicensePlate
      }
    }
    exportOverview {
      data {
        publishingBlockers {
          reasonId
          subReasonId
          comment
        }
      }
      exportItems {
        marketplace {
          title
          id
        }
        daysOnline
        firstPublishedAt
        updatedAt
        lastPublishingErrors {
          createdAt
          message
        }
        links {
          title
          url
        }
        publishingStatus
        canPublish
        exportListings {
          listings {
            daysOnline
            firstPublishedAt
            lastPublishedAt
            lastPublishingErrors {
              createdAt
              message
            }
            publishingStatus
            account {
              id
              title
            }
            link {
              title
              url
            }
          }
        }
      }
      restrictions {
        canAddPublishingBlocker
        canRemovePublishingBlocker
      }
    }
    dictionaries {
      autoscout24 {
        marketplaceAccountOptions {
          label
          value
        }
      }
      global {
        publishingBlockersReasons {
          label
          value
          subReasons {
            label
            value
          }
        }
        bodyTypeOptions {
          label
          value
        }
        fuelTypeOptions {
          label
          value
        }
        gearTypeOptions {
          label
          value
        }
        primaryWheelSeasonOptions {
          label
          value
        }
        taxRatingOptions {
          label
          value
        }
        vatTypeOptions {
          label
          value
        }
        carTypeOptions {
          label
          value
        }
        secondaryWheelsTreadDepth {
          label
          value
        }
        secondaryWheelsTiresDetails {
          label
          value
        }
        secondaryWheelsSeasonType {
          label
          value
        }
        secondaryWheelsRimsDetails {
          label
          value
        }
        secondaryWheelsRadius {
          label
          value
        }
        secondaryWheelsMaterial {
          label
          value
        }
        secondaryWheelsDamageOptions {
          label
          value
        }
      }
    }
    autoheroAndWkda {
      media {
        data {
          video {
            originalUrl
            createdAt
            resizedVideos {
              resolution
              url
            }
          }
          images {
            exterior {
              id
              original
              thumbnail
              uploadedUrl
              nonRemovable
              tags {
                id
                tagImageId
                position {
                  top
                  left
                }
              }
              photoId
            }
            interior {
              id
              original
              thumbnail
              uploadedUrl
              nonRemovable
              tags {
                id
                tagImageId
                position {
                  top
                  left
                }
              }
              photoId
            }
            panorama {
              id
              original
              thumbnail
              uploadedUrl
              nonRemovable
              tags {
                id
                tagImageId
                position {
                  top
                  left
                }
              }
              photoId
            }
            nextgen_highlight {
              id
              original
              thumbnail
              uploadedUrl
              nonRemovable
              tags {
                id
                tagImageId
                position {
                  top
                  left
                }
              }
              photoId
            }
          }
          checksum
        }
        restrictions {
          canAddImages
          canRemoveImages
          canSortImages
          canEditTags
          canRemoveTags
          canRotateImperfectionImage
          canSelectImperfectionImage
        }
      }
      imperfections {
        items {
          id
          category
          part {
            text
            locale
          }
          imperfectionType {
            text
            locale
          }
          image {
            id
            original
            thumbnail
            uploadedUrl
            nonRemovable
            tags {
              id
              tagImageId
              position {
                top
                left
              }
            }
            photoId
          }
        }
      }
      secondaryWheels {
        data {
          damageCondition
          grossPrice
          netPrice
          vatRate
          isActive
          isShippingRequired
          location
          dot {
            frontLeft
            frontRight
            rearLeft
            rearRight
          }
          manufacturer {
            frontLeft
            frontRight
            rearLeft
            rearRight
          }
          material {
            frontLeft
            frontRight
            rearLeft
            rearRight
          }
          radius {
            frontLeft
            frontRight
            rearLeft
            rearRight
          }
          seasonType {
            frontLeft
            frontRight
            rearLeft
            rearRight
          }
          treadDepth {
            frontLeft
            frontRight
            rearLeft
            rearRight
          }
          rimsDetails {
            frontLeft {
              label
              value
            }
            frontRight {
              label
              value
            }
            rearLeft {
              label
              value
            }
            rearRight {
              label
              value
            }
          }
          tiresDetails {
            frontLeft {
              label
              value
            }
            frontRight {
              label
              value
            }
            rearLeft {
              label
              value
            }
            rearRight {
              label
              value
            }
          }
          ad_secondary_wheels {
            id
            original
            thumbnail
          }
          ad_secondary_wheels_damages {
            id
            original
            thumbnail
          }
        }
        restrictions {
          canEditPrice
          canEditTableFields
          canEditSWImages
        }
      }
      featureDetails {
        ...FeatureDetails_Fragment
      }
      specsSpotlight {
        data {
          topHighlights
          topFeatureDetails
        }
        specSpotlightOptions {
          topHighlightsOptions {
            label
            value
          }
          topFeaturesOptions {
            label
            value
          }
        }
      }
    }
    autoscout24 {
      data {
        description
        title
        images {
          id
          original
          thumbnail
          uploadedUrl
          nonRemovable
          tags {
            id
            tagImageId
            position {
              top
              left
            }
          }
          photoId
        }
      }
    }
    mobiledeV2 {
      data {
        description
        title
        images {
          id
          original
          thumbnail
          uploadedUrl
          nonRemovable
          tags {
            id
            tagImageId
            position {
              top
              left
            }
          }
          photoId
        }
      }
    }
    externalUrls {
      data {
        url
        type
      }
    }
  }
}
    ${FeatureDetails_FragmentFragmentDoc}`;
export const useSaveDataMutation = <
  TError = SUCCESS_RESPONSE<null> | FAIL_RESPONSE,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    SaveDataMutation,
    TError,
    SaveDataMutationVariables,
    TContext
  >,
) =>
  useMutation<SaveDataMutation, TError, SaveDataMutationVariables, TContext>(
    ['SaveData'],
    (variables?: SaveDataMutationVariables) =>
      api<SaveDataMutation, SaveDataMutationVariables>(
        SaveDataDocument,
        variables,
      )(),
    options,
  );
useSaveDataMutation.fetcher = (
  variables: SaveDataMutationVariables,
  options?: RequestInit['headers'],
) =>
  api<SaveDataMutation, SaveDataMutationVariables>(
    SaveDataDocument,
    variables,
    options,
  );
export const UnpublishAdDocument = `
    mutation UnpublishAd($adId: UUID!, $marketplaceId: String!, $accountIds: [String!]!) {
  unpublishAd(adId: $adId, marketplaceId: $marketplaceId, accountIds: $accountIds) {
    marketplace {
      title
      id
    }
    daysOnline
    firstPublishedAt
    updatedAt
    firstEligibleToBePurchasedAt
    lastPublishingErrors {
      createdAt
      message
    }
    links {
      title
      url
    }
    publishingStatus
    canPublish
    comingSoon
    exportListings {
      listings {
        daysOnline
        firstPublishedAt
        lastPublishedAt
        lastPublishingErrors {
          createdAt
          message
        }
        publishingStatus
        account {
          id
          title
        }
        link {
          title
          url
        }
      }
    }
  }
}
    `;
export const useUnpublishAdMutation = <
  TError = SUCCESS_RESPONSE<null> | FAIL_RESPONSE,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UnpublishAdMutation,
    TError,
    UnpublishAdMutationVariables,
    TContext
  >,
) =>
  useMutation<
    UnpublishAdMutation,
    TError,
    UnpublishAdMutationVariables,
    TContext
  >(
    ['UnpublishAd'],
    (variables?: UnpublishAdMutationVariables) =>
      api<UnpublishAdMutation, UnpublishAdMutationVariables>(
        UnpublishAdDocument,
        variables,
      )(),
    options,
  );
useUnpublishAdMutation.fetcher = (
  variables: UnpublishAdMutationVariables,
  options?: RequestInit['headers'],
) =>
  api<UnpublishAdMutation, UnpublishAdMutationVariables>(
    UnpublishAdDocument,
    variables,
    options,
  );
export const UpdatePreparationCommentDocument = `
    mutation UpdatePreparationComment($adId: UUID!, $comment: String) {
  updatePreparationComment(adId: $adId, comment: $comment) {
    comment
  }
}
    `;
export const useUpdatePreparationCommentMutation = <
  TError = SUCCESS_RESPONSE<null> | FAIL_RESPONSE,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdatePreparationCommentMutation,
    TError,
    UpdatePreparationCommentMutationVariables,
    TContext
  >,
) =>
  useMutation<
    UpdatePreparationCommentMutation,
    TError,
    UpdatePreparationCommentMutationVariables,
    TContext
  >(
    ['UpdatePreparationComment'],
    (variables?: UpdatePreparationCommentMutationVariables) =>
      api<
        UpdatePreparationCommentMutation,
        UpdatePreparationCommentMutationVariables
      >(UpdatePreparationCommentDocument, variables)(),
    options,
  );
useUpdatePreparationCommentMutation.fetcher = (
  variables: UpdatePreparationCommentMutationVariables,
  options?: RequestInit['headers'],
) =>
  api<
    UpdatePreparationCommentMutation,
    UpdatePreparationCommentMutationVariables
  >(UpdatePreparationCommentDocument, variables, options);
export const UpdatePreparationStateDocument = `
    mutation UpdatePreparationState($adId: UUID!, $preparationState: String!) {
  updatePreparationState(adId: $adId, preparationState: $preparationState) {
    preparationState
    preparationStateOptions {
      label
      value
    }
  }
}
    `;
export const useUpdatePreparationStateMutation = <
  TError = SUCCESS_RESPONSE<null> | FAIL_RESPONSE,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdatePreparationStateMutation,
    TError,
    UpdatePreparationStateMutationVariables,
    TContext
  >,
) =>
  useMutation<
    UpdatePreparationStateMutation,
    TError,
    UpdatePreparationStateMutationVariables,
    TContext
  >(
    ['UpdatePreparationState'],
    (variables?: UpdatePreparationStateMutationVariables) =>
      api<
        UpdatePreparationStateMutation,
        UpdatePreparationStateMutationVariables
      >(UpdatePreparationStateDocument, variables)(),
    options,
  );
useUpdatePreparationStateMutation.fetcher = (
  variables: UpdatePreparationStateMutationVariables,
  options?: RequestInit['headers'],
) =>
  api<UpdatePreparationStateMutation, UpdatePreparationStateMutationVariables>(
    UpdatePreparationStateDocument,
    variables,
    options,
  );
export const CheckVersionDocument = `
    query CheckVersion($adId: UUID!) {
  adMgmt(adId: $adId) {
    version
    autoheroAndWkda {
      media {
        data {
          checksum
        }
      }
    }
  }
}
    `;
export const useCheckVersionQuery = <
  TData = CheckVersionQuery,
  TError = SUCCESS_RESPONSE<null> | FAIL_RESPONSE,
>(
  variables: CheckVersionQueryVariables,
  options?: UseQueryOptions<CheckVersionQuery, TError, TData>,
) =>
  useQuery<CheckVersionQuery, TError, TData>(
    ['CheckVersion', variables],
    api<CheckVersionQuery, CheckVersionQueryVariables>(
      CheckVersionDocument,
      variables,
    ),
    options,
  );

useCheckVersionQuery.getKey = (variables: CheckVersionQueryVariables) => [
  'CheckVersion',
  variables,
];
useCheckVersionQuery.fetcher = (
  variables: CheckVersionQueryVariables,
  options?: RequestInit['headers'],
) =>
  api<CheckVersionQuery, CheckVersionQueryVariables>(
    CheckVersionDocument,
    variables,
    options,
  );
export const GenerateDescriptionDocument = `
    query GenerateDescription($adId: UUID!, $marketplaceId: String!) {
  generateDescription(adId: $adId, marketplaceId: $marketplaceId) {
    marketplace {
      id
      title
    }
    description
  }
}
    `;
export const useGenerateDescriptionQuery = <
  TData = GenerateDescriptionQuery,
  TError = SUCCESS_RESPONSE<null> | FAIL_RESPONSE,
>(
  variables: GenerateDescriptionQueryVariables,
  options?: UseQueryOptions<GenerateDescriptionQuery, TError, TData>,
) =>
  useQuery<GenerateDescriptionQuery, TError, TData>(
    ['GenerateDescription', variables],
    api<GenerateDescriptionQuery, GenerateDescriptionQueryVariables>(
      GenerateDescriptionDocument,
      variables,
    ),
    options,
  );

useGenerateDescriptionQuery.getKey = (
  variables: GenerateDescriptionQueryVariables,
) => ['GenerateDescription', variables];
useGenerateDescriptionQuery.fetcher = (
  variables: GenerateDescriptionQueryVariables,
  options?: RequestInit['headers'],
) =>
  api<GenerateDescriptionQuery, GenerateDescriptionQueryVariables>(
    GenerateDescriptionDocument,
    variables,
    options,
  );
export const GetDataDocument = `
    query GetData($adId: UUID!, $priceChangelogPage: Int!, $priceChangelogPageSize: Int!) {
  adMgmt(adId: $adId) {
    id
    title
    country
    currencyCode
    version
    general {
      data {
        title
        subtitle
        description
        manufacturer
        model
        datModel
        subType
        subTypeExtra
        status
        stockNumber
        vin
        builtYear
        grossPriceMajorUnits
        netPriceMajorUnits
        expectedSalesPriceMajorUnits
        vatAmount
        vatType
        horsePower
        bodyType
        gearType
        primaryWheelSeason
        registration
        seatCount
        handoverKeyCount
        manufacturerCode
        modelCode
        carType
        taxRating
        bpmMajorUnits
        financedPriceMajorUnits
        mileage {
          distance
          unit
        }
        carPreownerCount
        countryOfOrigin
        licensePlate
        retailCountryLicensePlate
        lastServiceOn
        inspectionExpiryDate
        originalEngine
        carAttrAccidentBool
        wasInCommercialUse
        fuelType
        energyEfficiencyClass
        emissionClass
        color
        metallic
        upholstery
        upholsteryOriginal
        fuelConsumption {
          city
          highway
          combined
          common
        }
        co2Value
        kw
        ccm
        emissionSticker
        serviceHistory {
          id
          records {
            id
            lastServiceOn
            lastServiceMileage
            servicePerformedBy
            tasksPerformed
            documentType
            editable
            documents {
              id
              fileType
              url
              thumbnailUrl
            }
            type
          }
          documents {
            id
            fileType
            url
            thumbnailUrl
          }
        }
        partner {
          name
        }
        availableAtShowroom
        branchName
        localRetail
        upholsteryColor
        driveTrain
        doorCount
        hasFullServiceHistory
        oemPaintName
        isNonSmokerVehicle
        uniqueSellingPropositions {
          usp1
          usp2
          usp3
        }
        trimLevel
        carAccidentDamages {
          accidentDamages {
            id
            carPart
            damageType
            attributes
            showToCustomer
            isRepaired
          }
          accidentArea
        }
      }
      restrictions {
        canSeeCarType
        canSeeTaxRating
        canSeeManufacturerCode
        canSeeModelCode
        canSeeBpm
        canSeeFinancedPrice
        canSeeLicensePlate
        canSeeRetailCountryLicensePlate
        canEditVehicleFields
        canEditGrossPrice
        preSignedUrlGenerationEnabled
        savingActivityIntervalInSeconds
        canSeeEmissionSticker
        canEditEmissionSticker
      }
      additionalVehicleData {
        label
        value
      }
    }
    exportOverview {
      data {
        publishingBlockers {
          reasonId
          subReasonId
          comment
        }
      }
      exportItems {
        marketplace {
          title
          id
        }
        daysOnline
        firstPublishedAt
        updatedAt
        firstEligibleToBePurchasedAt
        lastPublishingErrors {
          createdAt
          message
        }
        links {
          title
          url
        }
        publishingStatus
        canPublish
        comingSoon
        exportListings {
          listings {
            daysOnline
            firstPublishedAt
            lastPublishedAt
            lastPublishingErrors {
              createdAt
              message
            }
            publishingStatus
            account {
              id
              title
            }
            link {
              title
              url
            }
          }
        }
      }
      restrictions {
        canAddPublishingBlocker
        canRemovePublishingBlocker
      }
    }
    dictionaries {
      autoscout24 {
        marketplaceAccountOptions {
          label
          value
        }
      }
      global {
        publishingBlockersReasons {
          label
          value
          subReasons {
            label
            value
            publishingContexts
          }
        }
        bodyTypeOptions {
          label
          value
        }
        fuelTypeOptions {
          label
          value
        }
        gearTypeOptions {
          label
          value
        }
        primaryWheelSeasonOptions {
          label
          value
        }
        taxRatingOptions {
          label
          value
        }
        vatTypeOptions {
          label
          value
        }
        carTypeOptions {
          label
          value
        }
        secondaryWheelsTreadDepth {
          label
          value
        }
        secondaryWheelsTiresDetails {
          label
          value
        }
        secondaryWheelsSeasonType {
          label
          value
        }
        secondaryWheelsRimsDetails {
          label
          value
        }
        secondaryWheelsRadius {
          label
          value
        }
        secondaryWheelsMaterial {
          label
          value
        }
        secondaryWheelsDamageOptions {
          label
          value
        }
        serviceHistoryTaskTypes {
          label
          value
        }
        emissionStickers {
          label
          value
        }
        efficiencyClasses {
          label
          value
        }
        modelOptions {
          label
          value
        }
        subtypeOptions {
          model
          subtypes {
            label
            value
          }
        }
        emissionClasses {
          label
          value
        }
        upholsteryOptions {
          label
          value
        }
        upholsteryColor {
          label
          value
        }
        driveTrain {
          label
          value
        }
        fieldLabels {
          name
          label
        }
        colorOptions {
          label
          value
        }
        featureDetailOptions {
          locale
          featureDetailGroupCategory {
            id
            name
            featureDetailGroupOptions {
              label
              id
              subGroupOptions {
                label
                value
              }
            }
          }
        }
        customFeatureTranslationConfigs {
          category {
            id
            name
          }
          subcategory {
            id
            name
          }
          featureGroup
          featureSubGroup
          translations {
            locale
            text
          }
          order
          defaultVisible
          classifieds
        }
        supportedLanguageLocales
        trimLevels {
          label
          value
        }
        grossPriceChangeReasons {
          value
          label
        }
        serviceHistoryConfigTasks {
          value
          label
          type
        }
      }
      mobiledeV2 {
        marketplaceAccountOptions {
          label
          value
        }
      }
    }
    autoheroAndWkda {
      imperfections {
        items {
          id
          category
          part {
            text
            locale
          }
          imperfectionType {
            text
            locale
          }
          image {
            id
            original
            thumbnail
            uploadedUrl
            nonRemovable
            tags {
              id
              tagImageId
              position {
                top
                left
              }
            }
            photoId
          }
        }
      }
      media {
        data {
          video {
            originalUrl
            createdAt
            resizedVideos {
              resolution
              url
            }
          }
          images {
            exterior {
              id
              original
              thumbnail
              uploadedUrl
              nonRemovable
              tags {
                id
                tagImageId
                position {
                  top
                  left
                }
              }
              photoId
            }
            interior {
              id
              original
              thumbnail
              uploadedUrl
              nonRemovable
              tags {
                id
                tagImageId
                position {
                  top
                  left
                }
              }
              photoId
            }
            panorama {
              id
              original
              thumbnail
              uploadedUrl
              nonRemovable
              tags {
                id
                tagImageId
                position {
                  top
                  left
                }
              }
              photoId
            }
            nextgen_highlight {
              id
              original
              thumbnail
              uploadedUrl
              nonRemovable
              tags {
                id
                tagImageId
                position {
                  top
                  left
                }
              }
              photoId
            }
          }
          checksum
        }
        restrictions {
          canAddImages
          canRemoveImages
          canSortImages
          canEditTags
          canRemoveTags
          canRotateImperfectionImage
          canSelectImperfectionImage
        }
      }
      secondaryWheels {
        data {
          damageCondition
          grossPrice
          netPrice
          vatRate
          isActive
          isShippingRequired
          location
          dot {
            frontLeft
            frontRight
            rearLeft
            rearRight
          }
          manufacturer {
            frontLeft
            frontRight
            rearLeft
            rearRight
          }
          material {
            frontLeft
            frontRight
            rearLeft
            rearRight
          }
          radius {
            frontLeft
            frontRight
            rearLeft
            rearRight
          }
          wheelWidth {
            frontLeft
            frontRight
            rearLeft
            rearRight
          }
          wheelHeight {
            frontLeft
            frontRight
            rearLeft
            rearRight
          }
          loadIndex {
            frontLeft
            frontRight
            rearLeft
            rearRight
          }
          speedIndex {
            frontLeft
            frontRight
            rearLeft
            rearRight
          }
          seasonType {
            frontLeft
            frontRight
            rearLeft
            rearRight
          }
          treadDepth {
            frontLeft
            frontRight
            rearLeft
            rearRight
          }
          rimsDetails {
            frontLeft {
              label
              value
            }
            frontRight {
              label
              value
            }
            rearLeft {
              label
              value
            }
            rearRight {
              label
              value
            }
          }
          tiresDetails {
            frontLeft {
              label
              value
            }
            frontRight {
              label
              value
            }
            rearLeft {
              label
              value
            }
            rearRight {
              label
              value
            }
          }
          ad_secondary_wheels {
            id
            original
            thumbnail
            uploadedUrl
          }
          ad_secondary_wheels_damages {
            id
            original
            thumbnail
            uploadedUrl
          }
        }
        restrictions {
          canEditPrice
          canEditTableFields
          canEditSWImages
        }
      }
      featureDetails {
        ...FeatureDetails_Fragment
      }
      specsSpotlight {
        data {
          topHighlights
          topFeatureDetails
        }
        specSpotlightOptions {
          topHighlightsOptions {
            label
            value
          }
          topFeaturesOptions {
            label
            value
          }
        }
      }
    }
    autoscout24 {
      data {
        description
        title
        images {
          id
          original
          thumbnail
          uploadedUrl
          nonRemovable
          tags {
            id
            tagImageId
            position {
              top
              left
            }
          }
          photoId
        }
        imagesLimit
      }
    }
    mobiledeV2 {
      data {
        description
        title
        images {
          id
          original
          thumbnail
          uploadedUrl
          nonRemovable
          tags {
            id
            tagImageId
            position {
              top
              left
            }
          }
          photoId
        }
        imagesLimit
      }
    }
    externalUrls {
      data {
        label
        url
        type
      }
    }
    priceManagementGroup {
      label
    }
  }
  pricingChangelog(
    adId: $adId
    pageSize: $priceChangelogPageSize
    page: $priceChangelogPage
  ) {
    data {
      page
      pageSize
      totalEntityCount
      totalPageCount
      items {
        changedBy
        stockNumber
        changedAt
        previousPrice
        newPrice
        changeReason
        changeComment
      }
    }
  }
}
    ${FeatureDetails_FragmentFragmentDoc}`;
export const useGetDataQuery = <
  TData = GetDataQuery,
  TError = SUCCESS_RESPONSE<null> | FAIL_RESPONSE,
>(
  variables: GetDataQueryVariables,
  options?: UseQueryOptions<GetDataQuery, TError, TData>,
) =>
  useQuery<GetDataQuery, TError, TData>(
    ['GetData', variables],
    api<GetDataQuery, GetDataQueryVariables>(GetDataDocument, variables),
    options,
  );

useGetDataQuery.getKey = (variables: GetDataQueryVariables) => [
  'GetData',
  variables,
];
useGetDataQuery.fetcher = (
  variables: GetDataQueryVariables,
  options?: RequestInit['headers'],
) =>
  api<GetDataQuery, GetDataQueryVariables>(GetDataDocument, variables, options);
export const GetExportItemsDocument = `
    query GetExportItems($adId: UUID!) {
  adMgmt(adId: $adId) {
    exportOverview {
      exportItems {
        marketplace {
          title
          id
        }
        daysOnline
        firstPublishedAt
        updatedAt
        firstEligibleToBePurchasedAt
        lastPublishingErrors {
          createdAt
          message
        }
        links {
          title
          url
        }
        publishingStatus
        canPublish
        comingSoon
        exportListings {
          listings {
            daysOnline
            firstPublishedAt
            lastPublishedAt
            lastPublishingErrors {
              createdAt
              message
            }
            publishingStatus
            account {
              id
              title
            }
            link {
              title
              url
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetExportItemsQuery = <
  TData = GetExportItemsQuery,
  TError = SUCCESS_RESPONSE<null> | FAIL_RESPONSE,
>(
  variables: GetExportItemsQueryVariables,
  options?: UseQueryOptions<GetExportItemsQuery, TError, TData>,
) =>
  useQuery<GetExportItemsQuery, TError, TData>(
    ['GetExportItems', variables],
    api<GetExportItemsQuery, GetExportItemsQueryVariables>(
      GetExportItemsDocument,
      variables,
    ),
    options,
  );

useGetExportItemsQuery.getKey = (variables: GetExportItemsQueryVariables) => [
  'GetExportItems',
  variables,
];
useGetExportItemsQuery.fetcher = (
  variables: GetExportItemsQueryVariables,
  options?: RequestInit['headers'],
) =>
  api<GetExportItemsQuery, GetExportItemsQueryVariables>(
    GetExportItemsDocument,
    variables,
    options,
  );
export const GetAdPreparationDocument = `
    query GetAdPreparation($adId: UUID!) {
  adPreparation(adId: $adId) {
    preparationState
    preparationStateOptions {
      label
      value
    }
    explainerLinks {
      label
      text
      url
    }
    tuningComment
    isFinanced
    comment
  }
}
    `;
export const useGetAdPreparationQuery = <
  TData = GetAdPreparationQuery,
  TError = SUCCESS_RESPONSE<null> | FAIL_RESPONSE,
>(
  variables: GetAdPreparationQueryVariables,
  options?: UseQueryOptions<GetAdPreparationQuery, TError, TData>,
) =>
  useQuery<GetAdPreparationQuery, TError, TData>(
    ['GetAdPreparation', variables],
    api<GetAdPreparationQuery, GetAdPreparationQueryVariables>(
      GetAdPreparationDocument,
      variables,
    ),
    options,
  );

useGetAdPreparationQuery.getKey = (
  variables: GetAdPreparationQueryVariables,
) => ['GetAdPreparation', variables];
useGetAdPreparationQuery.fetcher = (
  variables: GetAdPreparationQueryVariables,
  options?: RequestInit['headers'],
) =>
  api<GetAdPreparationQuery, GetAdPreparationQueryVariables>(
    GetAdPreparationDocument,
    variables,
    options,
  );
export const GetPreviewUrlDocument = `
    query GetPreviewUrl($uploadId: String!) {
  previewUrl(uploadId: $uploadId) {
    url
  }
}
    `;
export const useGetPreviewUrlQuery = <
  TData = GetPreviewUrlQuery,
  TError = SUCCESS_RESPONSE<null> | FAIL_RESPONSE,
>(
  variables: GetPreviewUrlQueryVariables,
  options?: UseQueryOptions<GetPreviewUrlQuery, TError, TData>,
) =>
  useQuery<GetPreviewUrlQuery, TError, TData>(
    ['GetPreviewUrl', variables],
    api<GetPreviewUrlQuery, GetPreviewUrlQueryVariables>(
      GetPreviewUrlDocument,
      variables,
    ),
    options,
  );

useGetPreviewUrlQuery.getKey = (variables: GetPreviewUrlQueryVariables) => [
  'GetPreviewUrl',
  variables,
];
useGetPreviewUrlQuery.fetcher = (
  variables: GetPreviewUrlQueryVariables,
  options?: RequestInit['headers'],
) =>
  api<GetPreviewUrlQuery, GetPreviewUrlQueryVariables>(
    GetPreviewUrlDocument,
    variables,
    options,
  );
export const GetPriceChangelogDataDocument = `
    query GetPriceChangelogData($adId: UUID!, $priceChangelogPage: Int!, $priceChangelogPageSize: Int!) {
  pricingChangelog(
    adId: $adId
    pageSize: $priceChangelogPageSize
    page: $priceChangelogPage
  ) {
    data {
      page
      pageSize
      totalEntityCount
      totalPageCount
      items {
        changedBy
        stockNumber
        changedAt
        previousPrice
        newPrice
        changeReason
        changeComment
      }
    }
  }
}
    `;
export const useGetPriceChangelogDataQuery = <
  TData = GetPriceChangelogDataQuery,
  TError = SUCCESS_RESPONSE<null> | FAIL_RESPONSE,
>(
  variables: GetPriceChangelogDataQueryVariables,
  options?: UseQueryOptions<GetPriceChangelogDataQuery, TError, TData>,
) =>
  useQuery<GetPriceChangelogDataQuery, TError, TData>(
    ['GetPriceChangelogData', variables],
    api<GetPriceChangelogDataQuery, GetPriceChangelogDataQueryVariables>(
      GetPriceChangelogDataDocument,
      variables,
    ),
    options,
  );

useGetPriceChangelogDataQuery.getKey = (
  variables: GetPriceChangelogDataQueryVariables,
) => ['GetPriceChangelogData', variables];
useGetPriceChangelogDataQuery.fetcher = (
  variables: GetPriceChangelogDataQueryVariables,
  options?: RequestInit['headers'],
) =>
  api<GetPriceChangelogDataQuery, GetPriceChangelogDataQueryVariables>(
    GetPriceChangelogDataDocument,
    variables,
    options,
  );
export const GetServiceHistoryDocumentUploadPresignedUrlDocument = `
    query GetServiceHistoryDocumentUploadPresignedUrl($uploadId: String!, $fileExt: String!) {
  getServiceHistoryDocumentUploadPresignedUrl(
    uploadId: $uploadId
    fileExt: $fileExt
  ) {
    bucket
    url
    headers {
      name
      values
    }
  }
}
    `;
export const useGetServiceHistoryDocumentUploadPresignedUrlQuery = <
  TData = GetServiceHistoryDocumentUploadPresignedUrlQuery,
  TError = SUCCESS_RESPONSE<null> | FAIL_RESPONSE,
>(
  variables: GetServiceHistoryDocumentUploadPresignedUrlQueryVariables,
  options?: UseQueryOptions<
    GetServiceHistoryDocumentUploadPresignedUrlQuery,
    TError,
    TData
  >,
) =>
  useQuery<GetServiceHistoryDocumentUploadPresignedUrlQuery, TError, TData>(
    ['GetServiceHistoryDocumentUploadPresignedUrl', variables],
    api<
      GetServiceHistoryDocumentUploadPresignedUrlQuery,
      GetServiceHistoryDocumentUploadPresignedUrlQueryVariables
    >(GetServiceHistoryDocumentUploadPresignedUrlDocument, variables),
    options,
  );

useGetServiceHistoryDocumentUploadPresignedUrlQuery.getKey = (
  variables: GetServiceHistoryDocumentUploadPresignedUrlQueryVariables,
) => ['GetServiceHistoryDocumentUploadPresignedUrl', variables];
useGetServiceHistoryDocumentUploadPresignedUrlQuery.fetcher = (
  variables: GetServiceHistoryDocumentUploadPresignedUrlQueryVariables,
  options?: RequestInit['headers'],
) =>
  api<
    GetServiceHistoryDocumentUploadPresignedUrlQuery,
    GetServiceHistoryDocumentUploadPresignedUrlQueryVariables
  >(GetServiceHistoryDocumentUploadPresignedUrlDocument, variables, options);
export const PublisherEventTrackingUploadPreSignedUrlDocument = `
    query PublisherEventTrackingUploadPreSignedUrl {
  data: publisherEventTrackingUploadPreSignedUrl {
    url
    signedHeaders {
      key
      value
    }
  }
}
    `;
export const usePublisherEventTrackingUploadPreSignedUrlQuery = <
  TData = PublisherEventTrackingUploadPreSignedUrlQuery,
  TError = SUCCESS_RESPONSE<null> | FAIL_RESPONSE,
>(
  variables?: PublisherEventTrackingUploadPreSignedUrlQueryVariables,
  options?: UseQueryOptions<
    PublisherEventTrackingUploadPreSignedUrlQuery,
    TError,
    TData
  >,
) =>
  useQuery<PublisherEventTrackingUploadPreSignedUrlQuery, TError, TData>(
    variables === undefined
      ? ['PublisherEventTrackingUploadPreSignedUrl']
      : ['PublisherEventTrackingUploadPreSignedUrl', variables],
    api<
      PublisherEventTrackingUploadPreSignedUrlQuery,
      PublisherEventTrackingUploadPreSignedUrlQueryVariables
    >(PublisherEventTrackingUploadPreSignedUrlDocument, variables),
    options,
  );

usePublisherEventTrackingUploadPreSignedUrlQuery.getKey = (
  variables?: PublisherEventTrackingUploadPreSignedUrlQueryVariables,
) =>
  variables === undefined
    ? ['PublisherEventTrackingUploadPreSignedUrl']
    : ['PublisherEventTrackingUploadPreSignedUrl', variables];
usePublisherEventTrackingUploadPreSignedUrlQuery.fetcher = (
  variables?: PublisherEventTrackingUploadPreSignedUrlQueryVariables,
  options?: RequestInit['headers'],
) =>
  api<
    PublisherEventTrackingUploadPreSignedUrlQuery,
    PublisherEventTrackingUploadPreSignedUrlQueryVariables
  >(PublisherEventTrackingUploadPreSignedUrlDocument, variables, options);
