import { Row, Button, Col } from 'antd';
import { usePageData } from '@hooks/usePageData';
import {
  POSSIBLE_PUBLISHING_STATUSES,
  MARKETPLACES,
  TRACKING_SECTION,
  TRACKING_SECTION_CATEGORY,
} from '@src/constants';
import { PublishingBlockerItem, PublishingBlockerProps } from './blocker';
import cn from './styles.less';
import cns from 'classnames';
import { useFieldArrayTyped } from '@hooks/useFieldsArrayTyped';
import { useWatch } from 'react-hook-form';
import { useAppForm } from '@hooks/useAppForm';
import { useAnalytics } from '@hooks/useAnalytics';

export const PublishingBlockers = (): JSX.Element => {
  const { fields, append, remove } = useFieldArrayTyped({
    name: 'adMgmt.exportOverview.data.publishingBlockers',
  });
  const { getValues } = useAppForm();
  const { data } = usePageData();
  const track = useAnalytics();
  const restrictions = data?.adMgmt.exportOverview.restrictions;

  const ahExportData = getValues('adMgmt.exportOverview.exportItems')?.find(
    (item) => item.marketplace.id.toUpperCase() === MARKETPLACES.AUTOHERO,
  );

  const isComingSoon = ahExportData?.comingSoon ?? null;

  const isAhUnpublished =
    useWatch({
      name: 'adMgmt.exportOverview.exportItems.0.publishingStatus',
    }) === POSSIBLE_PUBLISHING_STATUSES.UNPUBLISHED;

  const handleAddBlocker = () => {
    append({
      comment: '',
      reasonId: '',
      subReasonId: '',
    });
    track({
      eventType: 'add',
      eventCategory: 'modify',
      section: TRACKING_SECTION.BLOCKERS,
      sectionCategory: TRACKING_SECTION_CATEGORY.AH,
      fieldId: 'Reason Subreason',
    });
  };

  const handleRemoveBlocker = (index: number) => {
    track(
      {
        eventType: 'delete',
        eventCategory: 'modify',
        section: TRACKING_SECTION.BLOCKERS,
        sectionCategory: TRACKING_SECTION_CATEGORY.AH,
        fieldId: 'Reason Subreason',
      },
      {
        value: null,
        oldValue: fields[index],
      },
    );
    remove(index);
  };

  const renderBlockers = () =>
    fields.map((blocker, index) => {
      const publishingBlockerProps: PublishingBlockerProps = {
        handleRemoveBlocker,
        index,
      };
      return (
        <PublishingBlockerItem key={blocker.id} {...publishingBlockerProps} />
      );
    });

  const renderAddButton = () => {
    const buttonDisabled =
      (!isComingSoon && !isAhUnpublished) ||
      !restrictions?.canAddPublishingBlocker;

    let restrictionText = null;

    if (!restrictions?.canAddPublishingBlocker) {
      restrictionText = 'You have no permissions to add publishing blocker';
    }

    if (!isAhUnpublished) {
      restrictionText = 'Please unpublish the car to add a new blocker';
    }

    return (
      <Row>
        <Col span={12}>
          <Button
            disabled={buttonDisabled}
            type="primary"
            data-qa-selector="AddBlockerBtn"
            onClick={handleAddBlocker}
          >
            Add Blocker
          </Button>

          {buttonDisabled && (
            <span
              className={cn.adBlockerHint}
              data-qa-selector="restriction-text"
            >
              {restrictionText}
            </span>
          )}
        </Col>
      </Row>
    );
  };

  return (
    <>
      {fields.length > 0 ? (
        <div
          data-qa-selector="BlockerStatus"
          className={cns(cn.statusText, cn.hasBlockers)}
        >
          There are blockers for this ad
        </div>
      ) : (
        <div
          data-qa-selector="BlockerStatus"
          className={cns(cn.statusText, cn.noBlockers)}
        >
          No blockers for this ad
        </div>
      )}
      {renderBlockers()}
      {renderAddButton()}
    </>
  );
};
