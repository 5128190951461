import { InputNumber, InputNumberProps } from 'antd';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import {
  Tracking,
  useFormControllerTracking,
} from '@hooks/useFormControlledTracking';

type InputNumberControlledProps<T extends FieldValues> = {
  controllerProps: UseControllerProps<T>;
  tracking?: Tracking;
} & InputNumberProps;

const InputNumberControlled = <T extends FieldValues>({
  controllerProps,
  tracking,
  ...restInputNumberProps
}: InputNumberControlledProps<T>): JSX.Element => {
  const { field: props } = useController(controllerProps);
  const { onBlur, onFocus } = useFormControllerTracking({
    tracking,
    controllerProps: props,
  });

  return (
    <InputNumber
      {...props}
      {...restInputNumberProps}
      decimalSeparator={','}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};

export default InputNumberControlled;
