import {
  CustomFeatureTranslationConfig,
  FeatureDetailItem,
  FeatureDetailRestrictions,
  FeatureDetailTranslation,
} from '@gql_codegen/retail-types';
import { useAnalytics } from '@hooks/useAnalytics';
import { TRACKING_SECTION, TRACKING_SECTION_CATEGORY } from '@src/constants';
import { Button, Checkbox, Space } from 'antd';
import { v4 as uuid } from 'uuid';
import { EQUIPMENT_NEWLY_ADDED_MAP } from '../constants';

type EquipmentCheckboxProps = {
  item: FeatureDetailItem | undefined;
  record: CustomFeatureTranslationConfig;
  handleDeleteItem: (itemToDelete: FeatureDetailItem) => void;
  handleAddItemWKDAOnly: (newItem: FeatureDetailItem) => void;
  restrictions: FeatureDetailRestrictions;
};

export const EquipmentTableCheckbox = ({
  item,
  record,
  handleDeleteItem,
  handleAddItemWKDAOnly,
  restrictions,
}: EquipmentCheckboxProps): JSX.Element => {
  const track = useAnalytics();

  const isItemExist = typeof item !== 'undefined';
  const isItemTranslationsEmpty =
    isItemExist &&
    item.translations.some((translation) => translation.text === '');

  if (
    isItemTranslationsEmpty &&
    EQUIPMENT_NEWLY_ADDED_MAP.get(item.id)?.edited === false
  )
    return (
      <Button
        type="link"
        onClick={() => handleDeleteItem(item)}
        data-qa-selector="cancel-equipment-btn"
      >
        cancel
      </Button>
    );

  return (
    <Space size="small">
      <Checkbox
        disabled={!restrictions.canAddFeatureDetail}
        checked={isItemExist}
        data-qa-selector="equipment-checkbox"
        onClick={() => {
          if (isItemExist) {
            handleDeleteItem(item);

            track(
              {
                eventType: 'delete',
                eventCategory: 'modify',
                fieldId: 'Equipment_Checkbox',
                section: TRACKING_SECTION.EQUIPMENT,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
              },
              item,
            );
          } else {
            const newItem: FeatureDetailItem = {
              group: {
                name: record.featureGroup,
                translatedName: record.featureGroup,
              },
              id: uuid(),
              name: '',
              translations: record.translations.map<FeatureDetailTranslation>(
                (translation) => ({
                  locale: translation.locale,
                  text: translation.text,
                }),
              ),
              isOriginal: false,
              subGroup: record.featureSubGroup
                ? {
                    name: record.featureSubGroup,
                    translatedName: record.featureSubGroup,
                  }
                : null,
              isNewTranslation: false,
              uiFeatureDetailIdentifier: '',
              subcategory: record.subcategory
                ? {
                    id: record.subcategory.id,
                    name: record.subcategory.name,
                  }
                : null,
              type: null,
              price: null,
              classifieds: [],
            };

            handleAddItemWKDAOnly(newItem);
            EQUIPMENT_NEWLY_ADDED_MAP.set(newItem.id, {
              ...newItem,
              edited: false,
            });

            track(
              {
                eventType: 'add',
                eventCategory: 'modify',
                fieldId: 'Equipment_Checkbox',
                section: TRACKING_SECTION.EQUIPMENT,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
              },
              newItem,
            );
          }
        }}
      />
      {item && item.uiFeatureDetailIdentifier}
    </Space>
  );
};
