import {
  CustomFeatureTranslationConfig,
  FeatureDetailItem,
  FeatureDetailTranslation,
} from '@gql_codegen/retail-types';
import { useAnalytics } from '@hooks/useAnalytics';
import { TRACKING_SECTION, TRACKING_SECTION_CATEGORY } from '@src/constants';
import {
  Badge,
  Checkbox,
  Col,
  Row,
  Select,
  SelectProps,
  Tag,
  Typography,
} from 'antd';
import classNames from 'classnames';
import Fuse from 'fuse.js';
import { useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import {
  EQUIPMENT_CATEGORIES,
  INVALID_GROUPS,
  EQUIPMENT_NEWLY_ADDED_MAP,
} from '../../constants';
import { useEquipment } from '../../useEquipment';
import { useFindEquipmentHelpers } from '../../useFindEquipmentHelpers';
import { equipmentHelpers } from '../../utils';
import styles from './styles.less';
import { generateHexFromString } from '@utils/generateHexFromString';

const TagColors: Record<string, `#${string}`> = {
  [EQUIPMENT_CATEGORIES['highlights']]: '#faad14', // gold
  [EQUIPMENT_CATEGORIES['comfort']]: '#a0d911', //lime'
  [EQUIPMENT_CATEGORIES['multimedia']]: '#13c2c2', // cyan'
  [EQUIPMENT_CATEGORIES['light and sight']]: '#1890ff', // 'blue'
  [EQUIPMENT_CATEGORIES['security']]: '#722ed1', //'purple'
  [EQUIPMENT_CATEGORIES['additional']]: '#eb2f96', //magenta'
};

const RenderAddEquipmentItem = ({
  category,
  translations,
  featureGroup,
  featureSubGroup,
  checked,
  classifieds,
}: CustomFeatureTranslationConfig & { checked: boolean }) => (
  <Row data-qa-selector={`${featureGroup} | ${featureSubGroup}`}>
    <Col span={1}>
      <Checkbox disabled checked={checked} className={styles.checkboxCursor} />
    </Col>
    <Col span={7}>
      <Typography.Text
        ellipsis={{
          tooltip: `${featureGroup}${
            featureSubGroup ? ` | ${featureSubGroup}` : ''
          }`,
        }}
      >
        {featureGroup}
        {featureSubGroup ? ` | ${featureSubGroup}` : ''}
      </Typography.Text>
    </Col>
    <Col span={8}>
      <span>{translations[0]?.text}</span>
    </Col>
    <Col span={4} style={{ textAlign: 'center' }}>
      {classifieds.map((classified) => (
        <Tag key={classified} color={generateHexFromString(classified)}>
          {classified}
        </Tag>
      ))}
    </Col>
    <Col span={4} style={{ textAlign: 'end' }}>
      <Badge
        style={{ backgroundColor: TagColors[category.name] ?? '' }}
        count={category.name}
      />
    </Col>
  </Row>
);

export const AddEquipmentInput = () => {
  const {
    handleAddItemWKDAOnly,
    customFeatureTranslationConfigsSortedAndFiltered,
    restrictions,
  } = useEquipment();
  const track = useAnalytics();
  const { getItemByTranslationConfigNonTranslationCompare } =
    useFindEquipmentHelpers();

  //TODO: Remove after phase 2
  const searchDataWithoutUnclassified =
    customFeatureTranslationConfigsSortedAndFiltered.filter(
      (config) => config.featureGroup !== INVALID_GROUPS.unclassified,
    );
  const [searchResult, setSearchResult] = useState(
    searchDataWithoutUnclassified,
  );

  const fuseSearch = useMemo(
    () =>
      new Fuse(searchDataWithoutUnclassified, {
        keys: [
          {
            name: 'translations.text',
            weight: 5,
          },
          {
            name: 'featureGroup',
            weight: 5,
          },
          'featureSubGroup',
          'category.name',
          'subcategory.name',
        ],
        threshold: 0.4,
        ignoreLocation: true,
      }),
    [searchDataWithoutUnclassified],
  );

  const handleSearch = (newValue: string) => {
    if (newValue === '') return setSearchResult(searchDataWithoutUnclassified);

    const searchResult = fuseSearch.search(newValue.replace(' | ', ' '));
    setSearchResult(searchResult.map((result) => result.item));
  };

  const handleAddItem: SelectProps['onChange'] = (value: string) => {
    const valueFromOption = equipmentHelpers.convertValueToOptions(value);

    const newOption = searchDataWithoutUnclassified.find(
      (option) =>
        option.featureGroup === valueFromOption.group &&
        option.featureSubGroup === valueFromOption.subgroup,
    );

    if (!newOption)
      throw new Error(
        `AutoheroWKDA::EquipmentHeader::AddEquipmentInput::handleAddItem -> found no newOption`,
      );

    const hasItem = getItemByTranslationConfigNonTranslationCompare(newOption);

    const newEquipment: FeatureDetailItem = {
      group: {
        name: valueFromOption.group,
        translatedName: valueFromOption.group,
      },
      id: uuid(),
      name: '',
      translations: newOption.translations.map<FeatureDetailTranslation>(
        (translation) => ({
          locale: translation.locale,
          text: hasItem ? '' : translation.text,
        }),
      ),
      isOriginal: false,
      subGroup: newOption.featureSubGroup
        ? {
            name: newOption.featureSubGroup,
            translatedName: newOption.featureSubGroup,
          }
        : null,
      isNewTranslation: false,
      uiFeatureDetailIdentifier: '',
      subcategory: newOption.subcategory
        ? {
            id: newOption.subcategory.id,
            name: newOption.subcategory.name,
          }
        : null,
      type: null,
      price: null,
      classifieds: [],
    };

    handleAddItemWKDAOnly(newEquipment);
    EQUIPMENT_NEWLY_ADDED_MAP.set(newEquipment.id, {
      ...newEquipment,
      edited: false,
    });

    setSearchResult(searchDataWithoutUnclassified);
    track(
      {
        eventType: 'add',
        eventCategory: 'modify',
        fieldId: hasItem ? 'Equipment_Add_Custom' : 'Equipment_Checkbox',
        section: TRACKING_SECTION.EQUIPMENT,
        sectionCategory: TRACKING_SECTION_CATEGORY.AH,
      },
      newEquipment,
    );

    setTimeout(() => {
      const newDOMElem = document.querySelectorAll(
        `[data-row-key*="${equipmentHelpers.convertOptionToValue({
          group: newOption.featureGroup,
          subgroup: newOption.featureSubGroup ?? null,
        })}"]`,
      );
      const lastItem = newDOMElem.item(newDOMElem.length - 1);
      lastItem?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 16);
  };

  return (
    <Select
      onChange={handleAddItem}
      showSearch
      filterOption={false}
      onSearch={handleSearch}
      style={{ display: 'flex', marginBottom: '0.6rem' }}
      notFoundContent={null}
      placeholder="Add custom equipment"
      getPopupContainer={(triggerNode: HTMLElement) =>
        triggerNode.parentElement ?? document.body
      }
      value={null}
      disabled={!restrictions.canAddFeatureDetail}
      data-qa-selector="add-equipment-group-select"
    >
      {searchResult.map((item, idx) => (
        <Select.Option
          key={`${item.category.name}_${item.featureGroup}_${item.featureSubGroup}_${item.translations[0]?.text}`}
          value={equipmentHelpers.convertOptionToValue({
            group: item.featureGroup,
            subgroup: item.featureSubGroup ?? null,
          })}
          label={equipmentHelpers.generateLabel(item)}
          data-qa-selector="add-equipment-group-option"
          className={classNames(
            styles.dropdownElem,
            !(idx % 2 === 0) && styles.dropdownElemDarker,
          )}
        >
          <RenderAddEquipmentItem
            {...item}
            checked={
              typeof getItemByTranslationConfigNonTranslationCompare(item) ===
              'object'
            }
          />
        </Select.Option>
      ))}
    </Select>
  );
};
