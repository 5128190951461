import { Button, Spin } from 'antd';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { Tag as TagComponent } from '../Tag';
import cn from './styles.less';
import cns from 'classnames';
import { useRef, useState, useEffect } from 'react';
import { DRAGGABLE_MODES, GALLERY_MODES, TGalleryModes } from '@src/constants';
import { Image, TagInput, Tag } from '@gql_codegen/retail-types';
import { useImageFallback } from '@hooks/useImageFallback';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

type ImageGalleryItemProps = {
  item: Image;
  onRemove: (id: string) => void;
  onChangeTagPosition: (dragged: Tag) => void;
  onCreateTag: (item: Image, newTag: TagInput) => void;
  noButtons?: boolean;
  mode?: TGalleryModes | null | undefined;
  imperfectionImageId?: string;
  grayscale?: boolean;
};

export const ImageGalleryItem = ({
  item,
  onRemove,
  onChangeTagPosition,
  onCreateTag,
  noButtons = false,
  mode,
  imperfectionImageId,
  grayscale,
}: ImageGalleryItemProps): JSX.Element => {
  const { original, nonRemovable, tags = [] } = item;

  const imageRef = useRef<HTMLImageElement>(null);
  const [draggedTag, setDraggedTag] = useState<Tag>();
  const draggedTagRef = useRef(draggedTag);

  const { handleError, handleLoad, loading } = useImageFallback({
    imageRef,
    src: original,
  });

  useEffect(() => {
    draggedTagRef.current = draggedTag;
  }, [draggedTag]);

  const fitBounds = (num: number) => {
    if (num > 1) return 1;
    if (num < 0) return 0;
    return num;
  };

  const handleMouseMove = ({
    clientX,
    clientY,
  }: {
    clientX: number;
    clientY: number;
  }) => {
    if (!draggedTagRef?.current || !imageRef?.current) {
      return;
    }
    const imageBounds = imageRef.current.getBoundingClientRect();
    const newDraggedTagState = {
      ...draggedTagRef?.current,
      position: {
        left: fitBounds((clientX - imageBounds.left) / imageBounds.width),
        top: fitBounds((clientY - imageBounds.top) / imageBounds.height),
      },
    };
    setDraggedTag(newDraggedTagState);
  };

  const handleMouseUp = () => {
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);

    if (!draggedTagRef?.current) {
      return;
    }

    onChangeTagPosition(draggedTagRef?.current);

    setDraggedTag(draggedTagRef?.current);
  };

  const handleTagMouseDown = (tag: Tag) => {
    if (!mode || !DRAGGABLE_MODES.includes(mode)) {
      return;
    }
    setDraggedTag(tag);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  const handleRemove = () => {
    onRemove(item.id);
  };

  const handleCreateTag = ({
    clientX,
    clientY,
  }: {
    clientX: number;
    clientY: number;
  }) => {
    if (
      !imperfectionImageId ||
      mode !== GALLERY_MODES.CREATE_TAG ||
      !imageRef?.current
    ) {
      return;
    }
    const imageBounds = imageRef.current.getBoundingClientRect();
    const position = {
      left: (clientX - imageBounds.left) / imageBounds.width,
      top: (clientY - imageBounds.top) / imageBounds.height,
    };
    const newTag = {
      id: null,
      position,
      tagImageId: imperfectionImageId,
    };
    onCreateTag(item, newTag);
  };

  return (
    <div className={cn.imageContainer}>
      <div className={cn.imageActions}>
        {!nonRemovable && !noButtons && (
          <Button
            type="text"
            onClick={handleRemove}
            data-qa-selector="galleryRemove"
          >
            <DeleteOutlined style={{ color: '#ffffff' }} />
          </Button>
        )}
      </div>

      <img
        ref={imageRef}
        className={cns(cn.image, {
          [cn.createTagMode]: mode === GALLERY_MODES.CREATE_TAG,
          [cn.loading]: loading,
          [cn.__grayscale]: grayscale,
        })}
        src={original}
        alt="gallery item"
        data-qa-selector="image-gallery-item"
        onClick={handleCreateTag}
        onError={handleError}
        onLoad={handleLoad}
      />

      {(tags || []).map((tag) => {
        const isDragging =
          draggedTag && draggedTag.tagImageId === tag.tagImageId;
        const targetTag = isDragging ? draggedTag : tag;

        if (imperfectionImageId && imperfectionImageId !== tag.tagImageId) {
          return <TagComponent key={targetTag.tagImageId} tag={targetTag} />;
        }

        return (
          <TagComponent
            key={targetTag.tagImageId}
            tag={targetTag}
            isEditIcon={mode != null && DRAGGABLE_MODES.includes(mode)}
            onMouseDown={() => handleTagMouseDown(tag)}
          />
        );
      })}
      {loading && (
        <span className={cn.loadingWrapper}>
          <Spin indicator={antIcon} />
        </span>
      )}
    </div>
  );
};
