import { useRef, useEffect, memo } from 'react';
import { useWatch } from 'react-hook-form';
import { useAppForm } from '@hooks/useAppForm';

export const SyncAS24Title = memo(function SyncAS24Title() {
  const isMounted = useRef<boolean>(false);
  const { setValue } = useAppForm();
  const [subType, subTypeExtra] = useWatch({
    name: ['adMgmt.general.data.subType', 'adMgmt.general.data.subTypeExtra'],
  });

  useEffect(() => {
    if (isMounted.current) {
      setValue(
        'adMgmt.autoscout24.data.title',
        `${subType} ${subTypeExtra ?? ''}`,
      );
    } else {
      isMounted.current = true;
    }
  }, [subTypeExtra, setValue, subType, isMounted]);

  return <></>;
});
