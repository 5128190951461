import {
  BugTwoTone,
  CopyTwoTone,
  ExclamationCircleTwoTone,
} from '@ant-design/icons';
import { useTraceIds } from '@hooks/useTraceIds';
import { Button, notification, Popover } from 'antd';
import { useRef, useState } from 'react';
import cn from './styles.less';

const stateIcons = {
  default: <BugTwoTone twoToneColor="#ff7a45" />,
  success: <CopyTwoTone twoToneColor="#52c41a" />,
  error: <ExclamationCircleTwoTone twoToneColor="#f5222d" />,
};

export const GetTraceIds = () => {
  const [iconState, setIconState] =
    useState<keyof typeof stateIcons>('default');
  const traceID = useTraceIds();
  const intervalRef = useRef<NodeJS.Timer | number | undefined>();

  const handleClick = async () => {
    try {
      if (!traceID) throw Error('No Trace-ID found');
      const traceIdsJSON = JSON.stringify(traceID);
      if ('clipboard' in navigator) {
        await navigator.clipboard.writeText(traceIdsJSON);
      } else {
        document.execCommand('copy', false, traceIdsJSON);
      }
      setIconState('success');
      notification.success({ message: 'Copied Trace-ID to clipboard' });
    } catch (error) {
      setIconState('error');
      if (error instanceof Error) {
        notification.error({ message: error.message });
      }
    }

    clearTimeout(intervalRef.current);
    intervalRef.current = setInterval(() => setIconState('default'), 1000);
  };
  return (
    <Popover
      content="Click to copy Trace-ID for bug reporting"
      placement="left"
      className={cn.getTraceId}
    >
      <Button
        shape="circle"
        size="large"
        icon={stateIcons[iconState]}
        data-qa-selector="get-trace-ids"
        onClick={handleClick}
      />
    </Popover>
  );
};
