import { ServiceHistoryDocumentType } from '@gql_codegen/retail-types';

export const DOCUMENT_TYPES_OPTIONS: Array<{
  value: ServiceHistoryDocumentType;
  label: string;
}> = [
  { value: 'HARD_COPY', label: 'Hard Copy' },
  { value: 'DIGITAL', label: 'Digital' },
  { value: 'AUTO_HERO', label: 'Autohero Service Document' },
];

export const DOCUMENT_TYPES: Record<ServiceHistoryDocumentType, string> = {
  HARD_COPY: 'Hard Copy',
  DIGITAL: 'Digital',
  AUTO_HERO: 'Autohero Service Document',
};
