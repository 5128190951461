import {
  Button,
  Form,
  Modal,
  notification,
  Spin,
  Typography,
  Upload,
} from 'antd';
import type { UploadFile, UploadProps } from 'antd';
import React, { useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import cn from './styles.less';
import { getEquipmentCSV, logError } from '../../utils';

const { Dragger } = Upload;

interface ImportModalProps {
  show: boolean;
  hint?: string;
  maxSize?: number;
  onSuccess: (csv: string) => void;
  onError: (error: { message?: string | undefined }) => void;
  handleCancel: () => void;
}

interface UploadDetails {
  selectedFile: UploadFile | null;
  selectedFileList: UploadFile[];
}

const DEFAULT_HINT_TEXT = 'Allowed formats: csv. Max size: 5 Mb';

const dummyRequest = ({ onSuccess }: { onSuccess?: (arg: string) => void }) => {
  setTimeout(() => {
    onSuccess?.('ok');
  }, 0);
};

export const ImportModal: React.FC<ImportModalProps> = ({
  show,
  hint = DEFAULT_HINT_TEXT,
  maxSize = 5,
  handleCancel,
  onError,
  onSuccess,
}) => {
  const [form] = Form.useForm();
  const [uploadDetails, setUploadDetails] = useState<UploadDetails>({
    selectedFile: null,
    selectedFileList: [],
  });
  const [isImportInProgress, setIsImportInProgress] = useState<boolean>(false);

  const props: UploadProps = {
    name: 'file',
    multiple: false,
    accept: '.csv',
    disabled: isImportInProgress,
    customRequest: dummyRequest,
    onChange(info) {
      const nextState: UploadDetails = {
        selectedFile: null,
        selectedFileList: [],
      };
      switch (info.file.status) {
        case 'uploading':
          nextState.selectedFileList = [info.file];
          break;
        case 'done':
          nextState.selectedFile = info.file;
          nextState.selectedFileList = [info.file];
          break;
        default:
          nextState.selectedFile = null;
          nextState.selectedFileList = [];
      }
      setUploadDetails(nextState);
    },
    fileList: uploadDetails.selectedFileList,
    onRemove: () => {
      resetData();
    },
    showUploadList: {
      showRemoveIcon: false,
    },
    beforeUpload(file) {
      const isFileSizeValid = file.size / 1024 / 1024 <= maxSize;
      if (!isFileSizeValid) {
        notification.error({
          message: 'Invalid file size',
          description: `File cant be greater than ${maxSize}MB.`,
        });
      }
      return isFileSizeValid;
    },
  };

  const resetData = () => {
    setUploadDetails({
      selectedFile: null,
      selectedFileList: [],
    });
    form.resetFields();
  };

  const handleSubmit = async (uploadDetails: UploadFile) => {
    try {
      setIsImportInProgress(true);
      const csv = await getEquipmentCSV(uploadDetails?.originFileObj);
      onSuccess(csv);
    } catch (error) {
      const newError =
        error instanceof Error
          ? error
          : { message: typeof error === 'string' ? error : undefined };

      logError('Import Modal handleSubmit', newError);
      onError(newError);
    } finally {
      setIsImportInProgress(false);
    }
  };

  return (
    <Modal
      open={show}
      onCancel={handleCancel}
      footer={[
        <Button
          key="cancel"
          type="link"
          data-qa-selector={'csv-modal-btn-discard'}
          onClick={() => {
            resetData();
            handleCancel();
          }}
        >
          Discard
        </Button>,
        <Button
          form="upload-form"
          htmlType="submit"
          key="submit"
          type="primary"
          disabled={isImportInProgress}
          data-qa-selector={'csv-modal-btn-save'}
        >
          Update
        </Button>,
      ]}
      closable={false}
      afterClose={() => resetData()}
      width={600}
    >
      <div
        data-qa-selector={'csv-modal-content'}
        className={cn.importModalContainer}
      >
        <Form
          id="upload-form"
          form={form}
          layout="vertical"
          onFinish={() => {
            if (uploadDetails.selectedFile) {
              handleSubmit(uploadDetails.selectedFile);
            }
          }}
        >
          <Form.Item
            label="File:"
            name="uploadFile"
            data-qa-selector={'csv-modal-file-input'}
            rules={[{ required: true, message: 'This field is required.' }]}
          >
            <Dragger {...props} data-qa-selector={'csv-modal-drag-file-area'}>
              <p
                className="ant-upload-drag-icon"
                data-qa-selector={'csv-modal-drag-icon'}
              >
                <InboxOutlined />
              </p>
              <p
                className="ant-upload-text"
                data-qa-selector={'csv-modal-upload-text'}
              >
                Click or drag file to this area to upload
              </p>
              <p
                className="ant-upload-hint"
                data-qa-selector={'csv-modal-upload-hint'}
              >
                {hint}
              </p>
            </Dragger>
          </Form.Item>
        </Form>
        <Typography.Text type="warning">
          If you continue, all currently available equipment in equipment
          section will be deleted
        </Typography.Text>
        {isImportInProgress && (
          <div
            className={cn.loadingSpinner}
            data-qa-selector={'csv-modal-loading-spinner'}
          >
            <Spin size="large" />
          </div>
        )}
      </div>
    </Modal>
  );
};
