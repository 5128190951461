import { Panel } from '@components/Panel';
import { PublishingBlockers } from './PublishingBlockers';
import { Subsection } from '@components/Subsection';
import { ExportOverview } from './ExportOverview';

export const Export = (): JSX.Element => {
  return (
    <Panel title="Export" data-qa-selector="section-classifieds">
      <Subsection title="Export Overview">
        <ExportOverview />
      </Subsection>
      <Subsection title="Publishing Blockers">
        <PublishingBlockers />
      </Subsection>
    </Panel>
  );
};
