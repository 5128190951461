import { Subsection } from '@components/Subsection';
import { useAppForm } from '@hooks/useAppForm';
import React from 'react';
import { useFormState } from 'react-hook-form';
import { ComponentProps } from 'react';

type SpecsSpotlightSubsectionProps = ComponentProps<typeof Subsection>;

export const SpecsSpotlightSubsection: React.FC<
  SpecsSpotlightSubsectionProps
> = (props) => {
  const { control } = useAppForm();

  const { errors: specsSpotlightErrors } = useFormState({
    name: 'adMgmt.autoheroAndWkda.specsSpotlight.data',
    control,
  });

  const hasErrors =
    Object.keys(
      specsSpotlightErrors.adMgmt?.autoheroAndWkda?.specsSpotlight?.data ?? {},
    ).length > 0;

  return (
    <Subsection {...props} folded={hasErrors ? false : undefined}>
      {props.children}
    </Subsection>
  );
};
