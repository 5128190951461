import { useCallback } from 'react';
import { usePageData } from '@hooks/usePageData';
import {
  appAnalytics,
  AppAnalyticsEventsNames,
  AppAnalyticsEventsPayloads,
} from '@utils/analytics';

interface Props {
  eventType: AppAnalyticsEventsNames; // eventName
  fieldId: string;
  section?: string;
  sectionCategory?: string;
  eventCategory?: string;
}

type Meta = AppAnalyticsEventsPayloads['meta'];

export const useAnalytics = () => {
  const { data } = usePageData();
  const adId = data?.adMgmt?.id ?? '';
  const adCountry = data?.adMgmt?.country ?? '';
  const stockNumber = data?.adMgmt?.general?.data?.stockNumber ?? '';

  return useCallback(
    (
      {
        eventType,
        fieldId,
        section = '',
        sectionCategory = '',
        eventCategory = '',
      }: Props,
      meta: Meta = null,
    ) => {
      appAnalytics.trackEvent({
        name: eventType,
        props: {
          eventType: eventType,
          eventCategory: eventCategory,
          fieldId,
          stockNumber,
          adCountry,
          adId,
          section,
          sectionCategory,
          meta,
        },
      });
    },
    [adId, adCountry, stockNumber],
  );
};
