import { TRACKING_SECTION, TRACKING_SECTION_CATEGORY } from '@src/constants';
import { Form } from 'antd';
import CreoleEditorControlled from '@components/CreoleEditor';

export const MobileDescription = () => {
  return (
    <Form.Item
      data-qa-selector="classifieds-mobile-description"
      label="Mobile description"
    >
      <CreoleEditorControlled
        data-qa-selector="classifieds-mobile-description-textarea"
        allowClear
        autoSize
        maxLength={5000}
        controllerProps={{
          name: 'adMgmt.mobiledeV2.data.description',
        }}
        tracking={{
          fieldId: 'Description',
          eventCategory: 'modify',
          section: TRACKING_SECTION.MOBILE_DESCRIPTION,
          sectionCategory: TRACKING_SECTION_CATEGORY.CL,
        }}
      />
    </Form.Item>
  );
};
