// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Djl_XxtiXDq76ZyLqzNR {\n  color: #ff4d4f;\n}\n", "",{"version":3,"sources":["webpack://./src/sections/General/UniqueSellingPropositions/styles.less"],"names":[],"mappings":"AACA;EACE,cAAA;AAAF","sourcesContent":["@antVersion: antd-5-18-2;\n.error {\n  color: #ff4d4f;\n}\n\n@ant-prefix: antd-5-18-2;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "Djl_XxtiXDq76ZyLqzNR"
};
export default ___CSS_LOADER_EXPORT___;
