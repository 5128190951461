import {
  CustomFeatureTranslationConfig,
  FeatureDetailCategory,
  FeatureDetailItem,
} from '@gql_codegen/retail-types';
import { useAppForm } from '@hooks/useAppForm';
import { useCallback } from 'react';
import {
  FeaturePaths,
  INVALID_ITEMS,
  EQUIPMENT_NEWLY_ADDED_MAP,
  dataPaths,
} from './constants';
import { FeatureDetailsItemPathsMap } from './index';
import {
  featureDetailCompareWKDAOnly,
  featureDetailCompareWithTranslationConfig,
  logError,
} from './utils';
import { get } from 'radash';

export const useFindEquipmentHelpers = () => {
  const { getValues, getFieldState, formState } = useAppForm();

  const getCategoryFromInvalidItem = (
    itemToFind: FeatureDetailItem,
  ): FeatureDetailCategory | undefined => {
    const itemCategoryName = Object.values(INVALID_ITEMS).find(
      (invalidItem) =>
        itemToFind.group.name === invalidItem.group &&
        itemToFind.subGroup === invalidItem.subGroup,
    )?.category;

    if (!itemCategoryName) return;

    const categories = getValues(dataPaths.featureDetails);

    return categories.find((category) => category.name === itemCategoryName);
  };

  const getCategoryFromCustomFeatureTranslationConfigs = (
    itemToFind: FeatureDetailItem,
  ): FeatureDetailCategory | undefined => {
    const allEquipment = getValues(dataPaths.customFeatureTranslationConfigs);
    const newItemCategory = allEquipment.find(
      (equipment) =>
        equipment.featureGroup === itemToFind.group.name &&
        equipment.featureSubGroup === (itemToFind.subGroup?.name ?? null),
    );
    const categories = getValues(dataPaths.featureDetails);

    return categories.find(
      (category) => category.id === newItemCategory?.category.id,
    );
  };

  const getFeatureDetailsItemFromFeatureTranslationConfig = (
    config: CustomFeatureTranslationConfig,
  ): FeatureDetailItem | undefined => {
    const categories = getValues(dataPaths.featureDetails);
    const targetCategory = categories.find(
      (category) => category.id === config.category.id,
    );
    if (!targetCategory) {
      logError(
        'getFeatureDetailsItemFromFeatureTranslationConfig',
        `can't find a category with id ${config.category.id}`,
      );
      return undefined;
    }
    return targetCategory.items.find((item) =>
      featureDetailCompareWithTranslationConfig(config)(item),
    );
  };

  const getFeatureDetailsCategoryByItem = (
    itemToFind: FeatureDetailItem,
  ): FeatureDetailCategory | undefined => {
    const cachedCategoryPath =
      FeatureDetailsItemPathsMap.get(itemToFind)?.categoryPath;
    if (cachedCategoryPath) {
      return getValues(cachedCategoryPath);
    }

    const categoryFromCustomFeatureTranslationConfigs =
      getCategoryFromCustomFeatureTranslationConfigs(itemToFind) ??
      getCategoryFromInvalidItem(itemToFind);
    const featureDetails = getValues(dataPaths.featureDetails);
    return (
      featureDetails.find((featureDetail) =>
        featureDetail.items.find((item) =>
          featureDetailCompareWKDAOnly(item)(itemToFind),
        ),
      ) ?? categoryFromCustomFeatureTranslationConfigs
    );
  };

  const getFeatureDetailsCategoryPath = (
    categoryToFind: FeatureDetailCategory,
  ): FeaturePaths.FeatureDetailsCategoryPath | void => {
    const categories = getValues(dataPaths.featureDetails);
    const newItemCategoryIndex = categories.findIndex(
      (category) => category.id === categoryToFind.id,
    );
    if (newItemCategoryIndex === -1)
      return logError(
        'getFeatureDetailsCategoryPath',
        `can't find proper index of the new item`,
      );
    return `${dataPaths.featureDetails}.${newItemCategoryIndex}` as const;
  };

  const getFeatureDetailsCategoryItemsPath = (
    itemToFind: FeatureDetailItem,
  ): FeaturePaths.FeatureDetailsCategoryItemsPath | void => {
    const cachedCategoryItemsPath =
      FeatureDetailsItemPathsMap.get(itemToFind)?.categoryItemsPath;
    if (cachedCategoryItemsPath) {
      return cachedCategoryItemsPath;
    }

    const category = getFeatureDetailsCategoryByItem(itemToFind);
    if (!category)
      return logError(
        'getFeatureDetailsCategoryItemsPath',
        `can't get a category`,
      );
    const categoryPath = getFeatureDetailsCategoryPath(category);
    if (!categoryPath)
      return logError(
        'getFeatureDetailsCategoryItemsPath',
        `can't get a categoryPath`,
      );

    return `${categoryPath}.items` as const;
  };

  const getFeatureDetailsItemPath = (
    itemToFind: FeatureDetailItem,
  ): FeaturePaths.FeatureDetailsItemPath | void => {
    const cacheItemPath = FeatureDetailsItemPathsMap.get(itemToFind)?.itemPath;
    if (cacheItemPath) {
      return cacheItemPath;
    }

    const categoryItemsPath = getFeatureDetailsCategoryItemsPath(itemToFind);
    if (!categoryItemsPath)
      return logError(
        'getFeatureDetailsItemPath',
        `can't get a categoryItemsPath`,
      );
    const categoryItems = getValues(categoryItemsPath);
    const itemIdx = categoryItems.findIndex((item) =>
      featureDetailCompareWKDAOnly(item)(itemToFind),
    );
    if (itemIdx === -1)
      return logError('getFeatureDetailsItemPath', `can't get a itemIdx`);

    return `${categoryItemsPath}.${itemIdx}` as const;
  };

  const getFeatureDetailsTranslationsPathByItem = (
    itemToFind: FeatureDetailItem,
  ): FeaturePaths.FeatureDetailsItemTranslationsPath | void => {
    const itemPath = getFeatureDetailsItemPath(itemToFind);
    if (!itemPath)
      return logError(
        'getFeatureDetailsTranslationsPathByItem',
        `can't get a itemPath`,
      );

    return `${itemPath}.translations` as const;
  };

  const getFeatureDetailsTranslationWithIdxPathByItem = (
    itemToFind: FeatureDetailItem,
    locale: string,
  ): FeaturePaths.FeatureDetailsTranslationPath | void => {
    const translationsPath =
      getFeatureDetailsTranslationsPathByItem(itemToFind);
    if (!translationsPath)
      return logError(
        'getFeatureDetailsTranslationWithIdxPathByItem',
        `can't get a translationsPath`,
      );
    const translations = getValues(translationsPath);
    const translationsIdx = translations.findIndex(
      (translation) => translation.locale === locale,
    );
    if (translationsIdx === -1)
      return logError(
        'getFeatureDetailsTranslationWithIdxPathByItem',
        `can't find proper index of the translation`,
      );

    return `${translationsPath}.${translationsIdx}` as const;
  };

  const getCategoryByConfig = useCallback(
    (
      config: CustomFeatureTranslationConfig,
    ): FeatureDetailCategory | undefined => {
      const categories = getValues(dataPaths.featureDetails);

      const currentCategory = categories.find(
        (featureDetailCategory) =>
          config.category.id === featureDetailCategory.id,
      );

      if (!currentCategory) {
        logError(
          'getCategoryByConfig',
          `can't find a category with id ${config.category.id}`,
        );
        return;
      }

      return currentCategory;
    },
    [getValues],
  );

  const getItemByTranslationConfig = (
    config: CustomFeatureTranslationConfig,
  ): FeatureDetailItem | undefined => {
    const currentCategory = getCategoryByConfig(config);

    return currentCategory?.items.find(
      (featureDetailItem) =>
        featureDetailItem.group.name === config.featureGroup &&
        (featureDetailItem.subGroup?.name ?? null) === config.featureSubGroup &&
        JSON.stringify(config.translations) ===
          JSON.stringify(featureDetailItem.translations),
    );
  };

  const getItemByTranslationConfigNonTranslationCompare = useCallback(
    (config: CustomFeatureTranslationConfig): FeatureDetailItem | undefined => {
      const currentCategory = getCategoryByConfig(config);

      return currentCategory?.items.find(
        (featureDetailItem) =>
          featureDetailItem.group.name === config.featureGroup &&
          (featureDetailItem.subGroup?.name ?? null) === config.featureSubGroup,
      );
    },
    [getCategoryByConfig],
  );

  const getFieldStateByItem = (itemToFind: FeatureDetailItem) => {
    const featureDetailsPath = getFeatureDetailsItemPath(itemToFind);
    if (!featureDetailsPath) return;

    return getFieldState(featureDetailsPath);
  };

  const getFieldStateByConfig = (config: CustomFeatureTranslationConfig) => {
    const featureDetailItem = getItemByTranslationConfig(config);
    if (!featureDetailItem) return;
    const featureDetailsPath = getFeatureDetailsItemPath(featureDetailItem);
    if (!featureDetailsPath) return;

    return getFieldState(featureDetailsPath);
  };

  const isNewlyAddedEquipment = (
    itemToCheck: FeatureDetailItem,
  ): boolean | undefined => {
    const newItemFromMap = EQUIPMENT_NEWLY_ADDED_MAP.get(itemToCheck.id);
    if (newItemFromMap) return true;

    const itemPath = getFeatureDetailsItemPath(itemToCheck);

    if (!itemPath) return;

    const defaultItem = get<FeatureDetailItem | undefined>(
      formState.defaultValues,
      itemPath,
    );

    return defaultItem?.id !== itemToCheck.id;
  };

  return {
    getCategoryFromInvalidItem,
    getCategoryFromCustomFeatureTranslationConfigs,
    getFeatureDetailsCategoryByItem,
    getFeatureDetailsCategoryPath,
    getFeatureDetailsCategoryItemsPath,
    getFeatureDetailsItemPath,
    getFeatureDetailsTranslationsPathByItem,
    getFeatureDetailsTranslationWithIdxPathByItem,
    getFeatureDetailsItemFromFeatureTranslationConfig,
    getItemByTranslationConfig,
    getItemByTranslationConfigNonTranslationCompare,
    getCategoryByConfig,
    getFieldStateByConfig,
    getFieldStateByItem,
    isNewlyAddedEquipment,
  };
};
