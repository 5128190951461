import { useCallback, memo, useState } from 'react';
import { Form, Select } from 'antd';
import { usePageData } from '@hooks/usePageData';
import { prepareOptionList } from '@sections/General/CarData/prepareOptionList';
import { useAppForm } from '@hooks/useAppForm';
import { useAnalytics } from '@hooks/useAnalytics';
import { TRACKING_SECTION, TRACKING_SECTION_CATEGORY } from '@src/constants';
import { useWatch } from 'react-hook-form';

interface OptionType {
  disabled?: boolean;
  value: string | null;
  label: string;
  [name: string]: any;
}

export const Model = memo(function Model(): JSX.Element {
  const { data } = usePageData();
  const { setValue, control } = useAppForm();
  const track = useAnalytics();
  const modelOptions = data?.adMgmt?.dictionaries?.global?.modelOptions || [];
  const canEditVehicleFields =
    data?.adMgmt?.general?.restrictions?.canEditVehicleFields;
  const model = useWatch({ control, name: 'adMgmt.general.data.model' });
  const [oldValue, setOldValue] = useState(model);

  const handler = useCallback(
    (model: string) => {
      const label = data?.adMgmt?.dictionaries?.global?.modelOptions?.find(
        (option) => option.value === model,
      )?.label;
      setValue('adMgmt.general.data.model', model, { shouldDirty: true });
      if (label) {
        setValue('adMgmt.general.data.datModel', label, { shouldDirty: true });
      }
    },
    [setValue, data],
  );

  const handleBlur = useCallback(() => {
    oldValue !== model &&
      track(
        {
          eventType: 'edit',
          fieldId: 'Model',
          section: TRACKING_SECTION.CAR_DATA,
          sectionCategory: TRACKING_SECTION_CATEGORY.AH,
        },
        {
          value: model,
          oldValue: oldValue,
        },
      );
  }, [track, model, oldValue]);

  return (
    <Form.Item data-qa-selector="general-model" label="Model">
      <Select<string, OptionType>
        showSearch
        filterOption={(input, option) =>
          option?.label?.toLowerCase().includes(input.toLowerCase()) ?? false
        }
        data-qa-selector="general-model-select"
        value={model ?? ''}
        onChange={handler}
        disabled={!canEditVehicleFields}
        onBlur={handleBlur}
        onFocus={() => setOldValue(model)}
      >
        {prepareOptionList(modelOptions, 'value')}
      </Select>
    </Form.Item>
  );
});
