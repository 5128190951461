import { usePageData } from '@hooks/usePageData';
import { Subsection } from '@components/Subsection';
import { Descriptions } from 'antd';

export const AdditionalCarData = (): JSX.Element => {
  const { data } = usePageData();
  const { general } = data?.adMgmt || {};

  const items =
    general?.additionalVehicleData.map((item) => {
      return (
        <Descriptions.Item label={item.label} key={item.label}>
          {item.value}
        </Descriptions.Item>
      );
    }) ?? [];
  return (
    <Subsection
      title="Additional Car Data"
      foldable
      renderable={true}
      foldBtnQaSelector="additional-vehicle-info-button"
    >
      <Descriptions bordered column={1}>
        {items}
      </Descriptions>
    </Subsection>
  );
};
