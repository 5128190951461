// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WAg5aS2OU_1eMJccToIT {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  -webkit-transform: translate(-50%, -50%);\n          transform: translate(-50%, -50%);\n}\n.fd9nLMqo2mhoibqvJx3P div {\n  overflow: auto;\n  max-width: calc(100% - 5px);\n  max-height: calc(100vh - 55px - 40px);\n}\n", "",{"version":3,"sources":["webpack://./src/layout/styles.less"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EAEA,wCAAA;UAAA,gCAAA;AADF;AAIA;EAEI,cAAA;EAEA,2BAAA;EAEA,qCAAA;AALJ","sourcesContent":["@antVersion: antd-5-18-2;\n.loadingSpinner {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n\n  transform: translate(-50%, -50%);\n}\n\n.notification {\n  div {\n    overflow: auto;\n\n    max-width: calc(100% - 5px);\n\n    max-height: calc(\n      // 55px from notification config {top:55} in src/components/root and 40px is global offset top\n      100vh - 55px - 40px\n    );\n  }\n}\n\n@ant-prefix: antd-5-18-2;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingSpinner": "WAg5aS2OU_1eMJccToIT",
	"notification": "fd9nLMqo2mhoibqvJx3P"
};
export default ___CSS_LOADER_EXPORT___;
