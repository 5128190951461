import { Row, Col, Form, Button, Space, notification } from 'antd';
import { Subsection } from '@components/Subsection';
import { FormType } from '@src/layout';
import CheckboxControlled from '@components/formControlled/CheckboxControlled';
import { TRACKING_SECTION, TRACKING_SECTION_CATEGORY } from '@src/constants';
import { DamageItem } from './damageItem';
import { TextBox } from './textBox';
import { useAppForm } from '@hooks/useAppForm';
import { useWatch } from 'react-hook-form';
import { useEffect, useRef } from 'react';

import {
  ADMIN_REFURBISHMENTS_PAGE,
  PRODUCTION_ORDERS_PAGE,
} from '@src/constants';

const isProd = window?.__config?.APP_ENV === 'prod';

export const DamagesHistory = (): JSX.Element => {
  const { getValues, control } = useAppForm();
  const isMounted = useRef<boolean>(false);
  const [carAccidentDamages, stockNumber] = getValues([
    'adMgmt.general.data.carAccidentDamages',
    'adMgmt.general.data.stockNumber',
  ]);

  const damagesList = useWatch({
    name: `adMgmt.general.data.carAccidentDamages.accidentDamages`,
    control,
    disabled: !isMounted.current,
  });

  useEffect(() => {
    if (isMounted.current) {
      if (
        damagesList &&
        damagesList.filter((item) => item?.showToCustomer).length === 0 &&
        damagesList.filter((item) => item?.isRepaired).length === 0
      ) {
        notification.info({
          message: (
            <b>
              This unit has no selected active damages. Please, double check.
            </b>
          ),
          key: 'no_accidentDamages',
          duration: 10,
          placement: 'bottom',
        });
      }
    } else {
      isMounted.current = true;
    }
  }, [damagesList]);

  const adminCarDetailsLink = isProd
    ? `${ADMIN_REFURBISHMENTS_PAGE.PROD}${stockNumber}`
    : `${ADMIN_REFURBISHMENTS_PAGE.QA}${stockNumber}`;

  const productionOrderLink = isProd
    ? `${PRODUCTION_ORDERS_PAGE.PROD}${stockNumber}`
    : `${PRODUCTION_ORDERS_PAGE.QA}${stockNumber}`;

  const renderDamages = () => {
    const damages = carAccidentDamages?.accidentDamages ?? [];
    return damages.map((damage, index) => (
      <DamageItem
        key={damage?.id}
        index={index}
        data-qa-selector={`general-accident-damage-item-${index}`}
      />
    ));
  };

  const renderSummary = () =>
    damagesList
      .filter((item) => item?.showToCustomer)
      .map((item, index) => (
        <li
          key={item?.id}
          data-qa-selector={`general-accident-damage-summary-item-${index}`}
        >{`${item?.carPart} - ${
          item?.damageType === 'REPAINT' ? 'Repainted' : 'Repaired'
        }`}</li>
      ));

  return (
    <Subsection title="Accident Damage Data">
      <Row gutter={24}>
        <Col span={4}>
          <Form.Item data-qa-selector="general-accident-car">
            <CheckboxControlled<FormType>
              data-qa-selector="general-accident-car-checkbox"
              controllerProps={{
                name: 'adMgmt.general.data.carAttrAccidentBool',
              }}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.HISTORY_SERVICE,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Accident car',
              }}
            >
              Accident car
            </CheckboxControlled>
          </Form.Item>
        </Col>
      </Row>
      {!carAccidentDamages ||
      carAccidentDamages.accidentDamages.length === 0 ? (
        <></>
      ) : (
        <>
          <Row gutter={24}>
            <Col span={4}>
              <TextBox qaSelector={'general-accident-damage-area'}>
                <b>PA (Accident area):</b>
                <ul>
                  {carAccidentDamages.accidentArea.map((item, idx) => (
                    <li
                      data-qa-selector="general-accident-damage-area-item"
                      key={idx}
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              </TextBox>
            </Col>
            <Col span={6}>
              <TextBox qaSelector={'general-accident-damage-summary'}>
                <b>Summary:</b>
                <ul>{renderSummary()}</ul>
              </TextBox>
            </Col>
            <Col span={4}>
              <Space direction="horizontal">
                <Button
                  type="primary"
                  data-qa-selector="admin-car-details-link"
                  href={adminCarDetailsLink}
                  target="_blank"
                >
                  Admin - Car Details
                </Button>
                <Button
                  type="primary"
                  data-qa-selector="production-order-link"
                  href={productionOrderLink}
                  target="_blank"
                >
                  Production Order
                </Button>
              </Space>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={4}>Car Part</Col>
            <Col span={4}>Damage Type</Col>
            <Col span={6}>Attributes</Col>
            <Col span={3}>Show to Customer</Col>
            <Col span={3}>Repaired</Col>
          </Row>
          <br />
          {renderDamages()}
        </>
      )}
    </Subsection>
  );
};
