import { useWatch } from 'react-hook-form';
import { Form, Row, Col, Select, Typography } from 'antd';
import { useAppForm } from '@hooks/useAppForm';
import { Subsection } from '@components/Subsection';
import SelectControlled from '@components/formControlled/SelectControlled';
import { usePageData } from '@hooks/usePageData';
import { cleanNumberInput } from '@utils/validators';
import { OptionNumberItem, OptionStringItem } from '@gql_codegen/retail-types';
import InputNumberControlled from '@components/formControlled/InputNumberControlled';
import { TRACKING_SECTION, TRACKING_SECTION_CATEGORY } from '@src/constants';

const { Text } = Typography;

const EMISSION_STICKER_NAME = 'emissionSticker';

export const EmissionAndConsumption = (): JSX.Element => {
  const {
    formState: { errors },
    control,
  } = useAppForm();
  const generalErrors = errors?.adMgmt?.general?.data || {};
  const { data } = usePageData();
  const { general, dictionaries, country } = data?.adMgmt || {};
  const { restrictions } = general || {};
  const {
    fuelTypeOptions,
    emissionStickers,
    efficiencyClasses,
    emissionClasses,
    fieldLabels,
  } = dictionaries?.global || {};
  const isDE = country === 'DE';
  const {
    canEditVehicleFields,
    canSeeEmissionSticker,
    canEditEmissionSticker,
  } = restrictions || {};

  const [kw, ccm] = useWatch({
    control,
    name: ['adMgmt.general.data.kw', 'adMgmt.general.data.ccm'],
  });

  const prepareOptionList = (
    options: OptionNumberItem[] | OptionStringItem[] | undefined,
  ) => {
    if (!options) {
      return [];
    }
    return options.map((option, index) => {
      return (
        <Select.Option key={index} value={option.value}>
          {option.label}
        </Select.Option>
      );
    });
  };

  const emissionStickerLabel = fieldLabels?.find(
    ({ name }) => name === EMISSION_STICKER_NAME,
  )?.label;

  return (
    <Subsection title="Emission And Consumption">
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item
            data-qa-selector="general-fuel-type"
            label="Fuel Type"
            required
            validateStatus={generalErrors?.fuelType ? 'error' : 'success'}
            help={
              <div data-qa-selector="general-fuel-type-explain-error">
                {generalErrors?.fuelType?.message}
              </div>
            }
          >
            <SelectControlled
              data-qa-selector="general-fuel-type-select"
              controllerProps={{
                name: 'adMgmt.general.data.fuelType',
              }}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.EMISSION_CONSUMPTION,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Fuel Type',
              }}
            >
              {prepareOptionList(fuelTypeOptions)}
            </SelectControlled>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            data-qa-selector="general-efficiency-class"
            label="Efficiency Class"
          >
            <SelectControlled
              data-qa-selector="general-efficiency-class-input"
              controllerProps={{
                name: 'adMgmt.general.data.energyEfficiencyClass',
              }}
              disabled={!isDE}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.EMISSION_CONSUMPTION,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Efficiency Class',
              }}
            >
              {prepareOptionList(efficiencyClasses)}
            </SelectControlled>
          </Form.Item>
        </Col>
        {canSeeEmissionSticker ? (
          <Col span={6}>
            <Form.Item
              data-qa-selector="general-emission-sticker"
              label={emissionStickerLabel || 'Emission Sticker'}
            >
              <SelectControlled
                data-qa-selector="general-emission-sticker-input"
                controllerProps={{
                  name: 'adMgmt.general.data.emissionSticker',
                }}
                disabled={!canEditEmissionSticker ?? true}
                tracking={{
                  eventCategory: 'modify',
                  section: TRACKING_SECTION.EMISSION_CONSUMPTION,
                  sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                  fieldId: 'Emission Sticker',
                }}
              >
                {prepareOptionList(emissionStickers)}
              </SelectControlled>
            </Form.Item>
          </Col>
        ) : null}

        <Col span={6}>
          <Form.Item
            data-qa-selector="general-emission-class"
            label="Emission Class"
          >
            <SelectControlled
              data-qa-selector="general-emission-class-input"
              controllerProps={{
                name: 'adMgmt.general.data.emissionClass',
              }}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.EMISSION_CONSUMPTION,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Emission Class',
              }}
            >
              {prepareOptionList(emissionClasses)}
            </SelectControlled>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item
            data-qa-selector="general-city"
            label="City"
            validateStatus={
              generalErrors?.fuelConsumption?.city ? 'error' : 'success'
            }
            help={
              <div data-qa-selector="general-city-explain-error">
                {generalErrors?.fuelConsumption?.city?.message}
              </div>
            }
          >
            <InputNumberControlled
              data-qa-selector="general-city-input"
              controllerProps={{
                name: 'adMgmt.general.data.fuelConsumption.city',
              }}
              maxLength={8}
              min={0}
              onKeyDown={cleanNumberInput}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.EMISSION_CONSUMPTION,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'City',
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            data-qa-selector="general-highway"
            label="Highway"
            validateStatus={
              generalErrors?.fuelConsumption?.highway ? 'error' : 'success'
            }
            help={
              <div data-qa-selector="general-highway-explain-error">
                {generalErrors?.fuelConsumption?.highway?.message}
              </div>
            }
          >
            <InputNumberControlled
              data-qa-selector="general-highway-input"
              controllerProps={{
                name: 'adMgmt.general.data.fuelConsumption.highway',
              }}
              maxLength={8}
              min={0}
              onKeyDown={cleanNumberInput}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.EMISSION_CONSUMPTION,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Highway',
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            data-qa-selector="general-combined"
            label="Combined"
            validateStatus={
              generalErrors?.fuelConsumption?.combined ? 'error' : 'success'
            }
            help={
              <div data-qa-selector="general-combined-explain-error">
                {generalErrors?.fuelConsumption?.combined?.message}
              </div>
            }
          >
            <InputNumberControlled
              data-qa-selector="general-combined-input"
              controllerProps={{
                name: 'adMgmt.general.data.fuelConsumption.combined',
              }}
              maxLength={8}
              min={0}
              onKeyDown={cleanNumberInput}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.EMISSION_CONSUMPTION,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Combined',
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            data-qa-selector="general-fuel-consumption"
            label="Fuel Consumption"
          >
            <InputNumberControlled
              data-qa-selector="general-fuel-consumption-input"
              controllerProps={{
                name: 'adMgmt.general.data.fuelConsumption.common',
              }}
              maxLength={8}
              disabled
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.EMISSION_CONSUMPTION,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Fuel Consumption',
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item
            data-qa-selector="general-c02"
            label="CO2"
            required
            validateStatus={generalErrors?.co2Value ? 'error' : 'success'}
            help={
              <div data-qa-selector="general-c02-explain-error">
                {generalErrors?.co2Value?.message}
              </div>
            }
          >
            <InputNumberControlled
              data-qa-selector="general-c02-input"
              controllerProps={{
                name: 'adMgmt.general.data.co2Value',
              }}
              maxLength={8}
              min={0}
              onKeyDown={cleanNumberInput}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.EMISSION_CONSUMPTION,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'CO2',
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item data-qa-selector="general-kw" label="KW">
            <InputNumberControlled
              step={1}
              min={0}
              max={1000}
              precision={0}
              data-qa-selector="general-kw-input"
              controllerProps={{
                name: 'adMgmt.general.data.kw',
              }}
              disabled={!canEditVehicleFields}
              style={{ marginBottom: 5 }}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.EMISSION_CONSUMPTION,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'KW',
              }}
            />
            {kw === null && general?.data?.kw ? (
              <Text type="warning" data-qa-selector="general-kw-description">
                No empty value allowed: &quot;{general?.data?.kw}&quot; will be
                restored
              </Text>
            ) : null}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item data-qa-selector="general-ccm" label="CCM">
            <InputNumberControlled
              data-qa-selector="general-ccm-input"
              controllerProps={{
                name: 'adMgmt.general.data.ccm',
              }}
              disabled={!canEditVehicleFields}
              style={{ marginBottom: 5 }}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.EMISSION_CONSUMPTION,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'CCM',
              }}
            />
            {ccm === null && general?.data?.ccm ? (
              <Text type="warning" data-qa-selector="general-ccm-description">
                No empty value allowed: &quot;{general?.data?.ccm}&quot; will be
                restored
              </Text>
            ) : null}
          </Form.Item>
        </Col>
      </Row>
    </Subsection>
  );
};
