import { Row, Col } from 'antd';
import { ImperfectionsField } from './ImperfectionsField';

export const Imperfections = (): JSX.Element => {
  return (
    <Row>
      <Col span={24}>
        <div data-qa-selector="imperfectionField">
          <ImperfectionsField />
        </div>
      </Col>
    </Row>
  );
};
