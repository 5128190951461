import cn from './styles.less';

export const Panel = (props: {
  title: string;
  'data-qa-selector': string;
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <div className={cn.panel} data-qa-selector={props['data-qa-selector']}>
      <div className={cn.panelHeading}>{props.title}</div>
      <div className={cn.panelBody}>{props.children}</div>
    </div>
  );
};
