const isUrl = (value: string | undefined) => {
  if (!value) {
    return false;
  }
  try {
    const url = new URL(value);

    return !(url.protocol !== 'http:' && url.protocol !== 'https:');
  } catch (e) {
    return false;
  }
};

export default isUrl;
