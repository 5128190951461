import { useEffect, useRef } from 'react';
import { Row, Col, Form } from 'antd';
import { Subsection } from '@components/Subsection';
import InputControlled from '@components/formControlled/InputControlled';
import { TRACKING_SECTION, TRACKING_SECTION_CATEGORY } from '@src/constants';
import { useAppForm } from '@hooks/useAppForm';
import styles from './styles.less';

export const UniqueSellingPropositions = () => {
  const {
    formState: { errors: formErrors },
  } = useAppForm();
  const errorRef = useRef<HTMLDivElement>(null);
  const errors = formErrors?.adMgmt?.general?.data?.uniqueSellingPropositions;

  useEffect(() => {
    if (errors?.message) {
      errorRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [errors]);

  return (
    <Subsection
      title="USPs"
      data-qa-selector="section-unique-selling-propositions"
    >
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item
            label="USP1"
            data-qa-selector="usp1"
            validateStatus={errors?.usp1?.message ? 'error' : 'success'}
            help={
              <div data-qa-selector="usp1-error">{errors?.usp1?.message}</div>
            }
          >
            <InputControlled
              controllerProps={{
                name: 'adMgmt.general.data.uniqueSellingPropositions.usp1',
              }}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.GENERAL,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Usp1',
              }}
              placeholder="USP1"
              showCount={true}
              maxLength={25}
              format={(value) => value.trim()}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="USP2"
            data-qa-selector="usp2"
            validateStatus={errors?.usp2?.message ? 'error' : 'success'}
            help={
              <div data-qa-selector="usp2-error">{errors?.usp2?.message}</div>
            }
          >
            <InputControlled
              controllerProps={{
                name: 'adMgmt.general.data.uniqueSellingPropositions.usp2',
              }}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.GENERAL,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Usp2',
              }}
              placeholder="USP2"
              showCount={true}
              maxLength={25}
              format={(value) => value.trim()}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="USP3"
            data-qa-selector="usp3"
            validateStatus={errors?.usp3?.message ? 'error' : 'success'}
            help={
              <div data-qa-selector="usp3-error">{errors?.usp3?.message}</div>
            }
          >
            <InputControlled
              controllerProps={{
                name: 'adMgmt.general.data.uniqueSellingPropositions.usp3',
              }}
              tracking={{
                eventCategory: 'modify',
                section: TRACKING_SECTION.GENERAL,
                sectionCategory: TRACKING_SECTION_CATEGORY.AH,
                fieldId: 'Usp3',
              }}
              placeholder="USP3"
              showCount={true}
              maxLength={25}
              format={(value) => value.trim()}
            />
          </Form.Item>
        </Col>
      </Row>
      {errors?.message && (
        <Row gutter={24}>
          <Col span={6}>
            <div
              className={styles.error}
              ref={errorRef}
              data-qa-selector="usps-error"
            >
              {errors?.message}
            </div>
          </Col>
        </Row>
      )}
    </Subsection>
  );
};
