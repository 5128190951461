// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AsuEraQkkiGkA66RX51_ {\n  position: sticky;\n  z-index: 11;\n  top: 40px;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  padding: 12px 10px;\n  background-color: #ffffff;\n  -webkit-box-shadow: 0 8px 10px -10px #000000;\n          box-shadow: 0 8px 10px -10px #000000;\n}\n.NoeMTjYUstzxZvWZ_C_F,\n.Bc5lj_fZb7lyD673abC5,\n.QzL9pnaoHtT3z7bjOirA {\n  font-size: 22px;\n}\n.Bc5lj_fZb7lyD673abC5 {\n  padding: 0;\n  font-weight: 700;\n}\n.QzL9pnaoHtT3z7bjOirA {\n  text-transform: uppercase;\n  color: #000000;\n  font-weight: 700;\n}\n.qgew9zS7OwZwGCAEwTxU {\n  margin-right: 30px;\n}\n", "",{"version":3,"sources":["webpack://./src/sections/ActionBar/styles.less"],"names":[],"mappings":"AAGA;EACE,gBAAA;EACA,WAAA;EACA,SAAA;EAEA,yBAAA;MAAA,sBAAA;UAAA,mBAAA;EACA,yBAAA;MAAA,sBAAA;UAAA,8BAAA;EAEA,kBAAA;EAEA,yBAAA;EACA,4CAAA;UAAA,oCAAA;AALF;AAQA;;;EACE,eAAA;AAJF;AAOA;EAGE,UAAA;EAEA,gBAAA;AARF;AAWA;EAGE,yBAAA;EAEA,cAAA;EAEA,gBAAA;AAbF;AAgBA;EACE,kBAAA;AAdF","sourcesContent":["@antVersion: antd-5-18-2;\n@import 'src/styles/colors.less';\n\n.root {\n  position: sticky;\n  z-index: 11;\n  top: 40px;\n\n  align-items: center;\n  justify-content: space-between;\n\n  padding: 12px 10px;\n\n  background-color: @white;\n  box-shadow: 0 8px 10px -10px @black;\n}\n\n.bigfont {\n  font-size: 22px;\n}\n\n.backBtn {\n  &:extend(.bigfont);\n\n  padding: 0;\n\n  font-weight: 700;\n}\n\n.subTitle {\n  &:extend(.bigfont);\n\n  text-transform: uppercase;\n\n  color: @black;\n\n  font-weight: 700;\n}\n\n.spinerWrapper {\n  margin-right: 30px;\n}\n\n@ant-prefix: antd-5-18-2;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "AsuEraQkkiGkA66RX51_",
	"bigfont": "NoeMTjYUstzxZvWZ_C_F",
	"backBtn": "Bc5lj_fZb7lyD673abC5",
	"subTitle": "QzL9pnaoHtT3z7bjOirA",
	"spinerWrapper": "qgew9zS7OwZwGCAEwTxU"
};
export default ___CSS_LOADER_EXPORT___;
