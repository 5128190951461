// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UOf6zeHwG_IwR51VM90B {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n}\n.oqfJ1fHORVr97Iu8JS_6 {\n  background-color: #f3f5f7;\n}\n.BSWTvqaxbpYSoD6w43Rr {\n  cursor: inherit !important;\n}\n.BSWTvqaxbpYSoD6w43Rr span {\n  cursor: inherit;\n}\n.BSWTvqaxbpYSoD6w43Rr input[type='checkbox'] {\n  cursor: inherit;\n}\n", "",{"version":3,"sources":["webpack://./src/sections/Classifieds/AutoheroWKDA/Equipment/EquipmentHeader/AddEquipmentInput/styles.less"],"names":[],"mappings":"AACA;EACE,oBAAA;EAAA,oBAAA;EAAA,aAAA;EACA,yBAAA;MAAA,sBAAA;UAAA,8BAAA;AAAF;AAEE;EACE,yBAAA;AAAJ;AAIA;EACE,0BAAA;AAFF;AACA;EAII,eAAA;AAFJ;AAFA;EAQI,eAAA;AAHJ","sourcesContent":["@antVersion: antd-5-18-2;\n.dropdownElem {\n  display: flex;\n  justify-content: space-between;\n\n  &Darker {\n    background-color: #f3f5f7;\n  }\n}\n\n.checkboxCursor {\n  cursor: inherit !important;\n\n  span {\n    cursor: inherit;\n  }\n\n  input[type='checkbox'] {\n    cursor: inherit;\n  }\n}\n\n@ant-prefix: antd-5-18-2;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownElem": "UOf6zeHwG_IwR51VM90B",
	"dropdownElemDarker": "oqfJ1fHORVr97Iu8JS_6",
	"checkboxCursor": "BSWTvqaxbpYSoD6w43Rr"
};
export default ___CSS_LOADER_EXPORT___;
