import { OptionNumberItem, OptionStringItem } from '@gql_codegen/retail-types';
import { Select } from 'antd';

export const prepareOptionList = (
  options: OptionNumberItem[] | OptionStringItem[] | undefined,
  field: keyof OptionStringItem = 'label',
) => {
  if (!options) {
    return [];
  }
  return options.map(
    (option: OptionNumberItem | OptionStringItem, index: number) => {
      return (
        <Select.Option
          key={index}
          value={option.value}
          label={option[field]}
          data-qa-value={option.value}
        >
          {option[field]}
        </Select.Option>
      );
    },
  );
};
