const getCookieValue = (cookieName: string): string | null => {
  return (
    document.cookie
      ?.split('; ')
      ?.find((row) => row.startsWith(`${cookieName}=`))
      ?.split('=')[1] ?? null
  );
};

export default getCookieValue;
