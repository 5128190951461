// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PKIgvUewmIYAcdB0FHNA {\n  display: inline-block;\n  height: 24px;\n  color: #2780e3;\n}\n.PKIgvUewmIYAcdB0FHNA:hover {\n  color: #1862b5;\n}\n", "",{"version":3,"sources":["webpack://./src/sections/General/VehicleHistoryAndService/CarHistoryUrl/link.styles.less"],"names":[],"mappings":"AAGA;EACE,qBAAA;EAEA,YAAA;EAEA,cAAA;AAJF;AAME;EACE,cAAA;AAJJ","sourcesContent":["@antVersion: antd-5-18-2;\n@import 'src/styles/colors.less';\n\n.link {\n  display: inline-block;\n\n  height: 24px;\n\n  color: @link-secondary-color;\n\n  &:hover {\n    color: @link-secondary-hover-color;\n  }\n}\n\n@ant-prefix: antd-5-18-2;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "PKIgvUewmIYAcdB0FHNA"
};
export default ___CSS_LOADER_EXPORT___;
