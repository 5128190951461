import {
  CustomFeatureTranslationConfig,
  FeatureDetailItem,
} from '@gql_codegen/retail-types';
import { retailKibanaLogger } from '@utils/logger';
import { SelectProps } from 'antd';
import { RcFile } from 'antd/lib/upload';

export type EquipmentFilters = { text: string; value: string };

export const logError = (module: string, ...rest: unknown[]) => {
  retailKibanaLogger.warn(
    `AutoheroWKDA::EquipmentNew::${module} => ${JSON.stringify(rest)}`,
  );
  return console.error(`AutoheroWKDA::EquipmentNew::${module} -> `, ...rest);
};
export const logInfo = (module: string, ...rest: unknown[]) => {
  return console.info(`AutoheroWKDA::EquipmentNew::${module} -> `, ...rest);
};

type featureDetailCompareFn = (
  firstItem: FeatureDetailItem,
) => (secondItem: FeatureDetailItem) => boolean;
export const featureDetailCompare: featureDetailCompareFn =
  (firstItem) => (secondItem) => {
    const checks: boolean[] = [
      //group check
      secondItem.group.name === firstItem.group.name,
    ];

    if (secondItem.subGroup && firstItem.subGroup) {
      //subgroup check
      checks.push(firstItem.subGroup.name === secondItem.subGroup.name);
    }

    return checks.every(Boolean);
  };
export const featureDetailCompareWKDAOnly: featureDetailCompareFn =
  (firstItem) => (secondItem) => {
    if (firstItem.id && secondItem.id) {
      return firstItem.id === secondItem.id;
    }

    const checks: boolean[] = [
      //translations check
      firstItem.translations.some((featureDetailTranslation) =>
        secondItem.translations.some(
          (secondFeatureDetailTranslation) =>
            featureDetailTranslation.text ===
              secondFeatureDetailTranslation.text &&
            featureDetailTranslation.locale ===
              secondFeatureDetailTranslation.locale,
        ),
      ),
      featureDetailCompare(firstItem)(secondItem),
    ];
    return checks.every(Boolean);
  };

export const featureDetailCompareWithTranslationConfig =
  (config: CustomFeatureTranslationConfig) =>
  (featureDetailItem: FeatureDetailItem): boolean => {
    const checks: boolean[] = [
      //group check
      featureDetailItem.group.name === config.featureGroup,
      //translations check
      featureDetailItem.translations.some((featureDetailTranslation) =>
        config.translations.some(
          (translationConfig) =>
            featureDetailTranslation.text === translationConfig.text &&
            featureDetailTranslation.locale === translationConfig.locale,
        ),
      ),
    ];

    if (featureDetailItem.subGroup && config.featureSubGroup) {
      //subgroup check
      checks.push(featureDetailItem.subGroup.name === config.featureSubGroup);
    }

    return checks.every(Boolean);
  };

type TOption = { group: string; subgroup: string | null };

const getWhitespacesToAnyCharRegExp = (inputString: string): RegExp =>
  new RegExp(inputString.replace(/\s/gim, '(\\s|\\W)'), 'gmi');

const optionsFilter: SelectProps['filterOption'] = (
  inputValue,
  option,
): boolean => {
  const label = option?.label?.toString() ?? '';
  const inputRegexp = getWhitespacesToAnyCharRegExp(inputValue);

  return inputRegexp.test(label);
};

export const equipmentHelpers = {
  separator: '__',
  convertOptionToValue({ group, subgroup }: TOption): string {
    return `${group}${this.separator}${subgroup !== null ? subgroup : ''}`;
  },
  convertValueToOptions(value: string): TOption {
    const [group, subgroup = null] = value.split(this.separator);
    if (typeof group !== 'string')
      throw new Error(
        `AutoheroWKDA::Equipment::utils::convertValueToOptions -> incorrect value: ${value}`,
      );
    return { group, subgroup: subgroup === '' ? null : subgroup };
  },
  generateLabel(item: CustomFeatureTranslationConfig): string {
    return `${item.featureGroup} ${item.featureSubGroup} ${item.translations
      .map((translation) => translation.text)
      .join(' ')} ${item.category.name}`;
  },
  optionsFilter,
  getWhitespacesToAnyCharRegExp,
};

export const getEquipmentCSV = (file: RcFile | undefined): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader?.readAsText(file as Blob);
    reader.addEventListener('load', (event) => {
      const csv = event?.target?.result;
      if (typeof csv !== 'string') return reject('csv is not a string');
      resolve(csv);
    });
    reader.addEventListener('error', (error) => {
      reject(error);
    });
  });
