// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cD7jzN3ohhEub0SJqzDl {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: distribute;\n      justify-content: space-around;\n  max-width: 200px;\n}\n.Nw7GPDUZ3BX8WrtU5zQQ {\n  word-break: break-all;\n}\n.V8MIYOmHseSuBUFNLT6_ {\n  margin-right: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/sections/General/VehicleHistoryAndService/ServiceHistory/ServiceHistoryTable/styles.less"],"names":[],"mappings":"AACA;EACE,oBAAA;EAAA,oBAAA;EAAA,aAAA;EACA,yBAAA;MAAA,6BAAA;EAEA,gBAAA;AADF;AAIA;EACE,qBAAA;AAFF;AAKA;EACE,kBAAA;AAHF","sourcesContent":["@antVersion: antd-5-18-2;\n.actionButtons {\n  display: flex;\n  justify-content: space-around;\n\n  max-width: 200px;\n}\n\n.tableColumnWordBreak {\n  word-break: break-all;\n}\n\n.editButton {\n  margin-right: 10px;\n}\n\n@ant-prefix: antd-5-18-2;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionButtons": "cD7jzN3ohhEub0SJqzDl",
	"tableColumnWordBreak": "Nw7GPDUZ3BX8WrtU5zQQ",
	"editButton": "V8MIYOmHseSuBUFNLT6_"
};
export default ___CSS_LOADER_EXPORT___;
