// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eu74gEtfq9K_A6FSTKW4 {\n  font-size: 20px;\n}\n.yugXAl3jFz70BMpc4ixk {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  margin-left: 16px;\n}\n.yugXAl3jFz70BMpc4ixk button {\n  min-width: 120px;\n  margin-bottom: 16px;\n  margin-left: auto;\n}\n.yugXAl3jFz70BMpc4ixk button:last-child {\n  margin-bottom: 0;\n}\n.e8aTDP99TX9MyQi88skg {\n  width: 250px;\n  min-height: 150px;\n}\n.P6DzwmHMeRbUphJwJg5Q {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n", "",{"version":3,"sources":["webpack://./src/sections/Classifieds/AutoheroWKDA/Imperfections/ImperfectionsField/ImperfectionItemWrapper/styles.less"],"names":[],"mappings":"AACA;EACE,eAAA;AAAF;AAGA;EACE,oBAAA;EAAA,oBAAA;EAAA,aAAA;EACA,4BAAA;EAAA,6BAAA;MAAA,0BAAA;UAAA,sBAAA;EAEA,iBAAA;AAFF;AAFA;EAOI,gBAAA;EAEA,mBAAA;EACA,iBAAA;AAHJ;AAKI;EACE,gBAAA;AAHN;AAQA;EACE,YAAA;EAEA,iBAAA;AAPF;AAUA;EACE,oBAAA;EAAA,oBAAA;EAAA,aAAA;AARF","sourcesContent":["@antVersion: antd-5-18-2;\n.imperfectionInfo {\n  font-size: 20px;\n}\n\n.actions {\n  display: flex;\n  flex-direction: column;\n\n  margin-left: 16px;\n\n  button {\n    min-width: 120px;\n\n    margin-bottom: 16px;\n    margin-left: auto;\n\n    &:last-child {\n      margin-bottom: 0;\n    }\n  }\n}\n\n.tagView {\n  width: 250px;\n\n  min-height: 150px;\n}\n\n.right {\n  display: flex;\n}\n\n@ant-prefix: antd-5-18-2;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imperfectionInfo": "eu74gEtfq9K_A6FSTKW4",
	"actions": "yugXAl3jFz70BMpc4ixk",
	"tagView": "e8aTDP99TX9MyQi88skg",
	"right": "P6DzwmHMeRbUphJwJg5Q"
};
export default ___CSS_LOADER_EXPORT___;
