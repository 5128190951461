import {
  LINKS_IDS,
  AUTOHERO_ORIGINS_MAP,
  EXTERNAL_URLS_ENV,
} from '@src/constants';
import { formatDateTime } from '@utils/date';

export const renderTitle = ({ key, title }: { key: string; title: string }) => {
  return <b data-qa-selector={`${key}-header`}>{title}</b>;
};

export const getFullExternalUrl = (link: { title: string; url: string }) => {
  const hostname = global.location.hostname; // TODO in future pass ENV variable on build stage
  let ENV: keyof typeof EXTERNAL_URLS_ENV = 'qa';

  if (
    hostname &&
    (hostname === EXTERNAL_URLS_ENV.localhost ||
      hostname.split('.')[1] === EXTERNAL_URLS_ENV.qa)
  ) {
    ENV = EXTERNAL_URLS_ENV.qa;
  } else {
    ENV = EXTERNAL_URLS_ENV.prod;
  }

  const { title, url } = link;
  let searchParam = '';

  if (title === LINKS_IDS.mobilede || title === LINKS_IDS.wkda) {
    return url;
  }

  let origin;

  if (title === LINKS_IDS.autohero) {
    origin = AUTOHERO_ORIGINS_MAP[ENV];
    searchParam = ENV === 'qa' ? '?auth=yes' : ''; // workaraund to make autohero links work on QA
  }

  if (!origin) {
    return null;
  }

  return origin + url + searchParam;
};

export const renderDate = (date: string | null): string | null => {
  if (!date) {
    return null;
  }
  return formatDateTime(date);
};
