import { Image } from '@gql_codegen/retail-types';
import { usePageData } from '@hooks/usePageData';
import { Empty } from 'antd';
import { ImageSlider } from './imageSlider';

export const ImagesPage = () => {
  const { data } = usePageData();
  const imagesData = data?.adMgmt.autoheroAndWkda.media.data?.images;
  if (!imagesData) return <></>;

  const images = Object.values(imagesData)
    .filter((it) => typeof it !== 'string')
    .flat() as Image[];

  if (images.length === 0)
    return <Empty description="No images :(" style={{ height: '100vh' }} />;

  return <ImageSlider images={images} />;
};
