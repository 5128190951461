import jwtDecode from 'jwt-decode';
import Analytics, { AnalyticsPlugin } from 'analytics';

import { snowflake } from './utils';
import { appAnalytics } from './analytics';
import getCookieValue from '@utils/getCookieValue';
import { LOGGER } from './constants';
import { AnalyticsPluginMethodArgs, TokenInfo } from './types';

export const AD_MGMT_PLUGIN_NAME = 'AD-MGMT-analytics';

export const adMgmtPlugin = (): AnalyticsPlugin => {
  return {
    name: AD_MGMT_PLUGIN_NAME,
    track: ({ payload, ...rest }: AnalyticsPluginMethodArgs) => {
      LOGGER.BASE &&
        console.log(
          `%c EVENT_TYPE/${payload.event} --- ${payload.properties.fieldId}`,
          'color: #FF6766; background-color: #000; font-weight: bolder; font-size: 12px;',
          '\n fieldId:',
          payload.properties.fieldId,
          '\n payload:',
          payload,
          '\n rest:',
          rest,
        );

      const token = getCookieValue('t');
      if (!token) {
        return;
      }
      const tokenData: TokenInfo = jwtDecode(token);

      const defaultEventType = payload?.event;
      const paths = new URL(window.location.href).pathname.match(/[^/]+/g);
      const defaultAdId = (paths?.[2] || '') as string;

      appAnalytics.addEvent({
        id: snowflake.generate(),
        userId: tokenData?.user_uuid,
        adId: payload?.properties?.adId || defaultAdId,
        eventType: payload?.properties?.eventType || defaultEventType,
        adCountry: payload?.properties?.adCountry,
        fieldId: payload?.properties?.fieldId ?? '',
        meta: payload?.properties?.meta,
        section: payload?.properties?.section,
        sectionCategory: payload?.properties?.sectionCategory,
        stockNumber: payload?.properties?.stockNumber,
        timeStamp: payload.meta.ts,
      });
    },
  };
};

export const analytics = Analytics({
  app: 'ad-mgmt',
  debug: true,
  version: __PACKAGE_VERSION__,
  plugins: [adMgmtPlugin()],
});
