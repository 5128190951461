import { usePageData } from '@hooks/usePageData';
import { Form, Col } from 'antd';
import SelectControlled from '@components/formControlled/SelectControlled';
import { filterOptionsHandler } from '@sections/General/CarData/utils/filterOptionsHandler';
import { useAppForm } from '@hooks/useAppForm';
import { TRACKING_SECTION, TRACKING_SECTION_CATEGORY } from '@src/constants';
import { prepareOptionList } from '@sections/General/CarData/prepareOptionList';
import InputControlled from '@components/formControlled/InputControlled';
import {
  GROSS_PRICE_PATH,
  PRICE_CHANGE_COMMENT,
  PRICE_CHANGE_REASON,
} from '@sections/General/CarData/GrossPrice/constants';
import { useEffect } from 'react';
import { useController } from 'react-hook-form';

export const ChangeReason = () => {
  const {
    formState: { errors },
    control,
    setValue,
  } = useAppForm();
  const { data } = usePageData();

  const {
    fieldState: { isDirty: isGrossPriceChanged },
  } = useController({
    control,
    name: GROSS_PRICE_PATH,
  });

  const changeReasonOptions =
    data?.adMgmt.dictionaries.global.grossPriceChangeReasons;

  useEffect(() => {
    if (!isGrossPriceChanged) {
      setValue(PRICE_CHANGE_REASON, undefined);
      setValue(PRICE_CHANGE_COMMENT, undefined);
    }
  }, [setValue, isGrossPriceChanged]);

  return (
    <>
      <Col span={6}>
        <Form.Item
          htmlFor="general-priceChangeReason-input"
          label="Price Change Reason"
          required
          validateStatus={errors?.priceChangeReason ? 'error' : 'success'}
          help={
            <div data-qa-selector="general-priceChangeReason-error">
              {errors?.priceChangeReason?.message}
            </div>
          }
        >
          <SelectControlled
            showSearch
            id="general-priceChangeReason-input"
            filterOption={filterOptionsHandler}
            data-qa-selector="general-priceChangeReason-select"
            placeholder="Select..."
            controllerProps={{
              control,
              name: PRICE_CHANGE_REASON,
            }}
            tracking={{
              eventCategory: 'modify',
              section: TRACKING_SECTION.CAR_DATA,
              sectionCategory: TRACKING_SECTION_CATEGORY.AH,
              fieldId: PRICE_CHANGE_REASON,
            }}
            disabled={!isGrossPriceChanged}
          >
            {prepareOptionList(changeReasonOptions)}
          </SelectControlled>
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          data-qa-selector="general-priceChangeComment"
          label="Reason Comment"
          required
          validateStatus={errors?.priceChangeComment ? 'error' : 'success'}
          help={
            <div data-qa-selector="general-priceChangeReason-error">
              {errors?.priceChangeComment?.message}
            </div>
          }
        >
          <InputControlled
            data-qa-selector="general-priceChangeComment"
            controllerProps={{
              control,
              name: PRICE_CHANGE_COMMENT,
            }}
            tracking={{
              eventCategory: 'modify',
              section: TRACKING_SECTION.CAR_DATA,
              sectionCategory: TRACKING_SECTION_CATEGORY.AH,
              fieldId: PRICE_CHANGE_COMMENT,
            }}
            maxLength={150}
            disabled={!isGrossPriceChanged}
          />
        </Form.Item>
      </Col>
      <Col span={6} />
    </>
  );
};
