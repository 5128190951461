import { CustomFeatureTranslationConfig } from '@gql_codegen/retail-types';
import { Tag } from 'antd';
import { PresetColorType } from 'antd/lib/_util/colors';
import classNames from 'classnames';
import styles from '../styles.less';
import { EQUIPMENT_CATEGORIES } from '../constants';

type EquipmentSelectOptionProps = CustomFeatureTranslationConfig;
const TagColors: Record<string, PresetColorType> = {
  [EQUIPMENT_CATEGORIES['highlights']]: 'gold',
  [EQUIPMENT_CATEGORIES['comfort']]: 'lime',
  [EQUIPMENT_CATEGORIES['multimedia']]: 'cyan',
  [EQUIPMENT_CATEGORIES['light and sight']]: 'blue',
  [EQUIPMENT_CATEGORIES['security']]: 'purple',
  [EQUIPMENT_CATEGORIES['additional']]: 'magenta',
};
export const EquipmentSelectOption = (props: EquipmentSelectOptionProps) => {
  return (
    <div className={styles.selectOption}>
      <span
        className={styles.selectOptionText}
        title={`${props.featureGroup} ${
          props.featureSubGroup ? `| ${props.featureSubGroup} ` : ''
        } ${Object.values(props.translations)
          .map((translation) => translation.text)
          .join(' • ')}`}
      >
        <span className={classNames(props.featureSubGroup && styles.grayText)}>
          {props.featureGroup}
        </span>
        {props.featureSubGroup && (
          <>
            <span> | </span>
            <span>{props.featureSubGroup}</span>
          </>
        )}
        {Object.values(props.translations).map(
          (translation, idx) =>
            translation.text.length > 0 && (
              <span
                key={`${props.featureGroup}_${props.featureSubGroup}_${translation.text}_${idx}`}
                className={styles.selectOptionTranslations}
              >
                <span className={styles.textSeparator}> • </span>
                <i>{translation.text}</i>
              </span>
            ),
        )}
      </span>
      <Tag color={TagColors[props.category.name] ?? ''}>
        {props.category.name}
      </Tag>
    </div>
  );
};
