import {
  FeatureDetailCategory,
  FeatureDetailItem,
  FeatureDetailTranslation,
} from '@gql_codegen/retail-types';
import { useAnalytics } from '@hooks/useAnalytics';
import { useAppForm } from '@hooks/useAppForm';
import { TRACKING_SECTION, TRACKING_SECTION_CATEGORY } from '@src/constants';
import { group } from '@utils/group';
import { notification } from 'antd';
import Papa from 'papaparse';
import { useMemo } from 'react';
import { SetValueConfig, useWatch } from 'react-hook-form';
import { v4 } from 'uuid';
import { Equipment } from '.';
import {
  EQUIPMENT_CATEGORIES_IDS,
  EquipmentCSVInput,
  EquipmentCSVRecord,
  FeaturePaths,
  INVALID_GROUPS,
  dataPaths,
} from './constants';
import { useFindEquipmentHelpers } from './useFindEquipmentHelpers';
import { featureDetailCompareWKDAOnly, logError } from './utils';

export const useEquipment = () => {
  const { setValue, getValues, control, trigger, getFieldState } = useAppForm();
  const track = useAnalytics();

  const featureDetailsData = useWatch({
    name: dataPaths.featureDetails,
    control,
  });

  const supportedLanguageLocales = useWatch({
    name: dataPaths.supportedLanguageLocales,
    control,
    exact: true,
  });

  const customFeatureTranslationConfigs = useWatch({
    name: dataPaths.customFeatureTranslationConfigs,
    control,
    exact: true,
  });

  const SECTIONS_ORDER = useMemo(
    () => featureDetailsData.map((fdItem) => fdItem.name),
    [featureDetailsData],
  );

  const locales: string[] = useMemo(() => {
    //check featureDetails first for locales
    return (
      featureDetailsData[0]?.items[0]?.translations.map(
        (translation) => translation.locale,
      ) ??
      //then check in the dictionary
      customFeatureTranslationConfigs?.[0]?.translations.map(
        (translation) => translation.locale,
      ) ??
      []
    );
  }, [featureDetailsData, customFeatureTranslationConfigs]);

  const uniqueClassifieds = useMemo((): string[] => {
    const classifieds = new Set<string>();

    customFeatureTranslationConfigs.map((config) => {
      config.classifieds.map((classified) => {
        classifieds.add(classified);
      });
    });

    return [...classifieds];
  }, [customFeatureTranslationConfigs]);

  const customFeatureTranslationConfigsSortedAndFiltered = useMemo(
    () =>
      [...customFeatureTranslationConfigs].sort((a, b) => a.order - b.order) ??
      [],
    [customFeatureTranslationConfigs],
  );

  const restrictions = useWatch({
    name: dataPaths.restrictions,
    control,
    exact: true,
  });

  const {
    getFeatureDetailsCategoryItemsPath,
    getFeatureDetailsCategoryByItem,
    getFeatureDetailsItemPath,
  } = useFindEquipmentHelpers();

  const handleRemoveAll = () => {
    if (!featureDetailsData) {
      return logError('handleRemoveAll', 'no equipment data found');
    }
    const clonedEquipmentData = JSON.parse(
      JSON.stringify(featureDetailsData),
    ) as typeof featureDetailsData;
    const hasDATEquipment = clonedEquipmentData.find((equipmentData) =>
      equipmentData.items.find((item) => item.uiFeatureDetailIdentifier),
    );

    if (hasDATEquipment) {
      const userConfirmsDeletion = confirm(
        `Are you sure you want to remove all? [DAT equipment will be removed]`,
      );
      if (!userConfirmsDeletion) return;
    }

    clonedEquipmentData.map((equipmentData) => {
      equipmentData.items = [];
      return equipmentData;
    });
    track(
      {
        eventType: 'click',
        eventCategory: 'modify',
        fieldId: 'Equipment_Remove_all',
        section: TRACKING_SECTION.EQUIPMENT,
        sectionCategory: TRACKING_SECTION_CATEGORY.AH,
      },
      clonedEquipmentData,
    );
    setValue(dataPaths.featureDetails, clonedEquipmentData, {
      shouldDirty: true,
    });
  };

  const handleEditItem = (
    itemToEdit: FeatureDetailItem,
    newValue: FeatureDetailItem,
    options?: SetValueConfig,
  ) => {
    const itemPath = getFeatureDetailsItemPath(itemToEdit);
    if (!itemPath)
      return logError('handleEditItem', `can't get a translationPath`);

    options = {
      shouldDirty: true,
      shouldTouch: false,
      ...options,
    };

    setValue(itemPath, newValue, options);
  };

  const editTranslationByPath = (
    path: FeaturePaths.FeatureDetailsTranslationPath,
    newTranslation: FeatureDetailTranslation,
    item: FeatureDetailItem,
    options?: SetValueConfig,
  ) => {
    options = {
      shouldDirty: true,
      ...options,
    };
    setValue(path, newTranslation, options);

    const { invalid } = getFieldState(path);
    if (invalid) {
      const categoryPath = getFeatureDetailsCategoryItemsPath(item);
      categoryPath && trigger(categoryPath);
    }
  };

  const validateDuplicateTranslation = (
    item: FeatureDetailItem,
    locale: string,
    newValue: string,
  ): boolean => {
    let isValid = true;
    const category = getFeatureDetailsCategoryByItem(item);
    if (!category || newValue === '') {
      return isValid;
    }
    const isUnclassifiedCase =
      category.id === EQUIPMENT_CATEGORIES_IDS['additional (unclassified)'] &&
      item.group.name !== INVALID_GROUPS.unclassified;

    if (isUnclassifiedCase)
      return validateUnclassifiedDuplicateTranslation(item, locale, newValue);

    const oldValue = item.translations.find((tr) => tr.locale === locale)?.text;
    if (oldValue && oldValue === newValue) return isValid;
    const categories = getValues(dataPaths.featureDetails);
    categories.forEach((cat) => {
      if (category.id !== cat.id) return;
      cat.items.forEach((it) => {
        if (
          item.subcategory?.id !== it.subcategory?.id ||
          (item.id && item.id === it.id)
        )
          return;
        it.translations.forEach((translation) => {
          if (translation.locale === locale && translation.text === newValue) {
            isValid = false;
          }
        });
      });
    });

    return isValid;
  };

  const validateUnclassifiedDuplicateTranslation = (
    item: FeatureDetailItem,
    locale: string,
    newValue: string,
  ): boolean => {
    let isValid = true;

    const itemNextConfig = customFeatureTranslationConfigs.find(
      (config) =>
        config.featureGroup === item.group.name &&
        config.featureSubGroup === (item.subGroup?.name ?? null),
    );
    if (!itemNextConfig) return isValid;

    const oldValue = item.translations.find((tr) => tr.locale === locale)?.text;
    if (oldValue && oldValue === newValue) return isValid;

    const categories = getValues(dataPaths.featureDetails);

    categories.forEach((cat) => {
      if (itemNextConfig.category.id !== cat.id) return;

      cat.items.forEach((it) => {
        if (
          itemNextConfig.subcategory?.id !== it.subcategory?.id ||
          (item.id && item.id === it.id)
        )
          return;
        it.translations.forEach((translation) => {
          if (translation.locale === locale && translation.text === newValue) {
            isValid = false;
          }
        });
      });
    });

    return isValid;
  };

  const handleAddItemWKDAOnly = (newItem: FeatureDetailItem) => {
    const newItemCategoryItemsPath =
      getFeatureDetailsCategoryItemsPath(newItem);
    if (!newItemCategoryItemsPath)
      return logError(
        'handleAddItemWKDAOnly',
        `can't find newItemCategoryItemsPath`,
      );

    const category = getFeatureDetailsCategoryByItem(newItem);

    if (!category)
      return logError('handleAddItemWKDAOnly', `can't get category`);

    const categoryItems = getValues(newItemCategoryItemsPath);

    categoryItems.push(newItem as (typeof categoryItems)[0]);

    const uniqueItems = categoryItems.filter(
      (categoryItem, idx, arr) =>
        idx ===
        arr.findIndex((arrItem) =>
          featureDetailCompareWKDAOnly(arrItem)(categoryItem),
        ),
    );

    setValue(newItemCategoryItemsPath, uniqueItems, {
      shouldDirty: true,
      shouldTouch: false,
    });
  };

  const handleDeleteItem = (itemToDelete: FeatureDetailItem) => {
    if (itemToDelete.uiFeatureDetailIdentifier) {
      //Check if equipment from DAT
      const userConfirmsDeletion = global.confirm(
        `Delete "${itemToDelete.name}"?`,
      );
      if (!userConfirmsDeletion) return;
    }

    const category = getFeatureDetailsCategoryByItem(itemToDelete);
    if (!category) return logError('handleDeleteItem', `can't get category`);

    const categoryItemsPath = getFeatureDetailsCategoryItemsPath(itemToDelete);
    if (!categoryItemsPath)
      return logError('handleDeleteItem', `can't get categoryItemsPath`);

    const categoryItems = getValues(categoryItemsPath);
    const filteredCategoryItems = categoryItems.filter(
      (categoryItem) =>
        !featureDetailCompareWKDAOnly(categoryItem)(itemToDelete),
    );

    setValue(categoryItemsPath, filteredCategoryItems, { shouldDirty: true });
    const { invalid } = getFieldState(categoryItemsPath);
    invalid && trigger(categoryItemsPath);
  };

  const handleDeleteCategory = (category: FeatureDetailCategory) => {
    const categories = getValues(dataPaths.featureDetails);
    const categoryToDeleteIndex = categories.findIndex(
      (cat) => cat.id === category.id,
    );

    if (categoryToDeleteIndex === -1)
      return logError(
        'handleDeleteCategory',
        `can't find proper index of the new item`,
      );

    const newItemCategoryItemsPath =
      `${dataPaths.featureDetails}.${categoryToDeleteIndex}.items` as const;
    const categoryItems = getValues(newItemCategoryItemsPath);
    const hasDATEquipment = categoryItems.find(
      (item) => item.uiFeatureDetailIdentifier,
    );

    if (hasDATEquipment) {
      const userConfirmsDeletion = confirm(
        `Are you sure you want to delete? [DAT equipment will be removed]`,
      );
      if (!userConfirmsDeletion) return;
    }

    track(
      {
        eventType: 'click',
        eventCategory: 'modify',
        fieldId: 'Equipment_Delete_equipment',
        section: TRACKING_SECTION.EQUIPMENT,
        sectionCategory: TRACKING_SECTION_CATEGORY.AH,
      },
      category,
    );
    setValue(newItemCategoryItemsPath, [], {
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const pushUniqueEquipmentFromCSV = (csvData: EquipmentCSVRecord[]) => {
    const hasDATEquipment = featureDetailsData.some((category) =>
      category.items.some(
        (item) => (item.uiFeatureDetailIdentifier?.length ?? 0) > 0,
      ),
    );

    if (hasDATEquipment) {
      const userConfirmsDeletion = confirm(
        `Are you sure you want to remove all? [DAT equipment will be removed]`,
      );
      if (!userConfirmsDeletion) return;
    }

    const csvEquipment: FeatureDetailItem[] = csvData.map<FeatureDetailItem>(
      (csvRecord) => ({
        id: v4(),
        group: { name: csvRecord.group, translatedName: csvRecord.group },
        subGroup:
          csvRecord.subGroup !== ''
            ? {
                name: csvRecord.subGroup,
                translatedName: csvRecord.subGroup,
              }
            : null,
        name: '',
        translations: csvRecord.translations,
        isOriginal: false,
        isNewTranslation: false,
        type: null,
        price: null,
        classifieds: [],
      }),
    );

    const equipmentWithCategory = csvEquipment
      .map((equipment) => {
        const newItemCategoryItemsPath =
          getFeatureDetailsCategoryItemsPath(equipment);
        if (!newItemCategoryItemsPath)
          return logError(
            'pushUniqueEquipmentFromCSV',
            `can't find newItemCategoryItemsPath`,
          );

        const category = getFeatureDetailsCategoryByItem(equipment);

        if (!category)
          return logError('pushUniqueEquipmentFromCSV', `can't get category`);

        return { equipment, category };
      })
      .filter(
        (
          equipment,
        ): equipment is {
          equipment: FeatureDetailItem;
          category: FeatureDetailCategory;
        } => typeof equipment === 'object',
      );

    const grouped = group(
      equipmentWithCategory,
      (equipment) => equipment.category.id,
    );

    const newFeatureDetail = featureDetailsData.map((featureDetailCategory) => {
      const newEquipments =
        grouped[featureDetailCategory.id]?.map((el) => ({ ...el.equipment })) ??
        [];

      const categoryCopy = structuredClone(featureDetailCategory);

      categoryCopy.items = newEquipments as typeof featureDetailCategory.items;
      return categoryCopy;
    });

    setValue(dataPaths.featureDetails, newFeatureDetail, {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true,
    });
  };

  const processCSV = (file: string) => {
    const data = Papa.parse<EquipmentCSVInput>(file, {
      delimiter: ',',
      header: true,
      skipEmptyLines: true,
      beforeFirstChunk: function (chunk) {
        const rows = chunk.split(/\r\n/);
        if (!rows.length) {
          return;
        }
        const headings = (rows[0] as string).split(',');
        const [group, subGroup, ...locales] = headings;
        if (group !== 'Feature Detail Group') {
          throw new Error(
            `Equipment CSV upload failed. Expected value to be "Feature Detail Group" but received "${group}"`,
          );
        } else {
          headings[0] = 'group';
        }
        if (subGroup !== 'Feature Detail Sub Group') {
          throw new Error(
            `Equipment CSV upload failed. Expected value to be "'Feature Detail Sub Group" but received "${subGroup}"`,
          );
        } else {
          headings[1] = 'subGroup';
        }

        if (
          !supportedLanguageLocales.some((supportedLocale) =>
            locales.includes(supportedLocale),
          )
        ) {
          throw new Error(
            `Equipment CSV upload failed. Unknown locale - "${locales.join(
              ',',
            )}"`,
          );
        }
        rows[0] = headings.join(',');
        return rows.join('\n');
      },
    });
    // Validation
    if (!data.meta.fields) throw new Error('Equipment CSV no fields found');

    const [, , ...locales] = data.meta.fields;
    // NOTE Prepare data structure
    const csvData = data.data.map<EquipmentCSVRecord>((item, index) => {
      locales.forEach((locale) => {
        const translation = item[locale];
        if (!translation) {
          throw new Error(
            `There is missing equipment translation for "${locale}" at record № ${
              index + 1
            }. Please add all missing translations and try again`,
          );
        }
      });

      const data: Equipment = {
        group: item.group,
        subGroup: item.subGroup,
        translations: locales.map((locale) => ({
          locale,
          text: item[locale] ?? '',
        })),
      };

      track(
        {
          eventType: 'click',
          eventCategory: 'modify',
          fieldId: 'Equipment_Upload_CSV',
          section: TRACKING_SECTION.EQUIPMENT,
          sectionCategory: TRACKING_SECTION_CATEGORY.AH,
        },
        data,
      );

      return data;
    });

    pushUniqueEquipmentFromCSV(csvData);

    // NOTE Success
    notification.success({
      message:
        'CSV equipment are transferred to BO successfully ✅ Please save.',
    });
  };

  const handleOpenMedia = () => {
    track(
      {
        eventType: 'click',
        eventCategory: 'modify',
        fieldId: 'Equipment_Open_media',
        section: TRACKING_SECTION.EQUIPMENT,
        sectionCategory: TRACKING_SECTION_CATEGORY.AH,
      },
      null,
    );
    open(`${location.pathname}/images`, '', 'popup');
  };

  return {
    processCSV,
    handleDeleteItem,
    handleEditItem,
    handleAddItemWKDAOnly,
    editTranslationByPath,
    validateDuplicateTranslation,
    handleOpenMedia,
    handleDeleteCategory,
    handleRemoveAll,
    featureDetailsData,
    restrictions,
    customFeatureTranslationConfigs,
    supportedLanguageLocales,
    locales,
    customFeatureTranslationConfigsSortedAndFiltered,
    SECTIONS_ORDER,
    uniqueClassifieds,
  };
};
