import axiosInstance from '@utils/axiosInstance';
import { useLayoutEffect, useState } from 'react';

type DebugData = {
  requestType: string;
  date: string;
  'x-b3-traceid': string;
  status: number;
  url: string;
  requestMethod: string;
  gqlQuery?: string;
};

export const useTraceIds = (): DebugData[] => {
  const [traceIds, setTraceId] = useState<DebugData[]>([]);

  useLayoutEffect(() => {
    const interceptorId = axiosInstance.interceptors.response.use(
      (response) => {
        const requestData = JSON.parse(response.config.data);
        const requestType = requestData.query ? 'GraphQL' : 'REST';
        const gqlQuery =
          requestType === 'GraphQL'
            ? requestData.query?.split('(')?.[0]
            : undefined;

        const newDebugObj: DebugData = {
          requestType,
          gqlQuery,
          date: response.headers['date'] ?? new Date().toJSON(),
          'x-b3-traceid': response.headers['x-b3-traceid'] ?? '',
          status: response.status,
          url: response.config.url ?? '',
          requestMethod: response.config.method ?? '',
        };
        setTraceId((prev) => [...prev, newDebugObj]);

        return response;
      },
    );
    return () => axiosInstance.interceptors.response.eject(interceptorId);
  }, []);

  return traceIds;
};
