import { Document } from '@src/types/serviceHistoryDocument';
import { Card, Col, Row } from 'antd';
import { useRef, useState } from 'react';
import { ReactImageGalleryItem } from 'react-image-gallery';
import { useResizeObserver } from 'usehooks-ts';
import { DocumentsModal } from '../DocumentsModal';
import { ImageThumbnail } from '../ImageThumbnail';
import { ServiceHistoryTableQaSelector } from '../ServiceHistoryTable';
import cn from './styles.less';

type ServiceHistoryDocumentsPreviewProps = {
  documents: Document[];
  maxHeight?: number;
};

export const ServiceHistoryDocumentsPreview = ({
  documents,
  maxHeight = 500,
}: ServiceHistoryDocumentsPreviewProps): JSX.Element => {
  const [selected, setSelected] = useState<number | undefined>(undefined);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const documentRef = useRef<HTMLElement>(document.documentElement);
  const itemRef = useRef<HTMLDivElement | null>(null);
  const overflowRef = useRef<HTMLDivElement | null>(null);
  const { height: tableCellHeaderHeight = 0 } = useResizeObserver({
    ref: {
      //TODO: this is really dirty hack, rewrite it to the refs when possible
      current: document.querySelector<HTMLTableCellElement>(
        `[data-qa-selector=${ServiceHistoryTableQaSelector}] th`,
      ),
    },
  });

  const mappedDocuments = documents.map(
    (document) =>
      ({
        original: document.url,
        originalAlt: document.id,
        thumbnail: document.thumbnailUrl,
        description: document.fileType,
      }) as ReactImageGalleryItem,
  );

  const onSelectDocument = (index: number) => {
    setScrollPosition(documentRef.current.scrollTop);
    setShowModal(true);
    setSelected(index);
  };

  const onModalClose = () => {
    documentRef.current.scrollTop = scrollPosition;
    setShowModal(false);
    setSelected(undefined);
  };

  return (
    <Row data-qa-selector="service-history-documents-preview">
      <DocumentsModal
        showModal={showModal}
        documentPosition={selected}
        onModalClose={onModalClose}
        mappedDocuments={mappedDocuments as ReactImageGalleryItem[]}
      />
      <Col span={24}>
        <Card
          type="inner"
          title={
            <div
              className={cn.title}
              style={{
                height: tableCellHeaderHeight + 16,
              }}
            >
              Documents
            </div>
          }
          size="small"
        >
          <div
            ref={overflowRef}
            className={cn.overflow}
            style={{
              height: maxHeight - tableCellHeaderHeight - 41,
            }}
            data-qa-selector="service-history-record-images-wrapper"
          >
            <Row gutter={[14, 14]} align="stretch" wrap={true}>
              {documents.map((document, index) => (
                <Col
                  ref={index === 0 ? itemRef : null}
                  span={12}
                  key={document.id}
                  onClick={() => onSelectDocument(index)}
                  data-qa-selector="service-history-record-image"
                  test-document-id={`id-${document.id}`}
                  className={cn.serviceHistoryRecordImage}
                >
                  <ImageThumbnail
                    document={document}
                    className={cn.thumbnail}
                  />
                </Col>
              ))}
            </Row>
          </div>
        </Card>
      </Col>
    </Row>
  );
};
