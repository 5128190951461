import { setItem, getItem, removeItem } from '@analytics/storage-utils';
import { UserInteractionEvent } from '@utils/analytics';

const STORE_NAME = '@analytics';
const STORE_VERSION = 'v1';
export const STORE_KEY = `${STORE_NAME}/${STORE_VERSION}`;
export const BACKUP_STORE_KEY = `${STORE_KEY}/backup`;

class StorageService {
  data: UserInteractionEvent[] = [];

  getAllEvents(): UserInteractionEvent[] {
    const value: UserInteractionEvent[] | undefined | null = getItem(STORE_KEY);
    if (value) {
      return value;
    } else {
      return this.data;
    }
  }

  removeAllEvents() {
    this.data = [];
    removeItem(STORE_KEY);
  }

  setAllEvents(data: UserInteractionEvent[]) {
    setItem(STORE_KEY, data);
    this.data = [];
  }

  addEvents(events: UserInteractionEvent[]) {
    const storage: UserInteractionEvent[] = this.getAllEvents();
    this.data = storage || this.data;
    this.data.push(...events);
    this.setAllEvents(this.data);
  }

  addEvent(event: UserInteractionEvent) {
    const storage: UserInteractionEvent[] = this.getAllEvents();
    this.data = storage || this.data;
    this.data.push(event);
    this.setAllEvents(this.data);
  }

  backupEvents() {
    const events = [...this.getBackupEvents(), ...this.getAllEvents()];
    setItem(BACKUP_STORE_KEY, events);
    this.removeAllEvents();

    return events;
  }

  getBackupEvents(): UserInteractionEvent[] {
    return getItem<UserInteractionEvent[]>(BACKUP_STORE_KEY) ?? [];
  }

  removeBackup() {
    removeItem(BACKUP_STORE_KEY);
  }
}

export const storageService = new StorageService();
