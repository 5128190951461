import { isValid, format } from 'date-fns';

export const CUSTOM_DATE_FORMATS = {
  DATE_TIME_FORMAT: 'dd/MM/yyyy HH:mm',
  DATE_FORMAT: 'dd/MM/yyyy',
  BACKEND_FORMAT: 'YYYY-MM-DD',
} as const;

export const parseDate = (date: string | Date): number | Date =>
  typeof date == 'string' ? Date.parse(date) : date;

type CustomFormatDateFn = (dateFormat: string) => (rawDate: string) => string;
export const customFormatDate: CustomFormatDateFn =
  (dateFormat) => (rawDate) => {
    const date = parseDate(rawDate);

    return isValid(date) ? format(date, dateFormat) : '';
  };

export const formatDateTime = customFormatDate(
  CUSTOM_DATE_FORMATS.DATE_TIME_FORMAT,
);
export const formatDate = customFormatDate(CUSTOM_DATE_FORMATS.DATE_FORMAT);
