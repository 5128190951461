import { Button } from 'antd';
import { useEquipment } from '../useEquipment';
import { logError } from '../utils';

type EquipmentTableDeleteEquipmentProps = {
  categoryName: string;
};
export const EquipmentTableDeleteEquipment = ({
  categoryName,
}: EquipmentTableDeleteEquipmentProps) => {
  const { handleDeleteCategory, featureDetailsData } = useEquipment();

  const equipmentCategory = featureDetailsData.find(
    (category) => category.name === categoryName,
  );
  if (!equipmentCategory) {
    logError(
      'EquipmentTableDeleteEquipment',
      'category not found',
      categoryName,
    );
    return <></>;
  }

  const hasItems = equipmentCategory.items.length > 0;
  return (
    <Button
      danger
      onClick={() => {
        handleDeleteCategory(equipmentCategory);
      }}
      data-qa-selector="delete-category"
      disabled={!hasItems}
    >
      Delete all category equipment
    </Button>
  );
};
